import React                     from "react";
import { FC }                    from "react";
import { useCallback }           from "react";
import { useState }              from "react";
import { Field }                 from "@relcu/form";
import { Form }                  from "@relcu/rc";
import { defaultMutators }       from "@relcu/ui";
import { format }                from "@relcu/ui";
import { CircularLoader }        from "@relcu/ui";
import { Ellipsis, ModalBody }   from "@relcu/ui";
import { ListItemText }          from "@relcu/ui";
import { ButtonVariants }        from "@relcu/ui";
import { Button }                from "@relcu/ui";
import { ListItemTitle }         from "@relcu/ui";
import { ListRow }               from "@relcu/ui";
import { Radio }                 from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { ModalProps }            from "@relcu/ui";
import { Modal }                 from "@relcu/ui";
import { EmptyList }             from "@relcu/ui";
import { useLazySummary }        from "@relcu/ui";
import { TITLE_FEE_SUM_FIELDS }  from "../../../offer.conditions";
import { GetTitleFeesBeta }      from "../../../Offer/__types__/GetTitleFeesBeta";
import { TitleFeeDialogClasses } from "./TitleFeeDialogClasses";
import "./title-fee-dialog.css";

export interface TitleFeeDialogProps extends Partial<ModalProps> {
  reFetch: (providerIds: string[]) => void;
  titleFees: GetTitleFeesBeta["titleFees"];
  loading: boolean;
  onApply: (titleId: string) => void;
  selectedId: string;
}

export const TitleFeeDialog: FC<TitleFeeDialogProps> = React.memo(function TitleFeeDialog(props) {
  const { onClose, reFetch, titleFees, loading, onApply, selectedId } = props;
  const [reFetchingId, setReFetchingId] = useState<string>();
  const calculateTitleFees = useLazySummary({ fields: TITLE_FEE_SUM_FIELDS });
  const reload = useCallback((providerId) => {
    setReFetchingId(providerId);
    reFetch([providerId]);
  }, [reFetch, setReFetchingId]);
  return <Modal
    title={"Title fees"}
    onClose={props.onClose}
    open={props.open}
    closeIcon={true}
    disableBackdropClick={false}
    className={TitleFeeDialogClasses.Modal}
    gap={"S"}
  >
    <ModalBody container direction={"column"} gap={"S"} className={TitleFeeDialogClasses.Container} flex={1}>
      <h3 className={TitleFeeDialogClasses.ContainerTitle}> Select a title company</h3>
      {
        !!titleFees?.length ?
          <Form
            initialValues={{ titleFee: selectedId }}
            onSubmit={({ titleFee }) => onApply(titleFee)}
            mutators={{
              ...defaultMutators
            }}>
            <Box container direction={"column"} gap={"XXS"} flex={1}>
              {
                titleFees.map((fee, k) => {
                  return <Field
                    type={"radio"}
                    value={fee?.providerId}
                    key={`tab-panel-item-${k}`}
                    name="titleFee">
                    {
                      ({ input, meta }) => {
                        return (
                          <Radio
                            disabled={!!fee.errorMessage}
                            {...input}
                            onChange={(e) => {
                              input.onChange(e);
                            }}
                            label={<ListRow className={TitleFeeDialogClasses.TitleFee} flex={1} gap={"XS"}>
                              <ListItemTitle title={fee.title} flexBasis={"20%"} flexShrink={2} flex={"1 2 20%"}/>
                              {loading && reFetchingId == fee.providerId ?
                                <Box container gap={"XXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                                  <CircularLoader/>
                                  <ListItemText text={"Loading..."}/>
                                </Box> :
                                fee.errorMessage ?
                                  <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                                    <ListItemText label={fee.errorMessage}
                                                  text={<Ellipsis
                                                    style={{ pointerEvents: "auto", color: "var(--color-error)" }}
                                                    from={90}>{fee.errorMessage}</Ellipsis>} tiny/>
                                    <Button style={{ pointerEvents: loading ? "none" : "auto" }}
                                            disabled={loading}
                                            variant={ButtonVariants.Ghost}
                                            onClick={() => {
                                              reload(fee.providerId);
                                            }}>
                                      Reload
                                    </Button>
                                  </Box>
                                  :
                                  <Box container gap={"XXXS"} flex={"1 0 15%"} justify={"end"} alignItems={"center"}>
                                    <ListItemText text={"Rate:"} tiny/>
                                    <ListItemText text={`$${format(calculateTitleFees(fee.fees), 2)}`}/>
                                  </Box>
                              }
                            </ListRow>}/>
                        );
                      }
                    }
                  </Field>;
                })
              }
            </Box>
            <Box container justify={"end"} gap={"XXS"} className={TitleFeeDialogClasses.Footer}>
              <Button variant={ButtonVariants.Outline} onClick={() => onClose()}>CANCEL</Button>
              <Button variant={ButtonVariants.Fill} type={"submit"}>APPLY</Button>
            </Box>
          </Form>
          :
          <Box flex={"1 1 auto"} justify={"center"}>
            <EmptyList icon={"pie_chart"} content={"Please update lead data and try again."}
                       title={"No matching rates"}
                       alignSelf={"center"}/>
          </Box>
      }
    </ModalBody>
  </Modal>;
});
