import React            from "react";
import { FC }           from "react";
import { Field }        from "@relcu/form";
import { Form }         from "@relcu/rc";
import { Checkbox }     from "@relcu/rc";
import { Stack }        from "@relcu/rc";
import { ISchema }      from "../../../../../types/ISchemas";
import { DetailsField } from "../../../../Generation";

export const Visibility: FC<{ schema: ISchema }> = React.memo(function Visibility({ schema }) {
  return (
    <Stack spacing={16} childrenRenderMode={"clone"} alignItems={"flex-start"} style={{ alignSelf: "stretch" }}>
      <DetailsField style={{ width: "100%" }}>
        <DetailsField.Title>Visibility</DetailsField.Title>
        <Form.InputNumber precision={0} name="visibility.width" label={"Width"}/>
        <Stack spacing={16}>
          <Field
            name="visibility.table"
            type="checkbox">
            {({ input }) => {
              return <Checkbox
                checked={input.checked}
                onChange={(value, checked) => input.onChange(checked)}>
                Table views
              </Checkbox>;
            }}
          </Field>
          {
            schema.className ==="Lead" && <>
              <Field
                name="visibility.focus"
                type="checkbox">
                {({ input }) => {
                  return <Checkbox
                    checked={input.checked}
                    onChange={(value, checked) => input.onChange(checked)}>
                    Focus view
                  </Checkbox>;
                }}
              </Field>
              <Field
                name="visibility.distribution"
                type="checkbox">
                {({ input }) => {
                  return <Checkbox
                    checked={input.checked}
                    onChange={(value, checked) => input.onChange(checked)}>
                    Distribution
                  </Checkbox>;
                }}
              </Field>
            </>
          }
        </Stack>
      </DetailsField>
    </Stack>
  );
});
