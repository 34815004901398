import React                from "react";
import { FC }               from "react";
import { useRef }           from "react";
import { useMemo }          from "react";
import { ReactNode }        from "react";
import { useBoxProps }      from "../../..";
import { BaseInputProps }   from "../../..";
import { IconType }         from "../../..";
import { SearchableMenu }   from "../../..";
import { classNames }       from "../../..";
import { BaseInputClasses } from "../../..";
import { InputState }        from "../../..";
import { Popper }            from "../../..";
import { Box }               from "../../..";
import { BoxComponentProps } from "../../..";
import { Alignment }         from "../../..";
import { MultiSelectToggle } from "./MultiSelectToggle/MultiSelectToggle";
import { useMultiSelect }    from "./useMultiSelect";

export enum MultiSelectVariants {
  Underlined = "underlined",
  Ghost = "ghost"
}

export interface MultiSelectProps extends BoxComponentProps, BaseInputProps {
  label?: string;
  value?: (object | string)[];
  variant?: MultiSelectVariants,
  alignment?: Alignment,
  readOnly?: boolean;
  searchable?: boolean;
  required?: boolean;
  options?: (object | string)[];
  loading?: boolean;
  keepErrorSpace?: boolean;
  state?: InputState;
  optionKey?: string;
  message?: string;
  optionLabel?: string;
  placeholder?: string;
  renderOption?: (option, selected?: boolean) => ReactNode;//todo use only menuItem
  menuProps?: object;
  searchIcon?: IconType;
  renderSelect?: (option) => ReactNode | string;
  renderHeader?: (option) => ReactNode | string;
  width?: number;
  showSelectedCount?: boolean;
  showSelectionActions?: boolean;
  searchText?: string
  headerProps?: { [ key: string ]: any }
  getOptionValueByKey?: boolean
  filterable?: boolean
  selectedFilter?: boolean
  onSelectedFilter?(filtered: boolean)
  onChange?(value);
  onType?(value: string, e),
  onCreateNew?(),
  treeView?: boolean;
}

export const MultiSelect: FC<MultiSelectProps> = React.memo(function MultiSelect(props) {
  const {
    label,
    value,
    state,
    message,
    optionKey,
    halfSize,
    fullSize,
    optionLabel,
    renderOption,
    renderSelect,
    loading,
    placeholder,
    width,
    onClick,
    options,
    onChange,
    className,
    direction,
    required,
    menuProps,
    searchIcon,
    showSelectionActions,
    showSelectedCount,
    disabled,
    readOnly,
    headerProps,
    keepErrorSpace,
    renderHeader,
    variant,
    alignment,
    searchable,
    filterable,
    ...p
  } = props;

  const {
    setAnchorBounding,
    setSelectedItems,
    setSelectedFilter,
    handleSelectAll,
    anchorBounding,
    selectedItems,
    selectedFilter,
    togglePopper,
    deleteItem,
    selectItem,
    allSelected,
    setSearch,
    search,
    items
  } = useMultiSelect(props);
  const toggleRef = useRef(null)
  const classes = classNames(className, {
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize,
    [ BaseInputClasses.Success ]: state == InputState.Success,
    [ BaseInputClasses.Error ]: state == InputState.Error,
    [ MultiSelectClasses.Active ]: !!anchorBounding,
    [ MultiSelectClasses.Empty ]: !options?.length
  }, BaseInputClasses.Input, MultiSelectClasses.MultiSelect);

  const disabledMod = useMemo(() => disabled || readOnly || options.length == 0, [disabled, readOnly, options]);
  return (
    <Box container
         className={classes}
         gap={"XXXS"}
         direction={"column"}
         {...useBoxProps(p)}>
      <MultiSelectToggle
        {...headerProps}
        ref={toggleRef}
        renderHeader={renderHeader}
        renderSelect={renderSelect}
        options={options}
        items={selectedItems}
        message={message}
        togglePopper={togglePopper}
        placeholder={placeholder}
        handleDeleteItem={deleteItem}
        opened={!!!anchorBounding}
        showSelectedCount={showSelectedCount}
        allSelected={allSelected}
        optionLabel={optionLabel}
        optionKey={optionKey}
        disabled={disabledMod}
        label={label}
        required={required}
        readOnly={readOnly}
        keepErrorSpace={keepErrorSpace}
        variant={variant}
      />
      <Popper
        open={!!anchorBounding}
        anchorBounding={anchorBounding}
        alignment={alignment}
        onClickAway={() => setAnchorBounding(null)}
        threshold={6}>
        <SearchableMenu
          selectedFilter={selectedFilter}
          onSelectedFilter={filterable ? (selectedFilter) => setSelectedFilter(selectedFilter) : null}
          searchable={searchable}
          onSelectAll={showSelectionActions ? () => handleSelectAll() : null}//todo multiclass case doesn't covered
          onUnSelectAll={showSelectionActions ? () => setSelectedItems([]) : null}
          searchIcon={searchIcon}
          renderOption={renderOption}
          options={items}
          selected={selectedItems}
          onSelect={selectItem}
          searchText={search}
          optionKey={optionKey}
          optionLabel={optionLabel}
          loading={loading}
          onType={setSearch}
          width={(width ?? toggleRef?.current?.clientWidth) as number}
          allSelected={allSelected}
        />
      </Popper>
    </Box>
  );
});
MultiSelect.defaultProps = {
  direction: "column",
  optionKey: "value",
  optionLabel: "label",
  getOptionValueByKey: false,
  filterable: true,
  searchable: true,
  alignment: Alignment.BottomLeft,
  variant: MultiSelectVariants.Underlined,
  value: [],
  options: []
};

export enum MultiSelectClasses {
  MultiSelect = "multi-select",
  MultiSelectGhost = "multi-select--ghost",
  HideBorder = "multi-select--hide-border",
  MultiSelectContainer = "multi-select__container",
  Empty = "multi-select--empty",
  Active = "multi-select--active",
}
