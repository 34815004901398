import { Checkbox }                from "@relcu/rc";
import { isValidDate }             from "@relcu/ui";
import { SwitchField }             from "@relcu/ui";
import { MultiInputField }         from "@relcu/ui";
import { Box }                     from "@relcu/ui";
import { useCallback }             from "react";
import React                       from "react";
import { FC }                      from "react";
import { Form }                    from "@relcu/form";
import { ModalFooter, ModalProps } from "@relcu/ui";
import { ModalBody }               from "@relcu/ui";
import { Modal }                   from "@relcu/ui";
import { defaultMutators }         from "@relcu/ui";
import { ButtonVariants }          from "@relcu/ui";
import { Button }                  from "@relcu/ui";
import { PointerField }            from "../../Field/PointerField";
import { PointerListField }        from "../../Field/PointerListField";

export interface LocalPresenceModalProps extends Partial<ModalProps> {
  data: any;
  onConfirm: (data) => void;
  numbers: string[];
  localNumbers: string[];
}
export const LocalPresenceModal: FC<LocalPresenceModalProps> = React.memo(function LocalPresenceModal(props) {
  const { onClose, open, modalProps, closeIcon, data, onConfirm, numbers,localNumbers } = props;

  const validate = useCallback((values) => {
    const errors: any = {};
    if (!values.codes?.length) {
      errors.codes = "Please insert a value";
    }
    if (!values.lines?.length) {
      errors.lines = "Please insert a value";
    }
    return errors;
  }, []);

  return <Modal
    title={`Local Presence`}
    onClose={onClose}
    open={open}
    closeIcon={closeIcon}
    disableBackdropClick={false}
    variant={"small"}
    modalProps={modalProps}>
    <Form
      onSubmit={(values, form) => {
        onConfirm({
          ...values,
          lines: (values as any).lines.map(l => ({
            phoneLine: l
          }))
        });
      }}
      validate={validate}
      initialValues={{
        codes: data?.codes?.map(code => code.value),
        lines: data?.lines || []
      }}
      mutators={defaultMutators}>
      {
        ({ form, handleSubmit,values }) => {
          return <form
            onSubmit={(e) => e.preventDefault()}
            style={{ display: "contents" }}>
            <ModalBody container direction={"column"} flex={"1 1 auto"} justify={"center"}>
              <Box container style={{ overflow: "hidden" }}>
                <MultiInputField name={"codes"} placeholder={"Enter area code"}/>
                <PointerListField
                  style={{ flex: 1 }}
                  name={"lines"}
                  targetClass={"PhoneLine"}
                  filters={{
                    localPresence: { equalTo: true },
                    number: {
                      notIn: localNumbers
                    }
                  }}
                />
              </Box>
              {
                <ModalFooter>
                  <Button variant={ButtonVariants.Outline}
                          onClick={() => onClose()}>CANCEL</Button>
                  <Button
                    onClick={handleSubmit}>
                    SAVE
                  </Button>
                </ModalFooter>
              }
            </ModalBody>
          </form>;
        }
      }
    </Form>
  </Modal>;
});
