import { DateTime }          from "@relcu/date";
import React                 from "react";
import { FC }                from "react";
import { useMemo }           from "react";
import { ReactElement }      from "react";
import { useCallback }       from "react";
import { useLayoutEffect }   from "react";
import { useState }          from "react";
import { TypographyColor }   from "../../..";
import { ButtonColors }      from "../../..";
import { usePrevious }       from "../../..";
import { Alignment }         from "../../../constants/Alignemnt";
import { CommonClasses }     from "../../../theme/classes";
import { classNames }        from "../../../utils/classNames";
import { BoxComponentProps } from "../../Box";
import { Box }               from "../../Box";
import { ButtonSizes }       from "../../Button";
import { Button }            from "../../Button";
import { ChipsSizes }        from "../../Chips";
import { ChipsColors }       from "../../Chips";
import { Chips }             from "../../Chips";
import { IconType }          from "../../Icon";
import { FontIcon }          from "../../Icon";
import { Tooltip }           from "../../Tooltip";
import { CoolDown }          from "./CoolDown";
import { QueueClasses }      from "./QueueClasses";
import { Typography }        from "../../..";
import { TypographySize }    from "../../..";

export interface QueueProps extends BoxComponentProps {
  disabled?: boolean;
  showCoolDownTime: boolean;
  statView?: boolean;
  showStatIcon: boolean;
  isLimitExceeded: boolean;
  lastTakenAt: string;
  coolDownPeriod: number;
  availableCount: number;
  onClick?(e);
  actionTitle?: string;
  stats?: ReactElement;
  state: "available" | "coolDown" | "finished" | "empty" | "rigging" | "redistribution";
  queueName?: string;
  animate?: number;
  icon: IconType;
  color: ButtonColors | TypographyColor;
  onAnimate?();
  collapsed: boolean;
  countVisible: boolean;
  repeatCount?: number;
}

export const Queue: FC<QueueProps> = React.memo(function QueueRedistribution(props) {
  const {
    coolDownPeriod,
    lastTakenAt,
    onClick,
    actionTitle,
    state,
    queueName,
    showCoolDownTime,
    availableCount,
    showStatIcon,
    isLimitExceeded,
    onAnimate,
    animate,
    color,
    disabled,
    countVisible,
    className,
    icon,
    stats,
    statView,
    collapsed,
    repeatCount,
    ...p
  } = props;
  const [showStat, setShowStat] = useState(statView);
  const prevAvailable = usePrevious(availableCount);
  const prevState = usePrevious(state);
  const [animationProgress, setAnimationProgress] = useState(!!animate);
  const classes = classNames(QueueClasses.Queue, {
    [ QueueClasses.ShowStat ]: showStat
  }, className);
  const toggleMode = useCallback(() => {
    setShowStat(!showStat);
  }, [showStat]);
  useLayoutEffect(() => {
    if ((state == "available" || state == "rigging") && prevAvailable < availableCount) {
      onAnimate();
      setAnimationProgress(true);
    }
  }, [state, prevAvailable, availableCount]);
  return <>{!collapsed ?
    <Box container direction={"column"} alignItems={"center"}
         className={classes} flexShrink={0} justify={"space-between"} {...p}>
      <Box container justify={!showStatIcon ? "end" : "space-between"} alignSelf={"stretch"}
           className={QueueClasses.Header}>
        {
          showStatIcon &&
          <Tooltip title={showStat ? "Back" : "Statistics"} alignment={Alignment.BottomLeft}>
            <FontIcon type={showStat ? "arrow_back" : "bar_chart"}
                      className={classNames(CommonClasses.ClickableIcon, QueueClasses.Switcher)} onClick={toggleMode}
                      role={"button"}/>
          </Tooltip>
        }
        {
          state == "rigging" &&
          <Chips color={ChipsColors.Success} size={ChipsSizes.Small}
                 thumbnail={<FontIcon type={"call"} style={{ fontSize: 11 }}/>} disabled label={"Phone call"}/>
        }
      </Box>
      <Box container direction={"column"} justify={"center"} gap={"XS"} alignItems={"center"} alignSelf={"stretch"}
           className={QueueClasses.Main}>
        <Box container flex={1} justify={"center"} direction={"column"} alignItems={"center"}
             alignSelf={"stretch"}>
          {
            stats && showStat ?
              stats :
              <Box container direction={"column"} gap={"XXXS"} alignItems={"center"}>
                <State
                  animationProgress={animationProgress}
                  setAnimationProgress={setAnimationProgress}
                  availableCount={availableCount}
                  coolDownPeriod={coolDownPeriod}
                  lastTakenAt={lastTakenAt}
                  isLimitExceeded={isLimitExceeded}
                  icon={icon}
                  showCoolDownTime={showCoolDownTime}
                  state={state}
                  collapsed={collapsed}
                  countVisible={countVisible}
                  color={color}
                  onClick={onClick}
                  actionTitle={actionTitle}
                  disabled={disabled}
                  repeatCount={repeatCount}
                />
                {
                  queueName && <p className={QueueClasses.Name}>{queueName}</p>
                }
              </Box>
          }
        </Box>
      </Box>
      <Box container alignSelf={"stretch"} justify={"center"} className={QueueClasses.Footer}>
        {
          isLimitExceeded ?
            <p className={QueueClasses.Description}>You have reached your daily lead cap</p>
            :
            state == "available" || state == "rigging" ?
              <Button onClick={onClick} alignSelf={"stretch"} color={color as ButtonColors} size={ButtonSizes.Huge}
                      disabled={disabled} flex={1}>{actionTitle.toUpperCase()}</Button> :
              state == "coolDown" ?
                <p className={QueueClasses.Description}>Cooldown</p> :
                state == "empty" && <p className={QueueClasses.Description}>All leads have been taken</p>
        }
      </Box>
    </Box> :
    <State
      animationProgress={animationProgress}
      queueName={queueName}
      setAnimationProgress={setAnimationProgress}
      availableCount={availableCount}
      coolDownPeriod={coolDownPeriod}
      lastTakenAt={lastTakenAt}
      isLimitExceeded={isLimitExceeded}
      icon={icon}
      showCoolDownTime={showCoolDownTime}
      state={state}
      collapsed={collapsed}
      color={color}
      onClick={onClick}
      actionTitle={actionTitle}
      disabled={disabled}
      countVisible={countVisible}
      repeatCount={repeatCount}
    />
  }</>;
});

Queue.defaultProps = {
  actionTitle: "TAKE LEAD",
  statView: false
};

interface StateProps extends Partial<QueueProps> {
  animationProgress: boolean,
  setAnimationProgress: any
}

export const State: FC<StateProps> = React.memo(function State(props) {
  const { state, collapsed, repeatCount, color, queueName, actionTitle, onClick, disabled, availableCount, coolDownPeriod, countVisible, showCoolDownTime, lastTakenAt, icon, animationProgress, setAnimationProgress } = props;
  const coolDownTiming = useMemo(() => {
    const coolDownEndDate = DateTime.fromJSDate(new Date(lastTakenAt)).plus({ minute: coolDownPeriod });
    const seconds = Math.round(coolDownEndDate.diffNow("second").get("seconds"));
    const percentage = (seconds * 100) / (coolDownPeriod * 60);
    return {
      seconds,
      percentage
    };
  }, [coolDownPeriod, lastTakenAt, state]);
  const coloredIcon = {
    warning: QueueClasses.IconWarning,
    primary: QueueClasses.IconPrimary,
    error: QueueClasses.IconError,
    success: QueueClasses.IconSuccess,
    info: QueueClasses.IconInfo
  };
  const classes = classNames(QueueClasses.State, {
    [ QueueClasses.StateSmallCoolDown ]: state == "coolDown" && collapsed,
    [ QueueClasses.StateSmall ]: !!collapsed,
    [ QueueClasses.StateBig ]: !collapsed,
    [ coloredIcon[ color ] ]: !collapsed && state != "coolDown" && state != "empty" && state != "finished"
  });
  const count = availableCount > 99 ? `99+` : availableCount;
  const coloredAnimate = {
    warning: QueueClasses.AnimateWarning,
    primary: QueueClasses.AnimatePrimary,
    error: QueueClasses.AnimateError,
    success: QueueClasses.AnimateSuccess,
    info: QueueClasses.AnimateInfo
  };
  const animateClasses = classNames({
    [ QueueClasses.Animate ]: animationProgress && (state == "available" || state == "rigging"),
    [ QueueClasses.TwoRepeat ]: repeatCount == 2,
    [ QueueClasses.FiveRepeat ]: repeatCount == 5
  }, coloredAnimate[ color ]);

  return <Box container justify={"center"} alignItems={"center"}
              className={classes}>
    {(() => {
      switch (state) {
        case "coolDown":
          return <CoolDown
            showTime={showCoolDownTime}
            coolDownTime={coolDownTiming.seconds}
            coolDownPercentage={coolDownTiming.percentage}
            collapsed={collapsed}/>;
        case "rigging":
        case "available":
          return <Box container justify={"center"} alignItems={"center"}
                      className={animateClasses}
                      onAnimationEnd={() => setAnimationProgress(false)}>
            {collapsed ?
              <Box className={QueueClasses.ButtonSwitcher}>
                <Button className={classNames(QueueClasses.Icon, QueueClasses.FontSmall)} color={color as ButtonColors}
                        disabled={disabled}
                        flex={1}
                        onlyIcon>
                  {countVisible && collapsed ? count
                    : <FontIcon type={icon} className={classNames(QueueClasses.Icon)}/>}
                </Button>
                <Tooltip title={`${actionTitle} from ${queueName}`}>
                  <Button
                    icon={icon}
                    onlyIcon
                    onClick={onClick}
                    color={color as ButtonColors}
                    className={QueueClasses.Icon}
                    disabled={disabled}
                    flex={1}>
                  </Button>
                </Tooltip>
              </Box>
              :
              <Box container justify={"center"} alignItems={"center"}
                   className={classNames(QueueClasses.ColorWhite, { [ QueueClasses.ButtonSwitcher ]: countVisible })}>
                {countVisible ?
                  <Typography size={TypographySize.Title} className={QueueClasses.ColorWhite}>{count}</Typography>
                  : <FontIcon type={icon} className={QueueClasses.IconCollapsed}/>}
                {countVisible && <FontIcon type={icon} className={QueueClasses.IconCollapsed}/>}
              </Box>}
          </Box>;
        case "finished":
          return <FontIcon type={"emoji_events"}
                           className={classNames({ [ QueueClasses.IconCollapsed ]: !collapsed }, CommonClasses.PrimaryIcon)}/>;
        case "empty":
          return <FontIcon type={"rc_custom-empty"}
                           className={classNames({ [ QueueClasses.IconCollapsed ]: !collapsed }, CommonClasses.GrayIcon)}/>;
      }
    })()}
  </Box>;
});
