export function postSchemas(data: Record<string, any>) {
  return fetch(`${window.__CONFIG__.api}/org/schemas`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ _id: data.className, ...data })
  });
}
