import { Stack }       from "@relcu/rc";
import { Typography }  from "@relcu/rc";
import React           from "react";
import { FC }          from "react";
import { useCallback } from "react";
import Editor          from "@monaco-editor/react";
import { useForm }     from "@relcu/final-form";
import { Field }       from "@relcu/final-form";

export const StatusEditor: FC<any> = React.memo(function StatusEditor(props) {
  const form = useForm();
  const handleEditorValidation = useCallback((markers) => {
    if (!!markers.length) {
      form.mutators?.setFieldData?.("value", { warning: "Invalid value." });
    } else {
      form.mutators?.setFieldData?.("value", { warning: null });
    }
  }, []);

  return <Field
    name={"value"}
    parse={(value) => {
      return JSON.parse(value);
    }}
    format={(value) => {
      return JSON.stringify(value, null, 2);
    }}
  >
    {({ input: { value, onChange }, meta: { data } }) => {
      return <Stack direction={"column"} style={{ flexGrow: 1 }} childrenRenderMode={"clone"}>
        {
          data?.warning &&
          <Typography
            style={{ color: "var(--rc-status-03-warning-primary)" }}
            variant={"base14"}>
            {data?.warning}
          </Typography>
        }

        <Editor
          height={"70vh"}
          defaultLanguage={"json"}
          value={value}
          theme={"dark"}
          onChange={onChange}
          options={{ minimap: { enabled: false } }}
          onValidate={handleEditorValidation}
        />
      </Stack>;
    }}
  </Field>;
});
