import { useMemo }                        from "react";
import { useState }                       from "react";
import { useApolloClient }                from "@apollo/client";
import { gql }                            from "@apollo/client";
import { useMutation }                    from "@apollo/client";
import { useAlert }                       from "@relcu/ui";
import { useSource }                      from "@relcu/ui";
import { useNavigate }                    from "@relcu/react-router";
import { toFirstLower }                   from "../../../utils/helpers";
import { getPrimaryBorrower }             from "../../../utils/helpers";
import { useViewerPhoneLines }            from "../../useViewerPhoneLines";
import { SendBulkPhoneMessageVariables }  from "./__types__/SendBulkPhoneMessage";
import { SendBulkPhoneMessage }           from "./__types__/SendBulkPhoneMessage";
import { BulkSendPhoneMessageModalAlert } from "./BulkSendPhoneMessageModal";
import { BulkSendPhoneMessageModalProps } from "./BulkSendPhoneMessageModal";

export function useBulkSendPhoneMessageModal(props: BulkSendPhoneMessageModalProps) {
  const client = useApolloClient();
  const { variables, ids, destroyHandler } = props;
  const { info, error } = useAlert();
  const navigate = useNavigate();
  const { $viewer } = useSource();
  const allowBehalfOfSend = $viewer.role !== "loan_officer";
  const [selected, setSelected] = useState(null);
  const isAllSelected = ids.some(item => item === "*");
  const query = {
    where: isAllSelected ? variables.where : { id: { in: ids } },
    order: variables.order
  };
  const primaryBorrower = selected?.__typename == "Lead" ? getPrimaryBorrower(selected) : selected;
  const { bulkFromNumbers: fromNumbers, defaultPhoneNumber } = useViewerPhoneLines();
  const numbers = useMemo(() => {
    if (allowBehalfOfSend) {
      return [{ label: "On behalf of assignees", value: "" }, ...fromNumbers];
    }
    return fromNumbers;
  }, [fromNumbers, allowBehalfOfSend]);
  const from = defaultPhoneNumber?.value || "";
  const [sendBulkPhoneMessage] = useMutation<SendBulkPhoneMessage, SendBulkPhoneMessageVariables>(SEND_BULK_PHONE_MESSAGE);
  const templateFilters = useMemo(() => {
    const filters: Record<string, object> = {
      type: { equalTo: "sms" },
      enabled: { equalTo: true },
      availableFor: { equalTo: "bulk" },
      AND: [
        { applyTo: { equalTo: toFirstLower(props.className) } }
      ],
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        }
      ]
    };
    return filters;
  }, [props.className, $viewer.id]);
  const flyerFilters = useMemo(() => {
    return {
      enabled: { equalTo: true },
      availableForType: { equalTo: "sms" },
      availableFor: { equalTo: "bulk" },
      applyTo: { equalTo: toFirstLower(props.className) },
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        }
      ]
    };
  }, [props.className, $viewer.id]);
  let receiver = useMemo(() => {
    const result = [];
    (selected?.members || []).map((member) => {
      member.contact?.phones?.map((phone) => {
        if (!result.find((n) => n.number == phone.number)) {
          result.push({
            firstName: member.contact.firstName,
            lastName: member.contact.lastName,
            number: phone.number
          });
        }
      });
    });
    return result;
  }, [selected]);
  const handleSelect = (data) => {
    setSelected(data);
  };
  const getTemplate = id => {
    return client.readFragment({
      id: client.cache.identify({ id, __typename: "PhoneMessageTemplate" }),
      fragment: PHONE_MESSAGE_TEMPLATE,
      fragmentName: "ShortPhoneMessageTemplate"
    });
  };
  async function onBulkSubmit(data) {
    const template = getTemplate(data.template);

    if (!data.template) {
      return { template: "Template is required." };
    }

    const variables: any = {
      from: data.from,
      where: query.where,
      flyerId: data.flyer,
      templateId: template.objectId,
      className: props.className
    };

    try {
      const result = await sendBulkPhoneMessage({ variables });
      if (result?.data.sendBulkPhoneMessage?.objectId) {
        info(BulkSendPhoneMessageModalAlert({
          handleClick: () => {
            navigate(`/user/${$viewer.objectId}/sms/${result?.data.sendBulkPhoneMessage?.objectId}`);
          }
        }), { width: "auto" });
        destroyHandler();
      } else {
        return result;
      }
    } catch (e) {
      console.error(e);
      error(e?.message ?? "Something went wrong");
    }
  }

  return {
    from,
    numbers,
    query,
    templateFilters,
    flyerFilters,
    getTemplate,
    onBulkSubmit,
    handleSelect,
    primaryBorrower,
    selected,
    receiver
  };
}

const SEND_BULK_PHONE_MESSAGE = gql`
  mutation SendBulkPhoneMessage( $from: String, $where: LeadWhereInput, $content: String, $className: String,$flyerId: String, $templateId: String ){
    sendBulkPhoneMessage(
      from: $from,
      where: $where,
      content: $content,
      className: $className,
      flyerId: $flyerId,
      templateId: $templateId
    ){
      id
      objectId
    }
  }
`;

const PHONE_MESSAGE_TEMPLATE = gql`
  fragment ShortPhoneMessageTemplate on PhoneMessageTemplate{
    id
    objectId
    title
    text
  }
`;

