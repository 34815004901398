/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The ActivityOrder input type is used when sorting objects of the Activity class.
 */
export enum ActivityOrder {
  ACL_ASC = "ACL_ASC",
  ACL_DESC = "ACL_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  createdBy_ASC = "createdBy_ASC",
  createdBy_DESC = "createdBy_DESC",
  data_ASC = "data_ASC",
  data_DESC = "data_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  meta_ASC = "meta_ASC",
  meta_DESC = "meta_DESC",
  objectId_ASC = "objectId_ASC",
  objectId_DESC = "objectId_DESC",
  relatedTo_ASC = "relatedTo_ASC",
  relatedTo_DESC = "relatedTo_DESC",
  type_ASC = "type_ASC",
  type_DESC = "type_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  wfaJobs_ASC = "wfaJobs_ASC",
  wfaJobs_DESC = "wfaJobs_DESC",
}

/**
 * Generated flyer type.
 */
export enum GenerationType {
  image = "image",
  pdf = "pdf",
}

/**
 * The SubscriptionEvent enum type is used in subscription in order to identify which event happened
 */
export enum SubscriptionEvent {
  CREATE = "CREATE",
  DELETE = "DELETE",
  ENTER = "ENTER",
  LEAVE = "LEAVE",
  UPDATE = "UPDATE",
}

/**
 * Allow to manage access rights. If not provided object will be publicly readable and writable
 */
export interface ACLInput {
  public?: PublicACLInput | null;
  roles?: RoleACLInput[] | null;
  teams?: TeamACLInput[] | null;
  users?: UserACLInput[] | null;
}

/**
 * The ActivityCreatedByUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ActivityCreatedByUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ActivitySubscriptionWhereInput input type is used in operations that involve filtering objects of Activity class.
 */
export interface ActivitySubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: ActivitySubscriptionWhereInput[] | null;
  NOR?: ActivitySubscriptionWhereInput[] | null;
  OR?: ActivitySubscriptionWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  createdBy?: ActivityCreatedByUnionWhereInput | null;
  data?: ObjectWhereInput | null;
  id?: IdWhereInput | null;
  meta?: ObjectWhereInput | null;
  objectId?: IdWhereInput | null;
  relatedTo?: ArrayWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  wfaJobs?: ArrayWhereInput | null;
}

/**
 * The ActivityWhereInput input type is used in operations that involve filtering objects of Activity class.
 */
export interface ActivityWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: ActivityWhereInput[] | null;
  NOR?: ActivityWhereInput[] | null;
  OR?: ActivityWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  createdBy?: ActivityCreatedByUnionWhereInput | null;
  data?: ObjectWhereInput | null;
  id?: IdWhereInput | null;
  meta?: ObjectWhereInput | null;
  objectId?: IdWhereInput | null;
  relatedTo?: ArrayWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  wfaJobs?: ArrayWhereInput | null;
}

/**
 * The AddressRelationWhereInput input type is used in operations that involve filtering objects of Address class.
 */
export interface AddressRelationWhereInput {
  exists?: boolean | null;
  have?: AddressWhereInput | null;
  haveNot?: AddressWhereInput | null;
}

/**
 * The AddressWhereInput input type is used in operations that involve filtering objects of Address class.
 */
export interface AddressWhereInput {
  AND?: AddressWhereInput[] | null;
  NOR?: AddressWhereInput[] | null;
  OR?: AddressWhereInput[] | null;
  city?: StringWhereInput | null;
  country?: StringWhereInput | null;
  county?: StringWhereInput | null;
  formatted?: StringWhereInput | null;
  lat?: NumberWhereInput | null;
  lng?: NumberWhereInput | null;
  state?: StringWhereInput | null;
  street?: StringWhereInput | null;
  street2?: StringWhereInput | null;
  zipCode?: StringWhereInput | null;
}

/**
 * The AppraisalFeeWhereInput input type is used in operations that involve filtering objects of AppraisalFee class.
 */
export interface AppraisalFeeWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: AppraisalFeeWhereInput[] | null;
  NOR?: AppraisalFeeWhereInput[] | null;
  OR?: AppraisalFeeWhereInput[] | null;
  appraisal?: AppraisalRelationWhereInput | null;
  coOp?: NumberWhereInput | null;
  condo?: NumberWhereInput | null;
  county?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  fipsCode?: StringWhereInput | null;
  id?: IdWhereInput | null;
  loanType?: StringWhereInput | null;
  manufactured?: NumberWhereInput | null;
  mobile?: NumberWhereInput | null;
  multiFamily?: NumberWhereInput | null;
  objectId?: IdWhereInput | null;
  pud?: NumberWhereInput | null;
  singleFamily?: NumberWhereInput | null;
  state?: StringWhereInput | null;
  townhome?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The AppraisalRelationWhereInput input type is used in operations that involve filtering objects of Appraisal class.
 */
export interface AppraisalRelationWhereInput {
  exists?: boolean | null;
  have?: AppraisalWhereInput | null;
  haveNot?: AppraisalWhereInput | null;
}

/**
 * The AppraisalWhereInput input type is used in operations that involve filtering objects of Appraisal class.
 */
export interface AppraisalWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: AppraisalWhereInput[] | null;
  NOR?: AppraisalWhereInput[] | null;
  OR?: AppraisalWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  defaultType?: StringWhereInput | null;
  enabled?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  types?: ArrayWhereInput | null;
  updatedAt?: DateWhereInput | null;
  uploadedFiles?: ArrayWhereInput | null;
}

/**
 * The ArrayWhereInput input type is used in operations that involve filtering objects by a field of type Array.
 */
export interface ArrayWhereInput {
  containedBy?: (any | null)[] | null;
  contains?: (any | null)[] | null;
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: (any | null)[] | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: (any | null)[] | null;
  notInQueryKey?: SelectInput | null;
}

export interface Attachment {
  objectId?: string | null;
  url: string;
}

/**
 * The BLOB object type is used to return the information about files.
 */
export interface Blob {
  content: any;
  name: string;
  type: string;
}

/**
 * The BooleanWhereInput input type is used in operations that involve filtering objects by a field of type Boolean.
 */
export interface BooleanWhereInput {
  equalTo?: boolean | null;
  exists?: boolean | null;
  inQueryKey?: SelectInput | null;
  notEqualTo?: boolean | null;
  notInQueryKey?: SelectInput | null;
}

/**
 * The BulkMailStatsPerUserRelationWhereInput input type is used in operations that involve filtering objects of BulkMailStatsPerUser class.
 */
export interface BulkMailStatsPerUserRelationWhereInput {
  exists?: boolean | null;
  have?: BulkMailStatsPerUserWhereInput | null;
  haveNot?: BulkMailStatsPerUserWhereInput | null;
}

/**
 * The BulkMailStatsPerUserSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of BulkMailStatsPerUser class.
 */
export interface BulkMailStatsPerUserSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: BulkMailStatsPerUserSubscriptionWhereInput | null;
  haveNot?: BulkMailStatsPerUserSubscriptionWhereInput | null;
}

/**
 * The BulkMailStatsPerUserSubscriptionWhereInput input type is used in operations that involve filtering objects of BulkMailStatsPerUser class.
 */
export interface BulkMailStatsPerUserSubscriptionWhereInput {
  AND?: BulkMailStatsPerUserSubscriptionWhereInput[] | null;
  NOR?: BulkMailStatsPerUserSubscriptionWhereInput[] | null;
  OR?: BulkMailStatsPerUserSubscriptionWhereInput[] | null;
  stats?: BulkMailStatsSubscriptionWhereInput | null;
  user?: BulkMailStatsPerUserUserUnionWhereInput | null;
}

/**
 * The BulkMailStatsPerUserUserUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface BulkMailStatsPerUserUserUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The BulkMailStatsPerUserWhereInput input type is used in operations that involve filtering objects of BulkMailStatsPerUser class.
 */
export interface BulkMailStatsPerUserWhereInput {
  AND?: BulkMailStatsPerUserWhereInput[] | null;
  NOR?: BulkMailStatsPerUserWhereInput[] | null;
  OR?: BulkMailStatsPerUserWhereInput[] | null;
  stats?: BulkMailStatsWhereInput | null;
  user?: UserRelationWhereInput | null;
}

/**
 * The BulkMailStatsSubscriptionWhereInput input type is used in operations that involve filtering objects of BulkMailStats class.
 */
export interface BulkMailStatsSubscriptionWhereInput {
  AND?: BulkMailStatsSubscriptionWhereInput[] | null;
  NOR?: BulkMailStatsSubscriptionWhereInput[] | null;
  OR?: BulkMailStatsSubscriptionWhereInput[] | null;
  canceled?: NumberWhereInput | null;
  clicked?: NumberWhereInput | null;
  failed?: NumberWhereInput | null;
  opened?: NumberWhereInput | null;
  queued?: NumberWhereInput | null;
  sent?: NumberWhereInput | null;
  spammed?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  unsubscribed?: NumberWhereInput | null;
}

/**
 * The BulkMailStatsWhereInput input type is used in operations that involve filtering objects of BulkMailStats class.
 */
export interface BulkMailStatsWhereInput {
  AND?: BulkMailStatsWhereInput[] | null;
  NOR?: BulkMailStatsWhereInput[] | null;
  OR?: BulkMailStatsWhereInput[] | null;
  canceled?: NumberWhereInput | null;
  clicked?: NumberWhereInput | null;
  failed?: NumberWhereInput | null;
  opened?: NumberWhereInput | null;
  queued?: NumberWhereInput | null;
  sent?: NumberWhereInput | null;
  spammed?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  unsubscribed?: NumberWhereInput | null;
}

/**
 * The BulkMessagingPermissionWhereInput input type is used in operations that involve filtering objects of BulkMessagingPermission class.
 */
export interface BulkMessagingPermissionWhereInput {
  AND?: BulkMessagingPermissionWhereInput[] | null;
  NOR?: BulkMessagingPermissionWhereInput[] | null;
  OR?: BulkMessagingPermissionWhereInput[] | null;
  email?: BooleanWhereInput | null;
  sms?: BooleanWhereInput | null;
}

/**
 * The ClosingCostWhereInput input type is used in operations that involve filtering objects of ClosingCost class.
 */
export interface ClosingCostWhereInput {
  AND?: ClosingCostWhereInput[] | null;
  NOR?: ClosingCostWhereInput[] | null;
  OR?: ClosingCostWhereInput[] | null;
  adminFee?: NumberWhereInput | null;
  appraisalFee?: NumberWhereInput | null;
  attorney?: NumberWhereInput | null;
  condoCertificate?: NumberWhereInput | null;
  creditAndOther?: CreditAndOtherWhereInput | null;
  discountPoints?: NumberWhereInput | null;
  docReview?: NumberWhereInput | null;
  earnestMoneyPaid?: NumberWhereInput | null;
  floodCertification?: NumberWhereInput | null;
  fundingFee?: NumberWhereInput | null;
  mip?: NumberWhereInput | null;
  originationFee?: NumberWhereInput | null;
  pestInspection?: NumberWhereInput | null;
  pmi?: NumberWhereInput | null;
  sellerConcession?: NumberWhereInput | null;
  subordination?: NumberWhereInput | null;
  survey?: NumberWhereInput | null;
  title?: TitleWhereInput | null;
  total?: NumberWhereInput | null;
}

/**
 * The ConferenceAssignedToUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ConferenceAssignedToUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ConferenceInitiatorUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ConferenceInitiatorUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ConferencePromptUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ConferencePromptUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ConferenceRecordingUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ConferenceRecordingUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface ConferenceScopePointerInput {
  createAndLinkLead?: CreateLeadFieldsInput | null;
  createAndLinkReferralPartner?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The ConferenceScopeUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ConferenceScopeUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ConferenceSubscriptionWhereInput input type is used in operations that involve filtering objects of Conference class.
 */
export interface ConferenceSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: ConferenceSubscriptionWhereInput[] | null;
  NOR?: ConferenceSubscriptionWhereInput[] | null;
  OR?: ConferenceSubscriptionWhereInput[] | null;
  assignedAt?: DateWhereInput | null;
  assignedTo?: ConferenceAssignedToUnionWhereInput | null;
  calls?: ParticipantSubscriptionRelationWhereInput | null;
  conferenceSid?: StringWhereInput | null;
  conferenceStartDate?: DateWhereInput | null;
  createdAt?: DateWhereInput | null;
  direction?: StringWhereInput | null;
  endDate?: DateWhereInput | null;
  forwardedFrom?: StringWhereInput | null;
  forwardedTo?: StringWhereInput | null;
  from?: StringWhereInput | null;
  id?: IdWhereInput | null;
  initiator?: ConferenceInitiatorUnionWhereInput | null;
  missed?: BooleanWhereInput | null;
  navigate?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  prompt?: ConferencePromptUnionWhereInput | null;
  provider?: StringWhereInput | null;
  queued?: BooleanWhereInput | null;
  recording?: ConferenceRecordingUnionWhereInput | null;
  recordingUrl?: StringWhereInput | null;
  scope?: ConferenceScopeUnionWhereInput | null;
  sid?: StringWhereInput | null;
  startDate?: DateWhereInput | null;
  status?: StringWhereInput | null;
  tags?: ArrayWhereInput | null;
  to?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
  voicemail?: ConferenceVoicemailUnionWhereInput | null;
}

/**
 * The ConferenceVoicemailUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ConferenceVoicemailUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ConferenceWhereInput input type is used in operations that involve filtering objects of Conference class.
 */
export interface ConferenceWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: ConferenceWhereInput[] | null;
  NOR?: ConferenceWhereInput[] | null;
  OR?: ConferenceWhereInput[] | null;
  assignedAt?: DateWhereInput | null;
  assignedTo?: UserRelationWhereInput | null;
  calls?: ParticipantRelationWhereInput | null;
  conferenceSid?: StringWhereInput | null;
  conferenceStartDate?: DateWhereInput | null;
  createdAt?: DateWhereInput | null;
  direction?: StringWhereInput | null;
  endDate?: DateWhereInput | null;
  forwardedFrom?: StringWhereInput | null;
  forwardedTo?: StringWhereInput | null;
  from?: StringWhereInput | null;
  id?: IdWhereInput | null;
  initiator?: ConferenceInitiatorUnionWhereInput | null;
  missed?: BooleanWhereInput | null;
  navigate?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  prompt?: RecordingRelationWhereInput | null;
  provider?: StringWhereInput | null;
  queued?: BooleanWhereInput | null;
  recording?: RecordingRelationWhereInput | null;
  recordingUrl?: StringWhereInput | null;
  scope?: ConferenceScopeUnionWhereInput | null;
  sid?: StringWhereInput | null;
  startDate?: DateWhereInput | null;
  status?: StringWhereInput | null;
  tags?: ArrayWhereInput | null;
  to?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
  voicemail?: RecordingRelationWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the Contact class.
 */
export interface ContactPointerInput {
  createAndLink?: CreateContactFieldsInput | null;
  link?: string | null;
}

/**
 * The ContactRelationWhereInput input type is used in operations that involve filtering objects of Contact class.
 */
export interface ContactRelationWhereInput {
  exists?: boolean | null;
  have?: ContactWhereInput | null;
  haveNot?: ContactWhereInput | null;
}

/**
 * The ContactWhereInput input type is used in operations that involve filtering objects of Contact class.
 */
export interface ContactWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: ContactWhereInput[] | null;
  NOR?: ContactWhereInput[] | null;
  OR?: ContactWhereInput[] | null;
  accountNumber?: StringWhereInput | null;
  address?: AddressWhereInput | null;
  addresses?: AddressRelationWhereInput | null;
  birthday?: DateWhereInput | null;
  company?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  emails?: EmailAddressRelationWhereInput | null;
  firstName?: StringWhereInput | null;
  gender?: StringWhereInput | null;
  id?: IdWhereInput | null;
  lastName?: StringWhereInput | null;
  middleName?: StringWhereInput | null;
  objectIcon?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  phones?: PhoneNumberRelationWhereInput | null;
  primaryEmail?: EmailWhereInput | null;
  primaryPhone?: PhoneWhereInput | null;
  ssn?: StringWhereInput | null;
  tags?: ArrayWhereInput | null;
  types?: ArrayWhereInput | null;
  updatedAt?: DateWhereInput | null;
  wfaJobs?: ArrayWhereInput | null;
}

/**
 * The ConventionalLoanLimitWhereInput input type is used in operations that involve filtering objects of ConventionalLoanLimit class.
 */
export interface ConventionalLoanLimitWhereInput {
  AND?: ConventionalLoanLimitWhereInput[] | null;
  NOR?: ConventionalLoanLimitWhereInput[] | null;
  OR?: ConventionalLoanLimitWhereInput[] | null;
  fourUnitLimit?: NumberWhereInput | null;
  issuerType?: StringWhereInput | null;
  oneUnitLimit?: NumberWhereInput | null;
  reportingYear?: DateWhereInput | null;
  threeUnitLimit?: NumberWhereInput | null;
  twoUnitLimit?: NumberWhereInput | null;
}

/**
 * The CostcoWhereInput input type is used in operations that involve filtering objects of Costco class.
 */
export interface CostcoWhereInput {
  AND?: CostcoWhereInput[] | null;
  NOR?: CostcoWhereInput[] | null;
  OR?: CostcoWhereInput[] | null;
  exclusivity?: StringWhereInput | null;
  membershipId?: StringWhereInput | null;
  membershipType?: StringWhereInput | null;
}

/**
 * The CountyLoanLimitWhereInput input type is used in operations that involve filtering objects of CountyLoanLimit class.
 */
export interface CountyLoanLimitWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: CountyLoanLimitWhereInput[] | null;
  NOR?: CountyLoanLimitWhereInput[] | null;
  OR?: CountyLoanLimitWhereInput[] | null;
  cbsaNumber?: StringWhereInput | null;
  conventionalLoanLimit?: ConventionalLoanLimitWhereInput | null;
  countyName?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  fhaLoanLimit?: FhaLoanLimitWhereInput | null;
  fipsCode?: StringWhereInput | null;
  id?: IdWhereInput | null;
  objectId?: IdWhereInput | null;
  stateCode?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The CreateAddressFieldsInput input type is used in operations that involve creation of objects in the Address class.
 */
export interface CreateAddressFieldsInput {
  city?: string | null;
  country?: string | null;
  county?: string | null;
  formatted?: string | null;
  lat?: number | null;
  lng?: number | null;
  state?: string | null;
  street?: string | null;
  street2?: string | null;
  zipCode?: string | null;
}

/**
 * The CreateBulkMessagingPermissionFieldsInput input type is used in operations that involve creation of objects in the BulkMessagingPermission class.
 */
export interface CreateBulkMessagingPermissionFieldsInput {
  email?: boolean | null;
  sms?: boolean | null;
}

/**
 * The CreateClosingCostFieldsInput input type is used in operations that involve creation of objects in the ClosingCost class.
 */
export interface CreateClosingCostFieldsInput {
  adminFee?: number | null;
  appraisalFee?: number | null;
  attorney?: number | null;
  condoCertificate?: number | null;
  creditAndOther?: CreateCreditAndOtherFieldsInput | null;
  discountPoints?: number | null;
  docReview?: number | null;
  earnestMoneyPaid?: number | null;
  floodCertification?: number | null;
  fundingFee?: number | null;
  mip?: number | null;
  originationFee?: number | null;
  pestInspection?: number | null;
  pmi?: number | null;
  sellerConcession?: number | null;
  subordination?: number | null;
  survey?: number | null;
  title?: CreateTitleFieldsInput | null;
  total?: number | null;
}

/**
 * The CreateContactFieldsInput input type is used in operations that involve creation of objects in the Contact class.
 */
export interface CreateContactFieldsInput {
  ACL?: ACLInput | null;
  accountNumber?: string | null;
  address?: CreateAddressFieldsInput | null;
  addresses?: (CreateAddressFieldsInput | null)[] | null;
  birthday?: any | null;
  company?: string | null;
  createdAt?: any | null;
  emails?: (CreateEmailAddressFieldsInput | null)[] | null;
  firstName?: string | null;
  gender?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  objectIcon?: string | null;
  objectName?: string | null;
  phones?: (CreatePhoneNumberFieldsInput | null)[] | null;
  primaryEmail?: any | null;
  primaryPhone?: any | null;
  ssn?: string | null;
  tags?: (any | null)[] | null;
  types?: any | null;
  updatedAt?: any | null;
  wfaJobs?: (any | null)[] | null;
}

export interface CreateContactInput {
  clientMutationId?: string | null;
  fields?: CreateContactFieldsInput | null;
}

/**
 * The CreateCostcoFieldsInput input type is used in operations that involve creation of objects in the Costco class.
 */
export interface CreateCostcoFieldsInput {
  exclusivity?: string | null;
  membershipId?: string | null;
  membershipType?: string | null;
}

/**
 * The CreateCreditAndOtherFieldsInput input type is used in operations that involve creation of objects in the CreditAndOther class.
 */
export interface CreateCreditAndOtherFieldsInput {
  creditReport?: number | null;
  documentDelivery?: number | null;
  loanSafe?: number | null;
  mers?: number | null;
  total?: number | null;
  transcript?: number | null;
  verification?: number | null;
}

/**
 * The CreateDataTablePermissionFieldsInput input type is used in operations that involve creation of objects in the DataTablePermission class.
 */
export interface CreateDataTablePermissionFieldsInput {
  create?: boolean | null;
  share?: boolean | null;
}

/**
 * The CreateDeviceFieldsInput input type is used in operations that involve creation of objects in the Device class.
 */
export interface CreateDeviceFieldsInput {
  ACL?: ACLInput | null;
  active?: boolean | null;
  apn?: string | null;
  browserName?: string | null;
  browserVersion?: string | null;
  createdAt?: any | null;
  deviceId?: string | null;
  deviceType?: string | null;
  engineName?: string | null;
  fcm?: string | null;
  osName?: string | null;
  osVersion?: string | null;
  osVersionName?: string | null;
  platformType?: string | null;
  platformVendor?: string | null;
  sessions?: SessionRelationInput | null;
  updatedAt?: any | null;
  user?: UserPointerInput | null;
  version?: string | null;
}

/**
 * The CreateDistributionQueueFieldsInput input type is used in operations that involve creation of objects in the DistributionQueue class.
 */
export interface CreateDistributionQueueFieldsInput {
  ACL?: ACLInput | null;
  autoDial?: boolean | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  disabled?: boolean | null;
  distributeOfflineUsers?: boolean | null;
  draft?: boolean | null;
  limits?: (CreateDistributionQueueLimitFieldsInput | null)[] | null;
  notify?: boolean | null;
  notifyMobile?: boolean | null;
  objectName?: string | null;
  priority?: number | null;
  rules?: (CreateDistributionQueueRuleFieldsInput | null)[] | null;
  schedule?: CreateDistributionQueueScheduleFieldsInput | null;
  skipTimezoneCheck?: boolean | null;
  strategy?: string | null;
  takeLeadFromMobile?: boolean | null;
  updatedAt?: any | null;
  view?: CreateDistributionQueueViewFieldsInput | null;
}

export interface CreateDistributionQueueInput {
  clientMutationId?: string | null;
  fields?: CreateDistributionQueueFieldsInput | null;
}

/**
 * The CreateDistributionQueueLimitFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueLimit class.
 */
export interface CreateDistributionQueueLimitFieldsInput {
  cdp?: number | null;
  daily?: number | null;
  filter?: (any | null)[] | null;
  monthly?: number | null;
  weekend?: number | null;
  weekly?: number | null;
}

/**
 * The CreateDistributionQueueRuleFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueRule class.
 */
export interface CreateDistributionQueueRuleFieldsInput {
  disabled?: boolean | null;
  leads?: string | null;
  leadsFilter?: any | null;
  limits?: (CreateDistributionQueueLimitFieldsInput | null)[] | null;
  name?: string | null;
  slug?: string | null;
  sort?: (any | null)[] | null;
  users?: string | null;
  usersFilter?: any | null;
}

/**
 * The CreateDistributionQueueScheduleFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueSchedule class.
 */
export interface CreateDistributionQueueScheduleFieldsInput {
  FRI?: string | null;
  MON?: string | null;
  SAT?: string | null;
  SUN?: string | null;
  THU?: string | null;
  TUE?: string | null;
  WED?: string | null;
}

/**
 * The CreateDistributionQueueSoundFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueSound class.
 */
export interface CreateDistributionQueueSoundFieldsInput {
  repeat?: number | null;
  sound: string;
}

/**
 * The CreateDistributionQueueViewFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueView class.
 */
export interface CreateDistributionQueueViewFieldsInput {
  color?: string | null;
  icon?: string | null;
  name?: string | null;
  phoneLeadSound?: CreateDistributionQueueSoundFieldsInput | null;
  showCoolDownTime?: boolean | null;
  showDailyCap?: boolean | null;
  showLeadCount?: boolean | null;
  showMonthlyCap?: boolean | null;
  showWeeklyCap?: boolean | null;
  webLeadSound?: CreateDistributionQueueSoundFieldsInput | null;
}

/**
 * The CreateEmailAddressFieldsInput input type is used in operations that involve creation of objects in the EmailAddress class.
 */
export interface CreateEmailAddressFieldsInput {
  address: any;
  isPrimary?: boolean | null;
  objectName?: string | null;
  optOut?: boolean | null;
  type?: string | null;
}

/**
 * The CreateEmailTemplateEditorPermissionFieldsInput input type is used in operations that involve creation of objects in the EmailTemplateEditorPermission class.
 */
export interface CreateEmailTemplateEditorPermissionFieldsInput {
  builder?: boolean | null;
  html?: boolean | null;
  plainText?: boolean | null;
}

/**
 * The CreateEmailTemplateFieldsInput input type is used in operations that involve creation of objects in the EmailTemplate class.
 */
export interface CreateEmailTemplateFieldsInput {
  ACL?: ACLInput | null;
  applyTo?: (any | null)[] | null;
  availableFor?: (any | null)[] | null;
  content: string;
  createdAt?: any | null;
  enabled?: boolean | null;
  forAutomation?: boolean | null;
  jsonContent?: any | null;
  lead?: boolean | null;
  loanProposal?: boolean | null;
  objectName?: string | null;
  owner?: UserPointerInput | null;
  previewSettings?: any | null;
  subject?: string | null;
  title: string;
  type?: string | null;
  updatedAt?: any | null;
}

export interface CreateEmailTemplateInput {
  clientMutationId?: string | null;
  fields?: CreateEmailTemplateFieldsInput | null;
}

/**
 * The CreateFileFieldsInput input type is used in operations that involve creation of objects in the File class.
 */
export interface CreateFileFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  isPublic?: boolean | null;
  mimeType?: string | null;
  name?: string | null;
  original?: string | null;
  size?: number | null;
  updatedAt?: any | null;
  uploadAttempts?: number | null;
  uploadStatus?: string | null;
  uploaded?: boolean | null;
  url?: string | null;
}

/**
 * The CreateFlyerFieldsInput input type is used in operations that involve creation of objects in the Flyer class.
 */
export interface CreateFlyerFieldsInput {
  ACL?: ACLInput | null;
  applyTo?: string | null;
  availableFor?: (any | null)[] | null;
  availableForType?: (any | null)[] | null;
  content: string;
  createdAt?: any | null;
  enabled?: boolean | null;
  jsonContent?: any | null;
  objectName?: string | null;
  owner?: UserPointerInput | null;
  previewSettings?: CreatePreviewSettingsFieldsInput | null;
  title: string;
  updatedAt?: any | null;
}

export interface CreateFlyerInput {
  clientMutationId?: string | null;
  fields?: CreateFlyerFieldsInput | null;
}

/**
 * The CreateFlyerPermissionFieldsInput input type is used in operations that involve creation of objects in the FlyerPermission class.
 */
export interface CreateFlyerPermissionFieldsInput {
  create?: boolean | null;
  send?: boolean | null;
}

/**
 * The CreateHomeBuyingProcessFieldsInput input type is used in operations that involve creation of objects in the HomeBuyingProcess class.
 */
export interface CreateHomeBuyingProcessFieldsInput {
  offerPlans?: string | null;
  stage?: string | null;
}

/**
 * The CreateIdentityFieldsInput input type is used in operations that involve creation of objects in the Identity class.
 */
export interface CreateIdentityFieldsInput {
  accessToken: string;
  enabled?: boolean | null;
  metadata?: CreateIdentityMetadataFieldsInput | null;
  provider: string;
  refreshToken?: string | null;
  scope?: (any | null)[] | null;
  updatedAt: any;
  userEmail?: string | null;
  userId: string;
}

/**
 * The CreateIdentityMetadataFieldsInput input type is used in operations that involve creation of objects in the IdentityMetadata class.
 */
export interface CreateIdentityMetadataFieldsInput {
  syncEmails?: string | null;
}

/**
 * The CreateLeadFieldsInput input type is used in operations that involve creation of objects in the Lead class.
 */
export interface CreateLeadFieldsInput {
  ACL?: ACLInput | null;
  a1822Group?: string | null;
  accountNumber?: string | null;
  activeLoan?: string | null;
  activeNetworkReferral?: boolean | null;
  activityTypeId?: string | null;
  adKeyword?: string | null;
  addedListReports?: boolean | null;
  addedSimpleNexus?: boolean | null;
  additionalCashAfterCurrentBalancePaidOff?: number | null;
  additionalInformation?: string | null;
  adwordCampaign?: string | null;
  affiliateName?: string | null;
  ageAtApplication?: string | null;
  ageOfYoungestBorrower?: number | null;
  ageSeconds?: string | null;
  agentAssigned?: boolean | null;
  agentAssignedDateTime?: any | null;
  agentName?: string | null;
  aggMtgBalance?: number | null;
  alternateLeadSource?: string | null;
  amortizationType?: string | null;
  annualReduction?: number | null;
  anotherLenderAndActive?: boolean | null;
  anotherLenderAndClosed?: any | null;
  apAgeSeconds?: string | null;
  apDomain?: string | null;
  apFormInputMethod?: string | null;
  apMaskedCertUrl?: string | null;
  apRetainEmailMatch?: string | null;
  apRetainExpire?: string | null;
  apRetainPhoneMatch?: string | null;
  apRetainSuccess?: string | null;
  apiDuplicate?: boolean | null;
  appraisalFee?: number | null;
  appraisalReceivedDate?: any | null;
  appraisalRequestedDate?: any | null;
  appraisedValue?: number | null;
  apr?: number | null;
  aptNumber?: string | null;
  armType?: string | null;
  assignedAt?: any | null;
  assignedBy?: UserPointerInput | null;
  assignedTo?: UserPointerInput | null;
  assignedToEmail?: string | null;
  assignedToNmls?: string | null;
  assignedToObjectId?: string | null;
  assignedToObjectName?: string | null;
  assignedUserApplyUrl?: string | null;
  assignedUserCustom6?: string | null;
  assignedUserCustom7?: string | null;
  assignedUserEmail?: string | null;
  assignedUserFive9Id?: string | null;
  assignedUserObjectId?: string | null;
  assignedUserTeam?: string | null;
  attorneyMobilePhone?: any | null;
  attorneyName?: string | null;
  attorneyPersonalEmail?: any | null;
  autoLoanBal?: number | null;
  autoLoanDel?: number | null;
  autoLoanQty?: number | null;
  availableHomeEquity?: number | null;
  bankerName?: string | null;
  bankingBridgeDashboardLink?: string | null;
  bankingBridgeLastEvent?: string | null;
  bankingBridgeLastEventDate?: any | null;
  bankingBridgeSubscriptionId?: string | null;
  bipCurrentAge?: string | null;
  bipCurrentLTV?: string | null;
  bipCurrentLoanAmount?: number | null;
  bipEPOExpirationDate?: any | null;
  bipEngagementDate?: any | null;
  bipFICOTier?: string | null;
  bipHELOCBalance?: number | null;
  bipHomeValue?: number | null;
  bipMortgageInquirySegment?: string | null;
  bipOpportunityDate?: any | null;
  bipOpportunityName?: string | null;
  bipPublicLoanAmount?: number | null;
  bipPublicLoanClosingLTV?: string | null;
  bipPublicLoanFundedDate?: any | null;
  bipPublicLoanInterestRate?: string | null;
  bipPublicLoanTerm?: string | null;
  bipPublicLoanType?: string | null;
  bipRevolvingDebt?: number | null;
  bipTappableEquity?: string | null;
  boCell?: string | null;
  boDob?: any | null;
  boEmployerAddress?: string | null;
  boEmployerCity?: string | null;
  boEmployerName?: string | null;
  boEmployerState?: string | null;
  boEmployerZip?: string | null;
  boEmploymentLengthInYears?: number | null;
  boFirstName?: string | null;
  boHomeEMail?: string | null;
  boHomePhone?: string | null;
  boLastName?: string | null;
  boWorkEMail?: string | null;
  boWorkPhone?: string | null;
  borrowerAcknowledged?: string | null;
  borrowerAnnualIncome?: number | null;
  borrowerBankruptcy?: boolean | null;
  borrowerBirthday?: any | null;
  borrowerCompany?: string | null;
  borrowerCreditRating?: string | null;
  borrowerCreditScore?: number | null;
  borrowerEmail?: any | null;
  borrowerEmails?: (any | null)[] | null;
  borrowerEmploymentOccupation?: string | null;
  borrowerFirstName?: string | null;
  borrowerForeclosure?: string | null;
  borrowerHomePhone?: any | null;
  borrowerHomePhoneOptOut?: boolean | null;
  borrowerInitialCDESignedDate?: any | null;
  borrowerIntendToContinueDate?: any | null;
  borrowerLastName?: string | null;
  borrowerLeESignedDate?: any | null;
  borrowerMaritalStatus?: string | null;
  borrowerMiddleName?: string | null;
  borrowerMobilePhone?: any | null;
  borrowerMobilePhoneOptOut?: boolean | null;
  borrowerPersonalEmail?: any | null;
  borrowerPersonalEmailOptOut?: boolean | null;
  borrowerPhone?: any | null;
  borrowerPhones?: (any | null)[] | null;
  borrowerPrimaryPhoneOptOut?: boolean | null;
  borrowerSSN?: string | null;
  borrowerVeteranStatus?: string | null;
  borrowerWorkEmail?: any | null;
  borrowerWorkPhone?: any | null;
  borrowerWorkPhoneOptOut?: boolean | null;
  borrowerisMilitary?: boolean | null;
  botSplashConversationUrl?: string | null;
  botSplashLastMessageReceivedDate?: any | null;
  botSplashSmsMessageSent?: string | null;
  botsplashLastMessageSentDate?: any | null;
  botsplashLinkClickTracking?: string | null;
  botsplashListenerName?: string | null;
  botsplashOriginalUrl?: string | null;
  botsplashTimestamp?: any | null;
  branchGroup?: string | null;
  branchId?: string | null;
  branchOfService?: string | null;
  browser?: string | null;
  bsLastMessageReceivedDate?: any | null;
  buyerPersonalityAndHobbies?: string | null;
  buyerRealtorAddressCity?: string | null;
  buyerRealtorAddressState?: string | null;
  buyerRealtorAddressStreet?: string | null;
  buyerRealtorAddressZip?: string | null;
  buyerRealtorContactId?: string | null;
  buyerRealtorFirstName?: string | null;
  buyerRealtorLastName?: string | null;
  buyerRealtorMobilePhone?: any | null;
  buyerRealtorName?: string | null;
  buyerRealtorPersonalEmail?: any | null;
  buyersAgentCity?: string | null;
  buyersAgentState?: string | null;
  calendlyTime?: any | null;
  callAttempts?: number | null;
  cashOut?: number | null;
  cert?: string | null;
  certId?: string | null;
  certUrl?: string | null;
  certificateSent?: boolean | null;
  channel?: string | null;
  cis?: number | null;
  citizenshipStatus?: string | null;
  closingDate?: any | null;
  cltv?: number | null;
  coBorrowerBirthday?: any | null;
  coBorrowerCreditRating?: string | null;
  coBorrowerCreditScore?: number | null;
  coBorrowerFirstName?: string | null;
  coBorrowerHomePhone?: any | null;
  coBorrowerInitialCDESignedDate?: any | null;
  coBorrowerIsMilitary?: boolean | null;
  coBorrowerLastName?: string | null;
  coBorrowerLeESignedDate?: any | null;
  coBorrowerMiddleName?: string | null;
  coBorrowerMobilePhone?: any | null;
  coBorrowerPhone?: any | null;
  coBorrowerResidentialAddress?: CreateAddressFieldsInput | null;
  coBorrowerSSN?: string | null;
  coBorrowerWorkPhone?: any | null;
  coCell?: string | null;
  coDob?: any | null;
  coEmployerAddress?: string | null;
  coEmployerCity?: string | null;
  coEmployerName?: string | null;
  coEmployerState?: string | null;
  coEmployerZip?: string | null;
  coEmploymentLengthInYears?: number | null;
  coFirstName?: string | null;
  coHomeEmail?: string | null;
  coHomePhone?: string | null;
  coLastName?: string | null;
  coWorkEmail?: string | null;
  coWorkPhone?: string | null;
  coborrowerTimeAtPreviousResidence?: number | null;
  collateralPropertyType?: string | null;
  collateralValueVerification?: boolean | null;
  contact?: any | null;
  contactAction?: string | null;
  contactConfirmed?: boolean | null;
  contactConfirmedDateTime?: any | null;
  contactEmail?: any | null;
  contactFirstName?: string | null;
  contactId?: string | null;
  contactLastName?: string | null;
  contactPhone?: any | null;
  contactStatus?: string | null;
  contactedStatusAttempts?: number | null;
  contingencyDate?: any | null;
  costCenter?: number | null;
  costco?: CreateCostcoFieldsInput | null;
  costcoDiscount?: boolean | null;
  costcoLenderFee?: string | null;
  costcoMembershipId?: string | null;
  createdAt?: any | null;
  createdBy?: UserPointerInput | null;
  creditCardDel?: number | null;
  creditCardQty?: number | null;
  creditReportPulledDate?: any | null;
  currentCashOut?: number | null;
  currentCombDebtPayment?: number | null;
  currentInstallmentDebtBalance?: number | null;
  currentInterestRate?: number | null;
  currentLeadStatus?: string | null;
  currentLoanAmount?: number | null;
  currentMortgageBalance?: number | null;
  currentMortgageDel?: number | null;
  currentMortgageLender?: string | null;
  currentMortgageMonthlyPayment?: number | null;
  currentMortgagePayment?: number | null;
  currentMortgageQty?: number | null;
  currentMortgageRate?: number | null;
  currentNeedSituation?: string | null;
  currentRate?: number | null;
  currentRevolvingDebtBalance?: number | null;
  currentStageActionCount?: number | null;
  currentStatusActionCount?: number | null;
  currentTotalDebtBalance?: number | null;
  currentlyRentOrOwn?: string | null;
  customRank?: string | null;
  customizedMessageToBorrower?: string | null;
  dayOfTheWeek?: string | null;
  declaredBankruptcy?: string | null;
  denialDate?: any | null;
  desiredAPR?: number | null;
  desiredRate?: number | null;
  deviceTypeUsed?: string | null;
  dischargedBankruptcyMonth?: string | null;
  disclosure?: string | null;
  disclosureViewedDateBorrower?: any | null;
  disclosureViewedDateCoBorrower?: any | null;
  distribute?: boolean | null;
  distributedAt?: any | null;
  distributedBy?: DistributionQueuePointerInput | null;
  distributedByObjectId?: string | null;
  distributionRules?: (any | null)[] | null;
  dmFico?: number | null;
  dmLTV?: number | null;
  dmLoanTypeFlag?: string | null;
  dncFederal?: string | null;
  dncKnownLitigator?: string | null;
  dncState?: string | null;
  domain?: string | null;
  downPayment?: number | null;
  dti?: number | null;
  duplicateOf?: LeadPointerInput | null;
  duplicateOfObjectId?: string | null;
  duplicates?: LeadRelationInput | null;
  eSignSentDate?: any | null;
  emailVerification?: string | null;
  employerName?: string | null;
  employersAllBorrowers?: string | null;
  encompassApr?: string | null;
  encompassBorrowerMiddleFicoScore?: number | null;
  encompassClosingDisclosureReceivedDate?: any | null;
  encompassClosingDisclosureSentDate?: any | null;
  encompassCoBorrowerMiddleFicoScore?: number | null;
  encompassCreditReportReceivedDate?: any | null;
  encompassESignConsentDate?: any | null;
  encompassInitialTilDisclosureProvidedDate?: any | null;
  encompassInitialTilDisclosureReceivedDate?: any | null;
  encompassInterviewerEmail?: string | null;
  encompassLeadId?: string | null;
  encompassLoanNumber?: string | null;
  encompassLoanPurposeTypeUrla?: string | null;
  encompassLoanType?: string | null;
  encompassLtv?: number | null;
  encompassMilestoneCurrentDate?: any | null;
  encompassPropertyState?: string | null;
  encompassPropertyStreetAddress?: string | null;
  encompassPropertyUsageType?: string | null;
  encompassReferringBanker?: string | null;
  encompassRequestedInterestRate?: number | null;
  encompassResubmitted?: boolean | null;
  encompassTotalLoanAmount?: number | null;
  epoEndDate?: any | null;
  equityInHome?: number | null;
  establishedRelationship?: boolean | null;
  estimatedCloseDate?: any | null;
  estimatedCreditProfile?: string | null;
  eventAttended?: boolean | null;
  eventDate?: any | null;
  eventDuration?: string | null;
  eventManager?: boolean | null;
  exemptFromFundingFee?: boolean | null;
  existingHomeValue?: number | null;
  externalChannel?: string | null;
  externalLastUpdatedDate?: any | null;
  faBranch?: string | null;
  fanniePIW?: string | null;
  fccConsent?: string | null;
  fhaMtgBalance?: number | null;
  fico?: number | null;
  field1?: string | null;
  field2?: string | null;
  field3?: string | null;
  field4?: string | null;
  fileStartedDate?: any | null;
  fileStarterLosId?: string | null;
  fileStarterName?: string | null;
  filterId?: string | null;
  finalLoPrice?: string | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstARMRateResetTermInMonths?: number | null;
  firstAmortizationTermMonths?: number | null;
  firstApr?: number | null;
  firstAssignCostCenter?: string | null;
  firstAssignedTo?: UserPointerInput | null;
  firstAssignedToObjectId?: string | null;
  firstContact?: string | null;
  firstContactAction?: string | null;
  firstContactActionDate?: any | null;
  firstContactCostCenter?: string | null;
  firstContactDate?: any | null;
  firstContactLoEmail?: string | null;
  firstDiscountPoints?: number | null;
  firstDistributedAt?: any | null;
  firstDistributedBy?: DistributionQueuePointerInput | null;
  firstDocumentType?: string | null;
  firstDownPayment?: number | null;
  firstDownPaymentUnit?: string | null;
  firstExpirationDate?: any | null;
  firstInitialARMTermInMonths?: number | null;
  firstInterestOnly?: string | null;
  firstInterestOnlyPeriodInMonths?: number | null;
  firstInterestRate?: number | null;
  firstLienBalance?: number | null;
  firstLoanAmount?: number | null;
  firstLoanAmountEncompass?: number | null;
  firstLoanProgramCategory?: string | null;
  firstLoanProgramName?: string | null;
  firstLoanProgramProductType?: string | null;
  firstLoanTermMonths?: number | null;
  firstLockTerm?: string | null;
  firstMonthlyInsurancePayment?: number | null;
  firstMonthlyPaymentUnit?: string | null;
  firstMonthlyPayments?: number | null;
  firstMonthlyPrincipalAndInterestPayment?: number | null;
  firstMortgageInsurancePayment?: number | null;
  firstOfferId?: string | null;
  firstOfferType?: string | null;
  firstOfferVendorId?: string | null;
  firstOriginationFee?: number | null;
  firstOriginationFeeOption?: string | null;
  firstOriginationFeeUnit?: string | null;
  firstOtherLenderFees?: number | null;
  firstPaymentDate?: any | null;
  firstProductName?: string | null;
  firstRatePercent?: number | null;
  firstRateType?: string | null;
  firstSMSReceivedAt?: any | null;
  firstTerm?: number | null;
  firstTimeHomeBuyer?: boolean | null;
  firstTransactionType?: string | null;
  firstUrl?: string | null;
  firstUseOfVaProgram?: boolean | null;
  five9FirstAssignedEmail?: string | null;
  five9SpamAni?: string | null;
  frBranch?: string | null;
  freddiePIW?: string | null;
  fundedDate?: any | null;
  gclid?: string | null;
  gosId?: number | null;
  groupId?: string | null;
  haveRealtor?: boolean | null;
  helocPaidThisRefinance?: boolean | null;
  highMtgBalance?: number | null;
  homeBuyingProcess?: CreateHomeBuyingProcessFieldsInput | null;
  homeBuyingProcessOfferPlans?: string | null;
  homeBuyingProcessStage?: string | null;
  homeEquityConversionInsight?: number | null;
  homeEquityLoanBalance?: number | null;
  incomeVerification?: boolean | null;
  individualOrJoinApplication?: string | null;
  individualOrJointApplication?: string | null;
  inquiryTimeOfDay?: string | null;
  intendedPropertyUse?: string | null;
  interestRatePercent?: number | null;
  introduction?: string | null;
  investor?: string | null;
  investorName?: string | null;
  ip?: string | null;
  ipGeo?: string | null;
  isBuyersAgent?: boolean | null;
  isSellingOrListingAgent?: boolean | null;
  juniorBankerEmail?: string | null;
  juniorBankerName?: string | null;
  keywords?: string | null;
  khlLoEmail?: string | null;
  kpm?: string | null;
  landingPageId?: string | null;
  languagePreference?: string | null;
  lastAction?: string | null;
  lastActionDate?: any | null;
  lastActionLoEmail?: string | null;
  lastActionTriggered?: string | null;
  lastCallMadeAt?: any | null;
  lastCallReceivedAt?: any | null;
  lastCompletedMilestone?: string | null;
  lastContactedAt?: any | null;
  lastEmailReceivedAt?: any | null;
  lastEmailSentAt?: any | null;
  lastFundedCLTV?: number | null;
  lastFundedCashOutAmount?: number | null;
  lastFundedCreditScore?: number | null;
  lastFundedDMILoanNumber?: string | null;
  lastFundedDate?: any | null;
  lastFundedDisbursementDate?: any | null;
  lastFundedEPODate?: any | null;
  lastFundedFirstPaymentDate?: any | null;
  lastFundedFundsReleased?: any | null;
  lastFundedInterestRate?: number | null;
  lastFundedInvestor?: string | null;
  lastFundedLOEmail?: string | null;
  lastFundedLTV?: number | null;
  lastFundedLeadSource?: string | null;
  lastFundedLoanAmount?: number | null;
  lastFundedLoanDelinquencyDate?: any | null;
  lastFundedLoanMaturesDate?: any | null;
  lastFundedLoanNumber?: string | null;
  lastFundedLoanOfficer?: string | null;
  lastFundedLoanProduct?: string | null;
  lastFundedLoanProgram?: string | null;
  lastFundedLoanPurpose?: string | null;
  lastFundedLoanTermInMonths?: number | null;
  lastFundedLoanTradeTotalBuyPrice?: number | null;
  lastFundedLoanType?: string | null;
  lastFundedMIPFundingAmount?: number | null;
  lastFundedMortgageInsuranceMonthlyPayment?: number | null;
  lastFundedNextPaymentDueDate?: any | null;
  lastFundedOccupancyType?: string | null;
  lastFundedOriginationDate?: any | null;
  lastFundedPI?: number | null;
  lastFundedPMIAmount?: number | null;
  lastFundedPropertyType?: string | null;
  lastFundedPropertyValue?: number | null;
  lastFundedPurchaseAdviceDate?: any | null;
  lastFundedPurchasePrice?: number | null;
  lastFundedRealtorNotes?: string | null;
  lastFundedSubjectPropertyAddress?: string | null;
  lastFundedSubjectPropertyCity?: string | null;
  lastFundedSubjectPropertyCounty?: string | null;
  lastFundedSubjectPropertyInsurance?: number | null;
  lastFundedSubjectPropertyState?: string | null;
  lastFundedSubjectPropertyTaxes?: number | null;
  lastFundedSubjectPropertyZip?: string | null;
  lastFundedTotalMonthlyPayment?: number | null;
  lastFundedWaiveEscrow?: boolean | null;
  lastIncomingContactedAt?: any | null;
  lastNetworkNoteDateTime?: any | null;
  lastNote?: string | null;
  lastNoteAddedAt?: any | null;
  lastOutgoingContactedAt?: any | null;
  lastSMSReceivedAt?: any | null;
  lastSMSSentAt?: any | null;
  leSentDate?: any | null;
  leadPricePaid?: number | null;
  leadProviderName?: string | null;
  leadReceivedBy?: string | null;
  leadSource?: LeadSourcePointerInput | null;
  leadSourceAppName?: string | null;
  leadSourceCampaign?: string | null;
  leadSourceId?: string | null;
  leadSourceMeta?: CreateLeadSourceMetaFieldsInput | null;
  leadSourceObjectId?: string | null;
  leadSourceObjectName?: string | null;
  leadSourcePartner?: string | null;
  leadSourceTitle?: string | null;
  leadStatus?: StatusInput | null;
  leadStatusActionCount?: number | null;
  leadStatusUpdatedAt?: any | null;
  leadTransferDate?: any | null;
  leadTransferred?: boolean | null;
  leadTransferredBy?: string | null;
  leakage?: number | null;
  lenderId?: string | null;
  lenderName?: string | null;
  loPreSigned?: number | null;
  loaCashOutReason?: string | null;
  loaCreditOrAutoLoans?: boolean | null;
  loaElseOnBalances?: string | null;
  loaEmploymentStatus?: string | null;
  loaEstimatedAppraisedValue?: number | null;
  loaMonthlyPayments?: number | null;
  loaMortgageBalance?: number | null;
  loaOtherLienBalance?: number | null;
  loaPersonalLoans?: boolean | null;
  loaVaStatement?: string | null;
  loan?: any | null;
  loanAmortizationType?: string | null;
  loanAmount?: number | null;
  loanApplicationId?: string | null;
  loanOfficer?: string | null;
  loanProduct?: string | null;
  loanProgram?: string | null;
  loanProgramName?: string | null;
  loanProposals?: number | null;
  loanPurchasePrice?: number | null;
  loanPurpose?: string | null;
  loanRequestCompleteDate?: any | null;
  loanStartedSource?: string | null;
  loanStatus?: string | null;
  loanTeamMemberName?: string | null;
  loanTerm?: string | null;
  loanTermYears?: number | null;
  loanTypeNotes?: string | null;
  loanTypePreferred?: string | null;
  locationMovingTo?: string | null;
  lockDate?: any | null;
  lockExpiryDate?: any | null;
  lockInDays?: string | null;
  lockStatus?: boolean | null;
  ltv?: number | null;
  mLOComments?: string | null;
  mailerUrl?: string | null;
  managerName?: string | null;
  maritalStatus?: string | null;
  maritalStatusAndKids?: string | null;
  members?: (CreateLeadMemberFieldsInput | null)[] | null;
  milestoneDateSubmittal?: any | null;
  militaryBranch?: string | null;
  militaryStatus?: string | null;
  mixRank?: number | null;
  mixTier?: number | null;
  mloEligibilityDate?: any | null;
  mloNextFollowUpDate?: any | null;
  mloStatusDate?: any | null;
  mloStatusGeneral?: string | null;
  mloStatusStl?: string | null;
  mobileDeviceIndicator?: string | null;
  monthlyReduction?: number | null;
  moomActionStatusDate?: any | null;
  moomActionStatusPurchase?: string | null;
  moomActionStatusRefi?: string | null;
  mortech?: CreateMortechFieldsInput | null;
  mortgageCoordinatorEmail?: string | null;
  mutualBuyersBenefitAmount?: number | null;
  mutualBuyersBenefitProgramOffer?: string | null;
  mutualDate?: any | null;
  mutualFlag?: boolean | null;
  mutualGiveAway?: boolean | null;
  needListingAgentToSell?: boolean | null;
  needPurchaseRealtor?: boolean | null;
  needSellingRealtor?: boolean | null;
  networkNotes?: string | null;
  newOrExitingProperty?: string | null;
  newPayment?: number | null;
  nextReminderDate?: any | null;
  numberOfUnits?: number | null;
  objectIcon?: string | null;
  objectName?: string | null;
  okToSentToNetwork?: boolean | null;
  oldCRMId?: string | null;
  orderId?: string | null;
  original?: any | null;
  originalBalance?: number | null;
  originalLeadSource?: LeadSourcePointerInput | null;
  originalLoanNumber?: string | null;
  originalMortgageAmount?: number | null;
  originalOriginationDate?: any | null;
  originalTerm?: number | null;
  otherDel?: number | null;
  otherIncomeSource?: string | null;
  otherQty?: number | null;
  outOfNetworkOther?: string | null;
  outOfNetworkReason?: string | null;
  ownUpConcierge?: boolean | null;
  ownerFullName?: string | null;
  ownerNMLS?: string | null;
  ownerObjectId?: string | null;
  pageName?: string | null;
  pageUrl?: string | null;
  pageUuid?: string | null;
  partnerId?: number | null;
  perksEmployerName?: string | null;
  perksFilterId?: string | null;
  perksReferralSource?: string | null;
  phones?: (any | null)[] | null;
  portfolioRetentionLoanNumber?: string | null;
  portfolioRetentionSoldTo?: string | null;
  portfolioSoldTo?: any | null;
  portfolioSoldToDate?: any | null;
  postalCode?: string | null;
  preferredTimeToContact?: string | null;
  prequalInterest?: boolean | null;
  prequalSentDateInitial?: any | null;
  prequalSentLo?: string | null;
  prequalStatus?: string | null;
  presentCltv?: number | null;
  presentLtv?: number | null;
  previousAgent?: boolean | null;
  previousAgentYesNoNotes?: string | null;
  previousAssignedTo?: UserPointerInput | null;
  previousAssignedToObjectId?: string | null;
  previousAssigneeName?: string | null;
  previousDistributedAt?: any | null;
  previousDistributedBy?: DistributionQueuePointerInput | null;
  previousEPODate?: any | null;
  previousFirstPaymentDate?: any | null;
  previousLoName?: string | null;
  priceMaxApprovalAmount?: string | null;
  pricePaid?: number | null;
  pricingType?: string | null;
  primaryMortgageBalance?: number | null;
  priority?: CreateLeadPriorityFieldsInput | null;
  prioritySequence?: number | null;
  privateMortgageInsuranceRequired?: boolean | null;
  proPair?: CreateProPairFieldsInput | null;
  proPairCustomRank?: number | null;
  proPairInput?: string | null;
  proPairInputFa?: string | null;
  proPairRank?: number | null;
  proPairRecState?: string | null;
  processingFee?: number | null;
  processorNameCustom?: string | null;
  productionCenter?: string | null;
  profitability?: string | null;
  property?: CreatePropertyFieldsInput | null;
  propertyAddress?: string | null;
  propertyCity?: string | null;
  propertyCounty?: string | null;
  propertyEstimatedValue?: number | null;
  propertyFound?: string | null;
  propertyLoanType?: string | null;
  propertyNeedsInfo?: string | null;
  propertyOccupancy?: string | null;
  propertyPurchaseYear?: number | null;
  propertyState?: string | null;
  propertyStreet?: string | null;
  propertyType?: string | null;
  propertyTypeDesired?: string | null;
  propertyUse?: string | null;
  propertyValue?: number | null;
  propertyWillBe?: string | null;
  propertyZip?: string | null;
  proposedCltv?: number | null;
  proposedLtv?: number | null;
  providerCreditScore?: number | null;
  pubId?: string | null;
  purchaseAdviceDate?: any | null;
  purchaseJourneyStage?: string | null;
  purchasePrice?: number | null;
  purposeOfLoan?: string | null;
  qualDate?: any | null;
  queId?: string | null;
  queuedCall?: string | null;
  quotedLoanProgram?: string | null;
  ratePlugContactDate?: any | null;
  ratePlugContactPreference?: string | null;
  ratePlugContactSubject?: string | null;
  ratePlugMatchDate?: any | null;
  ratePlugMatchStatus?: string | null;
  ratePreference?: string | null;
  rateTypeDesired?: string | null;
  reactivationDate?: any | null;
  realtagLink?: boolean | null;
  realtorLicenseNumber?: string | null;
  realtorOrigin?: string | null;
  reasonForMove?: string | null;
  recentHomeOwnerPolicy?: boolean | null;
  referenceSource?: string | null;
  referralAgentContactId?: string | null;
  referralAgentFirstName?: string | null;
  referralAgentLastName?: string | null;
  referralAgentMobilePhone?: any | null;
  referralAgentName?: string | null;
  referralAgentPersonalEmail?: any | null;
  referredTo?: string | null;
  referredToNetwork?: boolean | null;
  referredToNetworkDateTime?: any | null;
  referringCompanyName?: string | null;
  referringFirstName?: string | null;
  referringKWMarketCenter?: string | null;
  referringKWMarketCenterNumber?: string | null;
  referringKWRegion?: string | null;
  referringKWRegionName?: string | null;
  referringKWUID?: string | null;
  referringLastName?: string | null;
  referringMobile?: string | null;
  referringTeamEmail?: string | null;
  referringTeamId?: string | null;
  referringTeamName?: string | null;
  referringTeamPhone?: string | null;
  refinanceOrHEReason?: string | null;
  relcuLoan?: RelcuLoanPointerInput | null;
  relcuLoanObjectId?: string | null;
  requestedReverseMortgageAgent?: string | null;
  requestedReverseMortgageDate?: any | null;
  requestedSpanishSpeakerAgent?: string | null;
  requestedSpanishSpeakerDate?: any | null;
  resNum?: string | null;
  residentialAddress?: CreateAddressFieldsInput | null;
  residentialAddressCity?: string | null;
  residentialAddressState?: string | null;
  residentialAddressStreet?: string | null;
  residentialAddressZip?: string | null;
  rvLoanId?: string | null;
  rvLoanNumber?: number | null;
  sbActExec?: string | null;
  sbActExecEmail?: string | null;
  sbAlertType?: string | null;
  sbBrokerLenderName?: string | null;
  sbBrokerLoEmail?: string | null;
  sbBrokerLoPhone?: string | null;
  sbCRMId?: string | null;
  sbCashOutAlertDateTime?: any | null;
  sbCashOutAlertMessage?: string | null;
  sbCreditAlertDateTime?: any | null;
  sbCreditAlertMessage?: string | null;
  sbCurrentCreditScore?: string | null;
  sbEpoAlertDateTime?: any | null;
  sbEpoAlertMessage?: string | null;
  sbEquityAlertDateTime?: any | null;
  sbEquityAlertMessage?: string | null;
  sbFicoTier?: string | null;
  sbInvestor?: string | null;
  sbLensAlertDateTime?: any | null;
  sbLensAlertMessage?: string | null;
  sbListingAlertDateTime?: any | null;
  sbListingAlertMessage?: string | null;
  sbLoanChannel?: string | null;
  sbLoanOfficerEmail?: string | null;
  sbLoanOfficerName?: string | null;
  sbMarketAlertDateTime?: any | null;
  sbMarketAlertMessage?: string | null;
  sbMiRemovalAlertDateTime?: any | null;
  sbMiRemovalAlertMessage?: string | null;
  sbNMLS?: string | null;
  sbOther1?: string | null;
  sbRateTermAlertDateTime?: any | null;
  sbRateTermAlertMessage?: string | null;
  sbRateWatchAlertDateTime?: any | null;
  sbRateWatchAlertMessage?: string | null;
  sbReverseAlertDateTime?: any | null;
  sbReverseAlertMessage?: string | null;
  sbRiskRetentionAlertDateTime?: any | null;
  sbRiskRetentionAlertMessage?: string | null;
  sbSegmentCode?: string | null;
  sbSentToDate?: any | null;
  scoreBucket?: string | null;
  secondARMRateResetTermInMonths?: number | null;
  secondAmortizationTermMonths?: number | null;
  secondApr?: number | null;
  secondDiscountPoints?: number | null;
  secondDocumentType?: string | null;
  secondDownPayment?: number | null;
  secondDownPaymentUnit?: string | null;
  secondExpirationDate?: any | null;
  secondInitialARMTermInMonths?: number | null;
  secondInterestOnly?: string | null;
  secondInterestOnlyPeriodInMonths?: number | null;
  secondInterestRate?: number | null;
  secondLoanAmount?: number | null;
  secondLoanProgramCategory?: string | null;
  secondLoanProgramName?: string | null;
  secondLoanProgramProductType?: string | null;
  secondLoanTermMonths?: number | null;
  secondLockTerm?: string | null;
  secondMonthlyInsurancePayment?: number | null;
  secondMonthlyPaymentUnit?: string | null;
  secondMonthlyPayments?: number | null;
  secondMonthlyPrincipalAndInterestPayment?: number | null;
  secondMortgageInsurancePayment?: number | null;
  secondOfferId?: string | null;
  secondOfferType?: string | null;
  secondOfferVendorId?: string | null;
  secondOriginationFee?: number | null;
  secondOriginationFeeOption?: string | null;
  secondOriginationFeeUnit?: string | null;
  secondOtherLenderFees?: number | null;
  secondProductName?: string | null;
  secondTerm?: number | null;
  secondTransactionType?: string | null;
  secondUrl?: string | null;
  secondaryFinancing?: string | null;
  sellerCreditDesired?: string | null;
  sellerRealtorAddressCity?: string | null;
  sellerRealtorAddressState?: string | null;
  sellerRealtorAddressStreet?: string | null;
  sellerRealtorAddressZip?: string | null;
  sellerRealtorFirstName?: string | null;
  sellerRealtorLastName?: string | null;
  sellerRealtorMobilePhone?: any | null;
  sellerRealtorName?: string | null;
  sellerRealtorPersonalEmail?: any | null;
  sentToNetwork?: boolean | null;
  sentToNetworkDateTime?: any | null;
  sequence?: number | null;
  serviceTicketType?: string | null;
  servicing?: string | null;
  servicingRetainedOrReleased?: string | null;
  sessionId?: string | null;
  showProof?: string | null;
  sourceKeyId?: number | null;
  stat?: CreateLeadStatFieldsInput | null;
  stpDate?: any | null;
  studentLoanBal?: number | null;
  studentLoanDel?: number | null;
  studentLoanQty?: number | null;
  subId?: string | null;
  subjectAddress?: string | null;
  subjectCity?: string | null;
  subjectCounty?: string | null;
  subjectPropertyType?: string | null;
  subjectState?: string | null;
  subjectZip?: string | null;
  tags?: (any | null)[] | null;
  targetedRate?: number | null;
  tdEncompassDate?: any | null;
  teId?: string | null;
  teLoanStatus?: string | null;
  teToken?: string | null;
  team?: string | null;
  telesignPhoneLocationZip?: string | null;
  telesignPhoneTypeCode?: string | null;
  telesignPhoneTypeDescription?: string | null;
  termDisplayed?: number | null;
  thirdARMRateResetTermInMonths?: number | null;
  thirdAmortizationTermMonths?: number | null;
  thirdApr?: number | null;
  thirdDiscountPoints?: number | null;
  thirdDocumentType?: string | null;
  thirdDownPayment?: number | null;
  thirdDownPaymentUnit?: string | null;
  thirdExpirationDate?: any | null;
  thirdInitialARMTermInMonths?: number | null;
  thirdInterestOnly?: string | null;
  thirdInterestOnlyPeriodInMonths?: number | null;
  thirdInterestRate?: number | null;
  thirdLoanAmount?: number | null;
  thirdLoanProgramCategory?: string | null;
  thirdLoanProgramName?: string | null;
  thirdLoanProgramProductType?: string | null;
  thirdLoanTermMonths?: number | null;
  thirdLockTerm?: string | null;
  thirdMonthlyInsurancePayment?: number | null;
  thirdMonthlyPaymentUnit?: string | null;
  thirdMonthlyPayments?: number | null;
  thirdMonthlyPrincipalAndInterestPayment?: number | null;
  thirdMortgageInsurancePayment?: number | null;
  thirdOfferId?: string | null;
  thirdOfferType?: string | null;
  thirdOfferVendorId?: string | null;
  thirdOriginationFee?: number | null;
  thirdOriginationFeeOption?: string | null;
  thirdOriginationFeeUnit?: string | null;
  thirdOtherLenderFees?: number | null;
  thirdPartyTransfer?: string | null;
  thirdPartyTransferDate?: any | null;
  thirdProductName?: string | null;
  thirdTerm?: number | null;
  thirdTransactionType?: string | null;
  thirdUrl?: string | null;
  timeAtPreviousResidence?: number | null;
  timeAtResidence?: number | null;
  timeSubmitted?: string | null;
  timeframeForMove?: string | null;
  timezone?: string | null;
  totalDel?: number | null;
  totalMonthlyPayment?: number | null;
  transactionType?: string | null;
  transferAgentName?: string | null;
  transferDateTime?: any | null;
  transferUser?: string | null;
  triggerBsPostCheckbox?: boolean | null;
  typeOfVeteran?: string | null;
  underwritingFee?: number | null;
  universalLeadiD?: string | null;
  unixTimestamp?: any | null;
  unsecuredDebtAmount?: number | null;
  updatedAt?: any | null;
  updatedUniversalLeadId?: string | null;
  userName?: string | null;
  usoDonationAmount?: number | null;
  usoDonationSent?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
  utmTerm?: string | null;
  uuid?: string | null;
  vaDischarge?: string | null;
  vaMtgBalance?: number | null;
  variant?: string | null;
  vendorId?: string | null;
  view?: string | null;
  waiveEscrow?: boolean | null;
  webinarAttended?: string | null;
  webinarDate?: any | null;
  webinarTime?: string | null;
  wfaJobs?: (any | null)[] | null;
  workedWithBBMC?: string | null;
  workflows?: (CreateWorkflowStatusFieldsInput | null)[] | null;
  workingWith?: string | null;
  workingWithBanker?: boolean | null;
  workingWithBankerName?: string | null;
  wpm?: string | null;
  yearBuilt?: string | null;
  yearsInProperty?: number | null;
  zillowCompanyRelationShip?: string | null;
  zillowQuoteId?: string | null;
  zillowRequestId?: string | null;
}

/**
 * The CreateLeadMemberFieldsInput input type is used in operations that involve creation of objects in the LeadMember class.
 */
export interface CreateLeadMemberFieldsInput {
  annualIncome?: number | null;
  bankruptcy?: boolean | null;
  contact?: ContactPointerInput | null;
  creditRating?: string | null;
  creditScore?: number | null;
  employmentOccupation?: string | null;
  employmentStatus?: string | null;
  foreclosure?: string | null;
  isMilitary?: boolean | null;
  isPrimary?: boolean | null;
  jobStartedAt?: any | null;
  maritalStatus?: string | null;
  monthlyIncome?: number | null;
  type?: string | null;
  typeElaborate?: string | null;
  veteranStatus?: string | null;
}

/**
 * The CreateLeadPartnerFieldsInput input type is used in operations that involve creation of objects in the LeadPartner class.
 */
export interface CreateLeadPartnerFieldsInput {
  ACL?: ACLInput | null;
  contacts?: (CreateContactFieldsInput | null)[] | null;
  createdAt?: any | null;
  leadSources?: LeadSourceRelationInput | null;
  logo?: string | null;
  objectIcon?: string | null;
  objectName?: string | null;
  title: string;
  updatedAt?: any | null;
}

/**
 * The CreateLeadPriorityFieldsInput input type is used in operations that involve creation of objects in the LeadPriority class.
 */
export interface CreateLeadPriorityFieldsInput {
  enteredAt?: any | null;
  leadPosition?: number | null;
  queue?: PriorityQueuePointerInput | null;
  queuePosition?: number | null;
}

/**
 * The CreateLeadSourceFieldsInput input type is used in operations that involve creation of objects in the LeadSource class.
 */
export interface CreateLeadSourceFieldsInput {
  ACL?: ACLInput | null;
  adCost?: number | null;
  adCostBasis?: string | null;
  costPerLead?: number | null;
  createdAt?: any | null;
  inboundPhoneNumber?: PhoneLineRelationInput | null;
  isActive?: boolean | null;
  leadPartner?: LeadPartnerPointerInput | null;
  leads?: LeadRelationInput | null;
  objectIcon?: string | null;
  objectName?: string | null;
  provider?: string | null;
  proxyPercent?: number | null;
  proxyUrl?: string | null;
  skipForDuplicate?: boolean | null;
  stats?: CreateLeadSourceStatsFieldsInput | null;
  team?: string | null;
  title: string;
  updatedAt?: any | null;
}

/**
 * The CreateLeadSourceMetaFieldsInput input type is used in operations that involve creation of objects in the LeadSourceMeta class.
 */
export interface CreateLeadSourceMetaFieldsInput {
  bankingBridgeDashboardLink?: string | null;
  bankingBridgeLastEvent?: string | null;
  bankingBridgeLastEventDate?: any | null;
  bankingBridgeSubId?: string | null;
  bankingBridgeSubscriptionId?: string | null;
  landingPageUrl?: string | null;
}

/**
 * The CreateLeadSourceStatsFieldsInput input type is used in operations that involve creation of objects in the LeadSourceStats class.
 */
export interface CreateLeadSourceStatsFieldsInput {
  accepted?: number | null;
  balanced?: number | null;
  rejected?: number | null;
}

/**
 * The CreateLeadStatFieldsInput input type is used in operations that involve creation of objects in the LeadStat class.
 */
export interface CreateLeadStatFieldsInput {
  missedCallsCount?: number | null;
  unreadMessagesCount?: number | null;
}

/**
 * The CreateLoanCriteriaFieldsInput input type is used in operations that involve creation of objects in the LoanCriteria class.
 */
export interface CreateLoanCriteriaFieldsInput {
  cashOut?: number | null;
  cltv?: number | null;
  downPayment?: number | null;
  dti?: number | null;
  fico?: (any | null)[] | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstTimeHomeBuyer?: boolean | null;
  firstUseOfVaProgram?: boolean | null;
  isPmiEligible?: boolean | null;
  loanAmount?: number | null;
  loanPurpose?: string | null;
  ltv?: number | null;
  mortech?: CreateOfferMortechFieldsInput | null;
  property?: CreateOfferPropertyFieldsInput | null;
  requiresPestInspection?: boolean | null;
  secondaryFinancing?: string | null;
  totalLoanAmount?: number | null;
  veteranStatus?: string | null;
  waiveEscrow?: boolean | null;
}

/**
 * The CreateLoanEstimateFieldsInput input type is used in operations that involve creation of objects in the LoanEstimate class.
 */
export interface CreateLoanEstimateFieldsInput {
  ACL?: ACLInput | null;
  applyUrl?: string | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  draft?: boolean | null;
  file?: CreateFileFieldsInput | null;
  image?: CreateFileFieldsInput | null;
  lead?: LeadPointerInput | null;
  loanPurpose?: string | null;
  objectName?: string | null;
  offers?: LoanEstimateOfferRelationInput | null;
  owner?: UserPointerInput | null;
  propertyAddress?: string | null;
  propertyAnnualInsurance?: number | null;
  propertyAnnualTax?: number | null;
  propertyCity?: string | null;
  propertyCountry?: string | null;
  propertyCounty?: string | null;
  propertyFipsCode?: string | null;
  propertyOccupancy: string;
  propertyState?: string | null;
  propertyType: string;
  propertyZipCode: string;
  sent?: number | null;
  updatedAt?: any | null;
}

/**
 * The CreateLoanEstimateOfferFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOffer class.
 */
export interface CreateLoanEstimateOfferFieldsInput {
  ACL?: ACLInput | null;
  adminFee?: number | null;
  amiWaiverEligibility?: boolean | null;
  amortizationType?: string | null;
  appraisalFee?: number | null;
  apr?: number | null;
  attorneyFee?: number | null;
  cashAmount?: number | null;
  cashToClose?: number | null;
  closingCostTotal?: number | null;
  cltv?: number | null;
  condoCertificateFee?: number | null;
  conforming?: boolean | null;
  createdAt?: any | null;
  creditReportFee?: number | null;
  creditReportTotal?: number | null;
  currentMortgageBalance?: number | null;
  deleted?: boolean | null;
  discountPoints?: number | null;
  docReviewFee?: number | null;
  documentType?: string | null;
  downPayment?: number | null;
  dti?: number | null;
  earnestMoneyPaid?: number | null;
  electronicDocumentDelivery?: number | null;
  exempt?: boolean | null;
  ff?: number | null;
  fico?: number | null;
  fico1?: number | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstTimeHomeBuyer?: boolean | null;
  firstTimeUse?: boolean | null;
  firstUseOfVaProgram?: boolean | null;
  floodCertificationFee?: number | null;
  initialArmTerm?: string | null;
  insurance?: number | null;
  isHUDReo?: boolean | null;
  isStreamLine?: boolean | null;
  lead?: LeadPointerInput | null;
  lenderCredits?: number | null;
  lendersTitle?: number | null;
  lienAmount?: number | null;
  loanAmount?: number | null;
  loanEstimate?: LoanEstimatePointerInput | null;
  loanProduct?: string | null;
  loanProgram?: string | null;
  loanSafeFee?: number | null;
  loanTerm?: string | null;
  lockInDays?: string | null;
  lockInFee?: number | null;
  ltv?: number | null;
  mersFee?: number | null;
  mip?: number | null;
  monthlyIncome?: number | null;
  monthlyPaymentTotal?: number | null;
  monthlyPremium?: number | null;
  mortech?: CreateLoanEstimateOfferMortechFieldsInput | null;
  objectName?: string | null;
  optimalBlue?: CreateLoanEstimateOptimalBlueFieldsInput | null;
  originationFee?: number | null;
  otherCostTotal?: number | null;
  ownersTitle?: number | null;
  pestInspectionFee?: number | null;
  pi?: number | null;
  pmi?: number | null;
  pmiCompany?: string | null;
  pmiEligible?: boolean | null;
  pmiType?: string | null;
  points?: number | null;
  polly?: CreateLoanEstimateOfferPollyFieldsInput | null;
  prepaidCostTotal?: number | null;
  prepaidInterestAmount?: number | null;
  prepaidInterestDays?: number | null;
  prepaidInterestTotal?: number | null;
  price?: number | null;
  pricingEngine?: string | null;
  productType?: string | null;
  propertyAnnualInsurance?: number | null;
  propertyAnnualTax?: number | null;
  propertyInsurancePrepaidAmount?: number | null;
  propertyInsurancePrepaidMonth?: number | null;
  propertyInsurancePrepaidTotal?: number | null;
  propertyInsuranceYearly?: number | null;
  propertyTaxAmount?: number | null;
  propertyTaxMonth?: number | null;
  propertyTaxTotal?: number | null;
  propertyType?: string | null;
  propertyUse?: string | null;
  propertyValue?: number | null;
  purchasePrice?: number | null;
  rate?: number | null;
  rateId?: string | null;
  rateInvestor?: string | null;
  rateUpdated?: string | null;
  recordingCharges?: number | null;
  secondaryFinancing?: string | null;
  selfEmployed?: boolean | null;
  sellerConcession?: number | null;
  settlementFee?: number | null;
  subordinationFee?: number | null;
  surveyFee?: number | null;
  taxes?: number | null;
  titleCompany?: string | null;
  titleCompanyName?: string | null;
  titleFeeTotal?: number | null;
  titleInsurance?: number | null;
  totalLoanAmount?: number | null;
  transcriptFee?: number | null;
  transferTax?: number | null;
  updatedAt?: any | null;
  verificationFee?: number | null;
  veteranStatus?: string | null;
  waive?: boolean | null;
  waiveEscrow?: boolean | null;
  withAppraisal?: boolean | null;
  withCredit?: boolean | null;
}

export interface CreateLoanEstimateOfferInput {
  clientMutationId?: string | null;
  fields?: CreateLoanEstimateOfferFieldsInput | null;
}

/**
 * The CreateLoanEstimateOfferMortechFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOfferMortech class.
 */
export interface CreateLoanEstimateOfferMortechFieldsInput {
  deliveryType?: string | null;
  investor?: (any | null)[] | null;
  loanProductId?: string | null;
  loanProductName?: string | null;
  source?: string | null;
  view?: string | null;
}

/**
 * The CreateLoanEstimateOfferPollyFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOfferPolly class.
 */
export interface CreateLoanEstimateOfferPollyFieldsInput {
  automatedUnderwritingSystem?: string | null;
  debtServiceCoverageRatio?: number | null;
  monthsReserves?: number | null;
  prepaymentPenalty?: string | null;
}

/**
 * The CreateLoanEstimateOptimalBlueFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOptimalBlue class.
 */
export interface CreateLoanEstimateOptimalBlueFieldsInput {
  automatedUnderwritingSystem?: string | null;
  debtServiceCoverageRatio?: number | null;
  leadSourceMoom?: string | null;
  monthsReserves?: number | null;
  prepaymentPenalty?: string | null;
  productTypes?: any | null;
  searchID?: string | null;
  source?: string | null;
  view?: string | null;
  zeroPlus?: string | null;
}

/**
 * The CreateLoanProposalFieldsInput input type is used in operations that involve creation of objects in the LoanProposal class.
 */
export interface CreateLoanProposalFieldsInput {
  ACL?: ACLInput | null;
  applyUrl?: string | null;
  createdAt?: any | null;
  file?: CreateFileFieldsInput | null;
  image?: CreateFileFieldsInput | null;
  lead?: LeadPointerInput | null;
  objectName?: string | null;
  offers?: OfferRelationInput | null;
  owner?: UserPointerInput | null;
  sent?: number | null;
  updatedAt?: any | null;
}

export interface CreateLoanProposalInput {
  clientMutationId?: string | null;
  fields?: CreateLoanProposalFieldsInput | null;
}

/**
 * The CreateMailAuthFieldsInput input type is used in operations that involve creation of objects in the MailAuth class.
 */
export interface CreateMailAuthFieldsInput {
  accessToken?: string | null;
  expiresAt?: number | null;
  expiresIn?: number | null;
  pass?: string | null;
  refreshToken?: string | null;
  scope?: string | null;
  type?: string | null;
  user?: string | null;
}

/**
 * The CreateMailDnsFieldsInput input type is used in operations that involve creation of objects in the MailDns class.
 */
export interface CreateMailDnsFieldsInput {
  data?: string | null;
  host?: string | null;
  name?: string | null;
  type?: string | null;
  valid?: boolean | null;
}

/**
 * The CreateMailDomainFieldsInput input type is used in operations that involve creation of objects in the MailDomain class.
 */
export interface CreateMailDomainFieldsInput {
  ACL?: ACLInput | null;
  aliases?: (any | null)[] | null;
  apiKey?: string | null;
  createdAt?: any | null;
  disabled?: boolean | null;
  dns?: (CreateMailDnsFieldsInput | null)[] | null;
  domain: string;
  hookCreated?: boolean | null;
  objectName?: string | null;
  provider?: CreateMailProviderFieldsInput | null;
  sendGridId?: string | null;
  updatedAt?: any | null;
  useForOut?: boolean | null;
  valid?: boolean | null;
}

/**
 * The CreateMailImapFieldsInput input type is used in operations that involve creation of objects in the MailImap class.
 */
export interface CreateMailImapFieldsInput {
  host?: string | null;
  port?: number | null;
  service?: string | null;
  tls?: any | null;
}

/**
 * The CreateMailProviderFieldsInput input type is used in operations that involve creation of objects in the MailProvider class.
 */
export interface CreateMailProviderFieldsInput {
  clientId?: string | null;
  clientSecret?: string | null;
  imap?: CreateMailImapFieldsInput | null;
  returnUrl?: string | null;
  smtp?: CreateMailSmtpFieldsInput | null;
  sync?: string | null;
  tenantId?: string | null;
  type: string;
}

/**
 * The CreateMailSmtpFieldsInput input type is used in operations that involve creation of objects in the MailSmtp class.
 */
export interface CreateMailSmtpFieldsInput {
  host?: string | null;
  port?: number | null;
  secure?: boolean | null;
  service?: string | null;
  tls?: any | null;
}

/**
 * The CreateMailboxFieldsInput input type is used in operations that involve creation of objects in the Mailbox class.
 */
export interface CreateMailboxFieldsInput {
  ACL?: ACLInput | null;
  account: string;
  address: string;
  assignedTo?: MailboxAssignedToPointerInput | null;
  auth?: CreateMailAuthFieldsInput | null;
  common?: boolean | null;
  createdAt?: any | null;
  defaultRecipient?: UserPointerInput | null;
  delta?: any | null;
  disabled?: boolean | null;
  domain?: MailDomainPointerInput | null;
  historyId?: string | null;
  lastSyncedAt?: any | null;
  objectName?: string | null;
  updatedAt?: any | null;
}

/**
 * The CreateMonthlyPaymentFieldsInput input type is used in operations that involve creation of objects in the MonthlyPayment class.
 */
export interface CreateMonthlyPaymentFieldsInput {
  insurance?: number | null;
  mi?: number | null;
  pi?: number | null;
  pmi?: number | null;
  tax?: number | null;
  total?: number | null;
}

/**
 * The CreateMortechFieldsInput input type is used in operations that involve creation of objects in the Mortech class.
 */
export interface CreateMortechFieldsInput {
  amiLlpaWaiver?: boolean | null;
  deliveryType?: string | null;
  hudReo?: boolean | null;
  investorIds?: (any | null)[] | null;
  lockInDays?: string | null;
  product?: string | null;
  productName?: string | null;
  source?: string | null;
  streamLine?: boolean | null;
  streamLineWithAppraisal?: boolean | null;
  streamLineWithCredit?: boolean | null;
  view?: string | null;
}

/**
 * The CreateNoteFieldsInput input type is used in operations that involve creation of objects in the Note class.
 */
export interface CreateNoteFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  owner?: UserPointerInput | null;
  subject: NoteSubjectPointerInput;
  text: string;
  updatedAt?: any | null;
}

/**
 * The CreateNotificationControlsFieldsInput input type is used in operations that involve creation of objects in the NotificationControls class.
 */
export interface CreateNotificationControlsFieldsInput {
  bulkEmail?: boolean | null;
  bulkSMS?: boolean | null;
  distributedLead?: boolean | null;
  emails?: boolean | null;
  leadAssignments?: boolean | null;
  loanComments?: boolean | null;
  missedCalls?: boolean | null;
  sms?: boolean | null;
  tasksReminders?: boolean | null;
}

/**
 * The CreateOfferFieldsInput input type is used in operations that involve creation of objects in the Offer class.
 */
export interface CreateOfferFieldsInput {
  ACL?: ACLInput | null;
  closingCost?: CreateClosingCostFieldsInput | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  lead?: LeadPointerInput | null;
  lenderCredits?: number | null;
  loanCriteria?: CreateLoanCriteriaFieldsInput | null;
  loanProposal?: LoanProposalPointerInput | null;
  monthlyPayment?: CreateMonthlyPaymentFieldsInput | null;
  objectName?: string | null;
  prepaid?: CreatePrepaidFieldsInput | null;
  selectedRate?: CreateSelectedRateFieldsInput | null;
  totalClosingCost?: number | null;
  updatedAt?: any | null;
}

export interface CreateOfferInput {
  clientMutationId?: string | null;
  fields?: CreateOfferFieldsInput | null;
}

/**
 * The CreateOfferMortechFieldsInput input type is used in operations that involve creation of objects in the OfferMortech class.
 */
export interface CreateOfferMortechFieldsInput {
  amiLlpaWaiver?: boolean | null;
  hudReo?: boolean | null;
  investorIds?: (any | null)[] | null;
  lockInDays?: string | null;
  product?: string | null;
  productName?: string | null;
  source?: string | null;
  streamLine?: boolean | null;
  streamLineWithAppraisal?: boolean | null;
  streamLineWithCredit?: boolean | null;
  view?: string | null;
}

/**
 * The CreateOfferPropertyAddressFieldsInput input type is used in operations that involve creation of objects in the OfferPropertyAddress class.
 */
export interface CreateOfferPropertyAddressFieldsInput {
  propertyCounty?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

/**
 * The CreateOfferPropertyFieldsInput input type is used in operations that involve creation of objects in the OfferProperty class.
 */
export interface CreateOfferPropertyFieldsInput {
  currentMortgageBalance?: number | null;
  occupancy?: string | null;
  propertyAddress?: CreateOfferPropertyAddressFieldsInput | null;
  type?: string | null;
  value?: number | null;
}

/**
 * The CreateOptimalBlueUserConfigFieldsInput input type is used in operations that involve creation of objects in the OptimalBlueUserConfig class.
 */
export interface CreateOptimalBlueUserConfigFieldsInput {
  businessChannelId?: number | null;
  originatorId?: number | null;
}

/**
 * The CreatePhoneMessageTemplateFieldsInput input type is used in operations that involve creation of objects in the PhoneMessageTemplate class.
 */
export interface CreatePhoneMessageTemplateFieldsInput {
  ACL?: ACLInput | null;
  applyTo?: (any | null)[] | null;
  availableFor?: (any | null)[] | null;
  createdAt?: any | null;
  disabled?: boolean | null;
  enabled?: boolean | null;
  forAutomation?: boolean | null;
  loanProposal?: boolean | null;
  objectName?: string | null;
  owner?: UserPointerInput | null;
  text: string;
  title?: string | null;
  type: string;
  updatedAt?: any | null;
}

export interface CreatePhoneMessageTemplateInput {
  clientMutationId?: string | null;
  fields?: CreatePhoneMessageTemplateFieldsInput | null;
}

/**
 * The CreatePhoneNumberFieldsInput input type is used in operations that involve creation of objects in the PhoneNumber class.
 */
export interface CreatePhoneNumberFieldsInput {
  callOptOut?: boolean | null;
  carrier?: any | null;
  default?: boolean | null;
  isPrimary?: boolean | null;
  nationalFormat?: string | null;
  number: any;
  objectName?: string | null;
  optOut?: boolean | null;
  sms?: boolean | null;
  smsCountryCode?: string | null;
  smsOptOut?: boolean | null;
  type?: string | null;
}

/**
 * The CreatePrepaidFieldsInput input type is used in operations that involve creation of objects in the Prepaid class.
 */
export interface CreatePrepaidFieldsInput {
  dailyInterest?: number | null;
  daysToPayInterest?: number | null;
  insurance?: number | null;
  interest?: number | null;
  lockInFee?: number | null;
  monthlyInsurance?: number | null;
  monthlyTax?: number | null;
  monthsToPayInsurance?: number | null;
  monthsToPayTax?: number | null;
  tax?: number | null;
  total?: number | null;
  yearlyInsurance?: number | null;
}

/**
 * The CreatePreviewSettingsFieldsInput input type is used in operations that involve creation of objects in the PreviewSettings class.
 */
export interface CreatePreviewSettingsFieldsInput {
  mailbox?: MailboxPointerInput | null;
  scope?: PreviewSettingsScopePointerInput | null;
  user?: UserPointerInput | null;
}

/**
 * The CreatePriorityQueueFieldsInput input type is used in operations that involve creation of objects in the PriorityQueue class.
 */
export interface CreatePriorityQueueFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  enabled?: boolean | null;
  expandable?: boolean | null;
  fields?: (CreateTableViewFieldFieldsInput | null)[] | null;
  leadCount?: number | null;
  leadFilter?: any | null;
  leadSort?: (any | null)[] | null;
  objectName?: string | null;
  priority?: number | null;
  priorityView?: PriorityViewPointerInput | null;
  title?: string | null;
  updatedAt?: any | null;
}

/**
 * The CreatePriorityViewFieldsInput input type is used in operations that involve creation of objects in the PriorityView class.
 */
export interface CreatePriorityViewFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  createdBy?: UserPointerInput | null;
  objectName?: string | null;
  priority?: number | null;
  priorityQueues?: PriorityQueueRelationInput | null;
  rPerm?: (any | null)[] | null;
  title?: string | null;
  updatedAt?: any | null;
}

/**
 * The CreateProPairFieldsInput input type is used in operations that involve creation of objects in the ProPair class.
 */
export interface CreateProPairFieldsInput {
  excludeIds?: string | null;
  rank?: number | null;
}

/**
 * The CreatePropertyFieldsInput input type is used in operations that involve creation of objects in the Property class.
 */
export interface CreatePropertyFieldsInput {
  annualInsurance?: number | null;
  annualTaxes?: number | null;
  appraisedValue?: number | null;
  approvedVACondo?: boolean | null;
  currentMortgageBalance?: number | null;
  currentMortgageMonthlyPayment?: number | null;
  currentMortgageRate?: number | null;
  datePurchased?: any | null;
  dues?: number | null;
  estimatedValue?: number | null;
  firstLienBalance?: number | null;
  heloc?: boolean | null;
  monthlyHoa?: boolean | null;
  numberOfUnits?: string | null;
  occupancy?: string | null;
  pricePaidForHome?: number | null;
  propertyAddress?: CreateAddressFieldsInput | null;
  secondLienBalance?: number | null;
  solarPanels?: boolean | null;
  type?: string | null;
  use?: string | null;
  value?: number | null;
  yearBuilt?: number | null;
}

/**
 * The CreateQuotesFieldsInput input type is used in operations that involve creation of objects in the Quotes class.
 */
export interface CreateQuotesFieldsInput {
  apr?: number | null;
  loanAmount?: number | null;
  monthlyPremium?: number | null;
  piti?: number | null;
  price?: number | null;
  rate?: number | null;
  srp?: number | null;
}

/**
 * The CreateRecordingFieldsInput input type is used in operations that involve creation of objects in the Recording class.
 */
export interface CreateRecordingFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  name?: string | null;
  original?: string | null;
  owner?: UserPointerInput | null;
  transcriptionStatus?: string | null;
  transcriptionUrl?: string | null;
  type: string;
  updatedAt?: any | null;
  uploadAttempts?: number | null;
  uploadStatus?: string | null;
  uploaded?: boolean | null;
  url?: string | null;
}

/**
 * The CreateReferralPartnerFieldsInput input type is used in operations that involve creation of objects in the ReferralPartner class.
 */
export interface CreateReferralPartnerFieldsInput {
  ACL?: ACLInput | null;
  assignedAt?: any | null;
  assignedBy?: UserPointerInput | null;
  assignedByObjectId?: string | null;
  assignedTo?: UserPointerInput | null;
  assignedToObjectId?: string | null;
  birthday?: any | null;
  company?: string | null;
  companyAddress?: CreateAddressFieldsInput | null;
  companyLogo?: string | null;
  contact?: ContactPointerInput | null;
  contactObjectId?: string | null;
  createdAt?: any | null;
  duplicateOf?: ReferralPartnerPointerInput | null;
  emails?: (any | null)[] | null;
  firstAssignedTo?: UserPointerInput | null;
  firstName?: string | null;
  fullAddress?: string | null;
  lastName?: string | null;
  licenseNumber?: string | null;
  middleName?: string | null;
  nextReminderDate?: any | null;
  objectIcon?: string | null;
  objectName?: string | null;
  ownerObjectId?: string | null;
  phones?: (any | null)[] | null;
  positionName?: string | null;
  previousAssignedTo?: UserPointerInput | null;
  primaryEmail?: any | null;
  primaryPhone?: any | null;
  state?: string | null;
  street?: string | null;
  street2?: string | null;
  tags?: (any | null)[] | null;
  timezone?: string | null;
  types?: any | null;
  updatedAt?: any | null;
  zip?: string | null;
}

/**
 * The CreateRelcuLoanDocumentFieldsInput input type is used in operations that involve creation of objects in the RelcuLoanDocument class.
 */
export interface CreateRelcuLoanDocumentFieldsInput {
  createdDate?: any | null;
  losId?: string | null;
  milestoneLosId?: string | null;
  receivedDate?: any | null;
  requestedDate?: any | null;
  slug?: string | null;
  status?: string | null;
  title?: string | null;
}

/**
 * The CreateRelcuLoanFieldsInput input type is used in operations that involve creation of objects in the RelcuLoan class.
 */
export interface CreateRelcuLoanFieldsInput {
  ACL?: ACLInput | null;
  OfPLTMO?: number | null;
  a1822Group?: string | null;
  abaProvided?: any | null;
  active?: boolean | null;
  activeNetworkReferral?: boolean | null;
  adjPrincipalLimit?: number | null;
  affinityLeadType?: string | null;
  agentName?: string | null;
  annualHazardInsurance?: number | null;
  annualPropertyTax?: number | null;
  annualReviewDate?: any | null;
  applicationDate?: any | null;
  appraisalContingency?: any | null;
  appraisalDelivered?: any | null;
  appraisalExpectedDelivery?: any | null;
  appraisalInspection?: any | null;
  appraisalReceivedDate?: any | null;
  appraisalRequestedDate?: any | null;
  aprPercent?: number | null;
  archived?: boolean | null;
  armMarginPercent?: number | null;
  assignedTo?: UserPointerInput | null;
  assignedToObjectId?: string | null;
  assignedToObjectName?: string | null;
  assignedUserTeam?: string | null;
  avmProvided?: any | null;
  baseLoanAmount?: number | null;
  borrowerDOB?: any | null;
  borrowerEmail?: any | null;
  borrowerEmployerCity?: string | null;
  borrowerEmployerName?: string | null;
  borrowerEmployerState?: string | null;
  borrowerEmployerStreetAddress?: string | null;
  borrowerEmployerZipCode?: string | null;
  borrowerEquifaxScore?: number | null;
  borrowerExperianCreditScore?: number | null;
  borrowerFaxNumber?: any | null;
  borrowerFirstName?: string | null;
  borrowerFullName?: string | null;
  borrowerHomePhone?: any | null;
  borrowerInitialCDESignedDate?: any | null;
  borrowerIntendToContinueDate?: any | null;
  borrowerLastName?: string | null;
  borrowerLeESignedDate?: any | null;
  borrowerLosId?: string | null;
  borrowerMailingAddressCity?: string | null;
  borrowerMailingAddressState?: string | null;
  borrowerMailingAddressStreet?: string | null;
  borrowerMailingAddressZipCode?: string | null;
  borrowerMiddleFicoScore?: number | null;
  borrowerMobilePhone?: any | null;
  borrowerSSN?: string | null;
  borrowerSubmission?: any | null;
  borrowerTotalMonthlyIncome?: number | null;
  borrowerTransUnionScore?: number | null;
  borrowerWorkEmail?: any | null;
  borrowerWorkPhone?: any | null;
  brokerConditionSubmission?: any | null;
  brokersRequestForAppraisal?: any | null;
  buySideLockDate?: any | null;
  buySideLockExpires?: any | null;
  buyerAgentCompany?: string | null;
  buyerAgentEmail?: any | null;
  buyerAgentName?: string | null;
  buyerAgentPhone?: any | null;
  cashRequest?: number | null;
  cdMaxMonthlyPayment?: number | null;
  channelType?: string | null;
  charmBookletSent?: any | null;
  closingDisclosureDelivered?: any | null;
  closingDisclosureReDisclosureIssued?: any | null;
  closingDisclosureReDisclosureReceived?: any | null;
  closingDisclosureReceivedDate?: any | null;
  closingDisclosureSentDate?: any | null;
  closingDocumentPreparationDate?: any | null;
  closingDocumentSigningDate?: any | null;
  closingInterestRate?: number | null;
  cltv?: number | null;
  coBorrowerDOB?: any | null;
  coBorrowerEmail?: string | null;
  coBorrowerEmployerCity?: string | null;
  coBorrowerEmployerName?: string | null;
  coBorrowerEmployerState?: string | null;
  coBorrowerEmployerStreetAddress?: string | null;
  coBorrowerEmployerZipCode?: string | null;
  coBorrowerEquifaxScore?: number | null;
  coBorrowerExperianCreditScore?: number | null;
  coBorrowerFaxNumber?: string | null;
  coBorrowerFirstName?: string | null;
  coBorrowerFullName?: string | null;
  coBorrowerHomePhone?: string | null;
  coBorrowerInitialCDESignedDate?: any | null;
  coBorrowerLastName?: string | null;
  coBorrowerLeESignedDate?: any | null;
  coBorrowerLosId?: string | null;
  coBorrowerMailingAddressCity?: string | null;
  coBorrowerMailingAddressState?: string | null;
  coBorrowerMailingAddressStreet?: string | null;
  coBorrowerMailingAddressZipCode?: string | null;
  coBorrowerMiddleFicoScore?: number | null;
  coBorrowerMobilePhone?: string | null;
  coBorrowerResidentialAddress?: CreateAddressFieldsInput | null;
  coBorrowerSSN?: string | null;
  coBorrowerTotalMonthlyIncome?: number | null;
  coBorrowerWorkEmail?: string | null;
  coBorrowerWorkPhone?: string | null;
  conditionReviewed?: any | null;
  conditionSubmission?: any | null;
  costCenterCode?: string | null;
  createdAt?: any | null;
  creditReportReceivedDate?: any | null;
  creditReportRequestedDate?: any | null;
  creditScoreToUse?: string | null;
  credits?: number | null;
  currentMilestone?: string | null;
  currentMilestoneStage?: string | null;
  currentRateLockDate?: any | null;
  currentRateLockDateTime?: any | null;
  currentRateLockExpiresDate?: any | null;
  currentRateLockInvestorName?: string | null;
  currentRateLockRequestType?: string | null;
  currentRateLockStatus?: string | null;
  custLoanTypeReverse?: string | null;
  deleted?: boolean | null;
  denialDate?: any | null;
  denialReason1?: string | null;
  denialReason2?: string | null;
  denialReason3?: string | null;
  denialReason4?: string | null;
  differenceOfCostsChargedToPrice?: number | null;
  disbursementDate?: any | null;
  disclosureViewedDateBorrower?: any | null;
  disclosureViewedDateCoBorrower?: any | null;
  documents?: (CreateRelcuLoanDocumentFieldsInput | null)[] | null;
  downPayment?: number | null;
  dti?: number | null;
  eSignConsentDate?: any | null;
  earliestClosingDate?: any | null;
  earliestFeeCollection?: any | null;
  epoEndDate?: any | null;
  escrowCompanyName?: string | null;
  expectedInterestRate?: number | null;
  fanniePIW?: string | null;
  fhaVaLoanClosingDate?: any | null;
  fileStartedDate?: any | null;
  fileStarterLosId?: string | null;
  fileStarterName?: string | null;
  filterId?: string | null;
  financingContingency?: any | null;
  firstPaymentDate?: any | null;
  firstPaymentToInvestorDate?: any | null;
  firstTimeHomebuyersIndicator?: boolean | null;
  freddiePIW?: string | null;
  fundedDate?: any | null;
  fundingCloseDate?: any | null;
  fundingFundsSentDate?: any | null;
  fundingSentToFunderDate?: any | null;
  fundsAvailableAtClosing?: number | null;
  gfeApplicationDate?: any | null;
  grossRevenueS?: number | null;
  helocBrochureSent?: any | null;
  highCostDisclosure?: any | null;
  homeCounselingDisclosure?: any | null;
  hybridClosing?: boolean | null;
  inShipping?: any | null;
  incomplete?: any | null;
  initialDisclosureDueDate?: any | null;
  initialDisclosureProvidedDate?: any | null;
  initialGfeAppraisalProvidedDate?: any | null;
  initialGfeDisclosureProvidedDate?: any | null;
  initialGfeDisclosureReceivedDate?: any | null;
  initialInterestRate?: number | null;
  initialLoanAmount?: number | null;
  initialLoanBalanceUPB?: number | null;
  initialProduct?: string | null;
  initialRate?: number | null;
  initialTilDisclosureProvidedDate?: any | null;
  initialTilDisclosureReceivedDate?: any | null;
  intentToProceed?: any | null;
  interestRateCap?: number | null;
  interviewerEmail?: string | null;
  isEscrowWaived?: string | null;
  juniorBankerEmail?: any | null;
  juniorBankerName?: string | null;
  kWAgentID?: string | null;
  kWMarketCenter?: string | null;
  kWMarketCenterID?: string | null;
  kWRegion?: string | null;
  kWRegionName?: string | null;
  lOCAvailableAfter1stYear?: number | null;
  lOLockFinalPrice?: number | null;
  lastCompletedMilestone?: string | null;
  lead?: LeadPointerInput | null;
  leadId?: string | null;
  leadObjectId?: string | null;
  leadObjectName?: string | null;
  leadPricePaid?: number | null;
  leadProviderName?: string | null;
  leadSource?: string | null;
  leadSourceCampaign?: string | null;
  leadSourceId?: string | null;
  lenderCoName?: string | null;
  loanAmortizationTerm?: number | null;
  loanAmortizationType?: string | null;
  loanEstimate?: any | null;
  loanEstimateDelivered?: any | null;
  loanEstimateExpired?: any | null;
  loanEstimateIssuanceDue?: any | null;
  loanEstimateReDisclosureIssued?: any | null;
  loanEstimateReDisclosureReceived?: any | null;
  loanEstimateReceived?: any | null;
  loanEstimatedClosingDate?: any | null;
  loanFolder?: string | null;
  loanIsLocked?: boolean | null;
  loanMembers?: (CreateRelcuLoanMemberFieldsInput | null)[] | null;
  loanNumber?: string | null;
  loanOfficerName?: string | null;
  loanOpenerStartDate?: any | null;
  loanOverageUnderagePercent?: number | null;
  loanProgramName?: string | null;
  loanProgramType?: string | null;
  loanPurposeType?: string | null;
  loanPurposeTypeUrla?: string | null;
  loanRevision?: number | null;
  loanSource?: string | null;
  loanStatus?: string | null;
  loanTermInMonthDue?: number | null;
  loanType?: string | null;
  losCreatedAt?: any | null;
  losId?: string | null;
  losProvider?: string | null;
  losUpdatedAt?: any | null;
  ltv?: number | null;
  mIP?: number | null;
  mLOComments?: string | null;
  mLOEligibilityDate?: any | null;
  mLONextFollowUp?: any | null;
  mLOStatusDate?: any | null;
  mLOStatusStLouisRegion?: string | null;
  milestoneApprovedDate?: any | null;
  milestoneApprovedDueDate?: any | null;
  milestoneCompletedDate?: any | null;
  milestoneCompletedDueDate?: any | null;
  milestoneCurrentDate?: any | null;
  milestoneDocSignedDate?: any | null;
  milestoneDocSignedDueDate?: any | null;
  milestoneFileStartedDate?: any | null;
  milestoneFundedDate?: any | null;
  milestoneFundedDueDate?: any | null;
  milestoneProcessedDate?: any | null;
  milestoneSubmittedDate?: any | null;
  milestoneSubmittedDueDate?: any | null;
  milestones?: (CreateRelcuLoanMilestoneFieldsInput | null)[] | null;
  monthlyTenureAvailable?: number | null;
  mortgageCoordinatorEmail?: any | null;
  ncHomeLoanAmortizationScheduleSent?: any | null;
  nextPaymentDate?: any | null;
  notAccepted?: any | null;
  objectName?: string | null;
  origFee?: number | null;
  originalCreditProfile?: string | null;
  originalDateAdded?: any | null;
  originationDate?: any | null;
  otherCosts?: number | null;
  postClosing?: any | null;
  preDeny?: any | null;
  preQualify?: any | null;
  prequalSentDateInitial?: any | null;
  processorNameCustom?: string | null;
  productionCenter?: string | null;
  propertyAppraisedValue?: number | null;
  propertyCensusTract?: string | null;
  propertyCity?: string | null;
  propertyCounty?: string | null;
  propertyEstimatedValue?: number | null;
  propertyNumberOfUnits?: number | null;
  propertyOccupancyStatus?: string | null;
  propertyPostalCode?: string | null;
  propertyState?: string | null;
  propertyStreetAddress?: string | null;
  propertyType?: string | null;
  propertyUsageType?: string | null;
  proposedMaturityYears?: number | null;
  purchasePrice?: number | null;
  purchased?: any | null;
  qualDate?: any | null;
  rVLenderCase?: string | null;
  rVPrimaryMortgageBalance?: number | null;
  rateLockDate?: any | null;
  rateReLocked?: any | null;
  reDisclosure?: any | null;
  reactivationDate?: any | null;
  referralSource?: string | null;
  referredDate?: string | null;
  referredToNetworkDateTime?: any | null;
  referredToReverseGroup?: string | null;
  referringBanker?: string | null;
  refinancePropertyExistingLien?: number | null;
  refinancePropertyOriginalCost?: number | null;
  registered?: any | null;
  remainingPrincipalLimit?: number | null;
  requestedInterestRate?: number | null;
  requestedLoanAmount?: number | null;
  rescinded?: any | null;
  residentialAddress?: CreateAddressFieldsInput | null;
  reverseVisionLoanID?: string | null;
  rightOfRescissionNotice?: any | null;
  safeHarborSent?: any | null;
  scheduleClosing?: any | null;
  scheduledClosingDate?: any | null;
  scheduledFirstPaymentDate?: any | null;
  sellerAgentCompany?: string | null;
  sellerAgentEmail?: any | null;
  sellerAgentName?: string | null;
  sellerAgentPhone?: any | null;
  servicing?: any | null;
  specialInformationBookletSent?: any | null;
  ssplSent?: any | null;
  stateCounselingNoticeSent?: any | null;
  stateHighCostCounselingCertificate?: any | null;
  stateHighCostOrHighRiskOrCoveredDisclosureSent?: any | null;
  stpDate?: any | null;
  subsequentRateAdjustmentMonths?: number | null;
  surveyDate?: any | null;
  suspended?: any | null;
  tasks?: (CreateRelcuLoanTaskFieldsInput | null)[] | null;
  template?: string | null;
  texasConsumerDisclosureSent?: any | null;
  texasMortgageCompanyDisclosureSent?: any | null;
  texasNoticeOfPenaltiesDisclosureSent?: any | null;
  titleCompanyName?: string | null;
  totalExpertLoanStatus?: string | null;
  totalGrossMonthlyIncomeAmount?: number | null;
  totalIncome?: number | null;
  totalLESASetAside?: number | null;
  totalLoanAmount?: number | null;
  transferAgent?: string | null;
  tsumPropertyType?: string | null;
  underwritingAppraisalOrderedDate?: any | null;
  underwritingApprovalDate?: any | null;
  underwritingClearToCloseDate?: any | null;
  underwritingCreditApprovalDate?: any | null;
  underwritingResubmittedDate?: any | null;
  underwritingSubmittedDate?: any | null;
  updatedAt?: any | null;
  vaMilitaryBranchOfService?: string | null;
  vaSummaryCreditScore?: number | null;
  vaTypeOfVeteran?: string | null;
  vaVeteranServiceType?: string | null;
  velocifyLeadID?: string | null;
  velocifyMembershipType?: string | null;
  vendorId?: string | null;
  wfaJobs?: (any | null)[] | null;
  withdrawn?: any | null;
}

/**
 * The CreateRelcuLoanMemberFieldsInput input type is used in operations that involve creation of objects in the RelcuLoanMember class.
 */
export interface CreateRelcuLoanMemberFieldsInput {
  cellPhone?: any | null;
  company?: string | null;
  email?: any | null;
  firstName?: string | null;
  lastName?: string | null;
  losId?: string | null;
  name?: string | null;
  phone?: any | null;
  type?: string | null;
  workPhone?: any | null;
}

/**
 * The CreateRelcuLoanMilestoneFieldsInput input type is used in operations that involve creation of objects in the RelcuLoanMilestone class.
 */
export interface CreateRelcuLoanMilestoneFieldsInput {
  associate?: string | null;
  comment?: string | null;
  done?: boolean | null;
  losId?: string | null;
  name?: string | null;
  plannedDate?: any | null;
  slug?: string | null;
  startDate?: any | null;
}

/**
 * The CreateRelcuLoanTaskFieldsInput input type is used in operations that involve creation of objects in the RelcuLoanTask class.
 */
export interface CreateRelcuLoanTaskFieldsInput {
  completed?: boolean | null;
  losId?: string | null;
  milestoneLosId?: string | null;
  title?: string | null;
}

/**
 * The CreateSelectedRateFieldsInput input type is used in operations that involve creation of objects in the SelectedRate class.
 */
export interface CreateSelectedRateFieldsInput {
  lastUpdate?: any | null;
  pricingStatus?: string | null;
  productDesc?: string | null;
  productName?: string | null;
  productTerm?: string | null;
  quoteDetails?: CreateQuotesFieldsInput | null;
  vendorName?: string | null;
}

/**
 * The CreateSessionFieldsInput input type is used in operations that involve creation of objects in the Session class.
 */
export interface CreateSessionFieldsInput {
  ACL?: ACLInput | null;
  active?: boolean | null;
  code?: number | null;
  createdAt?: any | null;
  delay?: number | null;
  device?: DevicePointerInput | null;
  duration?: number | null;
  message?: string | null;
  reason?: string | null;
  updatedAt?: any | null;
  user?: UserPointerInput | null;
}

/**
 * The CreateTableViewFieldFieldsInput input type is used in operations that involve creation of objects in the TableViewField class.
 */
export interface CreateTableViewFieldFieldsInput {
  fixed?: string | null;
  key: string;
  properties?: any | null;
  sort?: string | null;
  width?: number | null;
}

/**
 * The CreateTableViewFieldsInput input type is used in operations that involve creation of objects in the TableView class.
 */
export interface CreateTableViewFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  createdBy?: UserPointerInput | null;
  fields?: (CreateTableViewFieldFieldsInput | null)[] | null;
  filter?: any | null;
  group: string;
  name: string;
  rPerm?: (any | null)[] | null;
  updatedAt?: any | null;
}

export interface CreateTableViewInput {
  clientMutationId?: string | null;
  fields?: CreateTableViewFieldsInput | null;
}

/**
 * The CreateTaskFieldsInput input type is used in operations that involve creation of objects in the Task class.
 */
export interface CreateTaskFieldsInput {
  ACL?: ACLInput | null;
  assignedAt?: any | null;
  assignedId?: string | null;
  assignedTo?: UserPointerInput | null;
  createdAt?: any | null;
  description?: string | null;
  done?: boolean | null;
  dueDate: any;
  notifiedAt?: any | null;
  objectName?: string | null;
  remind?: string | null;
  subject?: TaskSubjectPointerInput | null;
  subjectId?: string | null;
  title: string;
  updatedAt?: any | null;
  wfaJobs?: (any | null)[] | null;
}

export interface CreateTaskInput {
  clientMutationId?: string | null;
  fields?: CreateTaskFieldsInput | null;
}

/**
 * The CreateTitleFieldsInput input type is used in operations that involve creation of objects in the Title class.
 */
export interface CreateTitleFieldsInput {
  insurance?: number | null;
  lendersTitle?: number | null;
  ownersTitle?: number | null;
  recordingCharges?: number | null;
  settlementFee?: number | null;
  total?: number | null;
  transferTax?: number | null;
}

/**
 * The CreateUserFieldsInput input type is used in operations that involve creation of objects in the User class.
 */
export interface CreateUserFieldsInput {
  ACL?: ACLInput | null;
  accountLockoutExpiresAt?: any | null;
  applyUrl?: string | null;
  bookmarks?: (any | null)[] | null;
  bulkPermissions?: CreateBulkMessagingPermissionFieldsInput | null;
  calendar?: string | null;
  createdAt?: any | null;
  custom1?: string | null;
  custom10?: string | null;
  custom2?: string | null;
  custom3?: string | null;
  custom4?: string | null;
  custom5?: string | null;
  custom6?: string | null;
  custom7?: string | null;
  custom8?: string | null;
  custom9?: string | null;
  dataTableView?: CreateDataTablePermissionFieldsInput | null;
  deactivated?: boolean | null;
  deactivatedAt?: any | null;
  defaultPhoneLine?: PhoneLinePointerInput | null;
  devices?: DeviceRelationInput | null;
  email?: string | null;
  emailTemplateEditorPermissions?: CreateEmailTemplateEditorPermissionFieldsInput | null;
  failedLoginAttempts?: number | null;
  firstName?: string | null;
  fiveNineId?: string | null;
  flyerPermissions?: CreateFlyerPermissionFieldsInput | null;
  history?: (any | null)[] | null;
  identities?: (CreateIdentityFieldsInput | null)[] | null;
  lastName?: string | null;
  lastNotificationSeenAt?: any | null;
  licensedStates?: any | null;
  localPresence?: boolean | null;
  losId?: string | null;
  nmlsId?: string | null;
  notificationControls?: CreateNotificationControlsFieldsInput | null;
  objectIcon?: string | null;
  objectName?: string | null;
  onCall?: boolean | null;
  online?: boolean | null;
  optimalBlue?: CreateOptimalBlueUserConfigFieldsInput | null;
  password: string;
  passwordChangedAt?: any | null;
  passwordHistory?: (any | null)[] | null;
  phone?: string | null;
  phoneLines?: PhoneLineRelationInput | null;
  positionName?: string | null;
  reviewUrl?: string | null;
  role?: string | null;
  sessions?: SessionRelationInput | null;
  status?: string | null;
  tags?: (any | null)[] | null;
  team?: string | null;
  updatedAt?: any | null;
  username: string;
  voiceMail?: RecordingPointerInput | null;
  voicemailOutOfWork?: RecordingPointerInput | null;
  voicemailUrl?: string | null;
  wfaJobs?: (any | null)[] | null;
}

/**
 * The CreateWorkflowStatusFieldsInput input type is used in operations that involve creation of objects in the WorkflowStatus class.
 */
export interface CreateWorkflowStatusFieldsInput {
  workflowId?: string | null;
  workflowName?: string | null;
}

/**
 * The CreditAndOtherWhereInput input type is used in operations that involve filtering objects of CreditAndOther class.
 */
export interface CreditAndOtherWhereInput {
  AND?: CreditAndOtherWhereInput[] | null;
  NOR?: CreditAndOtherWhereInput[] | null;
  OR?: CreditAndOtherWhereInput[] | null;
  creditReport?: NumberWhereInput | null;
  documentDelivery?: NumberWhereInput | null;
  loanSafe?: NumberWhereInput | null;
  mers?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  transcript?: NumberWhereInput | null;
  verification?: NumberWhereInput | null;
}

/**
 * The DataTablePermissionWhereInput input type is used in operations that involve filtering objects of DataTablePermission class.
 */
export interface DataTablePermissionWhereInput {
  AND?: DataTablePermissionWhereInput[] | null;
  NOR?: DataTablePermissionWhereInput[] | null;
  OR?: DataTablePermissionWhereInput[] | null;
  create?: BooleanWhereInput | null;
  share?: BooleanWhereInput | null;
}

/**
 * The DateWhereInput input type is used in operations that involve filtering objects by a field of type Date.
 */
export interface DateWhereInput {
  equalTo?: any | null;
  exists?: boolean | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: (any | null)[] | null;
  inQueryKey?: SelectInput | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notIn?: (any | null)[] | null;
  notInQueryKey?: SelectInput | null;
}

export interface DeleteTableViewInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Allow to link OR add and link an object of the Device class.
 */
export interface DevicePointerInput {
  createAndLink?: CreateDeviceFieldsInput | null;
  link?: string | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the Device class into a relation field.
 */
export interface DeviceRelationInput {
  add?: string[] | null;
  createAndAdd?: CreateDeviceFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The DeviceRelationWhereInput input type is used in operations that involve filtering objects of Device class.
 */
export interface DeviceRelationWhereInput {
  exists?: boolean | null;
  have?: DeviceWhereInput | null;
  haveNot?: DeviceWhereInput | null;
}

/**
 * The DeviceWhereInput input type is used in operations that involve filtering objects of Device class.
 */
export interface DeviceWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: DeviceWhereInput[] | null;
  NOR?: DeviceWhereInput[] | null;
  OR?: DeviceWhereInput[] | null;
  active?: BooleanWhereInput | null;
  apn?: StringWhereInput | null;
  browserName?: StringWhereInput | null;
  browserVersion?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  deviceId?: StringWhereInput | null;
  deviceType?: StringWhereInput | null;
  engineName?: StringWhereInput | null;
  fcm?: StringWhereInput | null;
  id?: IdWhereInput | null;
  objectId?: IdWhereInput | null;
  osName?: StringWhereInput | null;
  osVersion?: StringWhereInput | null;
  osVersionName?: StringWhereInput | null;
  platformType?: StringWhereInput | null;
  platformVendor?: StringWhereInput | null;
  sessions?: SessionRelationWhereInput | null;
  updatedAt?: DateWhereInput | null;
  user?: UserRelationWhereInput | null;
  version?: StringWhereInput | null;
}

/**
 * The DistributionQueueLimitRelationWhereInput input type is used in operations that involve filtering objects of DistributionQueueLimit class.
 */
export interface DistributionQueueLimitRelationWhereInput {
  exists?: boolean | null;
  have?: DistributionQueueLimitWhereInput | null;
  haveNot?: DistributionQueueLimitWhereInput | null;
}

/**
 * The DistributionQueueLimitWhereInput input type is used in operations that involve filtering objects of DistributionQueueLimit class.
 */
export interface DistributionQueueLimitWhereInput {
  AND?: DistributionQueueLimitWhereInput[] | null;
  NOR?: DistributionQueueLimitWhereInput[] | null;
  OR?: DistributionQueueLimitWhereInput[] | null;
  cdp?: NumberWhereInput | null;
  daily?: NumberWhereInput | null;
  filter?: ArrayWhereInput | null;
  monthly?: NumberWhereInput | null;
  weekend?: NumberWhereInput | null;
  weekly?: NumberWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the DistributionQueue class.
 */
export interface DistributionQueuePointerInput {
  createAndLink?: CreateDistributionQueueFieldsInput | null;
  link?: string | null;
}

/**
 * The DistributionQueueRelationWhereInput input type is used in operations that involve filtering objects of DistributionQueue class.
 */
export interface DistributionQueueRelationWhereInput {
  exists?: boolean | null;
  have?: DistributionQueueWhereInput | null;
  haveNot?: DistributionQueueWhereInput | null;
}

/**
 * The DistributionQueueRuleRelationWhereInput input type is used in operations that involve filtering objects of DistributionQueueRule class.
 */
export interface DistributionQueueRuleRelationWhereInput {
  exists?: boolean | null;
  have?: DistributionQueueRuleWhereInput | null;
  haveNot?: DistributionQueueRuleWhereInput | null;
}

/**
 * The DistributionQueueRuleWhereInput input type is used in operations that involve filtering objects of DistributionQueueRule class.
 */
export interface DistributionQueueRuleWhereInput {
  AND?: DistributionQueueRuleWhereInput[] | null;
  NOR?: DistributionQueueRuleWhereInput[] | null;
  OR?: DistributionQueueRuleWhereInput[] | null;
  disabled?: BooleanWhereInput | null;
  leads?: StringWhereInput | null;
  leadsFilter?: ObjectWhereInput | null;
  limits?: DistributionQueueLimitRelationWhereInput | null;
  name?: StringWhereInput | null;
  slug?: StringWhereInput | null;
  sort?: ArrayWhereInput | null;
  users?: StringWhereInput | null;
  usersFilter?: ObjectWhereInput | null;
}

/**
 * The DistributionQueueScheduleWhereInput input type is used in operations that involve filtering objects of DistributionQueueSchedule class.
 */
export interface DistributionQueueScheduleWhereInput {
  AND?: DistributionQueueScheduleWhereInput[] | null;
  FRI?: StringWhereInput | null;
  MON?: StringWhereInput | null;
  NOR?: DistributionQueueScheduleWhereInput[] | null;
  OR?: DistributionQueueScheduleWhereInput[] | null;
  SAT?: StringWhereInput | null;
  SUN?: StringWhereInput | null;
  THU?: StringWhereInput | null;
  TUE?: StringWhereInput | null;
  WED?: StringWhereInput | null;
}

/**
 * The DistributionQueueSoundWhereInput input type is used in operations that involve filtering objects of DistributionQueueSound class.
 */
export interface DistributionQueueSoundWhereInput {
  AND?: DistributionQueueSoundWhereInput[] | null;
  NOR?: DistributionQueueSoundWhereInput[] | null;
  OR?: DistributionQueueSoundWhereInput[] | null;
  repeat?: NumberWhereInput | null;
  sound?: StringWhereInput | null;
}

/**
 * The DistributionQueueViewWhereInput input type is used in operations that involve filtering objects of DistributionQueueView class.
 */
export interface DistributionQueueViewWhereInput {
  AND?: DistributionQueueViewWhereInput[] | null;
  NOR?: DistributionQueueViewWhereInput[] | null;
  OR?: DistributionQueueViewWhereInput[] | null;
  color?: StringWhereInput | null;
  icon?: StringWhereInput | null;
  name?: StringWhereInput | null;
  phoneLeadSound?: DistributionQueueSoundWhereInput | null;
  showCoolDownTime?: BooleanWhereInput | null;
  showDailyCap?: BooleanWhereInput | null;
  showLeadCount?: BooleanWhereInput | null;
  showMonthlyCap?: BooleanWhereInput | null;
  showWeeklyCap?: BooleanWhereInput | null;
  webLeadSound?: DistributionQueueSoundWhereInput | null;
}

/**
 * The DistributionQueueWhereInput input type is used in operations that involve filtering objects of DistributionQueue class.
 */
export interface DistributionQueueWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: DistributionQueueWhereInput[] | null;
  NOR?: DistributionQueueWhereInput[] | null;
  OR?: DistributionQueueWhereInput[] | null;
  autoDial?: BooleanWhereInput | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  disabled?: BooleanWhereInput | null;
  distributeOfflineUsers?: BooleanWhereInput | null;
  draft?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  limits?: DistributionQueueLimitRelationWhereInput | null;
  notify?: BooleanWhereInput | null;
  notifyMobile?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  priority?: NumberWhereInput | null;
  rules?: DistributionQueueRuleRelationWhereInput | null;
  schedule?: DistributionQueueScheduleWhereInput | null;
  skipTimezoneCheck?: BooleanWhereInput | null;
  strategy?: StringWhereInput | null;
  takeLeadFromMobile?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
  view?: DistributionQueueViewWhereInput | null;
}

/**
 * The EmailAddressRelationWhereInput input type is used in operations that involve filtering objects of EmailAddress class.
 */
export interface EmailAddressRelationWhereInput {
  exists?: boolean | null;
  have?: EmailAddressWhereInput | null;
  haveNot?: EmailAddressWhereInput | null;
}

/**
 * The EmailAddressWhereInput input type is used in operations that involve filtering objects of EmailAddress class.
 */
export interface EmailAddressWhereInput {
  AND?: EmailAddressWhereInput[] | null;
  NOR?: EmailAddressWhereInput[] | null;
  OR?: EmailAddressWhereInput[] | null;
  address?: EmailWhereInput | null;
  isPrimary?: BooleanWhereInput | null;
  objectName?: StringWhereInput | null;
  optOut?: BooleanWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The EmailTemplateEditorPermissionWhereInput input type is used in operations that involve filtering objects of EmailTemplateEditorPermission class.
 */
export interface EmailTemplateEditorPermissionWhereInput {
  AND?: EmailTemplateEditorPermissionWhereInput[] | null;
  NOR?: EmailTemplateEditorPermissionWhereInput[] | null;
  OR?: EmailTemplateEditorPermissionWhereInput[] | null;
  builder?: BooleanWhereInput | null;
  html?: BooleanWhereInput | null;
  plainText?: BooleanWhereInput | null;
}

/**
 * The EmailTemplateOwnerUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface EmailTemplateOwnerUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The EmailTemplateRelationWhereInput input type is used in operations that involve filtering objects of EmailTemplate class.
 */
export interface EmailTemplateRelationWhereInput {
  exists?: boolean | null;
  have?: EmailTemplateWhereInput | null;
  haveNot?: EmailTemplateWhereInput | null;
}

/**
 * The EmailTemplateSubscriptionWhereInput input type is used in operations that involve filtering objects of EmailTemplate class.
 */
export interface EmailTemplateSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: EmailTemplateSubscriptionWhereInput[] | null;
  NOR?: EmailTemplateSubscriptionWhereInput[] | null;
  OR?: EmailTemplateSubscriptionWhereInput[] | null;
  applyTo?: ArrayWhereInput | null;
  availableFor?: ArrayWhereInput | null;
  content?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  enabled?: BooleanWhereInput | null;
  forAutomation?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  jsonContent?: ObjectWhereInput | null;
  lead?: BooleanWhereInput | null;
  loanProposal?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  owner?: EmailTemplateOwnerUnionWhereInput | null;
  previewSettings?: ObjectWhereInput | null;
  subject?: StringWhereInput | null;
  title?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The EmailTemplateWhereInput input type is used in operations that involve filtering objects of EmailTemplate class.
 */
export interface EmailTemplateWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: EmailTemplateWhereInput[] | null;
  NOR?: EmailTemplateWhereInput[] | null;
  OR?: EmailTemplateWhereInput[] | null;
  applyTo?: ArrayWhereInput | null;
  availableFor?: ArrayWhereInput | null;
  content?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  enabled?: BooleanWhereInput | null;
  forAutomation?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  jsonContent?: ObjectWhereInput | null;
  lead?: BooleanWhereInput | null;
  loanProposal?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  owner?: UserRelationWhereInput | null;
  previewSettings?: ObjectWhereInput | null;
  subject?: StringWhereInput | null;
  title?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The EmailWhereInput input type is used in operations that involve filtering objects by a field of type Email.
 */
export interface EmailWhereInput {
  equalTo?: any | null;
  exists?: boolean | null;
  in?: (any | null)[] | null;
  inQueryKey?: SelectInput | null;
  includes?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: any | null;
  notIn?: (any | null)[] | null;
  notInQueryKey?: SelectInput | null;
  notIncludes?: string | null;
  options?: string | null;
  regex?: string | null;
}

/**
 * The FhaLoanLimitWhereInput input type is used in operations that involve filtering objects of FhaLoanLimit class.
 */
export interface FhaLoanLimitWhereInput {
  AND?: FhaLoanLimitWhereInput[] | null;
  NOR?: FhaLoanLimitWhereInput[] | null;
  OR?: FhaLoanLimitWhereInput[] | null;
  fourUnitLimit?: NumberWhereInput | null;
  limitType?: StringWhereInput | null;
  medianPrice?: NumberWhereInput | null;
  medianPriceDeterminingLimit?: NumberWhereInput | null;
  oneUnitLimit?: NumberWhereInput | null;
  soaCode?: StringWhereInput | null;
  threeUnitLimit?: NumberWhereInput | null;
  twoUnitLimit?: NumberWhereInput | null;
  yearForMedianDeterminingLimit?: StringWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the File class.
 */
export interface FilePointerInput {
  createAndLink?: CreateFileFieldsInput | null;
  link?: string | null;
}

/**
 * The FileRelationWhereInput input type is used in operations that involve filtering objects of File class.
 */
export interface FileRelationWhereInput {
  exists?: boolean | null;
  have?: FileWhereInput | null;
  haveNot?: FileWhereInput | null;
}

/**
 * The FileSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of File class.
 */
export interface FileSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: FileSubscriptionWhereInput | null;
  haveNot?: FileSubscriptionWhereInput | null;
}

/**
 * The FileSubscriptionWhereInput input type is used in operations that involve filtering objects of File class.
 */
export interface FileSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: FileSubscriptionWhereInput[] | null;
  NOR?: FileSubscriptionWhereInput[] | null;
  OR?: FileSubscriptionWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  isPublic?: BooleanWhereInput | null;
  mimeType?: StringWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  original?: StringWhereInput | null;
  size?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
  uploadAttempts?: NumberWhereInput | null;
  uploadStatus?: StringWhereInput | null;
  uploaded?: BooleanWhereInput | null;
  url?: StringWhereInput | null;
}

/**
 * The FileWhereInput input type is used in operations that involve filtering objects of File class.
 */
export interface FileWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: FileWhereInput[] | null;
  NOR?: FileWhereInput[] | null;
  OR?: FileWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  isPublic?: BooleanWhereInput | null;
  mimeType?: StringWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  original?: StringWhereInput | null;
  size?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
  uploadAttempts?: NumberWhereInput | null;
  uploadStatus?: StringWhereInput | null;
  uploaded?: BooleanWhereInput | null;
  url?: StringWhereInput | null;
}

/**
 * The FlyerOwnerUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface FlyerOwnerUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The FlyerPermissionWhereInput input type is used in operations that involve filtering objects of FlyerPermission class.
 */
export interface FlyerPermissionWhereInput {
  AND?: FlyerPermissionWhereInput[] | null;
  NOR?: FlyerPermissionWhereInput[] | null;
  OR?: FlyerPermissionWhereInput[] | null;
  create?: BooleanWhereInput | null;
  send?: BooleanWhereInput | null;
}

/**
 * The FlyerRelationWhereInput input type is used in operations that involve filtering objects of Flyer class.
 */
export interface FlyerRelationWhereInput {
  exists?: boolean | null;
  have?: FlyerWhereInput | null;
  haveNot?: FlyerWhereInput | null;
}

/**
 * The FlyerRenderInput input type is used in operations that involve FlyerRenderInput.
 */
export interface FlyerRenderInput {
  contact?: string | null;
  scope?: string | null;
  strict?: boolean | null;
  template?: string | null;
  user?: string | null;
}

/**
 * The FlyerSubscriptionWhereInput input type is used in operations that involve filtering objects of Flyer class.
 */
export interface FlyerSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: FlyerSubscriptionWhereInput[] | null;
  NOR?: FlyerSubscriptionWhereInput[] | null;
  OR?: FlyerSubscriptionWhereInput[] | null;
  applyTo?: StringWhereInput | null;
  availableFor?: ArrayWhereInput | null;
  availableForType?: ArrayWhereInput | null;
  content?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  enabled?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  jsonContent?: ObjectWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  owner?: FlyerOwnerUnionWhereInput | null;
  previewSettings?: PreviewSettingsSubscriptionWhereInput | null;
  title?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The FlyerWhereInput input type is used in operations that involve filtering objects of Flyer class.
 */
export interface FlyerWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: FlyerWhereInput[] | null;
  NOR?: FlyerWhereInput[] | null;
  OR?: FlyerWhereInput[] | null;
  applyTo?: StringWhereInput | null;
  availableFor?: ArrayWhereInput | null;
  availableForType?: ArrayWhereInput | null;
  content?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  enabled?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  jsonContent?: ObjectWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  owner?: UserRelationWhereInput | null;
  previewSettings?: PreviewSettingsWhereInput | null;
  title?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The HomeBuyingProcessWhereInput input type is used in operations that involve filtering objects of HomeBuyingProcess class.
 */
export interface HomeBuyingProcessWhereInput {
  AND?: HomeBuyingProcessWhereInput[] | null;
  NOR?: HomeBuyingProcessWhereInput[] | null;
  OR?: HomeBuyingProcessWhereInput[] | null;
  offerPlans?: StringWhereInput | null;
  stage?: StringWhereInput | null;
}

/**
 * The IdWhereInput input type is used in operations that involve filtering objects by an id.
 */
export interface IdWhereInput {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: (string | null)[] | null;
  inQueryKey?: SelectInput | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notEqualTo?: string | null;
  notIn?: (string | null)[] | null;
  notInQueryKey?: SelectInput | null;
}

/**
 * The IdentityMetadataWhereInput input type is used in operations that involve filtering objects of IdentityMetadata class.
 */
export interface IdentityMetadataWhereInput {
  AND?: IdentityMetadataWhereInput[] | null;
  NOR?: IdentityMetadataWhereInput[] | null;
  OR?: IdentityMetadataWhereInput[] | null;
  syncEmails?: StringWhereInput | null;
}

/**
 * The IdentityRelationWhereInput input type is used in operations that involve filtering objects of Identity class.
 */
export interface IdentityRelationWhereInput {
  exists?: boolean | null;
  have?: IdentityWhereInput | null;
  haveNot?: IdentityWhereInput | null;
}

/**
 * The IdentityWhereInput input type is used in operations that involve filtering objects of Identity class.
 */
export interface IdentityWhereInput {
  AND?: IdentityWhereInput[] | null;
  NOR?: IdentityWhereInput[] | null;
  OR?: IdentityWhereInput[] | null;
  accessToken?: StringWhereInput | null;
  enabled?: BooleanWhereInput | null;
  metadata?: IdentityMetadataWhereInput | null;
  provider?: StringWhereInput | null;
  refreshToken?: StringWhereInput | null;
  scope?: ArrayWhereInput | null;
  updatedAt?: DateWhereInput | null;
  userEmail?: StringWhereInput | null;
  userId?: StringWhereInput | null;
}

/**
 * An entry from an object, i.e., a pair of key and value.
 */
export interface KeyValueInput {
  key: string;
  value: any;
}

/**
 * The LeadMemberRelationWhereInput input type is used in operations that involve filtering objects of LeadMember class.
 */
export interface LeadMemberRelationWhereInput {
  exists?: boolean | null;
  have?: LeadMemberWhereInput | null;
  haveNot?: LeadMemberWhereInput | null;
}

/**
 * The LeadMemberWhereInput input type is used in operations that involve filtering objects of LeadMember class.
 */
export interface LeadMemberWhereInput {
  AND?: LeadMemberWhereInput[] | null;
  NOR?: LeadMemberWhereInput[] | null;
  OR?: LeadMemberWhereInput[] | null;
  annualIncome?: NumberWhereInput | null;
  bankruptcy?: BooleanWhereInput | null;
  contact?: ContactRelationWhereInput | null;
  creditRating?: StringWhereInput | null;
  creditScore?: NumberWhereInput | null;
  employmentOccupation?: StringWhereInput | null;
  employmentStatus?: StringWhereInput | null;
  foreclosure?: StringWhereInput | null;
  isMilitary?: BooleanWhereInput | null;
  isPrimary?: BooleanWhereInput | null;
  jobStartedAt?: DateWhereInput | null;
  maritalStatus?: StringWhereInput | null;
  monthlyIncome?: NumberWhereInput | null;
  type?: StringWhereInput | null;
  typeElaborate?: StringWhereInput | null;
  veteranStatus?: StringWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the LeadPartner class.
 */
export interface LeadPartnerPointerInput {
  createAndLink?: CreateLeadPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The LeadPartnerRelationWhereInput input type is used in operations that involve filtering objects of LeadPartner class.
 */
export interface LeadPartnerRelationWhereInput {
  exists?: boolean | null;
  have?: LeadPartnerWhereInput | null;
  haveNot?: LeadPartnerWhereInput | null;
}

/**
 * The LeadPartnerWhereInput input type is used in operations that involve filtering objects of LeadPartner class.
 */
export interface LeadPartnerWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: LeadPartnerWhereInput[] | null;
  NOR?: LeadPartnerWhereInput[] | null;
  OR?: LeadPartnerWhereInput[] | null;
  contacts?: ContactRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  id?: IdWhereInput | null;
  leadSources?: LeadSourceRelationWhereInput | null;
  logo?: StringWhereInput | null;
  objectIcon?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  title?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the Lead class.
 */
export interface LeadPointerInput {
  createAndLink?: CreateLeadFieldsInput | null;
  link?: string | null;
}

/**
 * The LeadPriorityWhereInput input type is used in operations that involve filtering objects of LeadPriority class.
 */
export interface LeadPriorityWhereInput {
  AND?: LeadPriorityWhereInput[] | null;
  NOR?: LeadPriorityWhereInput[] | null;
  OR?: LeadPriorityWhereInput[] | null;
  enteredAt?: DateWhereInput | null;
  leadPosition?: NumberWhereInput | null;
  queue?: PriorityQueueRelationWhereInput | null;
  queuePosition?: NumberWhereInput | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the Lead class into a relation field.
 */
export interface LeadRelationInput {
  add?: string[] | null;
  createAndAdd?: CreateLeadFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The LeadRelationWhereInput input type is used in operations that involve filtering objects of Lead class.
 */
export interface LeadRelationWhereInput {
  exists?: boolean | null;
  have?: LeadWhereInput | null;
  haveNot?: LeadWhereInput | null;
}

/**
 * The LeadSourceMetaWhereInput input type is used in operations that involve filtering objects of LeadSourceMeta class.
 */
export interface LeadSourceMetaWhereInput {
  AND?: LeadSourceMetaWhereInput[] | null;
  NOR?: LeadSourceMetaWhereInput[] | null;
  OR?: LeadSourceMetaWhereInput[] | null;
  bankingBridgeDashboardLink?: StringWhereInput | null;
  bankingBridgeLastEvent?: StringWhereInput | null;
  bankingBridgeLastEventDate?: DateWhereInput | null;
  bankingBridgeSubId?: StringWhereInput | null;
  bankingBridgeSubscriptionId?: StringWhereInput | null;
  landingPageUrl?: StringWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the LeadSource class.
 */
export interface LeadSourcePointerInput {
  createAndLink?: CreateLeadSourceFieldsInput | null;
  link?: string | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the LeadSource class into a relation field.
 */
export interface LeadSourceRelationInput {
  add?: string[] | null;
  createAndAdd?: CreateLeadSourceFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The LeadSourceRelationWhereInput input type is used in operations that involve filtering objects of LeadSource class.
 */
export interface LeadSourceRelationWhereInput {
  exists?: boolean | null;
  have?: LeadSourceWhereInput | null;
  haveNot?: LeadSourceWhereInput | null;
}

/**
 * The LeadSourceStatsWhereInput input type is used in operations that involve filtering objects of LeadSourceStats class.
 */
export interface LeadSourceStatsWhereInput {
  AND?: LeadSourceStatsWhereInput[] | null;
  NOR?: LeadSourceStatsWhereInput[] | null;
  OR?: LeadSourceStatsWhereInput[] | null;
  accepted?: NumberWhereInput | null;
  balanced?: NumberWhereInput | null;
  rejected?: NumberWhereInput | null;
}

/**
 * The LeadSourceWhereInput input type is used in operations that involve filtering objects of LeadSource class.
 */
export interface LeadSourceWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: LeadSourceWhereInput[] | null;
  NOR?: LeadSourceWhereInput[] | null;
  OR?: LeadSourceWhereInput[] | null;
  adCost?: NumberWhereInput | null;
  adCostBasis?: StringWhereInput | null;
  costPerLead?: NumberWhereInput | null;
  createdAt?: DateWhereInput | null;
  id?: IdWhereInput | null;
  inboundPhoneNumber?: PhoneLineRelationWhereInput | null;
  isActive?: BooleanWhereInput | null;
  leadPartner?: LeadPartnerRelationWhereInput | null;
  leads?: LeadRelationWhereInput | null;
  objectIcon?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  provider?: StringWhereInput | null;
  proxyPercent?: NumberWhereInput | null;
  proxyUrl?: StringWhereInput | null;
  skipForDuplicate?: BooleanWhereInput | null;
  stats?: LeadSourceStatsWhereInput | null;
  team?: StringWhereInput | null;
  title?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The LeadStatWhereInput input type is used in operations that involve filtering objects of LeadStat class.
 */
export interface LeadStatWhereInput {
  AND?: LeadStatWhereInput[] | null;
  NOR?: LeadStatWhereInput[] | null;
  OR?: LeadStatWhereInput[] | null;
  missedCallsCount?: NumberWhereInput | null;
  unreadMessagesCount?: NumberWhereInput | null;
}

/**
 * The LeadWhereInput input type is used in operations that involve filtering objects of Lead class.
 */
export interface LeadWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: LeadWhereInput[] | null;
  NOR?: LeadWhereInput[] | null;
  OR?: LeadWhereInput[] | null;
  a1822Group?: StringWhereInput | null;
  accountNumber?: StringWhereInput | null;
  activeLoan?: StringWhereInput | null;
  activeNetworkReferral?: BooleanWhereInput | null;
  activityTypeId?: StringWhereInput | null;
  adKeyword?: StringWhereInput | null;
  addedListReports?: BooleanWhereInput | null;
  addedSimpleNexus?: BooleanWhereInput | null;
  additionalCashAfterCurrentBalancePaidOff?: NumberWhereInput | null;
  additionalInformation?: StringWhereInput | null;
  adwordCampaign?: StringWhereInput | null;
  affiliateName?: StringWhereInput | null;
  ageAtApplication?: StringWhereInput | null;
  ageOfYoungestBorrower?: NumberWhereInput | null;
  ageSeconds?: StringWhereInput | null;
  agentAssigned?: BooleanWhereInput | null;
  agentAssignedDateTime?: DateWhereInput | null;
  agentName?: StringWhereInput | null;
  aggMtgBalance?: NumberWhereInput | null;
  alternateLeadSource?: StringWhereInput | null;
  amortizationType?: StringWhereInput | null;
  annualReduction?: NumberWhereInput | null;
  anotherLenderAndActive?: BooleanWhereInput | null;
  anotherLenderAndClosed?: DateWhereInput | null;
  apAgeSeconds?: StringWhereInput | null;
  apDomain?: StringWhereInput | null;
  apFormInputMethod?: StringWhereInput | null;
  apMaskedCertUrl?: StringWhereInput | null;
  apRetainEmailMatch?: StringWhereInput | null;
  apRetainExpire?: StringWhereInput | null;
  apRetainPhoneMatch?: StringWhereInput | null;
  apRetainSuccess?: StringWhereInput | null;
  apiDuplicate?: BooleanWhereInput | null;
  appraisalFee?: NumberWhereInput | null;
  appraisalReceivedDate?: DateWhereInput | null;
  appraisalRequestedDate?: DateWhereInput | null;
  appraisedValue?: NumberWhereInput | null;
  apr?: NumberWhereInput | null;
  aptNumber?: StringWhereInput | null;
  armType?: StringWhereInput | null;
  assignedAt?: DateWhereInput | null;
  assignedBy?: UserRelationWhereInput | null;
  assignedTo?: UserRelationWhereInput | null;
  assignedToEmail?: StringWhereInput | null;
  assignedToNmls?: StringWhereInput | null;
  assignedToObjectId?: StringWhereInput | null;
  assignedToObjectName?: StringWhereInput | null;
  assignedUserApplyUrl?: StringWhereInput | null;
  assignedUserCustom6?: StringWhereInput | null;
  assignedUserCustom7?: StringWhereInput | null;
  assignedUserEmail?: StringWhereInput | null;
  assignedUserFive9Id?: StringWhereInput | null;
  assignedUserObjectId?: StringWhereInput | null;
  assignedUserTeam?: StringWhereInput | null;
  attorneyMobilePhone?: PhoneWhereInput | null;
  attorneyName?: StringWhereInput | null;
  attorneyPersonalEmail?: EmailWhereInput | null;
  autoLoanBal?: NumberWhereInput | null;
  autoLoanDel?: NumberWhereInput | null;
  autoLoanQty?: NumberWhereInput | null;
  availableHomeEquity?: NumberWhereInput | null;
  bankerName?: StringWhereInput | null;
  bankingBridgeDashboardLink?: StringWhereInput | null;
  bankingBridgeLastEvent?: StringWhereInput | null;
  bankingBridgeLastEventDate?: DateWhereInput | null;
  bankingBridgeSubscriptionId?: StringWhereInput | null;
  bipCurrentAge?: StringWhereInput | null;
  bipCurrentLTV?: StringWhereInput | null;
  bipCurrentLoanAmount?: NumberWhereInput | null;
  bipEPOExpirationDate?: DateWhereInput | null;
  bipEngagementDate?: DateWhereInput | null;
  bipFICOTier?: StringWhereInput | null;
  bipHELOCBalance?: NumberWhereInput | null;
  bipHomeValue?: NumberWhereInput | null;
  bipMortgageInquirySegment?: StringWhereInput | null;
  bipOpportunityDate?: DateWhereInput | null;
  bipOpportunityName?: StringWhereInput | null;
  bipPublicLoanAmount?: NumberWhereInput | null;
  bipPublicLoanClosingLTV?: StringWhereInput | null;
  bipPublicLoanFundedDate?: DateWhereInput | null;
  bipPublicLoanInterestRate?: StringWhereInput | null;
  bipPublicLoanTerm?: StringWhereInput | null;
  bipPublicLoanType?: StringWhereInput | null;
  bipRevolvingDebt?: NumberWhereInput | null;
  bipTappableEquity?: StringWhereInput | null;
  boCell?: StringWhereInput | null;
  boDob?: DateWhereInput | null;
  boEmployerAddress?: StringWhereInput | null;
  boEmployerCity?: StringWhereInput | null;
  boEmployerName?: StringWhereInput | null;
  boEmployerState?: StringWhereInput | null;
  boEmployerZip?: StringWhereInput | null;
  boEmploymentLengthInYears?: NumberWhereInput | null;
  boFirstName?: StringWhereInput | null;
  boHomeEMail?: StringWhereInput | null;
  boHomePhone?: StringWhereInput | null;
  boLastName?: StringWhereInput | null;
  boWorkEMail?: StringWhereInput | null;
  boWorkPhone?: StringWhereInput | null;
  borrowerAcknowledged?: StringWhereInput | null;
  borrowerAnnualIncome?: NumberWhereInput | null;
  borrowerBankruptcy?: BooleanWhereInput | null;
  borrowerBirthday?: DateWhereInput | null;
  borrowerCompany?: StringWhereInput | null;
  borrowerCreditRating?: StringWhereInput | null;
  borrowerCreditScore?: NumberWhereInput | null;
  borrowerEmail?: EmailWhereInput | null;
  borrowerEmails?: EmailAddressRelationWhereInput | null;
  borrowerEmploymentOccupation?: StringWhereInput | null;
  borrowerFirstName?: StringWhereInput | null;
  borrowerForeclosure?: StringWhereInput | null;
  borrowerHomePhone?: PhoneWhereInput | null;
  borrowerHomePhoneOptOut?: BooleanWhereInput | null;
  borrowerInitialCDESignedDate?: DateWhereInput | null;
  borrowerIntendToContinueDate?: DateWhereInput | null;
  borrowerLastName?: StringWhereInput | null;
  borrowerLeESignedDate?: DateWhereInput | null;
  borrowerMaritalStatus?: StringWhereInput | null;
  borrowerMiddleName?: StringWhereInput | null;
  borrowerMobilePhone?: PhoneWhereInput | null;
  borrowerMobilePhoneOptOut?: BooleanWhereInput | null;
  borrowerPersonalEmail?: EmailWhereInput | null;
  borrowerPersonalEmailOptOut?: BooleanWhereInput | null;
  borrowerPhone?: PhoneWhereInput | null;
  borrowerPhones?: PhoneNumberRelationWhereInput | null;
  borrowerPrimaryPhoneOptOut?: BooleanWhereInput | null;
  borrowerSSN?: StringWhereInput | null;
  borrowerVeteranStatus?: StringWhereInput | null;
  borrowerWorkEmail?: EmailWhereInput | null;
  borrowerWorkPhone?: PhoneWhereInput | null;
  borrowerWorkPhoneOptOut?: BooleanWhereInput | null;
  borrowerisMilitary?: BooleanWhereInput | null;
  botSplashConversationUrl?: StringWhereInput | null;
  botSplashLastMessageReceivedDate?: DateWhereInput | null;
  botSplashSmsMessageSent?: StringWhereInput | null;
  botsplashLastMessageSentDate?: DateWhereInput | null;
  botsplashLinkClickTracking?: StringWhereInput | null;
  botsplashListenerName?: StringWhereInput | null;
  botsplashOriginalUrl?: StringWhereInput | null;
  botsplashTimestamp?: DateWhereInput | null;
  branchGroup?: StringWhereInput | null;
  branchId?: StringWhereInput | null;
  branchOfService?: StringWhereInput | null;
  browser?: StringWhereInput | null;
  bsLastMessageReceivedDate?: DateWhereInput | null;
  buyerPersonalityAndHobbies?: StringWhereInput | null;
  buyerRealtorAddressCity?: StringWhereInput | null;
  buyerRealtorAddressState?: StringWhereInput | null;
  buyerRealtorAddressStreet?: StringWhereInput | null;
  buyerRealtorAddressZip?: StringWhereInput | null;
  buyerRealtorContactId?: StringWhereInput | null;
  buyerRealtorFirstName?: StringWhereInput | null;
  buyerRealtorLastName?: StringWhereInput | null;
  buyerRealtorMobilePhone?: PhoneWhereInput | null;
  buyerRealtorName?: StringWhereInput | null;
  buyerRealtorPersonalEmail?: EmailWhereInput | null;
  buyersAgentCity?: StringWhereInput | null;
  buyersAgentState?: StringWhereInput | null;
  calendlyTime?: DateWhereInput | null;
  callAttempts?: NumberWhereInput | null;
  cashOut?: NumberWhereInput | null;
  cert?: StringWhereInput | null;
  certId?: StringWhereInput | null;
  certUrl?: StringWhereInput | null;
  certificateSent?: BooleanWhereInput | null;
  channel?: StringWhereInput | null;
  cis?: NumberWhereInput | null;
  citizenshipStatus?: StringWhereInput | null;
  closingDate?: DateWhereInput | null;
  cltv?: NumberWhereInput | null;
  coBorrowerBirthday?: DateWhereInput | null;
  coBorrowerCreditRating?: StringWhereInput | null;
  coBorrowerCreditScore?: NumberWhereInput | null;
  coBorrowerFirstName?: StringWhereInput | null;
  coBorrowerHomePhone?: PhoneWhereInput | null;
  coBorrowerInitialCDESignedDate?: DateWhereInput | null;
  coBorrowerIsMilitary?: BooleanWhereInput | null;
  coBorrowerLastName?: StringWhereInput | null;
  coBorrowerLeESignedDate?: DateWhereInput | null;
  coBorrowerMiddleName?: StringWhereInput | null;
  coBorrowerMobilePhone?: PhoneWhereInput | null;
  coBorrowerPhone?: PhoneWhereInput | null;
  coBorrowerResidentialAddress?: AddressWhereInput | null;
  coBorrowerSSN?: StringWhereInput | null;
  coBorrowerWorkPhone?: PhoneWhereInput | null;
  coCell?: StringWhereInput | null;
  coDob?: DateWhereInput | null;
  coEmployerAddress?: StringWhereInput | null;
  coEmployerCity?: StringWhereInput | null;
  coEmployerName?: StringWhereInput | null;
  coEmployerState?: StringWhereInput | null;
  coEmployerZip?: StringWhereInput | null;
  coEmploymentLengthInYears?: NumberWhereInput | null;
  coFirstName?: StringWhereInput | null;
  coHomeEmail?: StringWhereInput | null;
  coHomePhone?: StringWhereInput | null;
  coLastName?: StringWhereInput | null;
  coWorkEmail?: StringWhereInput | null;
  coWorkPhone?: StringWhereInput | null;
  coborrowerTimeAtPreviousResidence?: NumberWhereInput | null;
  collateralPropertyType?: StringWhereInput | null;
  collateralValueVerification?: BooleanWhereInput | null;
  contact?: ContactRelationWhereInput | null;
  contactAction?: StringWhereInput | null;
  contactConfirmed?: BooleanWhereInput | null;
  contactConfirmedDateTime?: DateWhereInput | null;
  contactEmail?: EmailWhereInput | null;
  contactFirstName?: StringWhereInput | null;
  contactId?: StringWhereInput | null;
  contactLastName?: StringWhereInput | null;
  contactPhone?: PhoneWhereInput | null;
  contactStatus?: StringWhereInput | null;
  contactedStatusAttempts?: NumberWhereInput | null;
  contingencyDate?: DateWhereInput | null;
  costCenter?: NumberWhereInput | null;
  costco?: CostcoWhereInput | null;
  costcoDiscount?: BooleanWhereInput | null;
  costcoLenderFee?: StringWhereInput | null;
  costcoMembershipId?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  createdBy?: UserRelationWhereInput | null;
  creditCardDel?: NumberWhereInput | null;
  creditCardQty?: NumberWhereInput | null;
  creditReportPulledDate?: DateWhereInput | null;
  currentCashOut?: NumberWhereInput | null;
  currentCombDebtPayment?: NumberWhereInput | null;
  currentInstallmentDebtBalance?: NumberWhereInput | null;
  currentInterestRate?: NumberWhereInput | null;
  currentLeadStatus?: StringWhereInput | null;
  currentLoanAmount?: NumberWhereInput | null;
  currentMortgageBalance?: NumberWhereInput | null;
  currentMortgageDel?: NumberWhereInput | null;
  currentMortgageLender?: StringWhereInput | null;
  currentMortgageMonthlyPayment?: NumberWhereInput | null;
  currentMortgagePayment?: NumberWhereInput | null;
  currentMortgageQty?: NumberWhereInput | null;
  currentMortgageRate?: NumberWhereInput | null;
  currentNeedSituation?: StringWhereInput | null;
  currentRate?: NumberWhereInput | null;
  currentRevolvingDebtBalance?: NumberWhereInput | null;
  currentStageActionCount?: NumberWhereInput | null;
  currentStatusActionCount?: NumberWhereInput | null;
  currentTotalDebtBalance?: NumberWhereInput | null;
  currentlyRentOrOwn?: StringWhereInput | null;
  customRank?: StringWhereInput | null;
  customizedMessageToBorrower?: StringWhereInput | null;
  dayOfTheWeek?: StringWhereInput | null;
  declaredBankruptcy?: StringWhereInput | null;
  denialDate?: DateWhereInput | null;
  desiredAPR?: NumberWhereInput | null;
  desiredRate?: NumberWhereInput | null;
  deviceTypeUsed?: StringWhereInput | null;
  dischargedBankruptcyMonth?: StringWhereInput | null;
  disclosure?: StringWhereInput | null;
  disclosureViewedDateBorrower?: DateWhereInput | null;
  disclosureViewedDateCoBorrower?: DateWhereInput | null;
  distribute?: BooleanWhereInput | null;
  distributedAt?: DateWhereInput | null;
  distributedBy?: DistributionQueueRelationWhereInput | null;
  distributedByObjectId?: StringWhereInput | null;
  distributionRules?: ArrayWhereInput | null;
  dmFico?: NumberWhereInput | null;
  dmLTV?: NumberWhereInput | null;
  dmLoanTypeFlag?: StringWhereInput | null;
  dncFederal?: StringWhereInput | null;
  dncKnownLitigator?: StringWhereInput | null;
  dncState?: StringWhereInput | null;
  domain?: StringWhereInput | null;
  downPayment?: NumberWhereInput | null;
  dti?: NumberWhereInput | null;
  duplicateOf?: LeadRelationWhereInput | null;
  duplicateOfObjectId?: StringWhereInput | null;
  duplicates?: LeadRelationWhereInput | null;
  eSignSentDate?: DateWhereInput | null;
  emailVerification?: StringWhereInput | null;
  employerName?: StringWhereInput | null;
  employersAllBorrowers?: StringWhereInput | null;
  encompassApr?: StringWhereInput | null;
  encompassBorrowerMiddleFicoScore?: NumberWhereInput | null;
  encompassClosingDisclosureReceivedDate?: DateWhereInput | null;
  encompassClosingDisclosureSentDate?: DateWhereInput | null;
  encompassCoBorrowerMiddleFicoScore?: NumberWhereInput | null;
  encompassCreditReportReceivedDate?: DateWhereInput | null;
  encompassESignConsentDate?: DateWhereInput | null;
  encompassInitialTilDisclosureProvidedDate?: DateWhereInput | null;
  encompassInitialTilDisclosureReceivedDate?: DateWhereInput | null;
  encompassInterviewerEmail?: StringWhereInput | null;
  encompassLeadId?: StringWhereInput | null;
  encompassLoanNumber?: StringWhereInput | null;
  encompassLoanPurposeTypeUrla?: StringWhereInput | null;
  encompassLoanType?: StringWhereInput | null;
  encompassLtv?: NumberWhereInput | null;
  encompassMilestoneCurrentDate?: DateWhereInput | null;
  encompassPropertyState?: StringWhereInput | null;
  encompassPropertyStreetAddress?: StringWhereInput | null;
  encompassPropertyUsageType?: StringWhereInput | null;
  encompassReferringBanker?: StringWhereInput | null;
  encompassRequestedInterestRate?: NumberWhereInput | null;
  encompassResubmitted?: BooleanWhereInput | null;
  encompassTotalLoanAmount?: NumberWhereInput | null;
  epoEndDate?: DateWhereInput | null;
  equityInHome?: NumberWhereInput | null;
  establishedRelationship?: BooleanWhereInput | null;
  estimatedCloseDate?: DateWhereInput | null;
  estimatedCreditProfile?: StringWhereInput | null;
  eventAttended?: BooleanWhereInput | null;
  eventDate?: DateWhereInput | null;
  eventDuration?: StringWhereInput | null;
  eventManager?: BooleanWhereInput | null;
  exemptFromFundingFee?: BooleanWhereInput | null;
  existingHomeValue?: NumberWhereInput | null;
  externalChannel?: StringWhereInput | null;
  externalLastUpdatedDate?: DateWhereInput | null;
  faBranch?: StringWhereInput | null;
  fanniePIW?: StringWhereInput | null;
  fccConsent?: StringWhereInput | null;
  fhaMtgBalance?: NumberWhereInput | null;
  fico?: NumberWhereInput | null;
  field1?: StringWhereInput | null;
  field2?: StringWhereInput | null;
  field3?: StringWhereInput | null;
  field4?: StringWhereInput | null;
  fileStartedDate?: DateWhereInput | null;
  fileStarterLosId?: StringWhereInput | null;
  fileStarterName?: StringWhereInput | null;
  filterId?: StringWhereInput | null;
  finalLoPrice?: StringWhereInput | null;
  financeFf?: BooleanWhereInput | null;
  financeMip?: BooleanWhereInput | null;
  firstARMRateResetTermInMonths?: NumberWhereInput | null;
  firstAmortizationTermMonths?: NumberWhereInput | null;
  firstApr?: NumberWhereInput | null;
  firstAssignCostCenter?: StringWhereInput | null;
  firstAssignedTo?: UserRelationWhereInput | null;
  firstAssignedToObjectId?: StringWhereInput | null;
  firstContact?: StringWhereInput | null;
  firstContactAction?: StringWhereInput | null;
  firstContactActionDate?: DateWhereInput | null;
  firstContactCostCenter?: StringWhereInput | null;
  firstContactDate?: DateWhereInput | null;
  firstContactLoEmail?: StringWhereInput | null;
  firstDiscountPoints?: NumberWhereInput | null;
  firstDistributedAt?: DateWhereInput | null;
  firstDistributedBy?: DistributionQueueRelationWhereInput | null;
  firstDocumentType?: StringWhereInput | null;
  firstDownPayment?: NumberWhereInput | null;
  firstDownPaymentUnit?: StringWhereInput | null;
  firstExpirationDate?: DateWhereInput | null;
  firstInitialARMTermInMonths?: NumberWhereInput | null;
  firstInterestOnly?: StringWhereInput | null;
  firstInterestOnlyPeriodInMonths?: NumberWhereInput | null;
  firstInterestRate?: NumberWhereInput | null;
  firstLienBalance?: NumberWhereInput | null;
  firstLoanAmount?: NumberWhereInput | null;
  firstLoanAmountEncompass?: NumberWhereInput | null;
  firstLoanProgramCategory?: StringWhereInput | null;
  firstLoanProgramName?: StringWhereInput | null;
  firstLoanProgramProductType?: StringWhereInput | null;
  firstLoanTermMonths?: NumberWhereInput | null;
  firstLockTerm?: StringWhereInput | null;
  firstMonthlyInsurancePayment?: NumberWhereInput | null;
  firstMonthlyPaymentUnit?: StringWhereInput | null;
  firstMonthlyPayments?: NumberWhereInput | null;
  firstMonthlyPrincipalAndInterestPayment?: NumberWhereInput | null;
  firstMortgageInsurancePayment?: NumberWhereInput | null;
  firstOfferId?: StringWhereInput | null;
  firstOfferType?: StringWhereInput | null;
  firstOfferVendorId?: StringWhereInput | null;
  firstOriginationFee?: NumberWhereInput | null;
  firstOriginationFeeOption?: StringWhereInput | null;
  firstOriginationFeeUnit?: StringWhereInput | null;
  firstOtherLenderFees?: NumberWhereInput | null;
  firstPaymentDate?: DateWhereInput | null;
  firstProductName?: StringWhereInput | null;
  firstRatePercent?: NumberWhereInput | null;
  firstRateType?: StringWhereInput | null;
  firstSMSReceivedAt?: DateWhereInput | null;
  firstTerm?: NumberWhereInput | null;
  firstTimeHomeBuyer?: BooleanWhereInput | null;
  firstTransactionType?: StringWhereInput | null;
  firstUrl?: StringWhereInput | null;
  firstUseOfVaProgram?: BooleanWhereInput | null;
  five9FirstAssignedEmail?: StringWhereInput | null;
  five9SpamAni?: StringWhereInput | null;
  frBranch?: StringWhereInput | null;
  freddiePIW?: StringWhereInput | null;
  fundedDate?: DateWhereInput | null;
  gclid?: StringWhereInput | null;
  gosId?: NumberWhereInput | null;
  groupId?: StringWhereInput | null;
  haveRealtor?: BooleanWhereInput | null;
  helocPaidThisRefinance?: BooleanWhereInput | null;
  highMtgBalance?: NumberWhereInput | null;
  homeBuyingProcess?: HomeBuyingProcessWhereInput | null;
  homeBuyingProcessOfferPlans?: StringWhereInput | null;
  homeBuyingProcessStage?: StringWhereInput | null;
  homeEquityConversionInsight?: NumberWhereInput | null;
  homeEquityLoanBalance?: NumberWhereInput | null;
  id?: IdWhereInput | null;
  incomeVerification?: BooleanWhereInput | null;
  individualOrJoinApplication?: StringWhereInput | null;
  individualOrJointApplication?: StringWhereInput | null;
  inquiryTimeOfDay?: StringWhereInput | null;
  intendedPropertyUse?: StringWhereInput | null;
  interestRatePercent?: NumberWhereInput | null;
  introduction?: StringWhereInput | null;
  investor?: StringWhereInput | null;
  investorName?: StringWhereInput | null;
  ip?: StringWhereInput | null;
  ipGeo?: StringWhereInput | null;
  isBuyersAgent?: BooleanWhereInput | null;
  isSellingOrListingAgent?: BooleanWhereInput | null;
  juniorBankerEmail?: StringWhereInput | null;
  juniorBankerName?: StringWhereInput | null;
  keywords?: StringWhereInput | null;
  khlLoEmail?: StringWhereInput | null;
  kpm?: StringWhereInput | null;
  landingPageId?: StringWhereInput | null;
  languagePreference?: StringWhereInput | null;
  lastAction?: StringWhereInput | null;
  lastActionDate?: DateWhereInput | null;
  lastActionLoEmail?: StringWhereInput | null;
  lastActionTriggered?: StringWhereInput | null;
  lastCallMadeAt?: DateWhereInput | null;
  lastCallReceivedAt?: DateWhereInput | null;
  lastCompletedMilestone?: StringWhereInput | null;
  lastContactedAt?: DateWhereInput | null;
  lastEmailReceivedAt?: DateWhereInput | null;
  lastEmailSentAt?: DateWhereInput | null;
  lastFundedCLTV?: NumberWhereInput | null;
  lastFundedCashOutAmount?: NumberWhereInput | null;
  lastFundedCreditScore?: NumberWhereInput | null;
  lastFundedDMILoanNumber?: StringWhereInput | null;
  lastFundedDate?: DateWhereInput | null;
  lastFundedDisbursementDate?: DateWhereInput | null;
  lastFundedEPODate?: DateWhereInput | null;
  lastFundedFirstPaymentDate?: DateWhereInput | null;
  lastFundedFundsReleased?: DateWhereInput | null;
  lastFundedInterestRate?: NumberWhereInput | null;
  lastFundedInvestor?: StringWhereInput | null;
  lastFundedLOEmail?: StringWhereInput | null;
  lastFundedLTV?: NumberWhereInput | null;
  lastFundedLeadSource?: StringWhereInput | null;
  lastFundedLoanAmount?: NumberWhereInput | null;
  lastFundedLoanDelinquencyDate?: DateWhereInput | null;
  lastFundedLoanMaturesDate?: DateWhereInput | null;
  lastFundedLoanNumber?: StringWhereInput | null;
  lastFundedLoanOfficer?: StringWhereInput | null;
  lastFundedLoanProduct?: StringWhereInput | null;
  lastFundedLoanProgram?: StringWhereInput | null;
  lastFundedLoanPurpose?: StringWhereInput | null;
  lastFundedLoanTermInMonths?: NumberWhereInput | null;
  lastFundedLoanTradeTotalBuyPrice?: NumberWhereInput | null;
  lastFundedLoanType?: StringWhereInput | null;
  lastFundedMIPFundingAmount?: NumberWhereInput | null;
  lastFundedMortgageInsuranceMonthlyPayment?: NumberWhereInput | null;
  lastFundedNextPaymentDueDate?: DateWhereInput | null;
  lastFundedOccupancyType?: StringWhereInput | null;
  lastFundedOriginationDate?: DateWhereInput | null;
  lastFundedPI?: NumberWhereInput | null;
  lastFundedPMIAmount?: NumberWhereInput | null;
  lastFundedPropertyType?: StringWhereInput | null;
  lastFundedPropertyValue?: NumberWhereInput | null;
  lastFundedPurchaseAdviceDate?: DateWhereInput | null;
  lastFundedPurchasePrice?: NumberWhereInput | null;
  lastFundedRealtorNotes?: StringWhereInput | null;
  lastFundedSubjectPropertyAddress?: StringWhereInput | null;
  lastFundedSubjectPropertyCity?: StringWhereInput | null;
  lastFundedSubjectPropertyCounty?: StringWhereInput | null;
  lastFundedSubjectPropertyInsurance?: NumberWhereInput | null;
  lastFundedSubjectPropertyState?: StringWhereInput | null;
  lastFundedSubjectPropertyTaxes?: NumberWhereInput | null;
  lastFundedSubjectPropertyZip?: StringWhereInput | null;
  lastFundedTotalMonthlyPayment?: NumberWhereInput | null;
  lastFundedWaiveEscrow?: BooleanWhereInput | null;
  lastIncomingContactedAt?: DateWhereInput | null;
  lastNetworkNoteDateTime?: DateWhereInput | null;
  lastNote?: StringWhereInput | null;
  lastNoteAddedAt?: DateWhereInput | null;
  lastOutgoingContactedAt?: DateWhereInput | null;
  lastSMSReceivedAt?: DateWhereInput | null;
  lastSMSSentAt?: DateWhereInput | null;
  leSentDate?: DateWhereInput | null;
  leadPricePaid?: NumberWhereInput | null;
  leadProviderName?: StringWhereInput | null;
  leadReceivedBy?: StringWhereInput | null;
  leadSource?: LeadSourceRelationWhereInput | null;
  leadSourceAppName?: StringWhereInput | null;
  leadSourceCampaign?: StringWhereInput | null;
  leadSourceId?: StringWhereInput | null;
  leadSourceMeta?: LeadSourceMetaWhereInput | null;
  leadSourceObjectId?: StringWhereInput | null;
  leadSourceObjectName?: StringWhereInput | null;
  leadSourcePartner?: StringWhereInput | null;
  leadSourceTitle?: StringWhereInput | null;
  leadStatus?: StatusWhereInput | null;
  leadStatusActionCount?: NumberWhereInput | null;
  leadStatusUpdatedAt?: DateWhereInput | null;
  leadTransferDate?: DateWhereInput | null;
  leadTransferred?: BooleanWhereInput | null;
  leadTransferredBy?: StringWhereInput | null;
  leakage?: NumberWhereInput | null;
  lenderId?: StringWhereInput | null;
  lenderName?: StringWhereInput | null;
  loPreSigned?: NumberWhereInput | null;
  loaCashOutReason?: StringWhereInput | null;
  loaCreditOrAutoLoans?: BooleanWhereInput | null;
  loaElseOnBalances?: StringWhereInput | null;
  loaEmploymentStatus?: StringWhereInput | null;
  loaEstimatedAppraisedValue?: NumberWhereInput | null;
  loaMonthlyPayments?: NumberWhereInput | null;
  loaMortgageBalance?: NumberWhereInput | null;
  loaOtherLienBalance?: NumberWhereInput | null;
  loaPersonalLoans?: BooleanWhereInput | null;
  loaVaStatement?: StringWhereInput | null;
  loan?: ObjectWhereInput | null;
  loanAmortizationType?: StringWhereInput | null;
  loanAmount?: NumberWhereInput | null;
  loanApplicationId?: StringWhereInput | null;
  loanOfficer?: StringWhereInput | null;
  loanProduct?: StringWhereInput | null;
  loanProgram?: StringWhereInput | null;
  loanProgramName?: StringWhereInput | null;
  loanProposals?: NumberWhereInput | null;
  loanPurchasePrice?: NumberWhereInput | null;
  loanPurpose?: StringWhereInput | null;
  loanRequestCompleteDate?: DateWhereInput | null;
  loanStartedSource?: StringWhereInput | null;
  loanStatus?: StringWhereInput | null;
  loanTeamMemberName?: StringWhereInput | null;
  loanTerm?: StringWhereInput | null;
  loanTermYears?: NumberWhereInput | null;
  loanTypeNotes?: StringWhereInput | null;
  loanTypePreferred?: StringWhereInput | null;
  locationMovingTo?: StringWhereInput | null;
  lockDate?: DateWhereInput | null;
  lockExpiryDate?: DateWhereInput | null;
  lockInDays?: StringWhereInput | null;
  lockStatus?: BooleanWhereInput | null;
  ltv?: NumberWhereInput | null;
  mLOComments?: StringWhereInput | null;
  mailerUrl?: StringWhereInput | null;
  managerName?: StringWhereInput | null;
  maritalStatus?: StringWhereInput | null;
  maritalStatusAndKids?: StringWhereInput | null;
  members?: LeadMemberRelationWhereInput | null;
  milestoneDateSubmittal?: DateWhereInput | null;
  militaryBranch?: StringWhereInput | null;
  militaryStatus?: StringWhereInput | null;
  mixRank?: NumberWhereInput | null;
  mixTier?: NumberWhereInput | null;
  mloEligibilityDate?: DateWhereInput | null;
  mloNextFollowUpDate?: DateWhereInput | null;
  mloStatusDate?: DateWhereInput | null;
  mloStatusGeneral?: StringWhereInput | null;
  mloStatusStl?: StringWhereInput | null;
  mobileDeviceIndicator?: StringWhereInput | null;
  monthlyReduction?: NumberWhereInput | null;
  moomActionStatusDate?: DateWhereInput | null;
  moomActionStatusPurchase?: StringWhereInput | null;
  moomActionStatusRefi?: StringWhereInput | null;
  mortech?: MortechWhereInput | null;
  mortgageCoordinatorEmail?: StringWhereInput | null;
  mutualBuyersBenefitAmount?: NumberWhereInput | null;
  mutualBuyersBenefitProgramOffer?: StringWhereInput | null;
  mutualDate?: DateWhereInput | null;
  mutualFlag?: BooleanWhereInput | null;
  mutualGiveAway?: BooleanWhereInput | null;
  needListingAgentToSell?: BooleanWhereInput | null;
  needPurchaseRealtor?: BooleanWhereInput | null;
  needSellingRealtor?: BooleanWhereInput | null;
  networkNotes?: StringWhereInput | null;
  newOrExitingProperty?: StringWhereInput | null;
  newPayment?: NumberWhereInput | null;
  nextReminderDate?: DateWhereInput | null;
  numberOfUnits?: NumberWhereInput | null;
  objectIcon?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  okToSentToNetwork?: BooleanWhereInput | null;
  oldCRMId?: StringWhereInput | null;
  orderId?: StringWhereInput | null;
  original?: ObjectWhereInput | null;
  originalBalance?: NumberWhereInput | null;
  originalLeadSource?: LeadSourceRelationWhereInput | null;
  originalLoanNumber?: StringWhereInput | null;
  originalMortgageAmount?: NumberWhereInput | null;
  originalOriginationDate?: DateWhereInput | null;
  originalTerm?: NumberWhereInput | null;
  otherDel?: NumberWhereInput | null;
  otherIncomeSource?: StringWhereInput | null;
  otherQty?: NumberWhereInput | null;
  outOfNetworkOther?: StringWhereInput | null;
  outOfNetworkReason?: StringWhereInput | null;
  ownUpConcierge?: BooleanWhereInput | null;
  ownerFullName?: StringWhereInput | null;
  ownerNMLS?: StringWhereInput | null;
  ownerObjectId?: StringWhereInput | null;
  pageName?: StringWhereInput | null;
  pageUrl?: StringWhereInput | null;
  pageUuid?: StringWhereInput | null;
  partnerId?: NumberWhereInput | null;
  perksEmployerName?: StringWhereInput | null;
  perksFilterId?: StringWhereInput | null;
  perksReferralSource?: StringWhereInput | null;
  phones?: PhoneNumberRelationWhereInput | null;
  portfolioRetentionLoanNumber?: StringWhereInput | null;
  portfolioRetentionSoldTo?: StringWhereInput | null;
  portfolioSoldTo?: DateWhereInput | null;
  portfolioSoldToDate?: DateWhereInput | null;
  postalCode?: StringWhereInput | null;
  preferredTimeToContact?: StringWhereInput | null;
  prequalInterest?: BooleanWhereInput | null;
  prequalSentDateInitial?: DateWhereInput | null;
  prequalSentLo?: StringWhereInput | null;
  prequalStatus?: StringWhereInput | null;
  presentCltv?: NumberWhereInput | null;
  presentLtv?: NumberWhereInput | null;
  previousAgent?: BooleanWhereInput | null;
  previousAgentYesNoNotes?: StringWhereInput | null;
  previousAssignedTo?: UserRelationWhereInput | null;
  previousAssignedToObjectId?: StringWhereInput | null;
  previousAssigneeName?: StringWhereInput | null;
  previousDistributedAt?: DateWhereInput | null;
  previousDistributedBy?: DistributionQueueRelationWhereInput | null;
  previousEPODate?: DateWhereInput | null;
  previousFirstPaymentDate?: DateWhereInput | null;
  previousLoName?: StringWhereInput | null;
  priceMaxApprovalAmount?: StringWhereInput | null;
  pricePaid?: NumberWhereInput | null;
  pricingType?: StringWhereInput | null;
  primaryMortgageBalance?: NumberWhereInput | null;
  priority?: LeadPriorityWhereInput | null;
  prioritySequence?: NumberWhereInput | null;
  privateMortgageInsuranceRequired?: BooleanWhereInput | null;
  proPair?: ProPairWhereInput | null;
  proPairCustomRank?: NumberWhereInput | null;
  proPairInput?: StringWhereInput | null;
  proPairInputFa?: StringWhereInput | null;
  proPairRank?: NumberWhereInput | null;
  proPairRecState?: StringWhereInput | null;
  processingFee?: NumberWhereInput | null;
  processorNameCustom?: StringWhereInput | null;
  productionCenter?: StringWhereInput | null;
  profitability?: StringWhereInput | null;
  property?: PropertyWhereInput | null;
  propertyAddress?: StringWhereInput | null;
  propertyCity?: StringWhereInput | null;
  propertyCounty?: StringWhereInput | null;
  propertyEstimatedValue?: NumberWhereInput | null;
  propertyFound?: StringWhereInput | null;
  propertyLoanType?: StringWhereInput | null;
  propertyNeedsInfo?: StringWhereInput | null;
  propertyOccupancy?: StringWhereInput | null;
  propertyPurchaseYear?: NumberWhereInput | null;
  propertyState?: StringWhereInput | null;
  propertyStreet?: StringWhereInput | null;
  propertyType?: StringWhereInput | null;
  propertyTypeDesired?: StringWhereInput | null;
  propertyUse?: StringWhereInput | null;
  propertyValue?: NumberWhereInput | null;
  propertyWillBe?: StringWhereInput | null;
  propertyZip?: StringWhereInput | null;
  proposedCltv?: NumberWhereInput | null;
  proposedLtv?: NumberWhereInput | null;
  providerCreditScore?: NumberWhereInput | null;
  pubId?: StringWhereInput | null;
  purchaseAdviceDate?: DateWhereInput | null;
  purchaseJourneyStage?: StringWhereInput | null;
  purchasePrice?: NumberWhereInput | null;
  purposeOfLoan?: StringWhereInput | null;
  qualDate?: DateWhereInput | null;
  queId?: StringWhereInput | null;
  queuedCall?: StringWhereInput | null;
  quotedLoanProgram?: StringWhereInput | null;
  ratePlugContactDate?: DateWhereInput | null;
  ratePlugContactPreference?: StringWhereInput | null;
  ratePlugContactSubject?: StringWhereInput | null;
  ratePlugMatchDate?: DateWhereInput | null;
  ratePlugMatchStatus?: StringWhereInput | null;
  ratePreference?: StringWhereInput | null;
  rateTypeDesired?: StringWhereInput | null;
  reactivationDate?: DateWhereInput | null;
  realtagLink?: BooleanWhereInput | null;
  realtorLicenseNumber?: StringWhereInput | null;
  realtorOrigin?: StringWhereInput | null;
  reasonForMove?: StringWhereInput | null;
  recentHomeOwnerPolicy?: BooleanWhereInput | null;
  referenceSource?: StringWhereInput | null;
  referralAgentContactId?: StringWhereInput | null;
  referralAgentFirstName?: StringWhereInput | null;
  referralAgentLastName?: StringWhereInput | null;
  referralAgentMobilePhone?: PhoneWhereInput | null;
  referralAgentName?: StringWhereInput | null;
  referralAgentPersonalEmail?: EmailWhereInput | null;
  referredTo?: StringWhereInput | null;
  referredToNetwork?: BooleanWhereInput | null;
  referredToNetworkDateTime?: DateWhereInput | null;
  referringCompanyName?: StringWhereInput | null;
  referringFirstName?: StringWhereInput | null;
  referringKWMarketCenter?: StringWhereInput | null;
  referringKWMarketCenterNumber?: StringWhereInput | null;
  referringKWRegion?: StringWhereInput | null;
  referringKWRegionName?: StringWhereInput | null;
  referringKWUID?: StringWhereInput | null;
  referringLastName?: StringWhereInput | null;
  referringMobile?: StringWhereInput | null;
  referringTeamEmail?: StringWhereInput | null;
  referringTeamId?: StringWhereInput | null;
  referringTeamName?: StringWhereInput | null;
  referringTeamPhone?: StringWhereInput | null;
  refinanceOrHEReason?: StringWhereInput | null;
  relcuLoan?: RelcuLoanRelationWhereInput | null;
  relcuLoanObjectId?: StringWhereInput | null;
  requestedReverseMortgageAgent?: StringWhereInput | null;
  requestedReverseMortgageDate?: DateWhereInput | null;
  requestedSpanishSpeakerAgent?: StringWhereInput | null;
  requestedSpanishSpeakerDate?: DateWhereInput | null;
  resNum?: StringWhereInput | null;
  residentialAddress?: AddressWhereInput | null;
  residentialAddressCity?: StringWhereInput | null;
  residentialAddressState?: StringWhereInput | null;
  residentialAddressStreet?: StringWhereInput | null;
  residentialAddressZip?: StringWhereInput | null;
  rvLoanId?: StringWhereInput | null;
  rvLoanNumber?: NumberWhereInput | null;
  sbActExec?: StringWhereInput | null;
  sbActExecEmail?: StringWhereInput | null;
  sbAlertType?: StringWhereInput | null;
  sbBrokerLenderName?: StringWhereInput | null;
  sbBrokerLoEmail?: StringWhereInput | null;
  sbBrokerLoPhone?: StringWhereInput | null;
  sbCRMId?: StringWhereInput | null;
  sbCashOutAlertDateTime?: DateWhereInput | null;
  sbCashOutAlertMessage?: StringWhereInput | null;
  sbCreditAlertDateTime?: DateWhereInput | null;
  sbCreditAlertMessage?: StringWhereInput | null;
  sbCurrentCreditScore?: StringWhereInput | null;
  sbEpoAlertDateTime?: DateWhereInput | null;
  sbEpoAlertMessage?: StringWhereInput | null;
  sbEquityAlertDateTime?: DateWhereInput | null;
  sbEquityAlertMessage?: StringWhereInput | null;
  sbFicoTier?: StringWhereInput | null;
  sbInvestor?: StringWhereInput | null;
  sbLensAlertDateTime?: DateWhereInput | null;
  sbLensAlertMessage?: StringWhereInput | null;
  sbListingAlertDateTime?: DateWhereInput | null;
  sbListingAlertMessage?: StringWhereInput | null;
  sbLoanChannel?: StringWhereInput | null;
  sbLoanOfficerEmail?: StringWhereInput | null;
  sbLoanOfficerName?: StringWhereInput | null;
  sbMarketAlertDateTime?: DateWhereInput | null;
  sbMarketAlertMessage?: StringWhereInput | null;
  sbMiRemovalAlertDateTime?: DateWhereInput | null;
  sbMiRemovalAlertMessage?: StringWhereInput | null;
  sbNMLS?: StringWhereInput | null;
  sbOther1?: StringWhereInput | null;
  sbRateTermAlertDateTime?: DateWhereInput | null;
  sbRateTermAlertMessage?: StringWhereInput | null;
  sbRateWatchAlertDateTime?: DateWhereInput | null;
  sbRateWatchAlertMessage?: StringWhereInput | null;
  sbReverseAlertDateTime?: DateWhereInput | null;
  sbReverseAlertMessage?: StringWhereInput | null;
  sbRiskRetentionAlertDateTime?: DateWhereInput | null;
  sbRiskRetentionAlertMessage?: StringWhereInput | null;
  sbSegmentCode?: StringWhereInput | null;
  sbSentToDate?: DateWhereInput | null;
  scoreBucket?: StringWhereInput | null;
  secondARMRateResetTermInMonths?: NumberWhereInput | null;
  secondAmortizationTermMonths?: NumberWhereInput | null;
  secondApr?: NumberWhereInput | null;
  secondDiscountPoints?: NumberWhereInput | null;
  secondDocumentType?: StringWhereInput | null;
  secondDownPayment?: NumberWhereInput | null;
  secondDownPaymentUnit?: StringWhereInput | null;
  secondExpirationDate?: DateWhereInput | null;
  secondInitialARMTermInMonths?: NumberWhereInput | null;
  secondInterestOnly?: StringWhereInput | null;
  secondInterestOnlyPeriodInMonths?: NumberWhereInput | null;
  secondInterestRate?: NumberWhereInput | null;
  secondLoanAmount?: NumberWhereInput | null;
  secondLoanProgramCategory?: StringWhereInput | null;
  secondLoanProgramName?: StringWhereInput | null;
  secondLoanProgramProductType?: StringWhereInput | null;
  secondLoanTermMonths?: NumberWhereInput | null;
  secondLockTerm?: StringWhereInput | null;
  secondMonthlyInsurancePayment?: NumberWhereInput | null;
  secondMonthlyPaymentUnit?: StringWhereInput | null;
  secondMonthlyPayments?: NumberWhereInput | null;
  secondMonthlyPrincipalAndInterestPayment?: NumberWhereInput | null;
  secondMortgageInsurancePayment?: NumberWhereInput | null;
  secondOfferId?: StringWhereInput | null;
  secondOfferType?: StringWhereInput | null;
  secondOfferVendorId?: StringWhereInput | null;
  secondOriginationFee?: NumberWhereInput | null;
  secondOriginationFeeOption?: StringWhereInput | null;
  secondOriginationFeeUnit?: StringWhereInput | null;
  secondOtherLenderFees?: NumberWhereInput | null;
  secondProductName?: StringWhereInput | null;
  secondTerm?: NumberWhereInput | null;
  secondTransactionType?: StringWhereInput | null;
  secondUrl?: StringWhereInput | null;
  secondaryFinancing?: StringWhereInput | null;
  sellerCreditDesired?: StringWhereInput | null;
  sellerRealtorAddressCity?: StringWhereInput | null;
  sellerRealtorAddressState?: StringWhereInput | null;
  sellerRealtorAddressStreet?: StringWhereInput | null;
  sellerRealtorAddressZip?: StringWhereInput | null;
  sellerRealtorFirstName?: StringWhereInput | null;
  sellerRealtorLastName?: StringWhereInput | null;
  sellerRealtorMobilePhone?: PhoneWhereInput | null;
  sellerRealtorName?: StringWhereInput | null;
  sellerRealtorPersonalEmail?: EmailWhereInput | null;
  sentToNetwork?: BooleanWhereInput | null;
  sentToNetworkDateTime?: DateWhereInput | null;
  sequence?: NumberWhereInput | null;
  serviceTicketType?: StringWhereInput | null;
  servicing?: StringWhereInput | null;
  servicingRetainedOrReleased?: StringWhereInput | null;
  sessionId?: StringWhereInput | null;
  showProof?: StringWhereInput | null;
  sourceKeyId?: NumberWhereInput | null;
  stat?: LeadStatWhereInput | null;
  stpDate?: DateWhereInput | null;
  studentLoanBal?: NumberWhereInput | null;
  studentLoanDel?: NumberWhereInput | null;
  studentLoanQty?: NumberWhereInput | null;
  subId?: StringWhereInput | null;
  subjectAddress?: StringWhereInput | null;
  subjectCity?: StringWhereInput | null;
  subjectCounty?: StringWhereInput | null;
  subjectPropertyType?: StringWhereInput | null;
  subjectState?: StringWhereInput | null;
  subjectZip?: StringWhereInput | null;
  tags?: ArrayWhereInput | null;
  targetedRate?: NumberWhereInput | null;
  tdEncompassDate?: DateWhereInput | null;
  teId?: StringWhereInput | null;
  teLoanStatus?: StringWhereInput | null;
  teToken?: StringWhereInput | null;
  team?: StringWhereInput | null;
  telesignPhoneLocationZip?: StringWhereInput | null;
  telesignPhoneTypeCode?: StringWhereInput | null;
  telesignPhoneTypeDescription?: StringWhereInput | null;
  termDisplayed?: NumberWhereInput | null;
  thirdARMRateResetTermInMonths?: NumberWhereInput | null;
  thirdAmortizationTermMonths?: NumberWhereInput | null;
  thirdApr?: NumberWhereInput | null;
  thirdDiscountPoints?: NumberWhereInput | null;
  thirdDocumentType?: StringWhereInput | null;
  thirdDownPayment?: NumberWhereInput | null;
  thirdDownPaymentUnit?: StringWhereInput | null;
  thirdExpirationDate?: DateWhereInput | null;
  thirdInitialARMTermInMonths?: NumberWhereInput | null;
  thirdInterestOnly?: StringWhereInput | null;
  thirdInterestOnlyPeriodInMonths?: NumberWhereInput | null;
  thirdInterestRate?: NumberWhereInput | null;
  thirdLoanAmount?: NumberWhereInput | null;
  thirdLoanProgramCategory?: StringWhereInput | null;
  thirdLoanProgramName?: StringWhereInput | null;
  thirdLoanProgramProductType?: StringWhereInput | null;
  thirdLoanTermMonths?: NumberWhereInput | null;
  thirdLockTerm?: StringWhereInput | null;
  thirdMonthlyInsurancePayment?: NumberWhereInput | null;
  thirdMonthlyPaymentUnit?: StringWhereInput | null;
  thirdMonthlyPayments?: NumberWhereInput | null;
  thirdMonthlyPrincipalAndInterestPayment?: NumberWhereInput | null;
  thirdMortgageInsurancePayment?: NumberWhereInput | null;
  thirdOfferId?: StringWhereInput | null;
  thirdOfferType?: StringWhereInput | null;
  thirdOfferVendorId?: StringWhereInput | null;
  thirdOriginationFee?: NumberWhereInput | null;
  thirdOriginationFeeOption?: StringWhereInput | null;
  thirdOriginationFeeUnit?: StringWhereInput | null;
  thirdOtherLenderFees?: NumberWhereInput | null;
  thirdPartyTransfer?: StringWhereInput | null;
  thirdPartyTransferDate?: DateWhereInput | null;
  thirdProductName?: StringWhereInput | null;
  thirdTerm?: NumberWhereInput | null;
  thirdTransactionType?: StringWhereInput | null;
  thirdUrl?: StringWhereInput | null;
  timeAtPreviousResidence?: NumberWhereInput | null;
  timeAtResidence?: NumberWhereInput | null;
  timeSubmitted?: StringWhereInput | null;
  timeframeForMove?: StringWhereInput | null;
  timezone?: StringWhereInput | null;
  totalDel?: NumberWhereInput | null;
  totalMonthlyPayment?: NumberWhereInput | null;
  transactionType?: StringWhereInput | null;
  transferAgentName?: StringWhereInput | null;
  transferDateTime?: DateWhereInput | null;
  transferUser?: StringWhereInput | null;
  triggerBsPostCheckbox?: BooleanWhereInput | null;
  typeOfVeteran?: StringWhereInput | null;
  underwritingFee?: NumberWhereInput | null;
  universalLeadiD?: StringWhereInput | null;
  unixTimestamp?: DateWhereInput | null;
  unsecuredDebtAmount?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
  updatedUniversalLeadId?: StringWhereInput | null;
  userName?: StringWhereInput | null;
  usoDonationAmount?: NumberWhereInput | null;
  usoDonationSent?: StringWhereInput | null;
  utmCampaign?: StringWhereInput | null;
  utmContent?: StringWhereInput | null;
  utmMedium?: StringWhereInput | null;
  utmSource?: StringWhereInput | null;
  utmTerm?: StringWhereInput | null;
  uuid?: StringWhereInput | null;
  vaDischarge?: StringWhereInput | null;
  vaMtgBalance?: NumberWhereInput | null;
  variant?: StringWhereInput | null;
  vendorId?: StringWhereInput | null;
  view?: StringWhereInput | null;
  waiveEscrow?: BooleanWhereInput | null;
  webinarAttended?: StringWhereInput | null;
  webinarDate?: DateWhereInput | null;
  webinarTime?: StringWhereInput | null;
  wfaJobs?: ArrayWhereInput | null;
  workedWithBBMC?: StringWhereInput | null;
  workflows?: WorkflowStatusRelationWhereInput | null;
  workingWith?: StringWhereInput | null;
  workingWithBanker?: BooleanWhereInput | null;
  workingWithBankerName?: StringWhereInput | null;
  wpm?: StringWhereInput | null;
  yearBuilt?: StringWhereInput | null;
  yearsInProperty?: NumberWhereInput | null;
  zillowCompanyRelationShip?: StringWhereInput | null;
  zillowQuoteId?: StringWhereInput | null;
  zillowRequestId?: StringWhereInput | null;
}

/**
 * The LoanCriteriaWhereInput input type is used in operations that involve filtering objects of LoanCriteria class.
 */
export interface LoanCriteriaWhereInput {
  AND?: LoanCriteriaWhereInput[] | null;
  NOR?: LoanCriteriaWhereInput[] | null;
  OR?: LoanCriteriaWhereInput[] | null;
  cashOut?: NumberWhereInput | null;
  cltv?: NumberWhereInput | null;
  downPayment?: NumberWhereInput | null;
  dti?: NumberWhereInput | null;
  fico?: ArrayWhereInput | null;
  financeFf?: BooleanWhereInput | null;
  financeMip?: BooleanWhereInput | null;
  firstTimeHomeBuyer?: BooleanWhereInput | null;
  firstUseOfVaProgram?: BooleanWhereInput | null;
  isPmiEligible?: BooleanWhereInput | null;
  loanAmount?: NumberWhereInput | null;
  loanPurpose?: StringWhereInput | null;
  ltv?: NumberWhereInput | null;
  mortech?: OfferMortechWhereInput | null;
  property?: OfferPropertyWhereInput | null;
  requiresPestInspection?: BooleanWhereInput | null;
  secondaryFinancing?: StringWhereInput | null;
  totalLoanAmount?: NumberWhereInput | null;
  veteranStatus?: StringWhereInput | null;
  waiveEscrow?: BooleanWhereInput | null;
}

/**
 * The LoanEstimateOfferMortechWhereInput input type is used in operations that involve filtering objects of LoanEstimateOfferMortech class.
 */
export interface LoanEstimateOfferMortechWhereInput {
  AND?: LoanEstimateOfferMortechWhereInput[] | null;
  NOR?: LoanEstimateOfferMortechWhereInput[] | null;
  OR?: LoanEstimateOfferMortechWhereInput[] | null;
  deliveryType?: StringWhereInput | null;
  investor?: ArrayWhereInput | null;
  loanProductId?: StringWhereInput | null;
  loanProductName?: StringWhereInput | null;
  source?: StringWhereInput | null;
  view?: StringWhereInput | null;
}

/**
 * The LoanEstimateOfferPollyWhereInput input type is used in operations that involve filtering objects of LoanEstimateOfferPolly class.
 */
export interface LoanEstimateOfferPollyWhereInput {
  AND?: LoanEstimateOfferPollyWhereInput[] | null;
  NOR?: LoanEstimateOfferPollyWhereInput[] | null;
  OR?: LoanEstimateOfferPollyWhereInput[] | null;
  automatedUnderwritingSystem?: StringWhereInput | null;
  debtServiceCoverageRatio?: NumberWhereInput | null;
  monthsReserves?: NumberWhereInput | null;
  prepaymentPenalty?: StringWhereInput | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the LoanEstimateOffer class into a relation field.
 */
export interface LoanEstimateOfferRelationInput {
  add?: string[] | null;
  createAndAdd?: CreateLoanEstimateOfferFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The LoanEstimateOfferRelationWhereInput input type is used in operations that involve filtering objects of LoanEstimateOffer class.
 */
export interface LoanEstimateOfferRelationWhereInput {
  exists?: boolean | null;
  have?: LoanEstimateOfferWhereInput | null;
  haveNot?: LoanEstimateOfferWhereInput | null;
}

/**
 * The LoanEstimateOfferWhereInput input type is used in operations that involve filtering objects of LoanEstimateOffer class.
 */
export interface LoanEstimateOfferWhereInput {
  AND?: LoanEstimateOfferWhereInput[] | null;
  NOR?: LoanEstimateOfferWhereInput[] | null;
  OR?: LoanEstimateOfferWhereInput[] | null;
  adminFee?: NumberWhereInput | null;
  amiWaiverEligibility?: BooleanWhereInput | null;
  amortizationType?: StringWhereInput | null;
  appraisalFee?: NumberWhereInput | null;
  apr?: NumberWhereInput | null;
  attorneyFee?: NumberWhereInput | null;
  cashAmount?: NumberWhereInput | null;
  cashToClose?: NumberWhereInput | null;
  closingCostTotal?: NumberWhereInput | null;
  cltv?: NumberWhereInput | null;
  condoCertificateFee?: NumberWhereInput | null;
  conforming?: BooleanWhereInput | null;
  createdAt?: DateWhereInput | null;
  creditReportFee?: NumberWhereInput | null;
  creditReportTotal?: NumberWhereInput | null;
  currentMortgageBalance?: NumberWhereInput | null;
  deleted?: BooleanWhereInput | null;
  discountPoints?: NumberWhereInput | null;
  docReviewFee?: NumberWhereInput | null;
  documentType?: StringWhereInput | null;
  downPayment?: NumberWhereInput | null;
  dti?: NumberWhereInput | null;
  earnestMoneyPaid?: NumberWhereInput | null;
  electronicDocumentDelivery?: NumberWhereInput | null;
  exempt?: BooleanWhereInput | null;
  ff?: NumberWhereInput | null;
  fico?: NumberWhereInput | null;
  fico1?: NumberWhereInput | null;
  financeFf?: BooleanWhereInput | null;
  financeMip?: BooleanWhereInput | null;
  firstTimeHomeBuyer?: BooleanWhereInput | null;
  firstTimeUse?: BooleanWhereInput | null;
  firstUseOfVaProgram?: BooleanWhereInput | null;
  floodCertificationFee?: NumberWhereInput | null;
  id?: IdWhereInput | null;
  initialArmTerm?: StringWhereInput | null;
  insurance?: NumberWhereInput | null;
  isHUDReo?: BooleanWhereInput | null;
  isStreamLine?: BooleanWhereInput | null;
  lead?: LeadRelationWhereInput | null;
  lenderCredits?: NumberWhereInput | null;
  lendersTitle?: NumberWhereInput | null;
  lienAmount?: NumberWhereInput | null;
  loanAmount?: NumberWhereInput | null;
  loanEstimate?: LoanEstimateRelationWhereInput | null;
  loanProduct?: StringWhereInput | null;
  loanProgram?: StringWhereInput | null;
  loanSafeFee?: NumberWhereInput | null;
  loanTerm?: StringWhereInput | null;
  lockInDays?: StringWhereInput | null;
  lockInFee?: NumberWhereInput | null;
  ltv?: NumberWhereInput | null;
  mersFee?: NumberWhereInput | null;
  mip?: NumberWhereInput | null;
  monthlyIncome?: NumberWhereInput | null;
  monthlyPaymentTotal?: NumberWhereInput | null;
  monthlyPremium?: NumberWhereInput | null;
  mortech?: LoanEstimateOfferMortechWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  optimalBlue?: LoanEstimateOptimalBlueWhereInput | null;
  originationFee?: NumberWhereInput | null;
  otherCostTotal?: NumberWhereInput | null;
  ownersTitle?: NumberWhereInput | null;
  pestInspectionFee?: NumberWhereInput | null;
  pi?: NumberWhereInput | null;
  pmi?: NumberWhereInput | null;
  pmiCompany?: StringWhereInput | null;
  pmiEligible?: BooleanWhereInput | null;
  pmiType?: StringWhereInput | null;
  points?: NumberWhereInput | null;
  polly?: LoanEstimateOfferPollyWhereInput | null;
  prepaidCostTotal?: NumberWhereInput | null;
  prepaidInterestAmount?: NumberWhereInput | null;
  prepaidInterestDays?: NumberWhereInput | null;
  prepaidInterestTotal?: NumberWhereInput | null;
  price?: NumberWhereInput | null;
  pricingEngine?: StringWhereInput | null;
  productType?: StringWhereInput | null;
  propertyAnnualInsurance?: NumberWhereInput | null;
  propertyAnnualTax?: NumberWhereInput | null;
  propertyInsurancePrepaidAmount?: NumberWhereInput | null;
  propertyInsurancePrepaidMonth?: NumberWhereInput | null;
  propertyInsurancePrepaidTotal?: NumberWhereInput | null;
  propertyInsuranceYearly?: NumberWhereInput | null;
  propertyTaxAmount?: NumberWhereInput | null;
  propertyTaxMonth?: NumberWhereInput | null;
  propertyTaxTotal?: NumberWhereInput | null;
  propertyType?: StringWhereInput | null;
  propertyUse?: StringWhereInput | null;
  propertyValue?: NumberWhereInput | null;
  purchasePrice?: NumberWhereInput | null;
  rate?: NumberWhereInput | null;
  rateId?: StringWhereInput | null;
  rateInvestor?: StringWhereInput | null;
  rateUpdated?: StringWhereInput | null;
  recordingCharges?: NumberWhereInput | null;
  secondaryFinancing?: StringWhereInput | null;
  selfEmployed?: BooleanWhereInput | null;
  sellerConcession?: NumberWhereInput | null;
  settlementFee?: NumberWhereInput | null;
  subordinationFee?: NumberWhereInput | null;
  surveyFee?: NumberWhereInput | null;
  taxes?: NumberWhereInput | null;
  titleCompany?: StringWhereInput | null;
  titleCompanyName?: StringWhereInput | null;
  titleFeeTotal?: NumberWhereInput | null;
  titleInsurance?: NumberWhereInput | null;
  totalLoanAmount?: NumberWhereInput | null;
  transcriptFee?: NumberWhereInput | null;
  transferTax?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
  verificationFee?: NumberWhereInput | null;
  veteranStatus?: StringWhereInput | null;
  waive?: BooleanWhereInput | null;
  waiveEscrow?: BooleanWhereInput | null;
  withAppraisal?: BooleanWhereInput | null;
  withCredit?: BooleanWhereInput | null;
}

/**
 * The LoanEstimateOptimalBlueWhereInput input type is used in operations that involve filtering objects of LoanEstimateOptimalBlue class.
 */
export interface LoanEstimateOptimalBlueWhereInput {
  AND?: LoanEstimateOptimalBlueWhereInput[] | null;
  NOR?: LoanEstimateOptimalBlueWhereInput[] | null;
  OR?: LoanEstimateOptimalBlueWhereInput[] | null;
  automatedUnderwritingSystem?: StringWhereInput | null;
  debtServiceCoverageRatio?: NumberWhereInput | null;
  leadSourceMoom?: StringWhereInput | null;
  monthsReserves?: NumberWhereInput | null;
  prepaymentPenalty?: StringWhereInput | null;
  productTypes?: ArrayWhereInput | null;
  searchID?: StringWhereInput | null;
  source?: StringWhereInput | null;
  view?: StringWhereInput | null;
  zeroPlus?: StringWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the LoanEstimate class.
 */
export interface LoanEstimatePointerInput {
  createAndLink?: CreateLoanEstimateFieldsInput | null;
  link?: string | null;
}

/**
 * The LoanEstimateRelationWhereInput input type is used in operations that involve filtering objects of LoanEstimate class.
 */
export interface LoanEstimateRelationWhereInput {
  exists?: boolean | null;
  have?: LoanEstimateWhereInput | null;
  haveNot?: LoanEstimateWhereInput | null;
}

/**
 * The LoanEstimateWhereInput input type is used in operations that involve filtering objects of LoanEstimate class.
 */
export interface LoanEstimateWhereInput {
  AND?: LoanEstimateWhereInput[] | null;
  NOR?: LoanEstimateWhereInput[] | null;
  OR?: LoanEstimateWhereInput[] | null;
  applyUrl?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  draft?: BooleanWhereInput | null;
  file?: FileWhereInput | null;
  id?: IdWhereInput | null;
  image?: FileWhereInput | null;
  lead?: LeadRelationWhereInput | null;
  loanPurpose?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  offers?: LoanEstimateOfferRelationWhereInput | null;
  owner?: UserRelationWhereInput | null;
  propertyAddress?: StringWhereInput | null;
  propertyAnnualInsurance?: NumberWhereInput | null;
  propertyAnnualTax?: NumberWhereInput | null;
  propertyCity?: StringWhereInput | null;
  propertyCountry?: StringWhereInput | null;
  propertyCounty?: StringWhereInput | null;
  propertyFipsCode?: StringWhereInput | null;
  propertyOccupancy?: StringWhereInput | null;
  propertyState?: StringWhereInput | null;
  propertyType?: StringWhereInput | null;
  propertyZipCode?: StringWhereInput | null;
  sent?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the LoanProposal class.
 */
export interface LoanProposalPointerInput {
  createAndLink?: CreateLoanProposalFieldsInput | null;
  link?: string | null;
}

/**
 * The LoanProposalRelationWhereInput input type is used in operations that involve filtering objects of LoanProposal class.
 */
export interface LoanProposalRelationWhereInput {
  exists?: boolean | null;
  have?: LoanProposalWhereInput | null;
  haveNot?: LoanProposalWhereInput | null;
}

/**
 * The LoanProposalWhereInput input type is used in operations that involve filtering objects of LoanProposal class.
 */
export interface LoanProposalWhereInput {
  AND?: LoanProposalWhereInput[] | null;
  NOR?: LoanProposalWhereInput[] | null;
  OR?: LoanProposalWhereInput[] | null;
  applyUrl?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  file?: FileWhereInput | null;
  id?: IdWhereInput | null;
  image?: FileWhereInput | null;
  lead?: LeadRelationWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  offers?: OfferRelationWhereInput | null;
  owner?: UserRelationWhereInput | null;
  sent?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The MailAttachmentInput input type is used in operations that involve MailAttachmentInput.
 */
export interface MailAttachmentInput {
  mimeType?: string | null;
  name?: string | null;
  objectId?: string | null;
  url?: string | null;
}

/**
 * The MailAttachmentRelationWhereInput input type is used in operations that involve filtering objects of MailAttachment class.
 */
export interface MailAttachmentRelationWhereInput {
  exists?: boolean | null;
  have?: MailAttachmentWhereInput | null;
  haveNot?: MailAttachmentWhereInput | null;
}

/**
 * The MailAttachmentWhereInput input type is used in operations that involve filtering objects of MailAttachment class.
 */
export interface MailAttachmentWhereInput {
  AND?: MailAttachmentWhereInput[] | null;
  NOR?: MailAttachmentWhereInput[] | null;
  OR?: MailAttachmentWhereInput[] | null;
  contentId?: StringWhereInput | null;
  contentType?: StringWhereInput | null;
  file?: FileRelationWhereInput | null;
  filename?: StringWhereInput | null;
  isInline?: BooleanWhereInput | null;
  size?: NumberWhereInput | null;
  url?: StringWhereInput | null;
}

/**
 * The MailAuthSubscriptionWhereInput input type is used in operations that involve filtering objects of MailAuth class.
 */
export interface MailAuthSubscriptionWhereInput {
  AND?: MailAuthSubscriptionWhereInput[] | null;
  NOR?: MailAuthSubscriptionWhereInput[] | null;
  OR?: MailAuthSubscriptionWhereInput[] | null;
  accessToken?: StringWhereInput | null;
  expiresAt?: NumberWhereInput | null;
  expiresIn?: NumberWhereInput | null;
  pass?: StringWhereInput | null;
  refreshToken?: StringWhereInput | null;
  scope?: StringWhereInput | null;
  type?: StringWhereInput | null;
  user?: StringWhereInput | null;
}

/**
 * The MailAuthWhereInput input type is used in operations that involve filtering objects of MailAuth class.
 */
export interface MailAuthWhereInput {
  AND?: MailAuthWhereInput[] | null;
  NOR?: MailAuthWhereInput[] | null;
  OR?: MailAuthWhereInput[] | null;
  accessToken?: StringWhereInput | null;
  expiresAt?: NumberWhereInput | null;
  expiresIn?: NumberWhereInput | null;
  pass?: StringWhereInput | null;
  refreshToken?: StringWhereInput | null;
  scope?: StringWhereInput | null;
  type?: StringWhereInput | null;
  user?: StringWhereInput | null;
}

/**
 * The MailConversationFlyerUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailConversationFlyerUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailConversationParentUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailConversationParentUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailConversationRelationWhereInput input type is used in operations that involve filtering objects of MailConversation class.
 */
export interface MailConversationRelationWhereInput {
  exists?: boolean | null;
  have?: MailConversationWhereInput | null;
  haveNot?: MailConversationWhereInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface MailConversationScopePointerInput {
  createAndLinkLead?: CreateLeadFieldsInput | null;
  createAndLinkReferralPartner?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The MailConversationScopeUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailConversationScopeUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailConversationSubscriptionWhereInput input type is used in operations that involve filtering objects of MailConversation class.
 */
export interface MailConversationSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: MailConversationSubscriptionWhereInput[] | null;
  NOR?: MailConversationSubscriptionWhereInput[] | null;
  OR?: MailConversationSubscriptionWhereInput[] | null;
  attachmentsCount?: NumberWhereInput | null;
  bulkStats?: BulkMailStatsSubscriptionWhereInput | null;
  bulkStatsPerUser?: BulkMailStatsPerUserSubscriptionRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  emailId?: StringWhereInput | null;
  error?: StringWhereInput | null;
  flyer?: MailConversationFlyerUnionWhereInput | null;
  id?: IdWhereInput | null;
  includeUnsubscribeLink?: BooleanWhereInput | null;
  lastContent?: StringWhereInput | null;
  lastSender?: MailParticipantSubscriptionWhereInput | null;
  lastUpdatedAt?: DateWhereInput | null;
  objectId?: IdWhereInput | null;
  parent?: MailConversationParentUnionWhereInput | null;
  parties?: MailParticipantSubscriptionRelationWhereInput | null;
  replyCount?: NumberWhereInput | null;
  scope?: MailConversationScopeUnionWhereInput | null;
  scopeType?: StringWhereInput | null;
  status?: StringWhereInput | null;
  subject?: StringWhereInput | null;
  template?: MailConversationTemplateUnionWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The MailConversationTemplateUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailConversationTemplateUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailConversationWhereInput input type is used in operations that involve filtering objects of MailConversation class.
 */
export interface MailConversationWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: MailConversationWhereInput[] | null;
  NOR?: MailConversationWhereInput[] | null;
  OR?: MailConversationWhereInput[] | null;
  attachmentsCount?: NumberWhereInput | null;
  bulkStats?: BulkMailStatsWhereInput | null;
  bulkStatsPerUser?: BulkMailStatsPerUserRelationWhereInput | null;
  conversations?: MailConversationRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  emailId?: StringWhereInput | null;
  error?: StringWhereInput | null;
  flyer?: FlyerRelationWhereInput | null;
  id?: IdWhereInput | null;
  includeUnsubscribeLink?: BooleanWhereInput | null;
  lastContent?: StringWhereInput | null;
  lastSender?: MailParticipantWhereInput | null;
  lastUpdatedAt?: DateWhereInput | null;
  messages?: MailMessageRelationWhereInput | null;
  objectId?: IdWhereInput | null;
  parent?: MailConversationRelationWhereInput | null;
  parties?: MailParticipantRelationWhereInput | null;
  replyCount?: NumberWhereInput | null;
  scope?: MailConversationScopeUnionWhereInput | null;
  scopeType?: StringWhereInput | null;
  status?: StringWhereInput | null;
  subject?: StringWhereInput | null;
  template?: EmailTemplateRelationWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The MailDnsRelationWhereInput input type is used in operations that involve filtering objects of MailDns class.
 */
export interface MailDnsRelationWhereInput {
  exists?: boolean | null;
  have?: MailDnsWhereInput | null;
  haveNot?: MailDnsWhereInput | null;
}

/**
 * The MailDnsWhereInput input type is used in operations that involve filtering objects of MailDns class.
 */
export interface MailDnsWhereInput {
  AND?: MailDnsWhereInput[] | null;
  NOR?: MailDnsWhereInput[] | null;
  OR?: MailDnsWhereInput[] | null;
  data?: StringWhereInput | null;
  host?: StringWhereInput | null;
  name?: StringWhereInput | null;
  type?: StringWhereInput | null;
  valid?: BooleanWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the MailDomain class.
 */
export interface MailDomainPointerInput {
  createAndLink?: CreateMailDomainFieldsInput | null;
  link?: string | null;
}

/**
 * The MailDomainRelationWhereInput input type is used in operations that involve filtering objects of MailDomain class.
 */
export interface MailDomainRelationWhereInput {
  exists?: boolean | null;
  have?: MailDomainWhereInput | null;
  haveNot?: MailDomainWhereInput | null;
}

/**
 * The MailDomainWhereInput input type is used in operations that involve filtering objects of MailDomain class.
 */
export interface MailDomainWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: MailDomainWhereInput[] | null;
  NOR?: MailDomainWhereInput[] | null;
  OR?: MailDomainWhereInput[] | null;
  aliases?: ArrayWhereInput | null;
  apiKey?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  disabled?: BooleanWhereInput | null;
  dns?: MailDnsRelationWhereInput | null;
  domain?: StringWhereInput | null;
  hookCreated?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  provider?: MailProviderWhereInput | null;
  sendGridId?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  useForOut?: BooleanWhereInput | null;
  valid?: BooleanWhereInput | null;
}

/**
 * The MailImapWhereInput input type is used in operations that involve filtering objects of MailImap class.
 */
export interface MailImapWhereInput {
  AND?: MailImapWhereInput[] | null;
  NOR?: MailImapWhereInput[] | null;
  OR?: MailImapWhereInput[] | null;
  host?: StringWhereInput | null;
  port?: NumberWhereInput | null;
  service?: StringWhereInput | null;
  tls?: ObjectWhereInput | null;
}

/**
 * The MailMessageRelationWhereInput input type is used in operations that involve filtering objects of MailMessage class.
 */
export interface MailMessageRelationWhereInput {
  exists?: boolean | null;
  have?: MailMessageWhereInput | null;
  haveNot?: MailMessageWhereInput | null;
}

/**
 * The MailMessageScopeUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailMessageScopeUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailMessageWhereInput input type is used in operations that involve filtering objects of MailMessage class.
 */
export interface MailMessageWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: MailMessageWhereInput[] | null;
  NOR?: MailMessageWhereInput[] | null;
  OR?: MailMessageWhereInput[] | null;
  attachments?: MailAttachmentRelationWhereInput | null;
  body?: FileRelationWhereInput | null;
  bulkConversation?: MailConversationRelationWhereInput | null;
  click?: BooleanWhereInput | null;
  conversation?: MailConversationRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  date?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  emailId?: StringWhereInput | null;
  error?: StringWhereInput | null;
  html?: StringWhereInput | null;
  id?: IdWhereInput | null;
  inReplyTo?: MailMessageRelationWhereInput | null;
  initiatedBy?: StringWhereInput | null;
  loanEstimate?: LoanEstimateRelationWhereInput | null;
  loanProposal?: LoanProposalRelationWhereInput | null;
  mailbox?: MailboxRelationWhereInput | null;
  objectId?: IdWhereInput | null;
  open?: BooleanWhereInput | null;
  parties?: MailParticipantRelationWhereInput | null;
  provider?: StringWhereInput | null;
  providerMessageId?: StringWhereInput | null;
  references?: ArrayWhereInput | null;
  scope?: MailMessageScopeUnionWhereInput | null;
  snippet?: StringWhereInput | null;
  spam?: BooleanWhereInput | null;
  status?: StringWhereInput | null;
  statusUpdatedAt?: DateWhereInput | null;
  subject?: StringWhereInput | null;
  template?: EmailTemplateRelationWhereInput | null;
  text?: StringWhereInput | null;
  unsubscribe?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The MailMetadataSubscriptionWhereInput input type is used in operations that involve filtering objects of MailMetadata class.
 */
export interface MailMetadataSubscriptionWhereInput {
  AND?: MailMetadataSubscriptionWhereInput[] | null;
  NOR?: MailMetadataSubscriptionWhereInput[] | null;
  OR?: MailMetadataSubscriptionWhereInput[] | null;
  draftIds?: ArrayWhereInput | null;
  flags?: ArrayWhereInput | null;
  indexes?: ObjectWhereInput | null;
  isDraft?: BooleanWhereInput | null;
  label?: StringWhereInput | null;
  labels?: ArrayWhereInput | null;
  msgId?: StringWhereInput | null;
  provider?: StringWhereInput | null;
  thrId?: StringWhereInput | null;
  uid?: NumberWhereInput | null;
}

/**
 * The MailMetadataWhereInput input type is used in operations that involve filtering objects of MailMetadata class.
 */
export interface MailMetadataWhereInput {
  AND?: MailMetadataWhereInput[] | null;
  NOR?: MailMetadataWhereInput[] | null;
  OR?: MailMetadataWhereInput[] | null;
  draftIds?: ArrayWhereInput | null;
  flags?: ArrayWhereInput | null;
  indexes?: ObjectWhereInput | null;
  isDraft?: BooleanWhereInput | null;
  label?: StringWhereInput | null;
  labels?: ArrayWhereInput | null;
  msgId?: StringWhereInput | null;
  provider?: StringWhereInput | null;
  thrId?: StringWhereInput | null;
  uid?: NumberWhereInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface MailParticipantPartyPointerInput {
  createAndLinkContact?: CreateContactFieldsInput | null;
  createAndLinkUser?: CreateUserFieldsInput | null;
  link?: string | null;
}

/**
 * The MailParticipantPartyUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailParticipantPartyUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailParticipantRelationWhereInput input type is used in operations that involve filtering objects of MailParticipant class.
 */
export interface MailParticipantRelationWhereInput {
  exists?: boolean | null;
  have?: MailParticipantWhereInput | null;
  haveNot?: MailParticipantWhereInput | null;
}

/**
 * The MailParticipantSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of MailParticipant class.
 */
export interface MailParticipantSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: MailParticipantSubscriptionWhereInput | null;
  haveNot?: MailParticipantSubscriptionWhereInput | null;
}

/**
 * The MailParticipantSubscriptionWhereInput input type is used in operations that involve filtering objects of MailParticipant class.
 */
export interface MailParticipantSubscriptionWhereInput {
  AND?: MailParticipantSubscriptionWhereInput[] | null;
  NOR?: MailParticipantSubscriptionWhereInput[] | null;
  OR?: MailParticipantSubscriptionWhereInput[] | null;
  address?: StringWhereInput | null;
  deleted?: BooleanWhereInput | null;
  metadata?: MailMetadataSubscriptionWhereInput | null;
  notified?: BooleanWhereInput | null;
  party?: MailParticipantPartyUnionWhereInput | null;
  type?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
}

/**
 * The MailParticipantWhereInput input type is used in operations that involve filtering objects of MailParticipant class.
 */
export interface MailParticipantWhereInput {
  AND?: MailParticipantWhereInput[] | null;
  NOR?: MailParticipantWhereInput[] | null;
  OR?: MailParticipantWhereInput[] | null;
  address?: StringWhereInput | null;
  deleted?: BooleanWhereInput | null;
  metadata?: MailMetadataWhereInput | null;
  notified?: BooleanWhereInput | null;
  party?: MailParticipantPartyUnionWhereInput | null;
  type?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
}

/**
 * The MailProviderWhereInput input type is used in operations that involve filtering objects of MailProvider class.
 */
export interface MailProviderWhereInput {
  AND?: MailProviderWhereInput[] | null;
  NOR?: MailProviderWhereInput[] | null;
  OR?: MailProviderWhereInput[] | null;
  clientId?: StringWhereInput | null;
  clientSecret?: StringWhereInput | null;
  imap?: MailImapWhereInput | null;
  returnUrl?: StringWhereInput | null;
  smtp?: MailSmtpWhereInput | null;
  sync?: StringWhereInput | null;
  tenantId?: StringWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The MailRenderInput input type is used in operations that involve MailRenderInput.
 */
export interface MailRenderInput {
  contact?: string | null;
  proposal?: string | null;
  scope?: string | null;
  signature?: string | null;
  strict?: boolean | null;
  subject?: string | null;
  template?: string | null;
  templateId?: string | null;
  to?: (any | null)[] | null;
  user?: string | null;
}

/**
 * The MailSendInput input type is used in operations that involve MailSendInput.
 */
export interface MailSendInput {
  attachments?: (MailAttachmentInput | null)[] | null;
  bcc?: (any | null)[] | null;
  cc?: (any | null)[] | null;
  from?: string | null;
  html?: string | null;
  inReplyTo?: string | null;
  includeSignature?: boolean | null;
  loanEstimateId?: string | null;
  loanProposalId?: string | null;
  receiver?: string | null;
  scope?: string | null;
  subject?: string | null;
  template?: string | null;
  text?: string | null;
  to?: (any | null)[] | null;
  transport?: string | null;
}

/**
 * The MailSmtpWhereInput input type is used in operations that involve filtering objects of MailSmtp class.
 */
export interface MailSmtpWhereInput {
  AND?: MailSmtpWhereInput[] | null;
  NOR?: MailSmtpWhereInput[] | null;
  OR?: MailSmtpWhereInput[] | null;
  host?: StringWhereInput | null;
  port?: NumberWhereInput | null;
  secure?: BooleanWhereInput | null;
  service?: StringWhereInput | null;
  tls?: ObjectWhereInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface MailboxAssignedToPointerInput {
  createAndLinkLeadSource?: CreateLeadSourceFieldsInput | null;
  createAndLinkUser?: CreateUserFieldsInput | null;
  link?: string | null;
}

/**
 * The MailboxAssignedToUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailboxAssignedToUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailboxDefaultRecipientUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailboxDefaultRecipientUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The MailboxDomainUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface MailboxDomainUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * Allow to link OR add and link an object of the Mailbox class.
 */
export interface MailboxPointerInput {
  createAndLink?: CreateMailboxFieldsInput | null;
  link?: string | null;
}

/**
 * The MailboxRelationWhereInput input type is used in operations that involve filtering objects of Mailbox class.
 */
export interface MailboxRelationWhereInput {
  exists?: boolean | null;
  have?: MailboxWhereInput | null;
  haveNot?: MailboxWhereInput | null;
}

/**
 * The MailboxSubscriptionWhereInput input type is used in operations that involve filtering objects of Mailbox class.
 */
export interface MailboxSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: MailboxSubscriptionWhereInput[] | null;
  NOR?: MailboxSubscriptionWhereInput[] | null;
  OR?: MailboxSubscriptionWhereInput[] | null;
  account?: StringWhereInput | null;
  address?: StringWhereInput | null;
  assignedTo?: MailboxAssignedToUnionWhereInput | null;
  auth?: MailAuthSubscriptionWhereInput | null;
  common?: BooleanWhereInput | null;
  createdAt?: DateWhereInput | null;
  defaultRecipient?: MailboxDefaultRecipientUnionWhereInput | null;
  delta?: ObjectWhereInput | null;
  disabled?: BooleanWhereInput | null;
  domain?: MailboxDomainUnionWhereInput | null;
  historyId?: StringWhereInput | null;
  id?: IdWhereInput | null;
  lastSyncedAt?: DateWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The MailboxWhereInput input type is used in operations that involve filtering objects of Mailbox class.
 */
export interface MailboxWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: MailboxWhereInput[] | null;
  NOR?: MailboxWhereInput[] | null;
  OR?: MailboxWhereInput[] | null;
  account?: StringWhereInput | null;
  address?: StringWhereInput | null;
  assignedTo?: MailboxAssignedToUnionWhereInput | null;
  auth?: MailAuthWhereInput | null;
  common?: BooleanWhereInput | null;
  createdAt?: DateWhereInput | null;
  defaultRecipient?: UserRelationWhereInput | null;
  delta?: ObjectWhereInput | null;
  disabled?: BooleanWhereInput | null;
  domain?: MailDomainRelationWhereInput | null;
  historyId?: StringWhereInput | null;
  id?: IdWhereInput | null;
  lastSyncedAt?: DateWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The MonthlyPaymentWhereInput input type is used in operations that involve filtering objects of MonthlyPayment class.
 */
export interface MonthlyPaymentWhereInput {
  AND?: MonthlyPaymentWhereInput[] | null;
  NOR?: MonthlyPaymentWhereInput[] | null;
  OR?: MonthlyPaymentWhereInput[] | null;
  insurance?: NumberWhereInput | null;
  mi?: NumberWhereInput | null;
  pi?: NumberWhereInput | null;
  pmi?: NumberWhereInput | null;
  tax?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
}

/**
 * The MortechAddressInput input type is used in operations that involve MortechAddressInput.
 */
export interface MortechAddressInput {
  propertyCounty?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

/**
 * The MortechInput input type is used in operations that involve MortechInput.
 */
export interface MortechInput {
  amiLlpaWaiver?: boolean | null;
  hudReo?: boolean | null;
  investorIds?: (any | null)[] | null;
  lockInDays?: string | null;
  product?: string | null;
  source?: string | null;
  streamLine?: boolean | null;
  streamLineWithAppraisal?: boolean | null;
  streamLineWithCredit?: boolean | null;
  view?: string | null;
}

/**
 * The MortechPricingInput input type is used in operations that involve MortechPricingInput.
 */
export interface MortechPricingInput {
  cashOut?: number | null;
  cltv?: number | null;
  downPayment?: number | null;
  dti?: number | null;
  fico?: (any | null)[] | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstTimeHomeBuyer?: boolean | null;
  firstUseOfVaProgram?: boolean | null;
  hudReo?: boolean | null;
  loanAmount?: number | null;
  loanPurpose?: string | null;
  ltv?: number | null;
  mortech?: MortechInput | null;
  property?: MortechPropertyInput | null;
  secondaryFinancing?: string | null;
  streamLine?: boolean | null;
  streamLineWithAppraisal?: boolean | null;
  streamLineWithCredit?: boolean | null;
  veteranStatus?: string | null;
  waiveEscrow?: boolean | null;
}

/**
 * The MortechPropertyInput input type is used in operations that involve MortechPropertyInput.
 */
export interface MortechPropertyInput {
  occupancy?: string | null;
  propertyAddress?: MortechAddressInput | null;
  type?: string | null;
  value?: number | null;
}

/**
 * The MortechWhereInput input type is used in operations that involve filtering objects of Mortech class.
 */
export interface MortechWhereInput {
  AND?: MortechWhereInput[] | null;
  NOR?: MortechWhereInput[] | null;
  OR?: MortechWhereInput[] | null;
  amiLlpaWaiver?: BooleanWhereInput | null;
  deliveryType?: StringWhereInput | null;
  hudReo?: BooleanWhereInput | null;
  investorIds?: ArrayWhereInput | null;
  lockInDays?: StringWhereInput | null;
  product?: StringWhereInput | null;
  productName?: StringWhereInput | null;
  source?: StringWhereInput | null;
  streamLine?: BooleanWhereInput | null;
  streamLineWithAppraisal?: BooleanWhereInput | null;
  streamLineWithCredit?: BooleanWhereInput | null;
  view?: StringWhereInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface NoteSubjectPointerInput {
  createAndLinkContact?: CreateContactFieldsInput | null;
  createAndLinkLead?: CreateLeadFieldsInput | null;
  createAndLinkReferralPartner?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The NoteSubjectUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface NoteSubjectUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The NoteWhereInput input type is used in operations that involve filtering objects of Note class.
 */
export interface NoteWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: NoteWhereInput[] | null;
  NOR?: NoteWhereInput[] | null;
  OR?: NoteWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  id?: IdWhereInput | null;
  objectId?: IdWhereInput | null;
  owner?: UserRelationWhereInput | null;
  subject?: NoteSubjectUnionWhereInput | null;
  text?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The NotificationControlsWhereInput input type is used in operations that involve filtering objects of NotificationControls class.
 */
export interface NotificationControlsWhereInput {
  AND?: NotificationControlsWhereInput[] | null;
  NOR?: NotificationControlsWhereInput[] | null;
  OR?: NotificationControlsWhereInput[] | null;
  bulkEmail?: BooleanWhereInput | null;
  bulkSMS?: BooleanWhereInput | null;
  distributedLead?: BooleanWhereInput | null;
  emails?: BooleanWhereInput | null;
  leadAssignments?: BooleanWhereInput | null;
  loanComments?: BooleanWhereInput | null;
  missedCalls?: BooleanWhereInput | null;
  sms?: BooleanWhereInput | null;
  tasksReminders?: BooleanWhereInput | null;
}

/**
 * The NumberWhereInput input type is used in operations that involve filtering objects by a field of type Number.
 */
export interface NumberWhereInput {
  equalTo?: number | null;
  exists?: boolean | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: (number | null)[] | null;
  inQueryKey?: SelectInput | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: (number | null)[] | null;
  notInQueryKey?: SelectInput | null;
}

/**
 * The ObjectWhereInput input type is used in operations that involve filtering result by a field of type Object.
 */
export interface ObjectWhereInput {
  equalTo?: KeyValueInput | null;
  exists?: boolean | null;
  greaterThan?: KeyValueInput | null;
  greaterThanOrEqualTo?: KeyValueInput | null;
  in?: (KeyValueInput | null)[] | null;
  inQueryKey?: SelectInput | null;
  lessThan?: KeyValueInput | null;
  lessThanOrEqualTo?: KeyValueInput | null;
  notEqualTo?: KeyValueInput | null;
  notIn?: (KeyValueInput | null)[] | null;
  notInQueryKey?: SelectInput | null;
}

/**
 * The OfferMortechWhereInput input type is used in operations that involve filtering objects of OfferMortech class.
 */
export interface OfferMortechWhereInput {
  AND?: OfferMortechWhereInput[] | null;
  NOR?: OfferMortechWhereInput[] | null;
  OR?: OfferMortechWhereInput[] | null;
  amiLlpaWaiver?: BooleanWhereInput | null;
  hudReo?: BooleanWhereInput | null;
  investorIds?: ArrayWhereInput | null;
  lockInDays?: StringWhereInput | null;
  product?: StringWhereInput | null;
  productName?: StringWhereInput | null;
  source?: StringWhereInput | null;
  streamLine?: BooleanWhereInput | null;
  streamLineWithAppraisal?: BooleanWhereInput | null;
  streamLineWithCredit?: BooleanWhereInput | null;
  view?: StringWhereInput | null;
}

/**
 * The OfferPropertyAddressWhereInput input type is used in operations that involve filtering objects of OfferPropertyAddress class.
 */
export interface OfferPropertyAddressWhereInput {
  AND?: OfferPropertyAddressWhereInput[] | null;
  NOR?: OfferPropertyAddressWhereInput[] | null;
  OR?: OfferPropertyAddressWhereInput[] | null;
  propertyCounty?: StringWhereInput | null;
  state?: StringWhereInput | null;
  zipCode?: StringWhereInput | null;
}

/**
 * The OfferPropertyWhereInput input type is used in operations that involve filtering objects of OfferProperty class.
 */
export interface OfferPropertyWhereInput {
  AND?: OfferPropertyWhereInput[] | null;
  NOR?: OfferPropertyWhereInput[] | null;
  OR?: OfferPropertyWhereInput[] | null;
  currentMortgageBalance?: NumberWhereInput | null;
  occupancy?: StringWhereInput | null;
  propertyAddress?: OfferPropertyAddressWhereInput | null;
  type?: StringWhereInput | null;
  value?: NumberWhereInput | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the Offer class into a relation field.
 */
export interface OfferRelationInput {
  add?: string[] | null;
  createAndAdd?: CreateOfferFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The OfferRelationWhereInput input type is used in operations that involve filtering objects of Offer class.
 */
export interface OfferRelationWhereInput {
  exists?: boolean | null;
  have?: OfferWhereInput | null;
  haveNot?: OfferWhereInput | null;
}

/**
 * The OfferWhereInput input type is used in operations that involve filtering objects of Offer class.
 */
export interface OfferWhereInput {
  AND?: OfferWhereInput[] | null;
  NOR?: OfferWhereInput[] | null;
  OR?: OfferWhereInput[] | null;
  closingCost?: ClosingCostWhereInput | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  lead?: LeadRelationWhereInput | null;
  lenderCredits?: NumberWhereInput | null;
  loanCriteria?: LoanCriteriaWhereInput | null;
  loanProposal?: LoanProposalRelationWhereInput | null;
  monthlyPayment?: MonthlyPaymentWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  prepaid?: PrepaidWhereInput | null;
  selectedRate?: SelectedRateWhereInput | null;
  totalClosingCost?: NumberWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The OptimalBlueUserConfigWhereInput input type is used in operations that involve filtering objects of OptimalBlueUserConfig class.
 */
export interface OptimalBlueUserConfigWhereInput {
  AND?: OptimalBlueUserConfigWhereInput[] | null;
  NOR?: OptimalBlueUserConfigWhereInput[] | null;
  OR?: OptimalBlueUserConfigWhereInput[] | null;
  businessChannelId?: NumberWhereInput | null;
  originatorId?: NumberWhereInput | null;
}

/**
 * The ParticipantPartyUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface ParticipantPartyUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The ParticipantRelationWhereInput input type is used in operations that involve filtering objects of Participant class.
 */
export interface ParticipantRelationWhereInput {
  exists?: boolean | null;
  have?: ParticipantWhereInput | null;
  haveNot?: ParticipantWhereInput | null;
}

/**
 * The ParticipantSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of Participant class.
 */
export interface ParticipantSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: ParticipantSubscriptionWhereInput | null;
  haveNot?: ParticipantSubscriptionWhereInput | null;
}

/**
 * The ParticipantSubscriptionWhereInput input type is used in operations that involve filtering objects of Participant class.
 */
export interface ParticipantSubscriptionWhereInput {
  AND?: ParticipantSubscriptionWhereInput[] | null;
  NOR?: ParticipantSubscriptionWhereInput[] | null;
  OR?: ParticipantSubscriptionWhereInput[] | null;
  call?: StringWhereInput | null;
  conferenceStatus?: StringWhereInput | null;
  endDate?: DateWhereInput | null;
  event?: NumberWhereInput | null;
  hold?: BooleanWhereInput | null;
  moderator?: BooleanWhereInput | null;
  mute?: BooleanWhereInput | null;
  number?: StringWhereInput | null;
  party?: ParticipantPartyUnionWhereInput | null;
  startDate?: DateWhereInput | null;
  status?: StringWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The ParticipantWhereInput input type is used in operations that involve filtering objects of Participant class.
 */
export interface ParticipantWhereInput {
  AND?: ParticipantWhereInput[] | null;
  NOR?: ParticipantWhereInput[] | null;
  OR?: ParticipantWhereInput[] | null;
  call?: StringWhereInput | null;
  conferenceStatus?: StringWhereInput | null;
  endDate?: DateWhereInput | null;
  event?: NumberWhereInput | null;
  hold?: BooleanWhereInput | null;
  moderator?: BooleanWhereInput | null;
  mute?: BooleanWhereInput | null;
  number?: StringWhereInput | null;
  party?: ParticipantPartyUnionWhereInput | null;
  startDate?: DateWhereInput | null;
  status?: StringWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The PhoneLineAssignedToUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneLineAssignedToUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * Allow to link OR add and link an object of the PhoneLine class.
 */
export interface PhoneLinePointerInput {
  link?: string | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the PhoneLine class into a relation field.
 */
export interface PhoneLineRelationInput {
  add?: string[] | null;
  remove?: string[] | null;
}

/**
 * The PhoneLineRelationWhereInput input type is used in operations that involve filtering objects of PhoneLine class.
 */
export interface PhoneLineRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneLineWhereInput | null;
  haveNot?: PhoneLineWhereInput | null;
}

/**
 * The PhoneLineWhereInput input type is used in operations that involve filtering objects of PhoneLine class.
 */
export interface PhoneLineWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneLineWhereInput[] | null;
  NOR?: PhoneLineWhereInput[] | null;
  OR?: PhoneLineWhereInput[] | null;
  assignedTo?: PhoneLineAssignedToUnionWhereInput | null;
  callRouteToUser?: BooleanWhereInput | null;
  callRouteToUserRules?: ObjectWhereInput | null;
  common?: BooleanWhereInput | null;
  createdAt?: DateWhereInput | null;
  fax?: BooleanWhereInput | null;
  formattedNumber?: StringWhereInput | null;
  forwardToNumber?: PhoneWhereInput | null;
  holdMediaRepeat?: NumberWhereInput | null;
  holdMediaUrl?: StringWhereInput | null;
  id?: IdWhereInput | null;
  localPresence?: BooleanWhereInput | null;
  matchWithinLeadSource?: BooleanWhereInput | null;
  mms?: BooleanWhereInput | null;
  name?: StringWhereInput | null;
  number?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  resolution?: StringWhereInput | null;
  sms?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
  voice?: BooleanWhereInput | null;
  voicemailMediaUrl?: StringWhereInput | null;
}

/**
 * The PhoneMessageBulkConversationUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageBulkConversationUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageConversationFlyerUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageConversationFlyerUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageConversationRelationWhereInput input type is used in operations that involve filtering objects of PhoneMessageConversation class.
 */
export interface PhoneMessageConversationRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneMessageConversationWhereInput | null;
  haveNot?: PhoneMessageConversationWhereInput | null;
}

/**
 * The PhoneMessageConversationScopeUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageConversationScopeUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageConversationSubscriptionWhereInput input type is used in operations that involve filtering objects of PhoneMessageConversation class.
 */
export interface PhoneMessageConversationSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneMessageConversationSubscriptionWhereInput[] | null;
  NOR?: PhoneMessageConversationSubscriptionWhereInput[] | null;
  OR?: PhoneMessageConversationSubscriptionWhereInput[] | null;
  bulkOperation?: BooleanWhereInput | null;
  bulkStats?: PhoneMessageStatsSubscriptionWhereInput | null;
  bulkStatsPerUser?: PhoneMessageStatsPerUserSubscriptionRelationWhereInput | null;
  content?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  direction?: StringWhereInput | null;
  error?: StringWhereInput | null;
  flyer?: PhoneMessageConversationFlyerUnionWhereInput | null;
  id?: IdWhereInput | null;
  lastUpdatedAt?: DateWhereInput | null;
  objectId?: IdWhereInput | null;
  participants?: PhoneMessageParticipantSubscriptionRelationWhereInput | null;
  scope?: PhoneMessageConversationScopeUnionWhereInput | null;
  scopeType?: StringWhereInput | null;
  sid?: StringWhereInput | null;
  templateId?: StringWhereInput | null;
  type?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The PhoneMessageConversationUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageConversationUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageConversationWhereInput input type is used in operations that involve filtering objects of PhoneMessageConversation class.
 */
export interface PhoneMessageConversationWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneMessageConversationWhereInput[] | null;
  NOR?: PhoneMessageConversationWhereInput[] | null;
  OR?: PhoneMessageConversationWhereInput[] | null;
  bulkMessages?: PhoneMessageRelationWhereInput | null;
  bulkOperation?: BooleanWhereInput | null;
  bulkStats?: PhoneMessageStatsWhereInput | null;
  bulkStatsPerUser?: PhoneMessageStatsPerUserRelationWhereInput | null;
  content?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  direction?: StringWhereInput | null;
  error?: StringWhereInput | null;
  flyer?: FlyerRelationWhereInput | null;
  id?: IdWhereInput | null;
  lastUpdatedAt?: DateWhereInput | null;
  objectId?: IdWhereInput | null;
  participants?: PhoneMessageParticipantRelationWhereInput | null;
  scope?: PhoneMessageConversationScopeUnionWhereInput | null;
  scopeType?: StringWhereInput | null;
  sid?: StringWhereInput | null;
  templateId?: StringWhereInput | null;
  type?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The PhoneMessageLoanEstimateUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageLoanEstimateUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageParticipantPartyUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageParticipantPartyUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageParticipantRelationWhereInput input type is used in operations that involve filtering objects of PhoneMessageParticipant class.
 */
export interface PhoneMessageParticipantRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneMessageParticipantWhereInput | null;
  haveNot?: PhoneMessageParticipantWhereInput | null;
}

/**
 * The PhoneMessageParticipantSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of PhoneMessageParticipant class.
 */
export interface PhoneMessageParticipantSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneMessageParticipantSubscriptionWhereInput | null;
  haveNot?: PhoneMessageParticipantSubscriptionWhereInput | null;
}

/**
 * The PhoneMessageParticipantSubscriptionWhereInput input type is used in operations that involve filtering objects of PhoneMessageParticipant class.
 */
export interface PhoneMessageParticipantSubscriptionWhereInput {
  AND?: PhoneMessageParticipantSubscriptionWhereInput[] | null;
  NOR?: PhoneMessageParticipantSubscriptionWhereInput[] | null;
  OR?: PhoneMessageParticipantSubscriptionWhereInput[] | null;
  party?: PhoneMessageParticipantPartyUnionWhereInput | null;
  read?: BooleanWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The PhoneMessageParticipantWhereInput input type is used in operations that involve filtering objects of PhoneMessageParticipant class.
 */
export interface PhoneMessageParticipantWhereInput {
  AND?: PhoneMessageParticipantWhereInput[] | null;
  NOR?: PhoneMessageParticipantWhereInput[] | null;
  OR?: PhoneMessageParticipantWhereInput[] | null;
  party?: PhoneMessageParticipantPartyUnionWhereInput | null;
  read?: BooleanWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The PhoneMessageReceiverUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageReceiverUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageRelationWhereInput input type is used in operations that involve filtering objects of PhoneMessage class.
 */
export interface PhoneMessageRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneMessageWhereInput | null;
  haveNot?: PhoneMessageWhereInput | null;
}

/**
 * The PhoneMessageRenderInput input type is used in operations that involve PhoneMessageRenderInput.
 */
export interface PhoneMessageRenderInput {
  contact?: string | null;
  scope?: string | null;
  strict?: boolean | null;
  template?: string | null;
  templateId?: string | null;
  to?: string | null;
  user?: string | null;
}

/**
 * Allow to link OR add and link
 */
export interface PhoneMessageScopePointerInput {
  createAndLinkLead?: CreateLeadFieldsInput | null;
  createAndLinkReferralPartner?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The PhoneMessageScopeUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageScopeUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageStatsPerUserRelationWhereInput input type is used in operations that involve filtering objects of PhoneMessageStatsPerUser class.
 */
export interface PhoneMessageStatsPerUserRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneMessageStatsPerUserWhereInput | null;
  haveNot?: PhoneMessageStatsPerUserWhereInput | null;
}

/**
 * The PhoneMessageStatsPerUserSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of PhoneMessageStatsPerUser class.
 */
export interface PhoneMessageStatsPerUserSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneMessageStatsPerUserSubscriptionWhereInput | null;
  haveNot?: PhoneMessageStatsPerUserSubscriptionWhereInput | null;
}

/**
 * The PhoneMessageStatsPerUserSubscriptionWhereInput input type is used in operations that involve filtering objects of PhoneMessageStatsPerUser class.
 */
export interface PhoneMessageStatsPerUserSubscriptionWhereInput {
  AND?: PhoneMessageStatsPerUserSubscriptionWhereInput[] | null;
  NOR?: PhoneMessageStatsPerUserSubscriptionWhereInput[] | null;
  OR?: PhoneMessageStatsPerUserSubscriptionWhereInput[] | null;
  stats?: PhoneMessageStatsSubscriptionWhereInput | null;
  user?: PhoneMessageStatsPerUserUserUnionWhereInput | null;
}

/**
 * The PhoneMessageStatsPerUserUserUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageStatsPerUserUserUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageStatsPerUserWhereInput input type is used in operations that involve filtering objects of PhoneMessageStatsPerUser class.
 */
export interface PhoneMessageStatsPerUserWhereInput {
  AND?: PhoneMessageStatsPerUserWhereInput[] | null;
  NOR?: PhoneMessageStatsPerUserWhereInput[] | null;
  OR?: PhoneMessageStatsPerUserWhereInput[] | null;
  stats?: PhoneMessageStatsWhereInput | null;
  user?: UserRelationWhereInput | null;
}

/**
 * The PhoneMessageStatsSubscriptionWhereInput input type is used in operations that involve filtering objects of PhoneMessageStats class.
 */
export interface PhoneMessageStatsSubscriptionWhereInput {
  AND?: PhoneMessageStatsSubscriptionWhereInput[] | null;
  NOR?: PhoneMessageStatsSubscriptionWhereInput[] | null;
  OR?: PhoneMessageStatsSubscriptionWhereInput[] | null;
  canceled?: NumberWhereInput | null;
  delivered?: NumberWhereInput | null;
  failed?: NumberWhereInput | null;
  queued?: NumberWhereInput | null;
  sending?: NumberWhereInput | null;
  sent?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  undelivered?: NumberWhereInput | null;
}

/**
 * The PhoneMessageStatsWhereInput input type is used in operations that involve filtering objects of PhoneMessageStats class.
 */
export interface PhoneMessageStatsWhereInput {
  AND?: PhoneMessageStatsWhereInput[] | null;
  NOR?: PhoneMessageStatsWhereInput[] | null;
  OR?: PhoneMessageStatsWhereInput[] | null;
  canceled?: NumberWhereInput | null;
  delivered?: NumberWhereInput | null;
  failed?: NumberWhereInput | null;
  queued?: NumberWhereInput | null;
  sending?: NumberWhereInput | null;
  sent?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  undelivered?: NumberWhereInput | null;
}

/**
 * The PhoneMessageSubscriptionWhereInput input type is used in operations that involve filtering objects of PhoneMessage class.
 */
export interface PhoneMessageSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneMessageSubscriptionWhereInput[] | null;
  NOR?: PhoneMessageSubscriptionWhereInput[] | null;
  OR?: PhoneMessageSubscriptionWhereInput[] | null;
  attachments?: FileSubscriptionRelationWhereInput | null;
  bulkConversation?: PhoneMessageBulkConversationUnionWhereInput | null;
  content?: StringWhereInput | null;
  conversation?: PhoneMessageConversationUnionWhereInput | null;
  createdAt?: DateWhereInput | null;
  direction?: StringWhereInput | null;
  errorCode?: StringWhereInput | null;
  from?: StringWhereInput | null;
  id?: IdWhereInput | null;
  loanEstimate?: PhoneMessageLoanEstimateUnionWhereInput | null;
  objectId?: IdWhereInput | null;
  participants?: PhoneMessageParticipantSubscriptionRelationWhereInput | null;
  price?: NumberWhereInput | null;
  receiver?: PhoneMessageReceiverUnionWhereInput | null;
  scope?: PhoneMessageScopeUnionWhereInput | null;
  sid?: StringWhereInput | null;
  status?: StringWhereInput | null;
  statusUpdatedAt?: DateWhereInput | null;
  templateId?: StringWhereInput | null;
  to?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The PhoneMessageTemplateOwnerUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PhoneMessageTemplateOwnerUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PhoneMessageTemplateSubscriptionWhereInput input type is used in operations that involve filtering objects of PhoneMessageTemplate class.
 */
export interface PhoneMessageTemplateSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneMessageTemplateSubscriptionWhereInput[] | null;
  NOR?: PhoneMessageTemplateSubscriptionWhereInput[] | null;
  OR?: PhoneMessageTemplateSubscriptionWhereInput[] | null;
  applyTo?: ArrayWhereInput | null;
  availableFor?: ArrayWhereInput | null;
  createdAt?: DateWhereInput | null;
  disabled?: BooleanWhereInput | null;
  enabled?: BooleanWhereInput | null;
  forAutomation?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  loanProposal?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  owner?: PhoneMessageTemplateOwnerUnionWhereInput | null;
  text?: StringWhereInput | null;
  title?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The PhoneMessageTemplateWhereInput input type is used in operations that involve filtering objects of PhoneMessageTemplate class.
 */
export interface PhoneMessageTemplateWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneMessageTemplateWhereInput[] | null;
  NOR?: PhoneMessageTemplateWhereInput[] | null;
  OR?: PhoneMessageTemplateWhereInput[] | null;
  applyTo?: ArrayWhereInput | null;
  availableFor?: ArrayWhereInput | null;
  createdAt?: DateWhereInput | null;
  disabled?: BooleanWhereInput | null;
  enabled?: BooleanWhereInput | null;
  forAutomation?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  loanProposal?: BooleanWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  owner?: UserRelationWhereInput | null;
  text?: StringWhereInput | null;
  title?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The PhoneMessageWhereInput input type is used in operations that involve filtering objects of PhoneMessage class.
 */
export interface PhoneMessageWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: PhoneMessageWhereInput[] | null;
  NOR?: PhoneMessageWhereInput[] | null;
  OR?: PhoneMessageWhereInput[] | null;
  attachments?: FileRelationWhereInput | null;
  bulkConversation?: PhoneMessageConversationRelationWhereInput | null;
  content?: StringWhereInput | null;
  conversation?: PhoneMessageConversationRelationWhereInput | null;
  createdAt?: DateWhereInput | null;
  direction?: StringWhereInput | null;
  errorCode?: StringWhereInput | null;
  from?: StringWhereInput | null;
  id?: IdWhereInput | null;
  loanEstimate?: LoanEstimateRelationWhereInput | null;
  objectId?: IdWhereInput | null;
  participants?: PhoneMessageParticipantRelationWhereInput | null;
  price?: NumberWhereInput | null;
  receiver?: PhoneMessageReceiverUnionWhereInput | null;
  scope?: PhoneMessageScopeUnionWhereInput | null;
  sid?: StringWhereInput | null;
  status?: StringWhereInput | null;
  statusUpdatedAt?: DateWhereInput | null;
  templateId?: StringWhereInput | null;
  to?: StringWhereInput | null;
  unread?: BooleanWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The PhoneNumberRelationWhereInput input type is used in operations that involve filtering objects of PhoneNumber class.
 */
export interface PhoneNumberRelationWhereInput {
  exists?: boolean | null;
  have?: PhoneNumberWhereInput | null;
  haveNot?: PhoneNumberWhereInput | null;
}

/**
 * The PhoneNumberWhereInput input type is used in operations that involve filtering objects of PhoneNumber class.
 */
export interface PhoneNumberWhereInput {
  AND?: PhoneNumberWhereInput[] | null;
  NOR?: PhoneNumberWhereInput[] | null;
  OR?: PhoneNumberWhereInput[] | null;
  callOptOut?: BooleanWhereInput | null;
  carrier?: ObjectWhereInput | null;
  default?: BooleanWhereInput | null;
  isPrimary?: BooleanWhereInput | null;
  nationalFormat?: StringWhereInput | null;
  number?: PhoneWhereInput | null;
  objectName?: StringWhereInput | null;
  optOut?: BooleanWhereInput | null;
  sms?: BooleanWhereInput | null;
  smsCountryCode?: StringWhereInput | null;
  smsOptOut?: BooleanWhereInput | null;
  type?: StringWhereInput | null;
}

/**
 * The PhoneWhereInput input type is used in operations that involve filtering objects by a field of type Phone.
 */
export interface PhoneWhereInput {
  equalTo?: any | null;
  exists?: boolean | null;
  in?: (any | null)[] | null;
  inQueryKey?: SelectInput | null;
  includes?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: any | null;
  notIn?: (any | null)[] | null;
  notInQueryKey?: SelectInput | null;
  notIncludes?: string | null;
  options?: string | null;
  regex?: string | null;
}

/**
 * The PmiAddress input type is used in operations that involve PmiAddress.
 */
export interface PmiAddress {
  city?: string | null;
  county?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

/**
 * The PmiProperty input type is used in operations that involve PmiProperty.
 */
export interface PmiProperty {
  occupancy?: string | null;
  propertyAddress?: PmiAddress | null;
  type?: string | null;
  value?: number | null;
}

/**
 * The PmiRatesArgs input type is used in operations that involve PmiRatesArgs.
 */
export interface PmiRatesArgs {
  amortizationType?: string | null;
  borrower?: any | null;
  coBorrower?: any | null;
  dti?: number | null;
  firstTimeHomeBuyer?: boolean | null;
  initialArmTerm?: number | null;
  interestRate?: number | null;
  leadId?: string | null;
  loanAmount?: number | null;
  loanProgram?: string | null;
  loanPurpose?: string | null;
  loanTerm?: number | null;
  pmiPartners?: (any | null)[] | null;
  pmiProducts?: (any | null)[] | null;
  property?: PmiProperty | null;
}

/**
 * The PrepaidWhereInput input type is used in operations that involve filtering objects of Prepaid class.
 */
export interface PrepaidWhereInput {
  AND?: PrepaidWhereInput[] | null;
  NOR?: PrepaidWhereInput[] | null;
  OR?: PrepaidWhereInput[] | null;
  dailyInterest?: NumberWhereInput | null;
  daysToPayInterest?: NumberWhereInput | null;
  insurance?: NumberWhereInput | null;
  interest?: NumberWhereInput | null;
  lockInFee?: NumberWhereInput | null;
  monthlyInsurance?: NumberWhereInput | null;
  monthlyTax?: NumberWhereInput | null;
  monthsToPayInsurance?: NumberWhereInput | null;
  monthsToPayTax?: NumberWhereInput | null;
  tax?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  yearlyInsurance?: NumberWhereInput | null;
}

/**
 * The PreviewSettingsMailboxUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PreviewSettingsMailboxUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface PreviewSettingsScopePointerInput {
  createAndLinkLead?: CreateLeadFieldsInput | null;
  createAndLinkReferralPartner?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The PreviewSettingsScopeUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PreviewSettingsScopeUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PreviewSettingsSubscriptionWhereInput input type is used in operations that involve filtering objects of PreviewSettings class.
 */
export interface PreviewSettingsSubscriptionWhereInput {
  AND?: PreviewSettingsSubscriptionWhereInput[] | null;
  NOR?: PreviewSettingsSubscriptionWhereInput[] | null;
  OR?: PreviewSettingsSubscriptionWhereInput[] | null;
  mailbox?: PreviewSettingsMailboxUnionWhereInput | null;
  scope?: PreviewSettingsScopeUnionWhereInput | null;
  user?: PreviewSettingsUserUnionWhereInput | null;
}

/**
 * The PreviewSettingsUserUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface PreviewSettingsUserUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The PreviewSettingsWhereInput input type is used in operations that involve filtering objects of PreviewSettings class.
 */
export interface PreviewSettingsWhereInput {
  AND?: PreviewSettingsWhereInput[] | null;
  NOR?: PreviewSettingsWhereInput[] | null;
  OR?: PreviewSettingsWhereInput[] | null;
  mailbox?: MailboxRelationWhereInput | null;
  scope?: PreviewSettingsScopeUnionWhereInput | null;
  user?: UserRelationWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the PriorityQueue class.
 */
export interface PriorityQueuePointerInput {
  createAndLink?: CreatePriorityQueueFieldsInput | null;
  link?: string | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the PriorityQueue class into a relation field.
 */
export interface PriorityQueueRelationInput {
  add?: string[] | null;
  createAndAdd?: CreatePriorityQueueFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The PriorityQueueRelationWhereInput input type is used in operations that involve filtering objects of PriorityQueue class.
 */
export interface PriorityQueueRelationWhereInput {
  exists?: boolean | null;
  have?: PriorityQueueWhereInput | null;
  haveNot?: PriorityQueueWhereInput | null;
}

/**
 * The PriorityQueueWhereInput input type is used in operations that involve filtering objects of PriorityQueue class.
 */
export interface PriorityQueueWhereInput {
  AND?: PriorityQueueWhereInput[] | null;
  NOR?: PriorityQueueWhereInput[] | null;
  OR?: PriorityQueueWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  enabled?: BooleanWhereInput | null;
  expandable?: BooleanWhereInput | null;
  fields?: TableViewFieldRelationWhereInput | null;
  id?: IdWhereInput | null;
  leadCount?: NumberWhereInput | null;
  leadFilter?: ObjectWhereInput | null;
  leadSort?: ArrayWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  priority?: NumberWhereInput | null;
  priorityView?: PriorityViewRelationWhereInput | null;
  title?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the PriorityView class.
 */
export interface PriorityViewPointerInput {
  createAndLink?: CreatePriorityViewFieldsInput | null;
  link?: string | null;
}

/**
 * The PriorityViewRelationWhereInput input type is used in operations that involve filtering objects of PriorityView class.
 */
export interface PriorityViewRelationWhereInput {
  exists?: boolean | null;
  have?: PriorityViewWhereInput | null;
  haveNot?: PriorityViewWhereInput | null;
}

/**
 * The PriorityViewWhereInput input type is used in operations that involve filtering objects of PriorityView class.
 */
export interface PriorityViewWhereInput {
  AND?: PriorityViewWhereInput[] | null;
  NOR?: PriorityViewWhereInput[] | null;
  OR?: PriorityViewWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  createdBy?: UserRelationWhereInput | null;
  id?: IdWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  priority?: NumberWhereInput | null;
  priorityQueues?: PriorityQueueRelationWhereInput | null;
  rPerm?: ArrayWhereInput | null;
  title?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The ProPairWhereInput input type is used in operations that involve filtering objects of ProPair class.
 */
export interface ProPairWhereInput {
  AND?: ProPairWhereInput[] | null;
  NOR?: ProPairWhereInput[] | null;
  OR?: ProPairWhereInput[] | null;
  excludeIds?: StringWhereInput | null;
  rank?: NumberWhereInput | null;
}

/**
 * The PropertyWhereInput input type is used in operations that involve filtering objects of Property class.
 */
export interface PropertyWhereInput {
  AND?: PropertyWhereInput[] | null;
  NOR?: PropertyWhereInput[] | null;
  OR?: PropertyWhereInput[] | null;
  annualInsurance?: NumberWhereInput | null;
  annualTaxes?: NumberWhereInput | null;
  appraisedValue?: NumberWhereInput | null;
  approvedVACondo?: BooleanWhereInput | null;
  currentMortgageBalance?: NumberWhereInput | null;
  currentMortgageMonthlyPayment?: NumberWhereInput | null;
  currentMortgageRate?: NumberWhereInput | null;
  datePurchased?: DateWhereInput | null;
  dues?: NumberWhereInput | null;
  estimatedValue?: NumberWhereInput | null;
  firstLienBalance?: NumberWhereInput | null;
  heloc?: BooleanWhereInput | null;
  monthlyHoa?: BooleanWhereInput | null;
  numberOfUnits?: StringWhereInput | null;
  occupancy?: StringWhereInput | null;
  pricePaidForHome?: NumberWhereInput | null;
  propertyAddress?: AddressWhereInput | null;
  secondLienBalance?: NumberWhereInput | null;
  solarPanels?: BooleanWhereInput | null;
  type?: StringWhereInput | null;
  use?: StringWhereInput | null;
  value?: NumberWhereInput | null;
  yearBuilt?: NumberWhereInput | null;
}

/**
 * Allow to manage public rights.
 */
export interface PublicACLInput {
  read: boolean;
  write: boolean;
}

/**
 * The QuotesWhereInput input type is used in operations that involve filtering objects of Quotes class.
 */
export interface QuotesWhereInput {
  AND?: QuotesWhereInput[] | null;
  NOR?: QuotesWhereInput[] | null;
  OR?: QuotesWhereInput[] | null;
  apr?: NumberWhereInput | null;
  loanAmount?: NumberWhereInput | null;
  monthlyPremium?: NumberWhereInput | null;
  piti?: NumberWhereInput | null;
  price?: NumberWhereInput | null;
  rate?: NumberWhereInput | null;
  srp?: NumberWhereInput | null;
}

/**
 * The RecordingOwnerUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface RecordingOwnerUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * Allow to link OR add and link an object of the Recording class.
 */
export interface RecordingPointerInput {
  createAndLink?: CreateRecordingFieldsInput | null;
  link?: string | null;
}

/**
 * The RecordingRelationWhereInput input type is used in operations that involve filtering objects of Recording class.
 */
export interface RecordingRelationWhereInput {
  exists?: boolean | null;
  have?: RecordingWhereInput | null;
  haveNot?: RecordingWhereInput | null;
}

/**
 * The RecordingSubscriptionWhereInput input type is used in operations that involve filtering objects of Recording class.
 */
export interface RecordingSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: RecordingSubscriptionWhereInput[] | null;
  NOR?: RecordingSubscriptionWhereInput[] | null;
  OR?: RecordingSubscriptionWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  original?: StringWhereInput | null;
  owner?: RecordingOwnerUnionWhereInput | null;
  transcriptionStatus?: StringWhereInput | null;
  transcriptionUrl?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  uploadAttempts?: NumberWhereInput | null;
  uploadStatus?: StringWhereInput | null;
  uploaded?: BooleanWhereInput | null;
  url?: StringWhereInput | null;
}

/**
 * The RecordingWhereInput input type is used in operations that involve filtering objects of Recording class.
 */
export interface RecordingWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: RecordingWhereInput[] | null;
  NOR?: RecordingWhereInput[] | null;
  OR?: RecordingWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  deleted?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  original?: StringWhereInput | null;
  owner?: UserRelationWhereInput | null;
  transcriptionStatus?: StringWhereInput | null;
  transcriptionUrl?: StringWhereInput | null;
  type?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  uploadAttempts?: NumberWhereInput | null;
  uploadStatus?: StringWhereInput | null;
  uploaded?: BooleanWhereInput | null;
  url?: StringWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the ReferralPartner class.
 */
export interface ReferralPartnerPointerInput {
  createAndLink?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * The RelcuLoanDocumentRelationWhereInput input type is used in operations that involve filtering objects of RelcuLoanDocument class.
 */
export interface RelcuLoanDocumentRelationWhereInput {
  exists?: boolean | null;
  have?: RelcuLoanDocumentWhereInput | null;
  haveNot?: RelcuLoanDocumentWhereInput | null;
}

/**
 * The RelcuLoanDocumentWhereInput input type is used in operations that involve filtering objects of RelcuLoanDocument class.
 */
export interface RelcuLoanDocumentWhereInput {
  AND?: RelcuLoanDocumentWhereInput[] | null;
  NOR?: RelcuLoanDocumentWhereInput[] | null;
  OR?: RelcuLoanDocumentWhereInput[] | null;
  createdDate?: DateWhereInput | null;
  losId?: StringWhereInput | null;
  milestoneLosId?: StringWhereInput | null;
  receivedDate?: DateWhereInput | null;
  requestedDate?: DateWhereInput | null;
  slug?: StringWhereInput | null;
  status?: StringWhereInput | null;
  title?: StringWhereInput | null;
}

/**
 * The RelcuLoanMemberRelationWhereInput input type is used in operations that involve filtering objects of RelcuLoanMember class.
 */
export interface RelcuLoanMemberRelationWhereInput {
  exists?: boolean | null;
  have?: RelcuLoanMemberWhereInput | null;
  haveNot?: RelcuLoanMemberWhereInput | null;
}

/**
 * The RelcuLoanMemberWhereInput input type is used in operations that involve filtering objects of RelcuLoanMember class.
 */
export interface RelcuLoanMemberWhereInput {
  AND?: RelcuLoanMemberWhereInput[] | null;
  NOR?: RelcuLoanMemberWhereInput[] | null;
  OR?: RelcuLoanMemberWhereInput[] | null;
  cellPhone?: PhoneWhereInput | null;
  company?: StringWhereInput | null;
  email?: EmailWhereInput | null;
  firstName?: StringWhereInput | null;
  lastName?: StringWhereInput | null;
  losId?: StringWhereInput | null;
  name?: StringWhereInput | null;
  phone?: PhoneWhereInput | null;
  type?: StringWhereInput | null;
  workPhone?: PhoneWhereInput | null;
}

/**
 * The RelcuLoanMilestoneRelationWhereInput input type is used in operations that involve filtering objects of RelcuLoanMilestone class.
 */
export interface RelcuLoanMilestoneRelationWhereInput {
  exists?: boolean | null;
  have?: RelcuLoanMilestoneWhereInput | null;
  haveNot?: RelcuLoanMilestoneWhereInput | null;
}

/**
 * The RelcuLoanMilestoneWhereInput input type is used in operations that involve filtering objects of RelcuLoanMilestone class.
 */
export interface RelcuLoanMilestoneWhereInput {
  AND?: RelcuLoanMilestoneWhereInput[] | null;
  NOR?: RelcuLoanMilestoneWhereInput[] | null;
  OR?: RelcuLoanMilestoneWhereInput[] | null;
  associate?: StringWhereInput | null;
  comment?: StringWhereInput | null;
  done?: BooleanWhereInput | null;
  losId?: StringWhereInput | null;
  name?: StringWhereInput | null;
  plannedDate?: DateWhereInput | null;
  slug?: StringWhereInput | null;
  startDate?: DateWhereInput | null;
}

/**
 * Allow to link OR add and link an object of the RelcuLoan class.
 */
export interface RelcuLoanPointerInput {
  createAndLink?: CreateRelcuLoanFieldsInput | null;
  link?: string | null;
}

/**
 * The RelcuLoanRelationWhereInput input type is used in operations that involve filtering objects of RelcuLoan class.
 */
export interface RelcuLoanRelationWhereInput {
  exists?: boolean | null;
  have?: RelcuLoanWhereInput | null;
  haveNot?: RelcuLoanWhereInput | null;
}

/**
 * The RelcuLoanTaskRelationWhereInput input type is used in operations that involve filtering objects of RelcuLoanTask class.
 */
export interface RelcuLoanTaskRelationWhereInput {
  exists?: boolean | null;
  have?: RelcuLoanTaskWhereInput | null;
  haveNot?: RelcuLoanTaskWhereInput | null;
}

/**
 * The RelcuLoanTaskWhereInput input type is used in operations that involve filtering objects of RelcuLoanTask class.
 */
export interface RelcuLoanTaskWhereInput {
  AND?: RelcuLoanTaskWhereInput[] | null;
  NOR?: RelcuLoanTaskWhereInput[] | null;
  OR?: RelcuLoanTaskWhereInput[] | null;
  completed?: BooleanWhereInput | null;
  losId?: StringWhereInput | null;
  milestoneLosId?: StringWhereInput | null;
  title?: StringWhereInput | null;
}

/**
 * The RelcuLoanWhereInput input type is used in operations that involve filtering objects of RelcuLoan class.
 */
export interface RelcuLoanWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: RelcuLoanWhereInput[] | null;
  NOR?: RelcuLoanWhereInput[] | null;
  OR?: RelcuLoanWhereInput[] | null;
  OfPLTMO?: NumberWhereInput | null;
  a1822Group?: StringWhereInput | null;
  abaProvided?: DateWhereInput | null;
  active?: BooleanWhereInput | null;
  activeNetworkReferral?: BooleanWhereInput | null;
  adjPrincipalLimit?: NumberWhereInput | null;
  affinityLeadType?: StringWhereInput | null;
  agentName?: StringWhereInput | null;
  annualHazardInsurance?: NumberWhereInput | null;
  annualPropertyTax?: NumberWhereInput | null;
  annualReviewDate?: DateWhereInput | null;
  applicationDate?: DateWhereInput | null;
  appraisalContingency?: DateWhereInput | null;
  appraisalDelivered?: DateWhereInput | null;
  appraisalExpectedDelivery?: DateWhereInput | null;
  appraisalInspection?: DateWhereInput | null;
  appraisalReceivedDate?: DateWhereInput | null;
  appraisalRequestedDate?: DateWhereInput | null;
  aprPercent?: NumberWhereInput | null;
  archived?: BooleanWhereInput | null;
  armMarginPercent?: NumberWhereInput | null;
  assignedTo?: UserRelationWhereInput | null;
  assignedToObjectId?: StringWhereInput | null;
  assignedToObjectName?: StringWhereInput | null;
  assignedUserTeam?: StringWhereInput | null;
  avmProvided?: DateWhereInput | null;
  baseLoanAmount?: NumberWhereInput | null;
  borrowerDOB?: DateWhereInput | null;
  borrowerEmail?: EmailWhereInput | null;
  borrowerEmployerCity?: StringWhereInput | null;
  borrowerEmployerName?: StringWhereInput | null;
  borrowerEmployerState?: StringWhereInput | null;
  borrowerEmployerStreetAddress?: StringWhereInput | null;
  borrowerEmployerZipCode?: StringWhereInput | null;
  borrowerEquifaxScore?: NumberWhereInput | null;
  borrowerExperianCreditScore?: NumberWhereInput | null;
  borrowerFaxNumber?: PhoneWhereInput | null;
  borrowerFirstName?: StringWhereInput | null;
  borrowerFullName?: StringWhereInput | null;
  borrowerHomePhone?: PhoneWhereInput | null;
  borrowerInitialCDESignedDate?: DateWhereInput | null;
  borrowerIntendToContinueDate?: DateWhereInput | null;
  borrowerLastName?: StringWhereInput | null;
  borrowerLeESignedDate?: DateWhereInput | null;
  borrowerLosId?: StringWhereInput | null;
  borrowerMailingAddressCity?: StringWhereInput | null;
  borrowerMailingAddressState?: StringWhereInput | null;
  borrowerMailingAddressStreet?: StringWhereInput | null;
  borrowerMailingAddressZipCode?: StringWhereInput | null;
  borrowerMiddleFicoScore?: NumberWhereInput | null;
  borrowerMobilePhone?: PhoneWhereInput | null;
  borrowerSSN?: StringWhereInput | null;
  borrowerSubmission?: DateWhereInput | null;
  borrowerTotalMonthlyIncome?: NumberWhereInput | null;
  borrowerTransUnionScore?: NumberWhereInput | null;
  borrowerWorkEmail?: EmailWhereInput | null;
  borrowerWorkPhone?: PhoneWhereInput | null;
  brokerConditionSubmission?: DateWhereInput | null;
  brokersRequestForAppraisal?: DateWhereInput | null;
  buySideLockDate?: DateWhereInput | null;
  buySideLockExpires?: DateWhereInput | null;
  buyerAgentCompany?: StringWhereInput | null;
  buyerAgentEmail?: EmailWhereInput | null;
  buyerAgentName?: StringWhereInput | null;
  buyerAgentPhone?: PhoneWhereInput | null;
  cashRequest?: NumberWhereInput | null;
  cdMaxMonthlyPayment?: NumberWhereInput | null;
  channelType?: StringWhereInput | null;
  charmBookletSent?: DateWhereInput | null;
  closingDisclosureDelivered?: DateWhereInput | null;
  closingDisclosureReDisclosureIssued?: DateWhereInput | null;
  closingDisclosureReDisclosureReceived?: DateWhereInput | null;
  closingDisclosureReceivedDate?: DateWhereInput | null;
  closingDisclosureSentDate?: DateWhereInput | null;
  closingDocumentPreparationDate?: DateWhereInput | null;
  closingDocumentSigningDate?: DateWhereInput | null;
  closingInterestRate?: NumberWhereInput | null;
  cltv?: NumberWhereInput | null;
  coBorrowerDOB?: DateWhereInput | null;
  coBorrowerEmail?: StringWhereInput | null;
  coBorrowerEmployerCity?: StringWhereInput | null;
  coBorrowerEmployerName?: StringWhereInput | null;
  coBorrowerEmployerState?: StringWhereInput | null;
  coBorrowerEmployerStreetAddress?: StringWhereInput | null;
  coBorrowerEmployerZipCode?: StringWhereInput | null;
  coBorrowerEquifaxScore?: NumberWhereInput | null;
  coBorrowerExperianCreditScore?: NumberWhereInput | null;
  coBorrowerFaxNumber?: StringWhereInput | null;
  coBorrowerFirstName?: StringWhereInput | null;
  coBorrowerFullName?: StringWhereInput | null;
  coBorrowerHomePhone?: StringWhereInput | null;
  coBorrowerInitialCDESignedDate?: DateWhereInput | null;
  coBorrowerLastName?: StringWhereInput | null;
  coBorrowerLeESignedDate?: DateWhereInput | null;
  coBorrowerLosId?: StringWhereInput | null;
  coBorrowerMailingAddressCity?: StringWhereInput | null;
  coBorrowerMailingAddressState?: StringWhereInput | null;
  coBorrowerMailingAddressStreet?: StringWhereInput | null;
  coBorrowerMailingAddressZipCode?: StringWhereInput | null;
  coBorrowerMiddleFicoScore?: NumberWhereInput | null;
  coBorrowerMobilePhone?: StringWhereInput | null;
  coBorrowerResidentialAddress?: AddressWhereInput | null;
  coBorrowerSSN?: StringWhereInput | null;
  coBorrowerTotalMonthlyIncome?: NumberWhereInput | null;
  coBorrowerWorkEmail?: StringWhereInput | null;
  coBorrowerWorkPhone?: StringWhereInput | null;
  conditionReviewed?: DateWhereInput | null;
  conditionSubmission?: DateWhereInput | null;
  costCenterCode?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  creditReportReceivedDate?: DateWhereInput | null;
  creditReportRequestedDate?: DateWhereInput | null;
  creditScoreToUse?: StringWhereInput | null;
  credits?: NumberWhereInput | null;
  currentMilestone?: StringWhereInput | null;
  currentMilestoneStage?: StringWhereInput | null;
  currentRateLockDate?: DateWhereInput | null;
  currentRateLockDateTime?: DateWhereInput | null;
  currentRateLockExpiresDate?: DateWhereInput | null;
  currentRateLockInvestorName?: StringWhereInput | null;
  currentRateLockRequestType?: StringWhereInput | null;
  currentRateLockStatus?: StringWhereInput | null;
  custLoanTypeReverse?: StringWhereInput | null;
  deleted?: BooleanWhereInput | null;
  denialDate?: DateWhereInput | null;
  denialReason1?: StringWhereInput | null;
  denialReason2?: StringWhereInput | null;
  denialReason3?: StringWhereInput | null;
  denialReason4?: StringWhereInput | null;
  differenceOfCostsChargedToPrice?: NumberWhereInput | null;
  disbursementDate?: DateWhereInput | null;
  disclosureViewedDateBorrower?: DateWhereInput | null;
  disclosureViewedDateCoBorrower?: DateWhereInput | null;
  documents?: RelcuLoanDocumentRelationWhereInput | null;
  downPayment?: NumberWhereInput | null;
  dti?: NumberWhereInput | null;
  eSignConsentDate?: DateWhereInput | null;
  earliestClosingDate?: DateWhereInput | null;
  earliestFeeCollection?: DateWhereInput | null;
  epoEndDate?: DateWhereInput | null;
  escrowCompanyName?: StringWhereInput | null;
  expectedInterestRate?: NumberWhereInput | null;
  fanniePIW?: StringWhereInput | null;
  fhaVaLoanClosingDate?: DateWhereInput | null;
  fileStartedDate?: DateWhereInput | null;
  fileStarterLosId?: StringWhereInput | null;
  fileStarterName?: StringWhereInput | null;
  filterId?: StringWhereInput | null;
  financingContingency?: DateWhereInput | null;
  firstPaymentDate?: DateWhereInput | null;
  firstPaymentToInvestorDate?: DateWhereInput | null;
  firstTimeHomebuyersIndicator?: BooleanWhereInput | null;
  freddiePIW?: StringWhereInput | null;
  fundedDate?: DateWhereInput | null;
  fundingCloseDate?: DateWhereInput | null;
  fundingFundsSentDate?: DateWhereInput | null;
  fundingSentToFunderDate?: DateWhereInput | null;
  fundsAvailableAtClosing?: NumberWhereInput | null;
  gfeApplicationDate?: DateWhereInput | null;
  grossRevenueS?: NumberWhereInput | null;
  helocBrochureSent?: DateWhereInput | null;
  highCostDisclosure?: DateWhereInput | null;
  homeCounselingDisclosure?: DateWhereInput | null;
  hybridClosing?: BooleanWhereInput | null;
  id?: IdWhereInput | null;
  inShipping?: DateWhereInput | null;
  incomplete?: DateWhereInput | null;
  initialDisclosureDueDate?: DateWhereInput | null;
  initialDisclosureProvidedDate?: DateWhereInput | null;
  initialGfeAppraisalProvidedDate?: DateWhereInput | null;
  initialGfeDisclosureProvidedDate?: DateWhereInput | null;
  initialGfeDisclosureReceivedDate?: DateWhereInput | null;
  initialInterestRate?: NumberWhereInput | null;
  initialLoanAmount?: NumberWhereInput | null;
  initialLoanBalanceUPB?: NumberWhereInput | null;
  initialProduct?: StringWhereInput | null;
  initialRate?: NumberWhereInput | null;
  initialTilDisclosureProvidedDate?: DateWhereInput | null;
  initialTilDisclosureReceivedDate?: DateWhereInput | null;
  intentToProceed?: DateWhereInput | null;
  interestRateCap?: NumberWhereInput | null;
  interviewerEmail?: StringWhereInput | null;
  isEscrowWaived?: StringWhereInput | null;
  juniorBankerEmail?: EmailWhereInput | null;
  juniorBankerName?: StringWhereInput | null;
  kWAgentID?: StringWhereInput | null;
  kWMarketCenter?: StringWhereInput | null;
  kWMarketCenterID?: StringWhereInput | null;
  kWRegion?: StringWhereInput | null;
  kWRegionName?: StringWhereInput | null;
  lOCAvailableAfter1stYear?: NumberWhereInput | null;
  lOLockFinalPrice?: NumberWhereInput | null;
  lastCompletedMilestone?: StringWhereInput | null;
  lead?: LeadRelationWhereInput | null;
  leadId?: StringWhereInput | null;
  leadObjectId?: StringWhereInput | null;
  leadObjectName?: StringWhereInput | null;
  leadPricePaid?: NumberWhereInput | null;
  leadProviderName?: StringWhereInput | null;
  leadSource?: StringWhereInput | null;
  leadSourceCampaign?: StringWhereInput | null;
  leadSourceId?: StringWhereInput | null;
  lenderCoName?: StringWhereInput | null;
  loanAmortizationTerm?: NumberWhereInput | null;
  loanAmortizationType?: StringWhereInput | null;
  loanEstimate?: DateWhereInput | null;
  loanEstimateDelivered?: DateWhereInput | null;
  loanEstimateExpired?: DateWhereInput | null;
  loanEstimateIssuanceDue?: DateWhereInput | null;
  loanEstimateReDisclosureIssued?: DateWhereInput | null;
  loanEstimateReDisclosureReceived?: DateWhereInput | null;
  loanEstimateReceived?: DateWhereInput | null;
  loanEstimatedClosingDate?: DateWhereInput | null;
  loanFolder?: StringWhereInput | null;
  loanIsLocked?: BooleanWhereInput | null;
  loanMembers?: RelcuLoanMemberRelationWhereInput | null;
  loanNumber?: StringWhereInput | null;
  loanOfficerName?: StringWhereInput | null;
  loanOpenerStartDate?: DateWhereInput | null;
  loanOverageUnderagePercent?: NumberWhereInput | null;
  loanProgramName?: StringWhereInput | null;
  loanProgramType?: StringWhereInput | null;
  loanPurposeType?: StringWhereInput | null;
  loanPurposeTypeUrla?: StringWhereInput | null;
  loanRevision?: NumberWhereInput | null;
  loanSource?: StringWhereInput | null;
  loanStatus?: StringWhereInput | null;
  loanTermInMonthDue?: NumberWhereInput | null;
  loanType?: StringWhereInput | null;
  losCreatedAt?: DateWhereInput | null;
  losId?: StringWhereInput | null;
  losProvider?: StringWhereInput | null;
  losUpdatedAt?: DateWhereInput | null;
  ltv?: NumberWhereInput | null;
  mIP?: NumberWhereInput | null;
  mLOComments?: StringWhereInput | null;
  mLOEligibilityDate?: DateWhereInput | null;
  mLONextFollowUp?: DateWhereInput | null;
  mLOStatusDate?: DateWhereInput | null;
  mLOStatusStLouisRegion?: StringWhereInput | null;
  milestoneApprovedDate?: DateWhereInput | null;
  milestoneApprovedDueDate?: DateWhereInput | null;
  milestoneCompletedDate?: DateWhereInput | null;
  milestoneCompletedDueDate?: DateWhereInput | null;
  milestoneCurrentDate?: DateWhereInput | null;
  milestoneDocSignedDate?: DateWhereInput | null;
  milestoneDocSignedDueDate?: DateWhereInput | null;
  milestoneFileStartedDate?: DateWhereInput | null;
  milestoneFundedDate?: DateWhereInput | null;
  milestoneFundedDueDate?: DateWhereInput | null;
  milestoneProcessedDate?: DateWhereInput | null;
  milestoneSubmittedDate?: DateWhereInput | null;
  milestoneSubmittedDueDate?: DateWhereInput | null;
  milestones?: RelcuLoanMilestoneRelationWhereInput | null;
  monthlyTenureAvailable?: NumberWhereInput | null;
  mortgageCoordinatorEmail?: EmailWhereInput | null;
  ncHomeLoanAmortizationScheduleSent?: DateWhereInput | null;
  nextPaymentDate?: DateWhereInput | null;
  notAccepted?: DateWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  origFee?: NumberWhereInput | null;
  originalCreditProfile?: StringWhereInput | null;
  originalDateAdded?: DateWhereInput | null;
  originationDate?: DateWhereInput | null;
  otherCosts?: NumberWhereInput | null;
  postClosing?: DateWhereInput | null;
  preDeny?: DateWhereInput | null;
  preQualify?: DateWhereInput | null;
  prequalSentDateInitial?: DateWhereInput | null;
  processorNameCustom?: StringWhereInput | null;
  productionCenter?: StringWhereInput | null;
  propertyAppraisedValue?: NumberWhereInput | null;
  propertyCensusTract?: StringWhereInput | null;
  propertyCity?: StringWhereInput | null;
  propertyCounty?: StringWhereInput | null;
  propertyEstimatedValue?: NumberWhereInput | null;
  propertyNumberOfUnits?: NumberWhereInput | null;
  propertyOccupancyStatus?: StringWhereInput | null;
  propertyPostalCode?: StringWhereInput | null;
  propertyState?: StringWhereInput | null;
  propertyStreetAddress?: StringWhereInput | null;
  propertyType?: StringWhereInput | null;
  propertyUsageType?: StringWhereInput | null;
  proposedMaturityYears?: NumberWhereInput | null;
  purchasePrice?: NumberWhereInput | null;
  purchased?: DateWhereInput | null;
  qualDate?: DateWhereInput | null;
  rVLenderCase?: StringWhereInput | null;
  rVPrimaryMortgageBalance?: NumberWhereInput | null;
  rateLockDate?: DateWhereInput | null;
  rateReLocked?: DateWhereInput | null;
  reDisclosure?: DateWhereInput | null;
  reactivationDate?: DateWhereInput | null;
  referralSource?: StringWhereInput | null;
  referredDate?: StringWhereInput | null;
  referredToNetworkDateTime?: DateWhereInput | null;
  referredToReverseGroup?: StringWhereInput | null;
  referringBanker?: StringWhereInput | null;
  refinancePropertyExistingLien?: NumberWhereInput | null;
  refinancePropertyOriginalCost?: NumberWhereInput | null;
  registered?: DateWhereInput | null;
  remainingPrincipalLimit?: NumberWhereInput | null;
  requestedInterestRate?: NumberWhereInput | null;
  requestedLoanAmount?: NumberWhereInput | null;
  rescinded?: DateWhereInput | null;
  residentialAddress?: AddressWhereInput | null;
  reverseVisionLoanID?: StringWhereInput | null;
  rightOfRescissionNotice?: DateWhereInput | null;
  safeHarborSent?: DateWhereInput | null;
  scheduleClosing?: DateWhereInput | null;
  scheduledClosingDate?: DateWhereInput | null;
  scheduledFirstPaymentDate?: DateWhereInput | null;
  sellerAgentCompany?: StringWhereInput | null;
  sellerAgentEmail?: EmailWhereInput | null;
  sellerAgentName?: StringWhereInput | null;
  sellerAgentPhone?: PhoneWhereInput | null;
  servicing?: DateWhereInput | null;
  specialInformationBookletSent?: DateWhereInput | null;
  ssplSent?: DateWhereInput | null;
  stateCounselingNoticeSent?: DateWhereInput | null;
  stateHighCostCounselingCertificate?: DateWhereInput | null;
  stateHighCostOrHighRiskOrCoveredDisclosureSent?: DateWhereInput | null;
  stpDate?: DateWhereInput | null;
  subsequentRateAdjustmentMonths?: NumberWhereInput | null;
  surveyDate?: DateWhereInput | null;
  suspended?: DateWhereInput | null;
  tasks?: RelcuLoanTaskRelationWhereInput | null;
  template?: StringWhereInput | null;
  texasConsumerDisclosureSent?: DateWhereInput | null;
  texasMortgageCompanyDisclosureSent?: DateWhereInput | null;
  texasNoticeOfPenaltiesDisclosureSent?: DateWhereInput | null;
  titleCompanyName?: StringWhereInput | null;
  totalExpertLoanStatus?: StringWhereInput | null;
  totalGrossMonthlyIncomeAmount?: NumberWhereInput | null;
  totalIncome?: NumberWhereInput | null;
  totalLESASetAside?: NumberWhereInput | null;
  totalLoanAmount?: NumberWhereInput | null;
  transferAgent?: StringWhereInput | null;
  tsumPropertyType?: StringWhereInput | null;
  underwritingAppraisalOrderedDate?: DateWhereInput | null;
  underwritingApprovalDate?: DateWhereInput | null;
  underwritingClearToCloseDate?: DateWhereInput | null;
  underwritingCreditApprovalDate?: DateWhereInput | null;
  underwritingResubmittedDate?: DateWhereInput | null;
  underwritingSubmittedDate?: DateWhereInput | null;
  updatedAt?: DateWhereInput | null;
  vaMilitaryBranchOfService?: StringWhereInput | null;
  vaSummaryCreditScore?: NumberWhereInput | null;
  vaTypeOfVeteran?: StringWhereInput | null;
  vaVeteranServiceType?: StringWhereInput | null;
  velocifyLeadID?: StringWhereInput | null;
  velocifyMembershipType?: StringWhereInput | null;
  vendorId?: StringWhereInput | null;
  wfaJobs?: ArrayWhereInput | null;
  withdrawn?: DateWhereInput | null;
}

/**
 * The ReplaceContactInputType input type is used in operations that involve replacing contacts and all relations of
 */
export interface ReplaceContactInputType {
  fields?: UpdateContactFieldsInput | null;
  objectId?: string | null;
}

/**
 * Allow to manage roles in ACL.
 */
export interface RoleACLInput {
  read: boolean;
  roleName: string;
  write: boolean;
}

/**
 * The SchemaFieldInput is used to specify a field for an object class schema.
 */
export interface SchemaFieldInput {
  name: string;
  options?: any | null;
}

/**
 * The SearchInput type is used to specify a search operation on a full text search.
 */
export interface SearchInput {
  caseSensitive?: boolean | null;
  diacriticSensitive?: boolean | null;
  language?: string | null;
  term: string;
}

/**
 * The SelectInput type is used to specify an inQueryKey or a notInQueryKey operation on a constraint.
 */
export interface SelectInput {
  key: string;
  query: SubqueryInput;
}

/**
 * The SelectedRateWhereInput input type is used in operations that involve filtering objects of SelectedRate class.
 */
export interface SelectedRateWhereInput {
  AND?: SelectedRateWhereInput[] | null;
  NOR?: SelectedRateWhereInput[] | null;
  OR?: SelectedRateWhereInput[] | null;
  lastUpdate?: DateWhereInput | null;
  pricingStatus?: StringWhereInput | null;
  productDesc?: StringWhereInput | null;
  productName?: StringWhereInput | null;
  productTerm?: StringWhereInput | null;
  quoteDetails?: QuotesWhereInput | null;
  vendorName?: StringWhereInput | null;
}

/**
 * Allow to add, remove, createAndAdd objects of the Session class into a relation field.
 */
export interface SessionRelationInput {
  add?: string[] | null;
  createAndAdd?: CreateSessionFieldsInput[] | null;
  remove?: string[] | null;
}

/**
 * The SessionRelationWhereInput input type is used in operations that involve filtering objects of Session class.
 */
export interface SessionRelationWhereInput {
  exists?: boolean | null;
  have?: SessionWhereInput | null;
  haveNot?: SessionWhereInput | null;
}

/**
 * The SessionWhereInput input type is used in operations that involve filtering objects of Session class.
 */
export interface SessionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: SessionWhereInput[] | null;
  NOR?: SessionWhereInput[] | null;
  OR?: SessionWhereInput[] | null;
  active?: BooleanWhereInput | null;
  code?: NumberWhereInput | null;
  createdAt?: DateWhereInput | null;
  delay?: NumberWhereInput | null;
  device?: DeviceRelationWhereInput | null;
  duration?: NumberWhereInput | null;
  id?: IdWhereInput | null;
  message?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  reason?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  user?: UserRelationWhereInput | null;
}

/**
 * The GeoPointInput type is used in operations that involve inputting fields of type geo point.
 */
export interface StatusInput {
  action?: string | null;
  actionCount?: number | null;
  currentStageActionCount?: number | null;
  currentStatusActionCount?: number | null;
  status?: string | null;
  updatedAt?: any | null;
}

/**
 * The Status where input type is used in operations that involve filtering objects by a field of type Status.
 */
export interface StatusWhereInput {
  action?: StringWhereInput | null;
  actionCount?: NumberWhereInput | null;
  currentStageActionCount?: NumberWhereInput | null;
  currentStatusActionCount?: NumberWhereInput | null;
  exists?: boolean | null;
  status?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The StringWhereInput input type is used in operations that involve filtering objects by a field of type String.
 */
export interface StringWhereInput {
  equalTo?: string | null;
  exists?: boolean | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: (string | null)[] | null;
  inQueryKey?: SelectInput | null;
  includes?: string | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  matchesRegex?: string | null;
  notEqualTo?: string | null;
  notIn?: (string | null)[] | null;
  notInQueryKey?: SelectInput | null;
  notIncludes?: string | null;
  options?: string | null;
  regex?: string | null;
  text?: TextInput | null;
}

/**
 * The SubqueryInput type is used to specify a sub query to another class.
 */
export interface SubqueryInput {
  className: string;
  where: any;
}

/**
 * Available fields for selection
 */
export interface TableViewActions {
  field?: (string | null)[] | null;
  type: string;
}

/**
 * The TableViewCreatedByUnionWhereInput input type is used in operations that involve filtering objects.
 */
export interface TableViewCreatedByUnionWhereInput {
  exists?: boolean | null;
  have?: UnionRelationConstraintInput | null;
  haveNot?: UnionRelationConstraintInput | null;
}

/**
 * The TableViewFieldRelationWhereInput input type is used in operations that involve filtering objects of TableViewField class.
 */
export interface TableViewFieldRelationWhereInput {
  exists?: boolean | null;
  have?: TableViewFieldWhereInput | null;
  haveNot?: TableViewFieldWhereInput | null;
}

/**
 * The TableViewFieldSubscriptionRelationWhereInput input type is used in operations that involve filtering objects of TableViewField class.
 */
export interface TableViewFieldSubscriptionRelationWhereInput {
  exists?: boolean | null;
  have?: TableViewFieldSubscriptionWhereInput | null;
  haveNot?: TableViewFieldSubscriptionWhereInput | null;
}

/**
 * The TableViewFieldSubscriptionWhereInput input type is used in operations that involve filtering objects of TableViewField class.
 */
export interface TableViewFieldSubscriptionWhereInput {
  AND?: TableViewFieldSubscriptionWhereInput[] | null;
  NOR?: TableViewFieldSubscriptionWhereInput[] | null;
  OR?: TableViewFieldSubscriptionWhereInput[] | null;
  fixed?: StringWhereInput | null;
  key?: StringWhereInput | null;
  properties?: ObjectWhereInput | null;
  sort?: StringWhereInput | null;
  width?: NumberWhereInput | null;
}

/**
 * The TableViewFieldWhereInput input type is used in operations that involve filtering objects of TableViewField class.
 */
export interface TableViewFieldWhereInput {
  AND?: TableViewFieldWhereInput[] | null;
  NOR?: TableViewFieldWhereInput[] | null;
  OR?: TableViewFieldWhereInput[] | null;
  fixed?: StringWhereInput | null;
  key?: StringWhereInput | null;
  properties?: ObjectWhereInput | null;
  sort?: StringWhereInput | null;
  width?: NumberWhereInput | null;
}

/**
 * Available fields for selection
 */
export interface TableViewSelectedFields {
  field?: (string | null)[] | null;
  fields?: (string | null)[] | null;
  fixed?: string | null;
  name: string;
}

/**
 * The TableViewSubscriptionWhereInput input type is used in operations that involve filtering objects of TableView class.
 */
export interface TableViewSubscriptionWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: TableViewSubscriptionWhereInput[] | null;
  NOR?: TableViewSubscriptionWhereInput[] | null;
  OR?: TableViewSubscriptionWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  createdBy?: TableViewCreatedByUnionWhereInput | null;
  fields?: TableViewFieldSubscriptionRelationWhereInput | null;
  filter?: ObjectWhereInput | null;
  group?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  rPerm?: ArrayWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * The TableViewWhereInput input type is used in operations that involve filtering objects of TableView class.
 */
export interface TableViewWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: TableViewWhereInput[] | null;
  NOR?: TableViewWhereInput[] | null;
  OR?: TableViewWhereInput[] | null;
  createdAt?: DateWhereInput | null;
  createdBy?: UserRelationWhereInput | null;
  fields?: TableViewFieldRelationWhereInput | null;
  filter?: ObjectWhereInput | null;
  group?: StringWhereInput | null;
  id?: IdWhereInput | null;
  name?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  rPerm?: ArrayWhereInput | null;
  updatedAt?: DateWhereInput | null;
}

/**
 * Allow to link OR add and link
 */
export interface TaskSubjectPointerInput {
  createAndLinkLead?: CreateLeadFieldsInput | null;
  createAndLinkReferralPartner?: CreateReferralPartnerFieldsInput | null;
  link?: string | null;
}

/**
 * Allow to manage teams in ACL.
 */
export interface TeamACLInput {
  read: boolean;
  teamName: string;
  write: boolean;
}

/**
 * The TextInput type is used to specify a text operation on a constraint.
 */
export interface TextInput {
  search: SearchInput;
}

/**
 * The TitleFeeAddress input type is used in operations that involve TitleFeeAddress.
 */
export interface TitleFeeAddress {
  city?: string | null;
  county?: string | null;
  fipsCode?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

/**
 * The TitleFeeArgs input type is used in operations that involve TitleFeeArgs.
 */
export interface TitleFeeArgs {
  amortizationType?: string | null;
  cashOutAmount?: number | null;
  cltv?: number | null;
  currentMortgageBalance?: number | null;
  firstTimeHomeBuyer?: boolean | null;
  loanAmount?: number | null;
  loanPurpose?: string | null;
  loanType?: string | null;
  offerId?: string | null;
  property?: TitleFeeProperty | null;
  providerIds?: (any | null)[] | null;
}

/**
 * The TitleFeeProperty input type is used in operations that involve TitleFeeProperty.
 */
export interface TitleFeeProperty {
  occupancy?: string | null;
  propertyAddress?: TitleFeeAddress | null;
  type?: string | null;
  value?: number | null;
}

/**
 * The TitleWhereInput input type is used in operations that involve filtering objects of Title class.
 */
export interface TitleWhereInput {
  AND?: TitleWhereInput[] | null;
  NOR?: TitleWhereInput[] | null;
  OR?: TitleWhereInput[] | null;
  insurance?: NumberWhereInput | null;
  lendersTitle?: NumberWhereInput | null;
  ownersTitle?: NumberWhereInput | null;
  recordingCharges?: NumberWhereInput | null;
  settlementFee?: NumberWhereInput | null;
  total?: NumberWhereInput | null;
  transferTax?: NumberWhereInput | null;
}

/**
 * The UnionRelationConstraintInput input type is used in operations that involve filtering objects.
 */
export interface UnionRelationConstraintInput {
  link?: string | null;
}

/**
 * The UpdateAccountLockoutSettingsFieldsInput input type is used in operations that involve creation of objects in the AccountLockoutSettings class.
 */
export interface UpdateAccountLockoutSettingsFieldsInput {
  duration?: number | null;
  threshold?: number | null;
}

/**
 * The UpdateAddressFieldsInput input type is used in operations that involve creation of objects in the Address class.
 */
export interface UpdateAddressFieldsInput {
  city?: string | null;
  country?: string | null;
  county?: string | null;
  formatted?: string | null;
  lat?: number | null;
  lng?: number | null;
  state?: string | null;
  street?: string | null;
  street2?: string | null;
  zipCode?: string | null;
}

/**
 * The UpdateAdminFeesFieldsInput input type is used in operations that involve creation of objects in the AdminFees class.
 */
export interface UpdateAdminFeesFieldsInput {
  costcoExecutive?: number | null;
  costcoGoldStar?: number | null;
  creativePlanning?: number | null;
  creditKarma?: number | null;
  default?: number | null;
  editable?: boolean | null;
  nerdWallet?: number | null;
}

/**
 * The UpdateAppraisalFieldsInput input type is used in operations that involve creation of objects in the Appraisal class.
 */
export interface UpdateAppraisalFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  defaultType?: string | null;
  enabled?: boolean | null;
  objectName?: string | null;
  types?: (any | null)[] | null;
  updatedAt?: any | null;
  uploadedFiles?: (any | null)[] | null;
}

/**
 * The UpdateBulkMessagingPermissionFieldsInput input type is used in operations that involve creation of objects in the BulkMessagingPermission class.
 */
export interface UpdateBulkMessagingPermissionFieldsInput {
  email?: boolean | null;
  sms?: boolean | null;
}

/**
 * The UpdateCallableStatesHoursSettingsFieldsInput input type is used in operations that involve creation of objects in the CallableStatesHoursSettings class.
 */
export interface UpdateCallableStatesHoursSettingsFieldsInput {
  callableEndHour?: string | null;
  callableSaturdayEndHour?: string | null;
  callableSaturdayStartHour?: string | null;
  callableStartHour?: string | null;
  callableSundayEndHour?: string | null;
  callableSundayStartHour?: string | null;
  states?: (any | null)[] | null;
}

/**
 * The UpdateClosingCostFieldsInput input type is used in operations that involve creation of objects in the ClosingCost class.
 */
export interface UpdateClosingCostFieldsInput {
  adminFee?: number | null;
  appraisalFee?: number | null;
  attorney?: number | null;
  condoCertificate?: number | null;
  creditAndOther?: UpdateCreditAndOtherFieldsInput | null;
  discountPoints?: number | null;
  docReview?: number | null;
  earnestMoneyPaid?: number | null;
  floodCertification?: number | null;
  fundingFee?: number | null;
  mip?: number | null;
  originationFee?: number | null;
  pestInspection?: number | null;
  pmi?: number | null;
  sellerConcession?: number | null;
  subordination?: number | null;
  survey?: number | null;
  title?: UpdateTitleFieldsInput | null;
  total?: number | null;
}

/**
 * The UpdateClosingFeesFieldsInput input type is used in operations that involve creation of objects in the ClosingFees class.
 */
export interface UpdateClosingFeesFieldsInput {
  adminFee?: UpdateAdminFeesFieldsInput | null;
  attorneyFee?: number | null;
  attorneyFeeStates?: any | null;
  condoCertificateFee?: number | null;
  creditReportFee?: number | null;
  customTitleFees?: UpdateCustomTitleFeesFieldsInput | null;
  docReviewFee?: number | null;
  docReviewFeeStates?: any | null;
  electronicDocumentDelivery?: number | null;
  fixedCreditReportFee?: boolean | null;
  floodCertificationFee?: number | null;
  insurancePrepaymentMonths?: number | null;
  interestPrepaymentDays?: number | null;
  loanSafeFee?: number | null;
  lockInFee?: number | null;
  mersFee?: number | null;
  mersFeeStates?: any | null;
  originationFee?: UpdateOriginationFeeFieldsInput | null;
  pestInspectionFee?: number | null;
  pestInspectionFeeStates?: any | null;
  subordinationFee?: number | null;
  surveyFee?: number | null;
  surveyFeeStates?: any | null;
  taxPrepaymentMonths?: number | null;
  transcriptFee?: number | null;
  verificationFee?: number | null;
}

/**
 * The UpdateCompanyFieldsInput input type is used in operations that involve creation of objects in the Company class.
 */
export interface UpdateCompanyFieldsInput {
  facebook?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  logo?: string | null;
  x?: string | null;
}

/**
 * The UpdateConferenceFieldsInput input type is used in operations that involve creation of objects in the Conference class.
 */
export interface UpdateConferenceFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  objectName?: string | null;
  scope?: ConferenceScopePointerInput | null;
  tags?: (any | null)[] | null;
  updatedAt?: any | null;
}

/**
 * The UpdateContactFieldsInput input type is used in operations that involve creation of objects in the Contact class.
 */
export interface UpdateContactFieldsInput {
  ACL?: ACLInput | null;
  accountNumber?: string | null;
  address?: UpdateAddressFieldsInput | null;
  addresses?: (UpdateAddressFieldsInput | null)[] | null;
  birthday?: any | null;
  company?: string | null;
  createdAt?: any | null;
  emails?: (UpdateEmailAddressFieldsInput | null)[] | null;
  firstName?: string | null;
  gender?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  objectIcon?: string | null;
  objectName?: string | null;
  phones?: (UpdatePhoneNumberFieldsInput | null)[] | null;
  primaryEmail?: any | null;
  primaryPhone?: any | null;
  ssn?: string | null;
  tags?: (any | null)[] | null;
  types?: any | null;
  updatedAt?: any | null;
  wfaJobs?: (any | null)[] | null;
}

export interface UpdateContactInput {
  clientMutationId?: string | null;
  fields?: UpdateContactFieldsInput | null;
  id: string;
}

/**
 * The UpdateConvPurchaseFieldsInput input type is used in operations that involve creation of objects in the ConvPurchase class.
 */
export interface UpdateConvPurchaseFieldsInput {
  minCreditScore?: number | null;
  minDownPaymentPercent?: number | null;
}

/**
 * The UpdateConvRefinanceFieldsInput input type is used in operations that involve creation of objects in the ConvRefinance class.
 */
export interface UpdateConvRefinanceFieldsInput {
  minCreditScore?: number | null;
  minDownPaymentPercent?: number | null;
}

/**
 * The UpdateConventionalFieldsInput input type is used in operations that involve creation of objects in the Conventional class.
 */
export interface UpdateConventionalFieldsInput {
  pmiDownPaymentBoundary?: number | null;
  purchase?: UpdateConvPurchaseFieldsInput | null;
  refinance?: UpdateConvRefinanceFieldsInput | null;
}

/**
 * The UpdateCostcoFieldsInput input type is used in operations that involve creation of objects in the Costco class.
 */
export interface UpdateCostcoFieldsInput {
  exclusivity?: string | null;
  membershipId?: string | null;
  membershipType?: string | null;
}

/**
 * The UpdateCreditAndOtherFieldsInput input type is used in operations that involve creation of objects in the CreditAndOther class.
 */
export interface UpdateCreditAndOtherFieldsInput {
  creditReport?: number | null;
  documentDelivery?: number | null;
  loanSafe?: number | null;
  mers?: number | null;
  total?: number | null;
  transcript?: number | null;
  verification?: number | null;
}

/**
 * The UpdateCustomTitleFeesFieldsInput input type is used in operations that involve creation of objects in the CustomTitleFees class.
 */
export interface UpdateCustomTitleFeesFieldsInput {
  enabled?: boolean | null;
  ownersTitle?: number | null;
  recordingCharges?: number | null;
  titleInsurance?: number | null;
  transferTax?: number | null;
}

/**
 * The UpdateDataTablePermissionFieldsInput input type is used in operations that involve creation of objects in the DataTablePermission class.
 */
export interface UpdateDataTablePermissionFieldsInput {
  create?: boolean | null;
  share?: boolean | null;
}

/**
 * The UpdateDistributionQueueFieldsInput input type is used in operations that involve creation of objects in the DistributionQueue class.
 */
export interface UpdateDistributionQueueFieldsInput {
  ACL?: ACLInput | null;
  autoDial?: boolean | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  disabled?: boolean | null;
  distributeOfflineUsers?: boolean | null;
  draft?: boolean | null;
  limits?: (UpdateDistributionQueueLimitFieldsInput | null)[] | null;
  notify?: boolean | null;
  notifyMobile?: boolean | null;
  objectName?: string | null;
  priority?: number | null;
  rules?: (UpdateDistributionQueueRuleFieldsInput | null)[] | null;
  schedule?: UpdateDistributionQueueScheduleFieldsInput | null;
  skipTimezoneCheck?: boolean | null;
  strategy?: string | null;
  takeLeadFromMobile?: boolean | null;
  updatedAt?: any | null;
  view?: UpdateDistributionQueueViewFieldsInput | null;
}

export interface UpdateDistributionQueueInput {
  clientMutationId?: string | null;
  fields?: UpdateDistributionQueueFieldsInput | null;
  id: string;
}

/**
 * The UpdateDistributionQueueLimitFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueLimit class.
 */
export interface UpdateDistributionQueueLimitFieldsInput {
  cdp?: number | null;
  daily?: number | null;
  filter?: (any | null)[] | null;
  monthly?: number | null;
  weekend?: number | null;
  weekly?: number | null;
}

/**
 * The UpdateDistributionQueueRuleFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueRule class.
 */
export interface UpdateDistributionQueueRuleFieldsInput {
  disabled?: boolean | null;
  leads?: string | null;
  leadsFilter?: any | null;
  limits?: (UpdateDistributionQueueLimitFieldsInput | null)[] | null;
  name?: string | null;
  slug?: string | null;
  sort?: (any | null)[] | null;
  users?: string | null;
  usersFilter?: any | null;
}

/**
 * The UpdateDistributionQueueScheduleFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueSchedule class.
 */
export interface UpdateDistributionQueueScheduleFieldsInput {
  FRI?: string | null;
  MON?: string | null;
  SAT?: string | null;
  SUN?: string | null;
  THU?: string | null;
  TUE?: string | null;
  WED?: string | null;
}

/**
 * The UpdateDistributionQueueSoundFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueSound class.
 */
export interface UpdateDistributionQueueSoundFieldsInput {
  repeat?: number | null;
  sound?: string | null;
}

/**
 * The UpdateDistributionQueueViewFieldsInput input type is used in operations that involve creation of objects in the DistributionQueueView class.
 */
export interface UpdateDistributionQueueViewFieldsInput {
  color?: string | null;
  icon?: string | null;
  name?: string | null;
  phoneLeadSound?: UpdateDistributionQueueSoundFieldsInput | null;
  showCoolDownTime?: boolean | null;
  showDailyCap?: boolean | null;
  showLeadCount?: boolean | null;
  showMonthlyCap?: boolean | null;
  showWeeklyCap?: boolean | null;
  webLeadSound?: UpdateDistributionQueueSoundFieldsInput | null;
}

/**
 * The UpdateEmailAddressFieldsInput input type is used in operations that involve creation of objects in the EmailAddress class.
 */
export interface UpdateEmailAddressFieldsInput {
  address?: any | null;
  isPrimary?: boolean | null;
  objectName?: string | null;
  optOut?: boolean | null;
  type?: string | null;
}

/**
 * The UpdateEmailSignatureSettingsFieldsInput input type is used in operations that involve creation of objects in the EmailSignatureSettings class.
 */
export interface UpdateEmailSignatureSettingsFieldsInput {
  enabledMicrosoftGmail?: boolean | null;
  enabledRelcu?: boolean | null;
  name?: string | null;
  signature?: string | null;
}

/**
 * The UpdateEmailTemplateEditorPermissionFieldsInput input type is used in operations that involve creation of objects in the EmailTemplateEditorPermission class.
 */
export interface UpdateEmailTemplateEditorPermissionFieldsInput {
  builder?: boolean | null;
  html?: boolean | null;
  plainText?: boolean | null;
}

/**
 * The UpdateEmailTemplateFieldsInput input type is used in operations that involve creation of objects in the EmailTemplate class.
 */
export interface UpdateEmailTemplateFieldsInput {
  ACL?: ACLInput | null;
  applyTo?: (any | null)[] | null;
  availableFor?: (any | null)[] | null;
  content?: string | null;
  createdAt?: any | null;
  enabled?: boolean | null;
  forAutomation?: boolean | null;
  jsonContent?: any | null;
  lead?: boolean | null;
  loanProposal?: boolean | null;
  objectName?: string | null;
  owner?: UserPointerInput | null;
  previewSettings?: any | null;
  subject?: string | null;
  title?: string | null;
  type?: string | null;
  updatedAt?: any | null;
}

export interface UpdateEmailTemplateInput {
  clientMutationId?: string | null;
  fields?: UpdateEmailTemplateFieldsInput | null;
  id: string;
}

/**
 * The UpdateFhaFieldsInput input type is used in operations that involve creation of objects in the Fha class.
 */
export interface UpdateFhaFieldsInput {
  mip?: number | null;
  mipDownPaymentBoundary?: number | null;
  purchase?: UpdateFhaPurchaseFieldsInput | null;
  refinance?: UpdateFhaRefinanceFieldsInput | null;
}

/**
 * The UpdateFhaPurchaseFieldsInput input type is used in operations that involve creation of objects in the FhaPurchase class.
 */
export interface UpdateFhaPurchaseFieldsInput {
  minCreditScore?: number | null;
  minDownPaymentPercent?: number | null;
}

/**
 * The UpdateFhaRefinanceFieldsInput input type is used in operations that involve creation of objects in the FhaRefinance class.
 */
export interface UpdateFhaRefinanceFieldsInput {
  minCreditScore?: number | null;
  minDownPaymentPercent?: number | null;
}

/**
 * The UpdateFilterFieldsInput input type is used in operations that involve creation of objects in the Filter class.
 */
export interface UpdateFilterFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  name?: string | null;
  rules?: (any | null)[] | null;
  target?: string | null;
  updatedAt?: any | null;
}

export interface UpdateFilterInput {
  clientMutationId?: string | null;
  fields?: UpdateFilterFieldsInput | null;
  id: string;
}

/**
 * The UpdateFlyerFieldsInput input type is used in operations that involve creation of objects in the Flyer class.
 */
export interface UpdateFlyerFieldsInput {
  ACL?: ACLInput | null;
  applyTo?: string | null;
  availableFor?: (any | null)[] | null;
  availableForType?: (any | null)[] | null;
  content?: string | null;
  createdAt?: any | null;
  enabled?: boolean | null;
  jsonContent?: any | null;
  objectName?: string | null;
  owner?: UserPointerInput | null;
  previewSettings?: UpdatePreviewSettingsFieldsInput | null;
  title?: string | null;
  updatedAt?: any | null;
}

export interface UpdateFlyerInput {
  clientMutationId?: string | null;
  fields?: UpdateFlyerFieldsInput | null;
  id: string;
}

/**
 * The UpdateFlyerPermissionFieldsInput input type is used in operations that involve creation of objects in the FlyerPermission class.
 */
export interface UpdateFlyerPermissionFieldsInput {
  create?: boolean | null;
  send?: boolean | null;
}

/**
 * The UpdateHomeBuyingProcessFieldsInput input type is used in operations that involve creation of objects in the HomeBuyingProcess class.
 */
export interface UpdateHomeBuyingProcessFieldsInput {
  offerPlans?: string | null;
  stage?: string | null;
}

/**
 * The UpdateIdentityFieldsInput input type is used in operations that involve creation of objects in the Identity class.
 */
export interface UpdateIdentityFieldsInput {
  accessToken?: string | null;
  enabled?: boolean | null;
  metadata?: UpdateIdentityMetadataFieldsInput | null;
  provider?: string | null;
  refreshToken?: string | null;
  scope?: (any | null)[] | null;
  updatedAt?: any | null;
  userEmail?: string | null;
  userId?: string | null;
}

/**
 * The UpdateIdentityMetadataFieldsInput input type is used in operations that involve creation of objects in the IdentityMetadata class.
 */
export interface UpdateIdentityMetadataFieldsInput {
  syncEmails?: string | null;
}

/**
 * The UpdateLeadFieldsInput input type is used in operations that involve creation of objects in the Lead class.
 */
export interface UpdateLeadFieldsInput {
  ACL?: ACLInput | null;
  a1822Group?: string | null;
  accountNumber?: string | null;
  activeLoan?: string | null;
  activeNetworkReferral?: boolean | null;
  activityTypeId?: string | null;
  adKeyword?: string | null;
  addedListReports?: boolean | null;
  addedSimpleNexus?: boolean | null;
  additionalCashAfterCurrentBalancePaidOff?: number | null;
  additionalInformation?: string | null;
  adwordCampaign?: string | null;
  affiliateName?: string | null;
  ageAtApplication?: string | null;
  ageOfYoungestBorrower?: number | null;
  ageSeconds?: string | null;
  agentAssigned?: boolean | null;
  agentAssignedDateTime?: any | null;
  agentName?: string | null;
  aggMtgBalance?: number | null;
  alternateLeadSource?: string | null;
  amortizationType?: string | null;
  annualReduction?: number | null;
  anotherLenderAndActive?: boolean | null;
  anotherLenderAndClosed?: any | null;
  apAgeSeconds?: string | null;
  apDomain?: string | null;
  apFormInputMethod?: string | null;
  apMaskedCertUrl?: string | null;
  apRetainEmailMatch?: string | null;
  apRetainExpire?: string | null;
  apRetainPhoneMatch?: string | null;
  apRetainSuccess?: string | null;
  apiDuplicate?: boolean | null;
  appraisalFee?: number | null;
  appraisalReceivedDate?: any | null;
  appraisalRequestedDate?: any | null;
  appraisedValue?: number | null;
  apr?: number | null;
  aptNumber?: string | null;
  armType?: string | null;
  assignedAt?: any | null;
  assignedBy?: UserPointerInput | null;
  assignedTo?: UserPointerInput | null;
  assignedToEmail?: string | null;
  assignedToNmls?: string | null;
  assignedToObjectId?: string | null;
  assignedToObjectName?: string | null;
  assignedUserApplyUrl?: string | null;
  assignedUserCustom6?: string | null;
  assignedUserCustom7?: string | null;
  assignedUserEmail?: string | null;
  assignedUserFive9Id?: string | null;
  assignedUserObjectId?: string | null;
  assignedUserTeam?: string | null;
  attorneyMobilePhone?: any | null;
  attorneyName?: string | null;
  attorneyPersonalEmail?: any | null;
  autoLoanBal?: number | null;
  autoLoanDel?: number | null;
  autoLoanQty?: number | null;
  availableHomeEquity?: number | null;
  bankerName?: string | null;
  bankingBridgeDashboardLink?: string | null;
  bankingBridgeLastEvent?: string | null;
  bankingBridgeLastEventDate?: any | null;
  bankingBridgeSubscriptionId?: string | null;
  bipCurrentAge?: string | null;
  bipCurrentLTV?: string | null;
  bipCurrentLoanAmount?: number | null;
  bipEPOExpirationDate?: any | null;
  bipEngagementDate?: any | null;
  bipFICOTier?: string | null;
  bipHELOCBalance?: number | null;
  bipHomeValue?: number | null;
  bipMortgageInquirySegment?: string | null;
  bipOpportunityDate?: any | null;
  bipOpportunityName?: string | null;
  bipPublicLoanAmount?: number | null;
  bipPublicLoanClosingLTV?: string | null;
  bipPublicLoanFundedDate?: any | null;
  bipPublicLoanInterestRate?: string | null;
  bipPublicLoanTerm?: string | null;
  bipPublicLoanType?: string | null;
  bipRevolvingDebt?: number | null;
  bipTappableEquity?: string | null;
  boCell?: string | null;
  boDob?: any | null;
  boEmployerAddress?: string | null;
  boEmployerCity?: string | null;
  boEmployerName?: string | null;
  boEmployerState?: string | null;
  boEmployerZip?: string | null;
  boEmploymentLengthInYears?: number | null;
  boFirstName?: string | null;
  boHomeEMail?: string | null;
  boHomePhone?: string | null;
  boLastName?: string | null;
  boWorkEMail?: string | null;
  boWorkPhone?: string | null;
  borrowerAcknowledged?: string | null;
  borrowerAnnualIncome?: number | null;
  borrowerBankruptcy?: boolean | null;
  borrowerBirthday?: any | null;
  borrowerCompany?: string | null;
  borrowerCreditRating?: string | null;
  borrowerCreditScore?: number | null;
  borrowerEmail?: any | null;
  borrowerEmails?: (any | null)[] | null;
  borrowerEmploymentOccupation?: string | null;
  borrowerFirstName?: string | null;
  borrowerForeclosure?: string | null;
  borrowerHomePhone?: any | null;
  borrowerHomePhoneOptOut?: boolean | null;
  borrowerInitialCDESignedDate?: any | null;
  borrowerIntendToContinueDate?: any | null;
  borrowerLastName?: string | null;
  borrowerLeESignedDate?: any | null;
  borrowerMaritalStatus?: string | null;
  borrowerMiddleName?: string | null;
  borrowerMobilePhone?: any | null;
  borrowerMobilePhoneOptOut?: boolean | null;
  borrowerPersonalEmail?: any | null;
  borrowerPersonalEmailOptOut?: boolean | null;
  borrowerPhone?: any | null;
  borrowerPhones?: (any | null)[] | null;
  borrowerPrimaryPhoneOptOut?: boolean | null;
  borrowerSSN?: string | null;
  borrowerVeteranStatus?: string | null;
  borrowerWorkEmail?: any | null;
  borrowerWorkPhone?: any | null;
  borrowerWorkPhoneOptOut?: boolean | null;
  borrowerisMilitary?: boolean | null;
  botSplashConversationUrl?: string | null;
  botSplashLastMessageReceivedDate?: any | null;
  botSplashSmsMessageSent?: string | null;
  botsplashLastMessageSentDate?: any | null;
  botsplashLinkClickTracking?: string | null;
  botsplashListenerName?: string | null;
  botsplashOriginalUrl?: string | null;
  botsplashTimestamp?: any | null;
  branchGroup?: string | null;
  branchId?: string | null;
  branchOfService?: string | null;
  browser?: string | null;
  bsLastMessageReceivedDate?: any | null;
  buyerPersonalityAndHobbies?: string | null;
  buyerRealtorAddressCity?: string | null;
  buyerRealtorAddressState?: string | null;
  buyerRealtorAddressStreet?: string | null;
  buyerRealtorAddressZip?: string | null;
  buyerRealtorContactId?: string | null;
  buyerRealtorFirstName?: string | null;
  buyerRealtorLastName?: string | null;
  buyerRealtorMobilePhone?: any | null;
  buyerRealtorName?: string | null;
  buyerRealtorPersonalEmail?: any | null;
  buyersAgentCity?: string | null;
  buyersAgentState?: string | null;
  calendlyTime?: any | null;
  callAttempts?: number | null;
  cashOut?: number | null;
  cert?: string | null;
  certId?: string | null;
  certUrl?: string | null;
  certificateSent?: boolean | null;
  channel?: string | null;
  cis?: number | null;
  citizenshipStatus?: string | null;
  closingDate?: any | null;
  cltv?: number | null;
  coBorrowerBirthday?: any | null;
  coBorrowerCreditRating?: string | null;
  coBorrowerCreditScore?: number | null;
  coBorrowerFirstName?: string | null;
  coBorrowerHomePhone?: any | null;
  coBorrowerInitialCDESignedDate?: any | null;
  coBorrowerIsMilitary?: boolean | null;
  coBorrowerLastName?: string | null;
  coBorrowerLeESignedDate?: any | null;
  coBorrowerMiddleName?: string | null;
  coBorrowerMobilePhone?: any | null;
  coBorrowerPhone?: any | null;
  coBorrowerResidentialAddress?: UpdateAddressFieldsInput | null;
  coBorrowerSSN?: string | null;
  coBorrowerWorkPhone?: any | null;
  coCell?: string | null;
  coDob?: any | null;
  coEmployerAddress?: string | null;
  coEmployerCity?: string | null;
  coEmployerName?: string | null;
  coEmployerState?: string | null;
  coEmployerZip?: string | null;
  coEmploymentLengthInYears?: number | null;
  coFirstName?: string | null;
  coHomeEmail?: string | null;
  coHomePhone?: string | null;
  coLastName?: string | null;
  coWorkEmail?: string | null;
  coWorkPhone?: string | null;
  coborrowerTimeAtPreviousResidence?: number | null;
  collateralPropertyType?: string | null;
  collateralValueVerification?: boolean | null;
  contact?: any | null;
  contactAction?: string | null;
  contactConfirmed?: boolean | null;
  contactConfirmedDateTime?: any | null;
  contactEmail?: any | null;
  contactFirstName?: string | null;
  contactId?: string | null;
  contactLastName?: string | null;
  contactPhone?: any | null;
  contactStatus?: string | null;
  contactedStatusAttempts?: number | null;
  contingencyDate?: any | null;
  costCenter?: number | null;
  costco?: UpdateCostcoFieldsInput | null;
  costcoDiscount?: boolean | null;
  costcoLenderFee?: string | null;
  costcoMembershipId?: string | null;
  createdAt?: any | null;
  createdBy?: UserPointerInput | null;
  creditCardDel?: number | null;
  creditCardQty?: number | null;
  creditReportPulledDate?: any | null;
  currentCashOut?: number | null;
  currentCombDebtPayment?: number | null;
  currentInstallmentDebtBalance?: number | null;
  currentInterestRate?: number | null;
  currentLeadStatus?: string | null;
  currentLoanAmount?: number | null;
  currentMortgageBalance?: number | null;
  currentMortgageDel?: number | null;
  currentMortgageLender?: string | null;
  currentMortgageMonthlyPayment?: number | null;
  currentMortgagePayment?: number | null;
  currentMortgageQty?: number | null;
  currentMortgageRate?: number | null;
  currentNeedSituation?: string | null;
  currentRate?: number | null;
  currentRevolvingDebtBalance?: number | null;
  currentStageActionCount?: number | null;
  currentStatusActionCount?: number | null;
  currentTotalDebtBalance?: number | null;
  currentlyRentOrOwn?: string | null;
  customRank?: string | null;
  customizedMessageToBorrower?: string | null;
  dayOfTheWeek?: string | null;
  declaredBankruptcy?: string | null;
  denialDate?: any | null;
  desiredAPR?: number | null;
  desiredRate?: number | null;
  deviceTypeUsed?: string | null;
  dischargedBankruptcyMonth?: string | null;
  disclosure?: string | null;
  disclosureViewedDateBorrower?: any | null;
  disclosureViewedDateCoBorrower?: any | null;
  distribute?: boolean | null;
  distributedAt?: any | null;
  distributedBy?: DistributionQueuePointerInput | null;
  distributedByObjectId?: string | null;
  distributionRules?: (any | null)[] | null;
  dmFico?: number | null;
  dmLTV?: number | null;
  dmLoanTypeFlag?: string | null;
  dncFederal?: string | null;
  dncKnownLitigator?: string | null;
  dncState?: string | null;
  domain?: string | null;
  downPayment?: number | null;
  dti?: number | null;
  duplicateOf?: LeadPointerInput | null;
  duplicateOfObjectId?: string | null;
  duplicates?: LeadRelationInput | null;
  eSignSentDate?: any | null;
  emailVerification?: string | null;
  employerName?: string | null;
  employersAllBorrowers?: string | null;
  encompassApr?: string | null;
  encompassBorrowerMiddleFicoScore?: number | null;
  encompassClosingDisclosureReceivedDate?: any | null;
  encompassClosingDisclosureSentDate?: any | null;
  encompassCoBorrowerMiddleFicoScore?: number | null;
  encompassCreditReportReceivedDate?: any | null;
  encompassESignConsentDate?: any | null;
  encompassInitialTilDisclosureProvidedDate?: any | null;
  encompassInitialTilDisclosureReceivedDate?: any | null;
  encompassInterviewerEmail?: string | null;
  encompassLeadId?: string | null;
  encompassLoanNumber?: string | null;
  encompassLoanPurposeTypeUrla?: string | null;
  encompassLoanType?: string | null;
  encompassLtv?: number | null;
  encompassMilestoneCurrentDate?: any | null;
  encompassPropertyState?: string | null;
  encompassPropertyStreetAddress?: string | null;
  encompassPropertyUsageType?: string | null;
  encompassReferringBanker?: string | null;
  encompassRequestedInterestRate?: number | null;
  encompassResubmitted?: boolean | null;
  encompassTotalLoanAmount?: number | null;
  epoEndDate?: any | null;
  equityInHome?: number | null;
  establishedRelationship?: boolean | null;
  estimatedCloseDate?: any | null;
  estimatedCreditProfile?: string | null;
  eventAttended?: boolean | null;
  eventDate?: any | null;
  eventDuration?: string | null;
  eventManager?: boolean | null;
  exemptFromFundingFee?: boolean | null;
  existingHomeValue?: number | null;
  externalChannel?: string | null;
  externalLastUpdatedDate?: any | null;
  faBranch?: string | null;
  fanniePIW?: string | null;
  fccConsent?: string | null;
  fhaMtgBalance?: number | null;
  fico?: number | null;
  field1?: string | null;
  field2?: string | null;
  field3?: string | null;
  field4?: string | null;
  fileStartedDate?: any | null;
  fileStarterLosId?: string | null;
  fileStarterName?: string | null;
  filterId?: string | null;
  finalLoPrice?: string | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstARMRateResetTermInMonths?: number | null;
  firstAmortizationTermMonths?: number | null;
  firstApr?: number | null;
  firstAssignCostCenter?: string | null;
  firstAssignedTo?: UserPointerInput | null;
  firstAssignedToObjectId?: string | null;
  firstContact?: string | null;
  firstContactAction?: string | null;
  firstContactActionDate?: any | null;
  firstContactCostCenter?: string | null;
  firstContactDate?: any | null;
  firstContactLoEmail?: string | null;
  firstDiscountPoints?: number | null;
  firstDistributedAt?: any | null;
  firstDistributedBy?: DistributionQueuePointerInput | null;
  firstDocumentType?: string | null;
  firstDownPayment?: number | null;
  firstDownPaymentUnit?: string | null;
  firstExpirationDate?: any | null;
  firstInitialARMTermInMonths?: number | null;
  firstInterestOnly?: string | null;
  firstInterestOnlyPeriodInMonths?: number | null;
  firstInterestRate?: number | null;
  firstLienBalance?: number | null;
  firstLoanAmount?: number | null;
  firstLoanAmountEncompass?: number | null;
  firstLoanProgramCategory?: string | null;
  firstLoanProgramName?: string | null;
  firstLoanProgramProductType?: string | null;
  firstLoanTermMonths?: number | null;
  firstLockTerm?: string | null;
  firstMonthlyInsurancePayment?: number | null;
  firstMonthlyPaymentUnit?: string | null;
  firstMonthlyPayments?: number | null;
  firstMonthlyPrincipalAndInterestPayment?: number | null;
  firstMortgageInsurancePayment?: number | null;
  firstOfferId?: string | null;
  firstOfferType?: string | null;
  firstOfferVendorId?: string | null;
  firstOriginationFee?: number | null;
  firstOriginationFeeOption?: string | null;
  firstOriginationFeeUnit?: string | null;
  firstOtherLenderFees?: number | null;
  firstPaymentDate?: any | null;
  firstProductName?: string | null;
  firstRatePercent?: number | null;
  firstRateType?: string | null;
  firstSMSReceivedAt?: any | null;
  firstTerm?: number | null;
  firstTimeHomeBuyer?: boolean | null;
  firstTransactionType?: string | null;
  firstUrl?: string | null;
  firstUseOfVaProgram?: boolean | null;
  five9FirstAssignedEmail?: string | null;
  five9SpamAni?: string | null;
  frBranch?: string | null;
  freddiePIW?: string | null;
  fundedDate?: any | null;
  gclid?: string | null;
  gosId?: number | null;
  groupId?: string | null;
  haveRealtor?: boolean | null;
  helocPaidThisRefinance?: boolean | null;
  highMtgBalance?: number | null;
  homeBuyingProcess?: UpdateHomeBuyingProcessFieldsInput | null;
  homeBuyingProcessOfferPlans?: string | null;
  homeBuyingProcessStage?: string | null;
  homeEquityConversionInsight?: number | null;
  homeEquityLoanBalance?: number | null;
  incomeVerification?: boolean | null;
  individualOrJoinApplication?: string | null;
  individualOrJointApplication?: string | null;
  inquiryTimeOfDay?: string | null;
  intendedPropertyUse?: string | null;
  interestRatePercent?: number | null;
  introduction?: string | null;
  investor?: string | null;
  investorName?: string | null;
  ip?: string | null;
  ipGeo?: string | null;
  isBuyersAgent?: boolean | null;
  isSellingOrListingAgent?: boolean | null;
  juniorBankerEmail?: string | null;
  juniorBankerName?: string | null;
  keywords?: string | null;
  khlLoEmail?: string | null;
  kpm?: string | null;
  landingPageId?: string | null;
  languagePreference?: string | null;
  lastAction?: string | null;
  lastActionDate?: any | null;
  lastActionLoEmail?: string | null;
  lastActionTriggered?: string | null;
  lastCallMadeAt?: any | null;
  lastCallReceivedAt?: any | null;
  lastCompletedMilestone?: string | null;
  lastContactedAt?: any | null;
  lastEmailReceivedAt?: any | null;
  lastEmailSentAt?: any | null;
  lastFundedCLTV?: number | null;
  lastFundedCashOutAmount?: number | null;
  lastFundedCreditScore?: number | null;
  lastFundedDMILoanNumber?: string | null;
  lastFundedDate?: any | null;
  lastFundedDisbursementDate?: any | null;
  lastFundedEPODate?: any | null;
  lastFundedFirstPaymentDate?: any | null;
  lastFundedFundsReleased?: any | null;
  lastFundedInterestRate?: number | null;
  lastFundedInvestor?: string | null;
  lastFundedLOEmail?: string | null;
  lastFundedLTV?: number | null;
  lastFundedLeadSource?: string | null;
  lastFundedLoanAmount?: number | null;
  lastFundedLoanDelinquencyDate?: any | null;
  lastFundedLoanMaturesDate?: any | null;
  lastFundedLoanNumber?: string | null;
  lastFundedLoanOfficer?: string | null;
  lastFundedLoanProduct?: string | null;
  lastFundedLoanProgram?: string | null;
  lastFundedLoanPurpose?: string | null;
  lastFundedLoanTermInMonths?: number | null;
  lastFundedLoanTradeTotalBuyPrice?: number | null;
  lastFundedLoanType?: string | null;
  lastFundedMIPFundingAmount?: number | null;
  lastFundedMortgageInsuranceMonthlyPayment?: number | null;
  lastFundedNextPaymentDueDate?: any | null;
  lastFundedOccupancyType?: string | null;
  lastFundedOriginationDate?: any | null;
  lastFundedPI?: number | null;
  lastFundedPMIAmount?: number | null;
  lastFundedPropertyType?: string | null;
  lastFundedPropertyValue?: number | null;
  lastFundedPurchaseAdviceDate?: any | null;
  lastFundedPurchasePrice?: number | null;
  lastFundedRealtorNotes?: string | null;
  lastFundedSubjectPropertyAddress?: string | null;
  lastFundedSubjectPropertyCity?: string | null;
  lastFundedSubjectPropertyCounty?: string | null;
  lastFundedSubjectPropertyInsurance?: number | null;
  lastFundedSubjectPropertyState?: string | null;
  lastFundedSubjectPropertyTaxes?: number | null;
  lastFundedSubjectPropertyZip?: string | null;
  lastFundedTotalMonthlyPayment?: number | null;
  lastFundedWaiveEscrow?: boolean | null;
  lastIncomingContactedAt?: any | null;
  lastNetworkNoteDateTime?: any | null;
  lastNote?: string | null;
  lastNoteAddedAt?: any | null;
  lastOutgoingContactedAt?: any | null;
  lastSMSReceivedAt?: any | null;
  lastSMSSentAt?: any | null;
  leSentDate?: any | null;
  leadPricePaid?: number | null;
  leadProviderName?: string | null;
  leadReceivedBy?: string | null;
  leadSource?: LeadSourcePointerInput | null;
  leadSourceAppName?: string | null;
  leadSourceCampaign?: string | null;
  leadSourceId?: string | null;
  leadSourceMeta?: UpdateLeadSourceMetaFieldsInput | null;
  leadSourceObjectId?: string | null;
  leadSourceObjectName?: string | null;
  leadSourcePartner?: string | null;
  leadSourceTitle?: string | null;
  leadStatus?: StatusInput | null;
  leadStatusActionCount?: number | null;
  leadStatusUpdatedAt?: any | null;
  leadTransferDate?: any | null;
  leadTransferred?: boolean | null;
  leadTransferredBy?: string | null;
  leakage?: number | null;
  lenderId?: string | null;
  lenderName?: string | null;
  loPreSigned?: number | null;
  loaCashOutReason?: string | null;
  loaCreditOrAutoLoans?: boolean | null;
  loaElseOnBalances?: string | null;
  loaEmploymentStatus?: string | null;
  loaEstimatedAppraisedValue?: number | null;
  loaMonthlyPayments?: number | null;
  loaMortgageBalance?: number | null;
  loaOtherLienBalance?: number | null;
  loaPersonalLoans?: boolean | null;
  loaVaStatement?: string | null;
  loan?: any | null;
  loanAmortizationType?: string | null;
  loanAmount?: number | null;
  loanApplicationId?: string | null;
  loanOfficer?: string | null;
  loanProduct?: string | null;
  loanProgram?: string | null;
  loanProgramName?: string | null;
  loanProposals?: number | null;
  loanPurchasePrice?: number | null;
  loanPurpose?: string | null;
  loanRequestCompleteDate?: any | null;
  loanStartedSource?: string | null;
  loanStatus?: string | null;
  loanTeamMemberName?: string | null;
  loanTerm?: string | null;
  loanTermYears?: number | null;
  loanTypeNotes?: string | null;
  loanTypePreferred?: string | null;
  locationMovingTo?: string | null;
  lockDate?: any | null;
  lockExpiryDate?: any | null;
  lockInDays?: string | null;
  lockStatus?: boolean | null;
  ltv?: number | null;
  mLOComments?: string | null;
  mailerUrl?: string | null;
  managerName?: string | null;
  maritalStatus?: string | null;
  maritalStatusAndKids?: string | null;
  members?: (UpdateLeadMemberFieldsInput | null)[] | null;
  milestoneDateSubmittal?: any | null;
  militaryBranch?: string | null;
  militaryStatus?: string | null;
  mixRank?: number | null;
  mixTier?: number | null;
  mloEligibilityDate?: any | null;
  mloNextFollowUpDate?: any | null;
  mloStatusDate?: any | null;
  mloStatusGeneral?: string | null;
  mloStatusStl?: string | null;
  mobileDeviceIndicator?: string | null;
  monthlyReduction?: number | null;
  moomActionStatusDate?: any | null;
  moomActionStatusPurchase?: string | null;
  moomActionStatusRefi?: string | null;
  mortech?: UpdateMortechFieldsInput | null;
  mortgageCoordinatorEmail?: string | null;
  mutualBuyersBenefitAmount?: number | null;
  mutualBuyersBenefitProgramOffer?: string | null;
  mutualDate?: any | null;
  mutualFlag?: boolean | null;
  mutualGiveAway?: boolean | null;
  needListingAgentToSell?: boolean | null;
  needPurchaseRealtor?: boolean | null;
  needSellingRealtor?: boolean | null;
  networkNotes?: string | null;
  newOrExitingProperty?: string | null;
  newPayment?: number | null;
  nextReminderDate?: any | null;
  numberOfUnits?: number | null;
  objectIcon?: string | null;
  objectName?: string | null;
  okToSentToNetwork?: boolean | null;
  oldCRMId?: string | null;
  orderId?: string | null;
  original?: any | null;
  originalBalance?: number | null;
  originalLeadSource?: LeadSourcePointerInput | null;
  originalLoanNumber?: string | null;
  originalMortgageAmount?: number | null;
  originalOriginationDate?: any | null;
  originalTerm?: number | null;
  otherDel?: number | null;
  otherIncomeSource?: string | null;
  otherQty?: number | null;
  outOfNetworkOther?: string | null;
  outOfNetworkReason?: string | null;
  ownUpConcierge?: boolean | null;
  ownerFullName?: string | null;
  ownerNMLS?: string | null;
  ownerObjectId?: string | null;
  pageName?: string | null;
  pageUrl?: string | null;
  pageUuid?: string | null;
  partnerId?: number | null;
  perksEmployerName?: string | null;
  perksFilterId?: string | null;
  perksReferralSource?: string | null;
  phones?: (any | null)[] | null;
  portfolioRetentionLoanNumber?: string | null;
  portfolioRetentionSoldTo?: string | null;
  portfolioSoldTo?: any | null;
  portfolioSoldToDate?: any | null;
  postalCode?: string | null;
  preferredTimeToContact?: string | null;
  prequalInterest?: boolean | null;
  prequalSentDateInitial?: any | null;
  prequalSentLo?: string | null;
  prequalStatus?: string | null;
  presentCltv?: number | null;
  presentLtv?: number | null;
  previousAgent?: boolean | null;
  previousAgentYesNoNotes?: string | null;
  previousAssignedTo?: UserPointerInput | null;
  previousAssignedToObjectId?: string | null;
  previousAssigneeName?: string | null;
  previousDistributedAt?: any | null;
  previousDistributedBy?: DistributionQueuePointerInput | null;
  previousEPODate?: any | null;
  previousFirstPaymentDate?: any | null;
  previousLoName?: string | null;
  priceMaxApprovalAmount?: string | null;
  pricePaid?: number | null;
  pricingType?: string | null;
  primaryMortgageBalance?: number | null;
  priority?: UpdateLeadPriorityFieldsInput | null;
  prioritySequence?: number | null;
  privateMortgageInsuranceRequired?: boolean | null;
  proPair?: UpdateProPairFieldsInput | null;
  proPairCustomRank?: number | null;
  proPairInput?: string | null;
  proPairInputFa?: string | null;
  proPairRank?: number | null;
  proPairRecState?: string | null;
  processingFee?: number | null;
  processorNameCustom?: string | null;
  productionCenter?: string | null;
  profitability?: string | null;
  property?: UpdatePropertyFieldsInput | null;
  propertyAddress?: string | null;
  propertyCity?: string | null;
  propertyCounty?: string | null;
  propertyEstimatedValue?: number | null;
  propertyFound?: string | null;
  propertyLoanType?: string | null;
  propertyNeedsInfo?: string | null;
  propertyOccupancy?: string | null;
  propertyPurchaseYear?: number | null;
  propertyState?: string | null;
  propertyStreet?: string | null;
  propertyType?: string | null;
  propertyTypeDesired?: string | null;
  propertyUse?: string | null;
  propertyValue?: number | null;
  propertyWillBe?: string | null;
  propertyZip?: string | null;
  proposedCltv?: number | null;
  proposedLtv?: number | null;
  providerCreditScore?: number | null;
  pubId?: string | null;
  purchaseAdviceDate?: any | null;
  purchaseJourneyStage?: string | null;
  purchasePrice?: number | null;
  purposeOfLoan?: string | null;
  qualDate?: any | null;
  queId?: string | null;
  queuedCall?: string | null;
  quotedLoanProgram?: string | null;
  ratePlugContactDate?: any | null;
  ratePlugContactPreference?: string | null;
  ratePlugContactSubject?: string | null;
  ratePlugMatchDate?: any | null;
  ratePlugMatchStatus?: string | null;
  ratePreference?: string | null;
  rateTypeDesired?: string | null;
  reactivationDate?: any | null;
  realtagLink?: boolean | null;
  realtorLicenseNumber?: string | null;
  realtorOrigin?: string | null;
  reasonForMove?: string | null;
  recentHomeOwnerPolicy?: boolean | null;
  referenceSource?: string | null;
  referralAgentContactId?: string | null;
  referralAgentFirstName?: string | null;
  referralAgentLastName?: string | null;
  referralAgentMobilePhone?: any | null;
  referralAgentName?: string | null;
  referralAgentPersonalEmail?: any | null;
  referredTo?: string | null;
  referredToNetwork?: boolean | null;
  referredToNetworkDateTime?: any | null;
  referringCompanyName?: string | null;
  referringFirstName?: string | null;
  referringKWMarketCenter?: string | null;
  referringKWMarketCenterNumber?: string | null;
  referringKWRegion?: string | null;
  referringKWRegionName?: string | null;
  referringKWUID?: string | null;
  referringLastName?: string | null;
  referringMobile?: string | null;
  referringTeamEmail?: string | null;
  referringTeamId?: string | null;
  referringTeamName?: string | null;
  referringTeamPhone?: string | null;
  refinanceOrHEReason?: string | null;
  relcuLoan?: RelcuLoanPointerInput | null;
  relcuLoanObjectId?: string | null;
  requestedReverseMortgageAgent?: string | null;
  requestedReverseMortgageDate?: any | null;
  requestedSpanishSpeakerAgent?: string | null;
  requestedSpanishSpeakerDate?: any | null;
  resNum?: string | null;
  residentialAddress?: UpdateAddressFieldsInput | null;
  residentialAddressCity?: string | null;
  residentialAddressState?: string | null;
  residentialAddressStreet?: string | null;
  residentialAddressZip?: string | null;
  rvLoanId?: string | null;
  rvLoanNumber?: number | null;
  sbActExec?: string | null;
  sbActExecEmail?: string | null;
  sbAlertType?: string | null;
  sbBrokerLenderName?: string | null;
  sbBrokerLoEmail?: string | null;
  sbBrokerLoPhone?: string | null;
  sbCRMId?: string | null;
  sbCashOutAlertDateTime?: any | null;
  sbCashOutAlertMessage?: string | null;
  sbCreditAlertDateTime?: any | null;
  sbCreditAlertMessage?: string | null;
  sbCurrentCreditScore?: string | null;
  sbEpoAlertDateTime?: any | null;
  sbEpoAlertMessage?: string | null;
  sbEquityAlertDateTime?: any | null;
  sbEquityAlertMessage?: string | null;
  sbFicoTier?: string | null;
  sbInvestor?: string | null;
  sbLensAlertDateTime?: any | null;
  sbLensAlertMessage?: string | null;
  sbListingAlertDateTime?: any | null;
  sbListingAlertMessage?: string | null;
  sbLoanChannel?: string | null;
  sbLoanOfficerEmail?: string | null;
  sbLoanOfficerName?: string | null;
  sbMarketAlertDateTime?: any | null;
  sbMarketAlertMessage?: string | null;
  sbMiRemovalAlertDateTime?: any | null;
  sbMiRemovalAlertMessage?: string | null;
  sbNMLS?: string | null;
  sbOther1?: string | null;
  sbRateTermAlertDateTime?: any | null;
  sbRateTermAlertMessage?: string | null;
  sbRateWatchAlertDateTime?: any | null;
  sbRateWatchAlertMessage?: string | null;
  sbReverseAlertDateTime?: any | null;
  sbReverseAlertMessage?: string | null;
  sbRiskRetentionAlertDateTime?: any | null;
  sbRiskRetentionAlertMessage?: string | null;
  sbSegmentCode?: string | null;
  sbSentToDate?: any | null;
  scoreBucket?: string | null;
  secondARMRateResetTermInMonths?: number | null;
  secondAmortizationTermMonths?: number | null;
  secondApr?: number | null;
  secondDiscountPoints?: number | null;
  secondDocumentType?: string | null;
  secondDownPayment?: number | null;
  secondDownPaymentUnit?: string | null;
  secondExpirationDate?: any | null;
  secondInitialARMTermInMonths?: number | null;
  secondInterestOnly?: string | null;
  secondInterestOnlyPeriodInMonths?: number | null;
  secondInterestRate?: number | null;
  secondLoanAmount?: number | null;
  secondLoanProgramCategory?: string | null;
  secondLoanProgramName?: string | null;
  secondLoanProgramProductType?: string | null;
  secondLoanTermMonths?: number | null;
  secondLockTerm?: string | null;
  secondMonthlyInsurancePayment?: number | null;
  secondMonthlyPaymentUnit?: string | null;
  secondMonthlyPayments?: number | null;
  secondMonthlyPrincipalAndInterestPayment?: number | null;
  secondMortgageInsurancePayment?: number | null;
  secondOfferId?: string | null;
  secondOfferType?: string | null;
  secondOfferVendorId?: string | null;
  secondOriginationFee?: number | null;
  secondOriginationFeeOption?: string | null;
  secondOriginationFeeUnit?: string | null;
  secondOtherLenderFees?: number | null;
  secondProductName?: string | null;
  secondTerm?: number | null;
  secondTransactionType?: string | null;
  secondUrl?: string | null;
  secondaryFinancing?: string | null;
  sellerCreditDesired?: string | null;
  sellerRealtorAddressCity?: string | null;
  sellerRealtorAddressState?: string | null;
  sellerRealtorAddressStreet?: string | null;
  sellerRealtorAddressZip?: string | null;
  sellerRealtorFirstName?: string | null;
  sellerRealtorLastName?: string | null;
  sellerRealtorMobilePhone?: any | null;
  sellerRealtorName?: string | null;
  sellerRealtorPersonalEmail?: any | null;
  sentToNetwork?: boolean | null;
  sentToNetworkDateTime?: any | null;
  sequence?: number | null;
  serviceTicketType?: string | null;
  servicing?: string | null;
  servicingRetainedOrReleased?: string | null;
  sessionId?: string | null;
  showProof?: string | null;
  sourceKeyId?: number | null;
  stat?: UpdateLeadStatFieldsInput | null;
  stpDate?: any | null;
  studentLoanBal?: number | null;
  studentLoanDel?: number | null;
  studentLoanQty?: number | null;
  subId?: string | null;
  subjectAddress?: string | null;
  subjectCity?: string | null;
  subjectCounty?: string | null;
  subjectPropertyType?: string | null;
  subjectState?: string | null;
  subjectZip?: string | null;
  tags?: (any | null)[] | null;
  targetedRate?: number | null;
  tdEncompassDate?: any | null;
  teId?: string | null;
  teLoanStatus?: string | null;
  teToken?: string | null;
  team?: string | null;
  telesignPhoneLocationZip?: string | null;
  telesignPhoneTypeCode?: string | null;
  telesignPhoneTypeDescription?: string | null;
  termDisplayed?: number | null;
  thirdARMRateResetTermInMonths?: number | null;
  thirdAmortizationTermMonths?: number | null;
  thirdApr?: number | null;
  thirdDiscountPoints?: number | null;
  thirdDocumentType?: string | null;
  thirdDownPayment?: number | null;
  thirdDownPaymentUnit?: string | null;
  thirdExpirationDate?: any | null;
  thirdInitialARMTermInMonths?: number | null;
  thirdInterestOnly?: string | null;
  thirdInterestOnlyPeriodInMonths?: number | null;
  thirdInterestRate?: number | null;
  thirdLoanAmount?: number | null;
  thirdLoanProgramCategory?: string | null;
  thirdLoanProgramName?: string | null;
  thirdLoanProgramProductType?: string | null;
  thirdLoanTermMonths?: number | null;
  thirdLockTerm?: string | null;
  thirdMonthlyInsurancePayment?: number | null;
  thirdMonthlyPaymentUnit?: string | null;
  thirdMonthlyPayments?: number | null;
  thirdMonthlyPrincipalAndInterestPayment?: number | null;
  thirdMortgageInsurancePayment?: number | null;
  thirdOfferId?: string | null;
  thirdOfferType?: string | null;
  thirdOfferVendorId?: string | null;
  thirdOriginationFee?: number | null;
  thirdOriginationFeeOption?: string | null;
  thirdOriginationFeeUnit?: string | null;
  thirdOtherLenderFees?: number | null;
  thirdPartyTransfer?: string | null;
  thirdPartyTransferDate?: any | null;
  thirdProductName?: string | null;
  thirdTerm?: number | null;
  thirdTransactionType?: string | null;
  thirdUrl?: string | null;
  timeAtPreviousResidence?: number | null;
  timeAtResidence?: number | null;
  timeSubmitted?: string | null;
  timeframeForMove?: string | null;
  timezone?: string | null;
  totalDel?: number | null;
  totalMonthlyPayment?: number | null;
  transactionType?: string | null;
  transferAgentName?: string | null;
  transferDateTime?: any | null;
  transferUser?: string | null;
  triggerBsPostCheckbox?: boolean | null;
  typeOfVeteran?: string | null;
  underwritingFee?: number | null;
  universalLeadiD?: string | null;
  unixTimestamp?: any | null;
  unsecuredDebtAmount?: number | null;
  updatedAt?: any | null;
  updatedUniversalLeadId?: string | null;
  userName?: string | null;
  usoDonationAmount?: number | null;
  usoDonationSent?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
  utmTerm?: string | null;
  uuid?: string | null;
  vaDischarge?: string | null;
  vaMtgBalance?: number | null;
  variant?: string | null;
  vendorId?: string | null;
  view?: string | null;
  waiveEscrow?: boolean | null;
  webinarAttended?: string | null;
  webinarDate?: any | null;
  webinarTime?: string | null;
  wfaJobs?: (any | null)[] | null;
  workedWithBBMC?: string | null;
  workflows?: (UpdateWorkflowStatusFieldsInput | null)[] | null;
  workingWith?: string | null;
  workingWithBanker?: boolean | null;
  workingWithBankerName?: string | null;
  wpm?: string | null;
  yearBuilt?: string | null;
  yearsInProperty?: number | null;
  zillowCompanyRelationShip?: string | null;
  zillowQuoteId?: string | null;
  zillowRequestId?: string | null;
}

/**
 * The UpdateLeadMemberFieldsInput input type is used in operations that involve creation of objects in the LeadMember class.
 */
export interface UpdateLeadMemberFieldsInput {
  annualIncome?: number | null;
  bankruptcy?: boolean | null;
  contact?: ContactPointerInput | null;
  creditRating?: string | null;
  creditScore?: number | null;
  employmentOccupation?: string | null;
  employmentStatus?: string | null;
  foreclosure?: string | null;
  isMilitary?: boolean | null;
  isPrimary?: boolean | null;
  jobStartedAt?: any | null;
  maritalStatus?: string | null;
  monthlyIncome?: number | null;
  type?: string | null;
  typeElaborate?: string | null;
  veteranStatus?: string | null;
}

/**
 * The UpdateLeadPriorityFieldsInput input type is used in operations that involve creation of objects in the LeadPriority class.
 */
export interface UpdateLeadPriorityFieldsInput {
  enteredAt?: any | null;
  leadPosition?: number | null;
  queue?: PriorityQueuePointerInput | null;
  queuePosition?: number | null;
}

/**
 * The UpdateLeadSourceMetaFieldsInput input type is used in operations that involve creation of objects in the LeadSourceMeta class.
 */
export interface UpdateLeadSourceMetaFieldsInput {
  bankingBridgeDashboardLink?: string | null;
  bankingBridgeLastEvent?: string | null;
  bankingBridgeLastEventDate?: any | null;
  bankingBridgeSubId?: string | null;
  bankingBridgeSubscriptionId?: string | null;
  landingPageUrl?: string | null;
}

/**
 * The UpdateLeadStatFieldsInput input type is used in operations that involve creation of objects in the LeadStat class.
 */
export interface UpdateLeadStatFieldsInput {
  missedCallsCount?: number | null;
  unreadMessagesCount?: number | null;
}

/**
 * The UpdateLoanCriteriaFieldsInput input type is used in operations that involve creation of objects in the LoanCriteria class.
 */
export interface UpdateLoanCriteriaFieldsInput {
  cashOut?: number | null;
  cltv?: number | null;
  downPayment?: number | null;
  dti?: number | null;
  fico?: (any | null)[] | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstTimeHomeBuyer?: boolean | null;
  firstUseOfVaProgram?: boolean | null;
  isPmiEligible?: boolean | null;
  loanAmount?: number | null;
  loanPurpose?: string | null;
  ltv?: number | null;
  mortech?: UpdateOfferMortechFieldsInput | null;
  property?: UpdateOfferPropertyFieldsInput | null;
  requiresPestInspection?: boolean | null;
  secondaryFinancing?: string | null;
  totalLoanAmount?: number | null;
  veteranStatus?: string | null;
  waiveEscrow?: boolean | null;
}

/**
 * The UpdateLoanEstimateOfferFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOffer class.
 */
export interface UpdateLoanEstimateOfferFieldsInput {
  ACL?: ACLInput | null;
  adminFee?: number | null;
  amiWaiverEligibility?: boolean | null;
  amortizationType?: string | null;
  appraisalFee?: number | null;
  apr?: number | null;
  attorneyFee?: number | null;
  cashAmount?: number | null;
  cashToClose?: number | null;
  closingCostTotal?: number | null;
  cltv?: number | null;
  condoCertificateFee?: number | null;
  conforming?: boolean | null;
  createdAt?: any | null;
  creditReportFee?: number | null;
  creditReportTotal?: number | null;
  currentMortgageBalance?: number | null;
  deleted?: boolean | null;
  discountPoints?: number | null;
  docReviewFee?: number | null;
  documentType?: string | null;
  downPayment?: number | null;
  dti?: number | null;
  earnestMoneyPaid?: number | null;
  electronicDocumentDelivery?: number | null;
  exempt?: boolean | null;
  ff?: number | null;
  fico?: number | null;
  fico1?: number | null;
  financeFf?: boolean | null;
  financeMip?: boolean | null;
  firstTimeHomeBuyer?: boolean | null;
  firstTimeUse?: boolean | null;
  firstUseOfVaProgram?: boolean | null;
  floodCertificationFee?: number | null;
  initialArmTerm?: string | null;
  insurance?: number | null;
  isHUDReo?: boolean | null;
  isStreamLine?: boolean | null;
  lead?: LeadPointerInput | null;
  lenderCredits?: number | null;
  lendersTitle?: number | null;
  lienAmount?: number | null;
  loanAmount?: number | null;
  loanEstimate?: LoanEstimatePointerInput | null;
  loanProduct?: string | null;
  loanProgram?: string | null;
  loanSafeFee?: number | null;
  loanTerm?: string | null;
  lockInDays?: string | null;
  lockInFee?: number | null;
  ltv?: number | null;
  mersFee?: number | null;
  mip?: number | null;
  monthlyIncome?: number | null;
  monthlyPaymentTotal?: number | null;
  monthlyPremium?: number | null;
  mortech?: UpdateLoanEstimateOfferMortechFieldsInput | null;
  objectName?: string | null;
  optimalBlue?: UpdateLoanEstimateOptimalBlueFieldsInput | null;
  originationFee?: number | null;
  otherCostTotal?: number | null;
  ownersTitle?: number | null;
  pestInspectionFee?: number | null;
  pi?: number | null;
  pmi?: number | null;
  pmiCompany?: string | null;
  pmiEligible?: boolean | null;
  pmiType?: string | null;
  points?: number | null;
  polly?: UpdateLoanEstimateOfferPollyFieldsInput | null;
  prepaidCostTotal?: number | null;
  prepaidInterestAmount?: number | null;
  prepaidInterestDays?: number | null;
  prepaidInterestTotal?: number | null;
  price?: number | null;
  pricingEngine?: string | null;
  productType?: string | null;
  propertyAnnualInsurance?: number | null;
  propertyAnnualTax?: number | null;
  propertyInsurancePrepaidAmount?: number | null;
  propertyInsurancePrepaidMonth?: number | null;
  propertyInsurancePrepaidTotal?: number | null;
  propertyInsuranceYearly?: number | null;
  propertyTaxAmount?: number | null;
  propertyTaxMonth?: number | null;
  propertyTaxTotal?: number | null;
  propertyType?: string | null;
  propertyUse?: string | null;
  propertyValue?: number | null;
  purchasePrice?: number | null;
  rate?: number | null;
  rateId?: string | null;
  rateInvestor?: string | null;
  rateUpdated?: string | null;
  recordingCharges?: number | null;
  secondaryFinancing?: string | null;
  selfEmployed?: boolean | null;
  sellerConcession?: number | null;
  settlementFee?: number | null;
  subordinationFee?: number | null;
  surveyFee?: number | null;
  taxes?: number | null;
  titleCompany?: string | null;
  titleCompanyName?: string | null;
  titleFeeTotal?: number | null;
  titleInsurance?: number | null;
  totalLoanAmount?: number | null;
  transcriptFee?: number | null;
  transferTax?: number | null;
  updatedAt?: any | null;
  verificationFee?: number | null;
  veteranStatus?: string | null;
  waive?: boolean | null;
  waiveEscrow?: boolean | null;
  withAppraisal?: boolean | null;
  withCredit?: boolean | null;
}

export interface UpdateLoanEstimateOfferInput {
  clientMutationId?: string | null;
  fields?: UpdateLoanEstimateOfferFieldsInput | null;
  id: string;
}

/**
 * The UpdateLoanEstimateOfferMortechFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOfferMortech class.
 */
export interface UpdateLoanEstimateOfferMortechFieldsInput {
  deliveryType?: string | null;
  investor?: (any | null)[] | null;
  loanProductId?: string | null;
  loanProductName?: string | null;
  source?: string | null;
  view?: string | null;
}

/**
 * The UpdateLoanEstimateOfferPollyFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOfferPolly class.
 */
export interface UpdateLoanEstimateOfferPollyFieldsInput {
  automatedUnderwritingSystem?: string | null;
  debtServiceCoverageRatio?: number | null;
  monthsReserves?: number | null;
  prepaymentPenalty?: string | null;
}

/**
 * The UpdateLoanEstimateOptimalBlueFieldsInput input type is used in operations that involve creation of objects in the LoanEstimateOptimalBlue class.
 */
export interface UpdateLoanEstimateOptimalBlueFieldsInput {
  automatedUnderwritingSystem?: string | null;
  debtServiceCoverageRatio?: number | null;
  leadSourceMoom?: string | null;
  monthsReserves?: number | null;
  prepaymentPenalty?: string | null;
  productTypes?: any | null;
  searchID?: string | null;
  source?: string | null;
  view?: string | null;
  zeroPlus?: string | null;
}

/**
 * The UpdateLoanProposalSettingsFieldsInput input type is used in operations that involve creation of objects in the LoanProposalSettings class.
 */
export interface UpdateLoanProposalSettingsFieldsInput {
  template?: FilePointerInput | null;
  templateId?: string | null;
}

/**
 * The UpdateLocalPresenceCodeMappingsFieldsInput input type is used in operations that involve creation of objects in the LocalPresenceCodeMappings class.
 */
export interface UpdateLocalPresenceCodeMappingsFieldsInput {
  codes?: (any | null)[] | null;
  lines?: (UpdateLocalPresenceLineFieldsInput | null)[] | null;
}

/**
 * The UpdateLocalPresenceLineFieldsInput input type is used in operations that involve creation of objects in the LocalPresenceLine class.
 */
export interface UpdateLocalPresenceLineFieldsInput {
  phoneLine?: PhoneLinePointerInput | null;
}

/**
 * The UpdateLocalPresenceSettingsFieldsInput input type is used in operations that involve creation of objects in the LocalPresenceSettings class.
 */
export interface UpdateLocalPresenceSettingsFieldsInput {
  defaultPhoneLine?: PhoneLinePointerInput | null;
  enabled?: boolean | null;
  mappings?: (UpdateLocalPresenceCodeMappingsFieldsInput | null)[] | null;
}

/**
 * The UpdateMailConversationFieldsInput input type is used in operations that involve creation of objects in the MailConversation class.
 */
export interface UpdateMailConversationFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  parties?: (UpdateMailParticipantFieldsInput | null)[] | null;
  scope?: MailConversationScopePointerInput | null;
  updatedAt?: any | null;
}

/**
 * The UpdateMailDnsFieldsInput input type is used in operations that involve creation of objects in the MailDns class.
 */
export interface UpdateMailDnsFieldsInput {
  data?: string | null;
  host?: string | null;
  name?: string | null;
  type?: string | null;
  valid?: boolean | null;
}

/**
 * The UpdateMailDomainFieldsInput input type is used in operations that involve creation of objects in the MailDomain class.
 */
export interface UpdateMailDomainFieldsInput {
  ACL?: ACLInput | null;
  aliases?: (any | null)[] | null;
  apiKey?: string | null;
  createdAt?: any | null;
  disabled?: boolean | null;
  dns?: (UpdateMailDnsFieldsInput | null)[] | null;
  domain?: string | null;
  hookCreated?: boolean | null;
  objectName?: string | null;
  provider?: UpdateMailProviderFieldsInput | null;
  sendGridId?: string | null;
  updatedAt?: any | null;
  useForOut?: boolean | null;
  valid?: boolean | null;
}

/**
 * The UpdateMailImapFieldsInput input type is used in operations that involve creation of objects in the MailImap class.
 */
export interface UpdateMailImapFieldsInput {
  host?: string | null;
  port?: number | null;
  service?: string | null;
  tls?: any | null;
}

/**
 * The UpdateMailMetadataFieldsInput input type is used in operations that involve creation of objects in the MailMetadata class.
 */
export interface UpdateMailMetadataFieldsInput {
  draftIds?: (any | null)[] | null;
  flags?: (any | null)[] | null;
  indexes?: any | null;
  isDraft?: boolean | null;
  label?: string | null;
  labels?: (any | null)[] | null;
  msgId?: string | null;
  provider?: string | null;
  thrId?: string | null;
  uid?: number | null;
}

/**
 * The UpdateMailParticipantFieldsInput input type is used in operations that involve creation of objects in the MailParticipant class.
 */
export interface UpdateMailParticipantFieldsInput {
  address?: string | null;
  deleted?: boolean | null;
  metadata?: UpdateMailMetadataFieldsInput | null;
  notified?: boolean | null;
  party?: MailParticipantPartyPointerInput | null;
  type?: string | null;
  unread?: boolean | null;
}

/**
 * The UpdateMailProviderFieldsInput input type is used in operations that involve creation of objects in the MailProvider class.
 */
export interface UpdateMailProviderFieldsInput {
  clientId?: string | null;
  clientSecret?: string | null;
  imap?: UpdateMailImapFieldsInput | null;
  returnUrl?: string | null;
  smtp?: UpdateMailSmtpFieldsInput | null;
  sync?: string | null;
  tenantId?: string | null;
  type?: string | null;
}

/**
 * The UpdateMailSmtpFieldsInput input type is used in operations that involve creation of objects in the MailSmtp class.
 */
export interface UpdateMailSmtpFieldsInput {
  host?: string | null;
  port?: number | null;
  secure?: boolean | null;
  service?: string | null;
  tls?: any | null;
}

/**
 * The UpdateMetabaseSettingsFieldsInput input type is used in operations that involve creation of objects in the MetabaseSettings class.
 */
export interface UpdateMetabaseSettingsFieldsInput {
  baseUrl?: string | null;
  defaultUser?: any | null;
  sharedSecret?: string | null;
}

/**
 * The UpdateMonthlyPaymentFieldsInput input type is used in operations that involve creation of objects in the MonthlyPayment class.
 */
export interface UpdateMonthlyPaymentFieldsInput {
  insurance?: number | null;
  mi?: number | null;
  pi?: number | null;
  pmi?: number | null;
  tax?: number | null;
  total?: number | null;
}

/**
 * The UpdateMortechFieldsInput input type is used in operations that involve creation of objects in the Mortech class.
 */
export interface UpdateMortechFieldsInput {
  amiLlpaWaiver?: boolean | null;
  deliveryType?: string | null;
  hudReo?: boolean | null;
  investorIds?: (any | null)[] | null;
  lockInDays?: string | null;
  product?: string | null;
  productName?: string | null;
  source?: string | null;
  streamLine?: boolean | null;
  streamLineWithAppraisal?: boolean | null;
  streamLineWithCredit?: boolean | null;
  view?: string | null;
}

/**
 * The UpdateNotificationControlsFieldsInput input type is used in operations that involve creation of objects in the NotificationControls class.
 */
export interface UpdateNotificationControlsFieldsInput {
  bulkEmail?: boolean | null;
  bulkSMS?: boolean | null;
  distributedLead?: boolean | null;
  emails?: boolean | null;
  leadAssignments?: boolean | null;
  loanComments?: boolean | null;
  missedCalls?: boolean | null;
  sms?: boolean | null;
  tasksReminders?: boolean | null;
}

/**
 * The UpdateOfferFieldsInput input type is used in operations that involve creation of objects in the Offer class.
 */
export interface UpdateOfferFieldsInput {
  ACL?: ACLInput | null;
  closingCost?: UpdateClosingCostFieldsInput | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  lead?: LeadPointerInput | null;
  lenderCredits?: number | null;
  loanCriteria?: UpdateLoanCriteriaFieldsInput | null;
  loanProposal?: LoanProposalPointerInput | null;
  monthlyPayment?: UpdateMonthlyPaymentFieldsInput | null;
  objectName?: string | null;
  prepaid?: UpdatePrepaidFieldsInput | null;
  selectedRate?: UpdateSelectedRateFieldsInput | null;
  totalClosingCost?: number | null;
  updatedAt?: any | null;
}

export interface UpdateOfferInput {
  clientMutationId?: string | null;
  fields?: UpdateOfferFieldsInput | null;
  id: string;
}

/**
 * The UpdateOfferMortechFieldsInput input type is used in operations that involve creation of objects in the OfferMortech class.
 */
export interface UpdateOfferMortechFieldsInput {
  amiLlpaWaiver?: boolean | null;
  hudReo?: boolean | null;
  investorIds?: (any | null)[] | null;
  lockInDays?: string | null;
  product?: string | null;
  productName?: string | null;
  source?: string | null;
  streamLine?: boolean | null;
  streamLineWithAppraisal?: boolean | null;
  streamLineWithCredit?: boolean | null;
  view?: string | null;
}

/**
 * The UpdateOfferPropertyAddressFieldsInput input type is used in operations that involve creation of objects in the OfferPropertyAddress class.
 */
export interface UpdateOfferPropertyAddressFieldsInput {
  propertyCounty?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

/**
 * The UpdateOfferPropertyFieldsInput input type is used in operations that involve creation of objects in the OfferProperty class.
 */
export interface UpdateOfferPropertyFieldsInput {
  currentMortgageBalance?: number | null;
  occupancy?: string | null;
  propertyAddress?: UpdateOfferPropertyAddressFieldsInput | null;
  type?: string | null;
  value?: number | null;
}

/**
 * The UpdateOptimalBlueUserConfigFieldsInput input type is used in operations that involve creation of objects in the OptimalBlueUserConfig class.
 */
export interface UpdateOptimalBlueUserConfigFieldsInput {
  businessChannelId?: number | null;
  originatorId?: number | null;
}

/**
 * The UpdateOriginationFeeFieldsInput input type is used in operations that involve creation of objects in the OriginationFee class.
 */
export interface UpdateOriginationFeeFieldsInput {
  default?: number | null;
  editable?: boolean | null;
}

/**
 * The UpdatePasswordPolicySettingsFieldsInput input type is used in operations that involve creation of objects in the PasswordPolicySettings class.
 */
export interface UpdatePasswordPolicySettingsFieldsInput {
  containNumbers?: boolean | null;
  containSpecialCharacters?: boolean | null;
  includeUpAndLoCase?: boolean | null;
  length?: number | null;
  maxPasswordHistory?: number | null;
}

/**
 * The UpdatePhoneMessageFieldsInput input type is used in operations that involve creation of objects in the PhoneMessage class.
 */
export interface UpdatePhoneMessageFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  scope?: PhoneMessageScopePointerInput | null;
  updatedAt?: any | null;
}

/**
 * The UpdatePhoneMessageTemplateFieldsInput input type is used in operations that involve creation of objects in the PhoneMessageTemplate class.
 */
export interface UpdatePhoneMessageTemplateFieldsInput {
  ACL?: ACLInput | null;
  applyTo?: (any | null)[] | null;
  availableFor?: (any | null)[] | null;
  createdAt?: any | null;
  disabled?: boolean | null;
  enabled?: boolean | null;
  forAutomation?: boolean | null;
  loanProposal?: boolean | null;
  objectName?: string | null;
  owner?: UserPointerInput | null;
  text?: string | null;
  title?: string | null;
  type?: string | null;
  updatedAt?: any | null;
}

export interface UpdatePhoneMessageTemplateInput {
  clientMutationId?: string | null;
  fields?: UpdatePhoneMessageTemplateFieldsInput | null;
  id: string;
}

/**
 * The UpdatePhoneNumberFieldsInput input type is used in operations that involve creation of objects in the PhoneNumber class.
 */
export interface UpdatePhoneNumberFieldsInput {
  callOptOut?: boolean | null;
  carrier?: any | null;
  default?: boolean | null;
  isPrimary?: boolean | null;
  nationalFormat?: string | null;
  number?: any | null;
  objectName?: string | null;
  optOut?: boolean | null;
  sms?: boolean | null;
  smsCountryCode?: string | null;
  smsOptOut?: boolean | null;
  type?: string | null;
}

/**
 * The UpdatePrepaidFieldsInput input type is used in operations that involve creation of objects in the Prepaid class.
 */
export interface UpdatePrepaidFieldsInput {
  dailyInterest?: number | null;
  daysToPayInterest?: number | null;
  insurance?: number | null;
  interest?: number | null;
  lockInFee?: number | null;
  monthlyInsurance?: number | null;
  monthlyTax?: number | null;
  monthsToPayInsurance?: number | null;
  monthsToPayTax?: number | null;
  tax?: number | null;
  total?: number | null;
  yearlyInsurance?: number | null;
}

/**
 * The UpdatePreviewSettingsFieldsInput input type is used in operations that involve creation of objects in the PreviewSettings class.
 */
export interface UpdatePreviewSettingsFieldsInput {
  mailbox?: MailboxPointerInput | null;
  scope?: PreviewSettingsScopePointerInput | null;
  user?: UserPointerInput | null;
}

/**
 * The UpdatePricingFieldsInput input type is used in operations that involve creation of objects in the Pricing class.
 */
export interface UpdatePricingFieldsInput {
  averageLoanLimit?: number | null;
  closingFees?: UpdateClosingFeesFieldsInput | null;
  conventional?: UpdateConventionalFieldsInput | null;
  fha?: UpdateFhaFieldsInput | null;
  loanProposal?: UpdateLoanProposalSettingsFieldsInput | null;
  pmiEditable?: boolean | null;
  pmiProducts?: any | null;
  pmiRateProEnabled?: boolean | null;
  titleFeeMapping?: UpdateTitleFeeMappingFieldsInput | null;
  va?: UpdateVaFieldsInput | null;
}

/**
 * The UpdatePriorityQueueFieldsInput input type is used in operations that involve creation of objects in the PriorityQueue class.
 */
export interface UpdatePriorityQueueFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  enabled?: boolean | null;
  expandable?: boolean | null;
  fields?: (UpdateTableViewFieldFieldsInput | null)[] | null;
  leadCount?: number | null;
  leadFilter?: any | null;
  leadSort?: (any | null)[] | null;
  objectName?: string | null;
  priority?: number | null;
  priorityView?: PriorityViewPointerInput | null;
  title?: string | null;
  updatedAt?: any | null;
}

/**
 * The UpdatePriorityViewFieldsInput input type is used in operations that involve creation of objects in the PriorityView class.
 */
export interface UpdatePriorityViewFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  createdBy?: UserPointerInput | null;
  objectName?: string | null;
  priority?: number | null;
  priorityQueues?: PriorityQueueRelationInput | null;
  rPerm?: (any | null)[] | null;
  title?: string | null;
  updatedAt?: any | null;
}

/**
 * The UpdateProPairFieldsInput input type is used in operations that involve creation of objects in the ProPair class.
 */
export interface UpdateProPairFieldsInput {
  excludeIds?: string | null;
  rank?: number | null;
}

/**
 * The UpdateProPairSettingsFieldsInput input type is used in operations that involve creation of objects in the ProPairSettings class.
 */
export interface UpdateProPairSettingsFieldsInput {
  distribution?: boolean | null;
  enabled?: boolean | null;
  token?: string | null;
  url?: string | null;
}

/**
 * The UpdatePropertyFieldsInput input type is used in operations that involve creation of objects in the Property class.
 */
export interface UpdatePropertyFieldsInput {
  annualInsurance?: number | null;
  annualTaxes?: number | null;
  appraisedValue?: number | null;
  approvedVACondo?: boolean | null;
  currentMortgageBalance?: number | null;
  currentMortgageMonthlyPayment?: number | null;
  currentMortgageRate?: number | null;
  datePurchased?: any | null;
  dues?: number | null;
  estimatedValue?: number | null;
  firstLienBalance?: number | null;
  heloc?: boolean | null;
  monthlyHoa?: boolean | null;
  numberOfUnits?: string | null;
  occupancy?: string | null;
  pricePaidForHome?: number | null;
  propertyAddress?: UpdateAddressFieldsInput | null;
  secondLienBalance?: number | null;
  solarPanels?: boolean | null;
  type?: string | null;
  use?: string | null;
  value?: number | null;
  yearBuilt?: number | null;
}

/**
 * The UpdateQuotesFieldsInput input type is used in operations that involve creation of objects in the Quotes class.
 */
export interface UpdateQuotesFieldsInput {
  apr?: number | null;
  loanAmount?: number | null;
  monthlyPremium?: number | null;
  piti?: number | null;
  price?: number | null;
  rate?: number | null;
  srp?: number | null;
}

/**
 * The UpdateSelectedRateFieldsInput input type is used in operations that involve creation of objects in the SelectedRate class.
 */
export interface UpdateSelectedRateFieldsInput {
  lastUpdate?: any | null;
  pricingStatus?: string | null;
  productDesc?: string | null;
  productName?: string | null;
  productTerm?: string | null;
  quoteDetails?: UpdateQuotesFieldsInput | null;
  vendorName?: string | null;
}

/**
 * The UpdateSettingsFieldsInput input type is used in operations that involve creation of objects in the Settings class.
 */
export interface UpdateSettingsFieldsInput {
  ACL?: ACLInput | null;
  accountLockout?: UpdateAccountLockoutSettingsFieldsInput | null;
  callableEndHour?: string | null;
  callableSaturdayEndHour?: string | null;
  callableSaturdayStartHour?: string | null;
  callableStartHour?: string | null;
  callableStatesHours?: (UpdateCallableStatesHoursSettingsFieldsInput | null)[] | null;
  callableSundayEndHour?: string | null;
  callableSundayStartHour?: string | null;
  company?: UpdateCompanyFieldsInput | null;
  createdAt?: any | null;
  decouplePricingPage?: boolean | null;
  defaultVoiceMail?: string | null;
  emailSignature?: (UpdateEmailSignatureSettingsFieldsInput | null)[] | null;
  enableCallTransfer?: boolean | null;
  excludeProductCategory?: (any | null)[] | null;
  facebook?: string | null;
  holdMediaUrl?: string | null;
  instagram?: string | null;
  layoutUpdatedAt?: any | null;
  linkedin?: string | null;
  localPresence?: UpdateLocalPresenceSettingsFieldsInput | null;
  logo?: string | null;
  metabase?: UpdateMetabaseSettingsFieldsInput | null;
  nextDistributionLimit?: number | null;
  nextDistributionParallelization?: number | null;
  nextDistributionTick?: number | null;
  nextDistributionTimeOut?: number | null;
  passwordPolicy?: UpdatePasswordPolicySettingsFieldsInput | null;
  pricing?: UpdatePricingFieldsInput | null;
  proPair?: UpdateProPairSettingsFieldsInput | null;
  recordingDownloadPermission?: any | null;
  recordingEnabled?: boolean | null;
  recordingState?: any | null;
  schemaUpdatedAt?: any | null;
  testLeadAssignee?: UserPointerInput | null;
  testLeadMatching?: string | null;
  transcriptionEnabled?: boolean | null;
  updateManyStreamDelay?: number | null;
  updatedAt?: any | null;
  x?: string | null;
}

export interface UpdateSystemSettings {
  fields?: UpdateSettingsFieldsInput | null;
  id?: string | null;
}

/**
 * The UpdateTableViewFieldFieldsInput input type is used in operations that involve creation of objects in the TableViewField class.
 */
export interface UpdateTableViewFieldFieldsInput {
  fixed?: string | null;
  key?: string | null;
  properties?: any | null;
  sort?: string | null;
  width?: number | null;
}

/**
 * The UpdateTableViewFieldsInput input type is used in operations that involve creation of objects in the TableView class.
 */
export interface UpdateTableViewFieldsInput {
  ACL?: ACLInput | null;
  createdAt?: any | null;
  createdBy?: UserPointerInput | null;
  fields?: (UpdateTableViewFieldFieldsInput | null)[] | null;
  filter?: any | null;
  group?: string | null;
  name?: string | null;
  rPerm?: (any | null)[] | null;
  updatedAt?: any | null;
}

export interface UpdateTableViewInput {
  clientMutationId?: string | null;
  fields?: UpdateTableViewFieldsInput | null;
  id: string;
}

/**
 * The UpdateTitleFeeFieldMappingFieldsInput input type is used in operations that involve creation of objects in the TitleFeeFieldMapping class.
 */
export interface UpdateTitleFeeFieldMappingFieldsInput {
  hudLines?: (any | null)[] | null;
  mismoTypes?: (any | null)[] | null;
}

/**
 * The UpdateTitleFeeFieldsMappingFieldsInput input type is used in operations that involve creation of objects in the TitleFeeFieldsMapping class.
 */
export interface UpdateTitleFeeFieldsMappingFieldsInput {
  lendersTitle?: UpdateTitleFeeFieldMappingFieldsInput | null;
  ownersTitle?: UpdateTitleFeeFieldMappingFieldsInput | null;
  recordingCharges?: UpdateTitleFeeFieldMappingFieldsInput | null;
  settlementFee?: UpdateTitleFeeFieldMappingFieldsInput | null;
  titleInsurance?: UpdateTitleFeeFieldMappingFieldsInput | null;
  transferTax?: UpdateTitleFeeFieldMappingFieldsInput | null;
}

/**
 * The UpdateTitleFeeMappingFieldsInput input type is used in operations that involve creation of objects in the TitleFeeMapping class.
 */
export interface UpdateTitleFeeMappingFieldsInput {
  default?: string | null;
  fields?: UpdateTitleFeeFieldsMappingFieldsInput | null;
}

/**
 * The UpdateTitleFieldsInput input type is used in operations that involve creation of objects in the Title class.
 */
export interface UpdateTitleFieldsInput {
  insurance?: number | null;
  lendersTitle?: number | null;
  ownersTitle?: number | null;
  recordingCharges?: number | null;
  settlementFee?: number | null;
  total?: number | null;
  transferTax?: number | null;
}

/**
 * The UpdateUserFieldsInput input type is used in operations that involve creation of objects in the User class.
 */
export interface UpdateUserFieldsInput {
  ACL?: ACLInput | null;
  accountLockoutExpiresAt?: any | null;
  applyUrl?: string | null;
  bookmarks?: (any | null)[] | null;
  bulkPermissions?: UpdateBulkMessagingPermissionFieldsInput | null;
  calendar?: string | null;
  createdAt?: any | null;
  custom1?: string | null;
  custom10?: string | null;
  custom2?: string | null;
  custom3?: string | null;
  custom4?: string | null;
  custom5?: string | null;
  custom6?: string | null;
  custom7?: string | null;
  custom8?: string | null;
  custom9?: string | null;
  dataTableView?: UpdateDataTablePermissionFieldsInput | null;
  deactivated?: boolean | null;
  deactivatedAt?: any | null;
  defaultPhoneLine?: PhoneLinePointerInput | null;
  devices?: DeviceRelationInput | null;
  email?: string | null;
  emailTemplateEditorPermissions?: UpdateEmailTemplateEditorPermissionFieldsInput | null;
  failedLoginAttempts?: number | null;
  firstName?: string | null;
  fiveNineId?: string | null;
  flyerPermissions?: UpdateFlyerPermissionFieldsInput | null;
  history?: (any | null)[] | null;
  identities?: (UpdateIdentityFieldsInput | null)[] | null;
  lastName?: string | null;
  lastNotificationSeenAt?: any | null;
  licensedStates?: any | null;
  localPresence?: boolean | null;
  losId?: string | null;
  nmlsId?: string | null;
  notificationControls?: UpdateNotificationControlsFieldsInput | null;
  objectIcon?: string | null;
  objectName?: string | null;
  onCall?: boolean | null;
  online?: boolean | null;
  optimalBlue?: UpdateOptimalBlueUserConfigFieldsInput | null;
  password?: string | null;
  passwordChangedAt?: any | null;
  passwordHistory?: (any | null)[] | null;
  phone?: string | null;
  phoneLines?: PhoneLineRelationInput | null;
  positionName?: string | null;
  reviewUrl?: string | null;
  role?: string | null;
  sessions?: SessionRelationInput | null;
  status?: string | null;
  tags?: (any | null)[] | null;
  team?: string | null;
  updatedAt?: any | null;
  username?: string | null;
  voiceMail?: RecordingPointerInput | null;
  voicemailOutOfWork?: RecordingPointerInput | null;
  voicemailUrl?: string | null;
  wfaJobs?: (any | null)[] | null;
}

export interface UpdateUserInput {
  clientMutationId?: string | null;
  fields?: UpdateUserFieldsInput | null;
  id: string;
}

/**
 * The UpdateVaFieldsInput input type is used in operations that involve creation of objects in the Va class.
 */
export interface UpdateVaFieldsInput {
  purchase?: UpdateVaPurchaseFieldsInput | null;
  refinance?: UpdateVaRefinanceFieldsInput | null;
}

/**
 * The UpdateVaPurchaseFieldsInput input type is used in operations that involve creation of objects in the VaPurchase class.
 */
export interface UpdateVaPurchaseFieldsInput {
  firstUseFiveDownFF?: number | null;
  firstUseFiveOrMoreDownFF?: number | null;
  firstUseTenOrMoreDownFF?: number | null;
  minCreditScore?: number | null;
  minDownPaymentPercent?: number | null;
  secondUseFiveDownFF?: number | null;
  secondUseFiveOrMoreDownFF?: number | null;
  secondUseTenOrMoreDownFF?: number | null;
}

/**
 * The UpdateVaRefinanceFieldsInput input type is used in operations that involve creation of objects in the VaRefinance class.
 */
export interface UpdateVaRefinanceFieldsInput {
  firstUseCashOutRefinanceFF?: number | null;
  minCreditScore?: number | null;
  minDownPaymentPercent?: number | null;
  secondUseCashOutRefinanceFF?: number | null;
  streamlineRefinanceFF?: number | null;
}

/**
 * The UpdateWorkflowStatusFieldsInput input type is used in operations that involve creation of objects in the WorkflowStatus class.
 */
export interface UpdateWorkflowStatusFieldsInput {
  workflowId?: string | null;
  workflowName?: string | null;
}

/**
 * Allow to manage users in ACL.
 */
export interface UserACLInput {
  read: boolean;
  userId: string;
  write: boolean;
}

/**
 * Allow to link OR add and link an object of the User class.
 */
export interface UserPointerInput {
  createAndLink?: CreateUserFieldsInput | null;
  link?: string | null;
}

/**
 * The UserRelationWhereInput input type is used in operations that involve filtering objects of User class.
 */
export interface UserRelationWhereInput {
  exists?: boolean | null;
  have?: UserWhereInput | null;
  haveNot?: UserWhereInput | null;
}

/**
 * The UserWhereInput input type is used in operations that involve filtering objects of User class.
 */
export interface UserWhereInput {
  ACL?: ObjectWhereInput | null;
  AND?: UserWhereInput[] | null;
  NOR?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  accountLockoutExpiresAt?: DateWhereInput | null;
  applyUrl?: StringWhereInput | null;
  bookmarks?: ArrayWhereInput | null;
  bulkPermissions?: BulkMessagingPermissionWhereInput | null;
  calendar?: StringWhereInput | null;
  createdAt?: DateWhereInput | null;
  custom1?: StringWhereInput | null;
  custom10?: StringWhereInput | null;
  custom2?: StringWhereInput | null;
  custom3?: StringWhereInput | null;
  custom4?: StringWhereInput | null;
  custom5?: StringWhereInput | null;
  custom6?: StringWhereInput | null;
  custom7?: StringWhereInput | null;
  custom8?: StringWhereInput | null;
  custom9?: StringWhereInput | null;
  dataTableView?: DataTablePermissionWhereInput | null;
  deactivated?: BooleanWhereInput | null;
  deactivatedAt?: DateWhereInput | null;
  defaultPhoneLine?: PhoneLineRelationWhereInput | null;
  devices?: DeviceRelationWhereInput | null;
  email?: StringWhereInput | null;
  emailTemplateEditorPermissions?: EmailTemplateEditorPermissionWhereInput | null;
  failedLoginAttempts?: NumberWhereInput | null;
  firstName?: StringWhereInput | null;
  fiveNineId?: StringWhereInput | null;
  flyerPermissions?: FlyerPermissionWhereInput | null;
  history?: ArrayWhereInput | null;
  id?: IdWhereInput | null;
  identities?: IdentityRelationWhereInput | null;
  lastName?: StringWhereInput | null;
  lastNotificationSeenAt?: DateWhereInput | null;
  licensedStates?: ArrayWhereInput | null;
  localPresence?: BooleanWhereInput | null;
  losId?: StringWhereInput | null;
  nmlsId?: StringWhereInput | null;
  notificationControls?: NotificationControlsWhereInput | null;
  objectIcon?: StringWhereInput | null;
  objectId?: IdWhereInput | null;
  objectName?: StringWhereInput | null;
  onCall?: BooleanWhereInput | null;
  online?: BooleanWhereInput | null;
  optimalBlue?: OptimalBlueUserConfigWhereInput | null;
  password?: StringWhereInput | null;
  passwordChangedAt?: DateWhereInput | null;
  passwordHistory?: ArrayWhereInput | null;
  phone?: StringWhereInput | null;
  phoneLines?: PhoneLineRelationWhereInput | null;
  positionName?: StringWhereInput | null;
  reviewUrl?: StringWhereInput | null;
  role?: StringWhereInput | null;
  sessions?: SessionRelationWhereInput | null;
  status?: StringWhereInput | null;
  tags?: ArrayWhereInput | null;
  team?: StringWhereInput | null;
  updatedAt?: DateWhereInput | null;
  username?: StringWhereInput | null;
  voiceMail?: RecordingRelationWhereInput | null;
  voicemailOutOfWork?: RecordingRelationWhereInput | null;
  voicemailUrl?: StringWhereInput | null;
  wfaJobs?: ArrayWhereInput | null;
}

/**
 * The WorkflowStatusRelationWhereInput input type is used in operations that involve filtering objects of WorkflowStatus class.
 */
export interface WorkflowStatusRelationWhereInput {
  exists?: boolean | null;
  have?: WorkflowStatusWhereInput | null;
  haveNot?: WorkflowStatusWhereInput | null;
}

/**
 * The WorkflowStatusWhereInput input type is used in operations that involve filtering objects of WorkflowStatus class.
 */
export interface WorkflowStatusWhereInput {
  AND?: WorkflowStatusWhereInput[] | null;
  NOR?: WorkflowStatusWhereInput[] | null;
  OR?: WorkflowStatusWhereInput[] | null;
  workflowId?: StringWhereInput | null;
  workflowName?: StringWhereInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
