import { useParams }            from "@relcu/react-router";
import { Route }                from "@relcu/react-router";
import { toFirstUpper }         from "@relcu/ui";
import { useAsync }             from "@relcu/ui";
import { confirmModal }         from "@relcu/ui";
import React                    from "react";
import { useMemo }              from "react";
import { FC }                   from "react";
import { useState }             from "react";
import { useNavigate }          from "@relcu/react-router";
import { IconButton }           from "@relcu/rc";
import { ToggleDropdown }       from "@relcu/rc";
import { Button }               from "@relcu/rc";
import { CheckPicker }          from "@relcu/rc";
import { Badge }                from "@relcu/rc";
import { EmptyState }           from "@relcu/rc";
import { Content as RCContent } from "@relcu/rc";
import { useClassNames }        from "@relcu/rc";
import { Input }                from "@relcu/rc";
import { AutoComplete }         from "@relcu/rc";
import { Form, InputGroup }     from "@relcu/rc";
import { Icon }                 from "@relcu/rc";
import { Stack }                from "@relcu/rc";
import { Toolbar }              from "@relcu/rc";
import { Sidenav }              from "@relcu/rc";
import { Container }            from "@relcu/rc";
import { Typography }           from "@relcu/rc";
import { Page }                 from "@relcu/rc";
import { useModal }             from "@relcu/ui";
import { useAlert }             from "@relcu/ui";
import { ChipsColors }          from "@relcu/ui";
import { ChipsSizes }           from "@relcu/ui";
import { Chips }                from "@relcu/ui";
import { fetchJson }            from "../../../../utils/helpers";
import "./schema-view.css";
import { SchemaContent }        from "./SchemaContent";
import { EnumContent }          from "./SchemaContent";
import { SchemaCreateDialog }   from "./SchemaCreateDialog";
import { postSchemas }          from "./SchemaCreateDialog/postSchemas";

// For Layout
// const states = [
//   {
//     label: "Schemas",
//     value: "Schemas",
//     action: "Type"
//   },
//   {
//     label: "Layout",
//     value: "Layout",
//     action: "Type"
//   }
// ];
const options = [
  {
    value: "collection",
    label: "Collection",
    action: "Kind"
  },
  {
    value: "document",
    label: "Document",
    action: "Kind"
  },
  {
    value: "enum",
    label: "Enum",
    action: "Kind"
  },
  {
    value: "inputs",
    label: "Inputs",
    action: "Kind"
  },
  {
    value: "outputs",
    label: "Outputs",
    action: "Kind"
  }
];
export const SchemaView = React.memo(function SchemaView() {
  const [search, setSearch] = useState(null);
  const [expand, setExpand] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const alert = useAlert();
  const navigate = useNavigate();
  const { data = [], call } = useAsync(() => fetchJson("org/schemas"), []);
  const filteredData = useMemo(() => {
    if (!!!selectedFilter.length && !!search) {
      // return data.filter(schema => schema.className.includes(search.toLowerCase()) || schema.className.includes(search.toUpperCase()));
      return data.filter(schema => schema.className.toLowerCase().includes(search.toLowerCase()));
    } else if (!!selectedFilter.length && !!!search) {
      return selectedFilter.map(el => data.filter(schema => schema.kind === el)).flat();
    } else if (!!selectedFilter.length && !!search) {
      const found = data.filter(schema => schema.className.toLowerCase().includes(search.toLowerCase()));
      return selectedFilter.map(el => found.filter(schema => schema.kind === el)).flat();
    } else {
      return data;
    }
  }, [selectedFilter, search, data]);
  const [modal, fieldContextHolder] = useModal(SchemaCreateDialog);
  const handleCreate = () => {
    const { destroy } = modal({
      async onSubmit(data: Record<string, any>) {
        const response = await postSchemas(data);
        if (!response.ok) {
          alert.error("Failed to create schema");
        } else {
          alert.success(`${toFirstUpper(data.kind)} ${data.className} successfully created.`);
          await call();
          navigate(data.className);
          destroy();
        }
      }
    });
  };

  const removeSchema = async (className: string) => {
    try {
      const response = await fetch(`${window.__CONFIG__.api}/org/schemas/${className}`, {
        method: "DELETE",
        headers: { "Content-type": "application/json" }
      });
      if (response.ok) {
        await call();
        alert.success(`${className} Schema successfully deleted`);
      } else {
        alert.error(`An error occurred while deleting schema`);
      }
    } catch (error) {
      console.error(error);
      alert.error("An error occurred while processing the request");
    }
  };
  const handleRemove = async (className: string) => {
    try {
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete ${className} Schema?`,
        content: `All information related with ${className} Schema will be lost permanently`,
        label: "DELETE"
      });
      await removeSchema(className);
    } catch (err) {
      console.error(err);
    }
  };
  const { "*": className } = useParams();
  const selectedSchema = data.length && data.find(schema => schema.className === className);

  return (
    <Page>
      <Page.Sidebar width={expand ? 320 : 0}
                    isFullScreen={false}
                    style={{ borderWidth: expand ? "1px" : "0", opacity: expand ? 1 : 0, gap: 8 }}>
        <Sidenav.Header>
          <Toolbar spacing={6} size={"sm"}>
            {
              <Page.Toggle size={"sm"} expand={expand}
                           onToggle={() => setExpand(expand => !expand)}
                           disabled={!!!data.length}
              />
            }
            <Typography weights={"medium"} variant={"base16"}>Schemas</Typography>
            <Stack.Item grow={1}/>
            <Button size={"xs"}
                    style={{ alignSelf: "end" }}
                    onClick={handleCreate}>
              ADD
            </Button>
          </Toolbar>
          <Stack direction={"column"} spacing={16} style={{ marginLeft: 16, marginRight: 16 }}
                 childrenRenderMode={"clone"}>
            <InputGroup inside>
              <InputGroup.Addon>
                <Icon type={"search"}/>
              </InputGroup.Addon>
              <Form.Control onChange={(value: string) => setSearch(value)} name={"name"} accepter={AutoComplete}
                            placeholder={"Search..."}/>
            </InputGroup>
            {
              !!selectedFilter.length ? (

                <Badge
                  style={{ alignSelf: "end" }}
                  children={
                    <CheckPicker
                      menuStyle={{ width: 200 }}
                      data={[...options]}
                      placement={"autoVerticalEnd"}
                      value={selectedFilter}
                      onChange={(value: string[]) => setSelectedFilter(value)}
                      groupBy={"action"}
                      toggleAs={ToggleSelectFilter}
                      searchable={false}
                    />}
                  size={"sm"}/>
              ) : (
                <CheckPicker
                  menuStyle={{ width: 200 }}
                  style={{ alignSelf: "end" }}
                  data={[...options]}
                  placement={"autoVerticalEnd"}
                  value={selectedFilter}
                  onChange={(value: string[]) => setSelectedFilter(value)}
                  groupBy={"action"}
                  toggleAs={ToggleSelectFilter}
                  searchable={false}/>
              )
            }
          </Stack>
        </Sidenav.Header>

        <Sidenav style={{ height: "calc(100% - 146px)" }} expanded={expand} appearance="subtle">
          <Sidenav.Body>
            {
              <div
                className={"mail-list-container"}
                style={{ height: "100%" }}>
                {
                  filteredData.map(el => (
                    <SchemaCard
                      key={el.className}
                      title={`${el.className}`}
                      objectId={el.className}
                      selected={className === el.className}
                      onClick={() => navigate(el.className)}
                      onRemove={() => handleRemove(el.className)}
                      kind={el.kind}
                    />
                  ))
                }
              </div>
            }
          </Sidenav.Body>
        </Sidenav>
        {fieldContextHolder}
      </Page.Sidebar>
      <Route path={"/:className"} element={
        selectedSchema ? (
            selectedSchema.kind === "enum" ?
              <EnumContent data={selectedSchema}
                           reload={() => call()}
                           expand={expand} setExpand={setExpand}
              /> : <SchemaContent data={selectedSchema}
                                  reload={() => call()}
                                  expand={expand} setExpand={setExpand}
              />)
          : (
            <Container style={{ justifyContent: "center" }}>
              <RCContent>
                <EmptyState icon={"rc_data_object"} subtitle={`No schemas available`}/>
              </RCContent>
            </Container>
          )
      }/>

    </Page>
  );
});
const ToggleSelectFilter = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <Icon type={"filter_alt"} ref={ref} style={{ color: "var(--rc-accent-03-primary)", cursor: "pointer" }}/>;
});

export interface SchemaCardProps {
  title: string;
  objectId: string;
  selected?: boolean;
  enabled?: boolean;
  onClick?(t);
  onUpdate?(id: string, title: string);
  onRemove?();
  kind?: string;
}
export const SchemaCard: FC<SchemaCardProps> = React.memo(function SchemaCard(props) {
  const { kind, title, selected, onClick, enabled, objectId, onUpdate, onRemove } = props;
  const [templateName, setTemplateName] = useState(title);
  const [editItem, setEditItem] = useState(null);

  const { withClassPrefix, prefix, merge } = useClassNames("generation-item", "rc");
  const classes = merge(
    withClassPrefix({ selected })
  );

  return <Stack direction={"column"} onClick={() => onClick(title)} alignItems={"flex-start"} className={classes}
                childrenRenderMode={"clone"}>
    <Stack
      spacing={4}
      justifyContent={"center"}
      childrenRenderMode={"clone"}
      style={{ justifyContent: "flex-start", alignSelf: "stretch" }}>
      {
        kind &&
        <Icon type={"rc_data_object"} size={20} className={prefix("icon")}/>
      }
      {
        editItem === objectId ?
          <Stack childrenRenderMode={"clone"} spacing={8}>
            <Input defaultValue={title} size={"xs"} style={{ height: "20px" }} autoFocus={true}
                   onChange={setTemplateName}/>
            <Stack childrenRenderMode={"clone"}>
              <Icon onClick={() => {
                onUpdate(objectId, templateName);
                setEditItem(null);
              }} type="check" style={{ color: "var(--rc-accent-03-primary)" }}/>
              <Icon onClick={() => {
                setEditItem(null);
              }} type="clear" style={{ color: "var(--rc-accent-03-primary)" }}/>
            </Stack>
          </Stack> :
          <Typography variant={"base16"} weights={"regular"}
                      lineHeight={"lh20"} className={"ell-flex"}>{title}</Typography>
      }
      {
        enabled === false && <>
          <Stack.Item style={{ flex: 1 }}/>
          <Chips size={ChipsSizes.Small} color={ChipsColors.Grey} label={"Disabled"}/>
        </>
      }
    </Stack>
    <Stack spacing={4} style={{ paddingLeft: "24px" }} alignItems={"flex-start"} className={kind && prefix("schema")}>
      <Typography variant={"small12"}
                  color={"tertiary"}>{kind}</Typography>
    </Stack>
    <ToggleDropdown
      onClick={(event) => event.stopPropagation()}
      toggleRenderer={
        <IconButton
          className={prefix("dropdown-toggle")}
          size={"xs"}
          appearance={"subtle"} icon={<Icon type="more_vert"/>}/>
      }
      placement={"autoVerticalEnd"}
    >
      <ToggleDropdown.Item
        eventKey={3}
        onSelect={onRemove}>
        <Icon type="delete"/>
        Remove
      </ToggleDropdown.Item>
    </ToggleDropdown>
  </Stack>;
});
// export interface JsonEditorProps {
//   data: any;
//   onChange?(t);
//   onError?(t);
// }
//
// export const JsonEditor: FC<JsonEditorProps> = React.memo(function JsonEditor(props) {
//   const { data, onChange, onError } = props;
//
//   const handleEditorValidation = useCallback((markers) => {
//     onError(!!markers.length);
//   }, []);
//
//   return <Stack childrenRenderMode={"clone"} style={{ flex: 1 }}>
//     <Stack direction={"column"} style={{ flex: "1 1 100%", alignSelf: "stretch", width: "100%" }}
//            childrenRenderMode={"clone"}>
//       <Stack justifyContent={"space-between"} className={"mail-template-section-header"}>
//         <Typography variant={"base16"} lineHeight={"lh20"}>Editor</Typography>
//       </Stack>
//       <Stack.Item style={{ alignSelf: "stretch", flex: 1 }}>
//         {
//           <Field name={`editor`}>
//             {({ onSubmit }) => {
//               return <Editor
//                 height={"90vh"}
//                 defaultLanguage={"json"}
//                 value={JSON.stringify({ data }, null, 2)}
//                 theme={"dark"}
//                 onChange={onChange}
//                 options={{ minimap: { enabled: false } }}
//                 onValidate={handleEditorValidation}
//               />;
//             }}
//           </Field>
//         }
//       </Stack.Item>
//     </Stack>
//   </Stack>;
// });
