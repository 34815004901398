import { omit }            from "@relcu/ui";
import React                        from "react";
import { FC }                       from "react";
import { useEffect }                from "react";
import { useMemo }                  from "react";
import { useApolloClient }          from "@apollo/client";
import { useQuery }                 from "@apollo/client";
import { gql }                      from "@apollo/client";
import { getIn }                    from "@relcu/final-form";
import { useSource }                from "@relcu/ui";
import { LoanEstimate }             from "../../../../../graph/__types__/LoanEstimate";
import { LOAN_ESTIMATE }            from "../../../../../graph/operations.graphql";
import { useAppendObCustomFields }  from "../useDefaultOffer";
import { useDefaultOffer }          from "../useDefaultOffer";
import { createDefaultOfferEdge }   from "../utils";
import { GetLoanEstimateVariables } from "./__types__/GetLoanEstimate";
import { GetLoanEstimate }          from "./__types__/GetLoanEstimate";

export const GET_LOAN_ESTIMATE = gql`
  query GetLoanEstimate($where: LoanEstimateWhereInput){
    loanEstimates(where: $where,first: 1,order: [createdAt_DESC]){
      edges {
        node {
          ...LoanEstimate
        }
      }
    }
  }
  ${LOAN_ESTIMATE}
`;
export const ProposalContext = React.createContext<Partial<LoanEstimate>>(null);
export const ProposalProvider: FC = React.memo(function ProposalProvider(props) {
  const { data: defaultOffer, loading: loadingDefaultOffer } = useDefaultOffer();
  const client = useApolloClient();
  const { $object: lead } = useSource();
  const GET_LOAN_ESTIMATE_WITH_CUSTOM_FIELDS = useAppendObCustomFields(GET_LOAN_ESTIMATE);
  const {
    data: {
      loanEstimates: {
        __typename, edges: [{ node } = Object() as {
          node: LoanEstimate
        }] = [{}]
      } = {}
    } = {}, loading
  } = useQuery<GetLoanEstimate, GetLoanEstimateVariables>(GET_LOAN_ESTIMATE_WITH_CUSTOM_FIELDS, {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
    variables: {
      where: {
        lead: { have: { id: { equalTo: lead.id } } },
        draft: { equalTo: true },
        deleted: { equalTo: false }
      }
    }
  });
  const loanEstimate = useMemo(() => {
    if (!node) {
      return {
        propertyOccupancy: getIn(lead, "property.occupancy"),
        propertyAnnualInsurance: getIn(lead, "property.annualInsurance"),
        loanPurpose: getIn(lead, "loanPurpose"),
        propertyAnnualTax: getIn(lead, "property.annualTaxes"),
        propertyType: getIn(lead, "property.type"),
        propertyState: getIn(lead, "property.propertyAddress.state"),
        propertyCity: getIn(lead, "property.propertyAddress.city"),
        propertyCounty: getIn(lead, "property.propertyAddress.county"),
        propertyZipCode: getIn(lead, "property.propertyAddress.zipCode"),
        propertyAddress: getIn(lead, "property.propertyAddress.street")
      };
    }
    return node;
  }, [node, lead]);

  useEffect(() => {
    if (node && !node.offers.edges.length && !loadingDefaultOffer) {
      client.writeQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        data: {
          loanEstimates: {
            __typename,
            edges: [
              {
                node: {
                  ...node,
                  offers: {
                    ...node.offers,
                    edges: [
                      createDefaultOfferEdge(omit(defaultOffer,["rate","apr","price"]))
                    ]
                  }
                },
                __typename: "LoanEstimateEdge"
              }
            ]
          }
        },
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true }
          }
        }
      });
    }
  }, [node, loadingDefaultOffer, defaultOffer]);
  return <ProposalContext.Provider value={loanEstimate}>
    {!loading && !loadingDefaultOffer && props.children}
  </ProposalContext.Provider>;
});
