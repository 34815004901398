import { groupBy } from "../../../utils/helpers";

export function getItemField(item, key?) {
  return typeof item == "object" ? item?.[ key ] : item;
}

export function getItemLabel(item, label?) {
  return typeof item == "object" ? item?.[ label ] : item;
}

export function filterData(item, value, label) {
  return getItemLabel(item, label).toString().toLowerCase().indexOf(value) != -1;
}

export function filterStatusData(options, search, label){
  const filteredOptions = options.filter(op => {
    return !op.disabled && filterData(op, search, label);
  });
  const byStages = groupBy(filteredOptions, "parent");
  return Object.entries(byStages).reduce((previousValue, currentValue) => {
    return [...previousValue, { value: currentValue[ 0 ], label: currentValue[ 0 ], disabled: true }, ...currentValue[ 1 ]];
  }, []);
}

export const removeStages = (options) => {
  return options.filter(el => !el.disabled);
}

export function structureByParents (structuredActions) {
  const byStages = groupBy(structuredActions, "parent");
  return Object.entries(byStages).reduce((previousValue, currentValue) => {
    return [...previousValue, { value: currentValue[ 0 ], label: currentValue[ 0 ], disabled: true }, ...currentValue[ 1 ]];
  }, []);
}