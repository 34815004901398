import { DateTime }   from "@relcu/date";
import { FilterRule } from "./useFilter";

export function parseOperator(rule) {
  if (rule.operator == "exists") {
    return true;
  } else if (rule.operator == "notExists") {
    return false;
  }
  return rule.value;
}

export function applyStartWith(where: any, rule: FilterRule) {
  const value = rule.value.replace(/^[+]/, "[+]");
  where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `^${value}`, options: "i" } });
}

export function applyEndWith(where: any, rule: FilterRule) {
  where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `${rule.value}$`, options: "i" } });
}
export function applyContains(where: any, rule: FilterRule) {
  const value = rule.value.replace(/^[+]/, "[+]");
  where[ "AND" ].push({ [ rule.field ]: { "matchesRegex": `${value}`, options: "i" } });
}

export function applyOn(where: any, rule: FilterRule) {
  const start = DateTime.fromJSDate(new Date(rule.value)).set({ hour: 0, minute: 0, second: 0 });
  const end = start.plus({ days: 1 });
  const onDay = {
    greaterThan: start.toJSDate(),
    lessThan: end.toJSDate()
  };
  where[ "AND" ].push({ [ rule.field ]: { ...onDay } });
}

export function applyBoolean(where: any, rule: FilterRule) {
  where[ "AND" ].push({ [ rule.field ]: { [ rule.operator == "notExists" ? "notEqualTo" : "equalTo" ]: true } });
}

export function applyStatus(where: any, rule: FilterRule, field: string) {
  where[ "AND" ].push({
    [ field ]: {
      status: { [ rule.operator == "notExists" ? "exists" : rule.operator ]: parseOperator(rule) }
    }
  });
}
