import React                           from "react";
import { FC }                          from "react";
import { useContext }                  from "react";
import { useState }                    from "react";
import { useReactiveVar }              from "@apollo/client";
import { useLazyQuery }                from "@apollo/client";
import { useNavigate }                 from "@relcu/react-router";
import { Form }                        from "@relcu/final-form";
import { Field }                       from "@relcu/final-form";
import { useField }                    from "@relcu/final-form";
import { FormSpy }                     from "@relcu/final-form";
import { useForm }                     from "@relcu/final-form";
import { Advice }                      from "@relcu/rc";
import { RadioButton }                 from "@relcu/rc";
import { Whisper }                     from "@relcu/rc";
import { Typography }                  from "@relcu/rc";
import { Popover }                     from "@relcu/rc";
import { FormField }                   from "@relcu/rc";
import { IconButton }                  from "@relcu/rc";
import { Icon }                        from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { Stack }                       from "@relcu/rc";
import { useSource }                   from "@relcu/ui";
import { toFirstLower }                from "@relcu/ui";
import { toFirstUpper }                from "@relcu/ui";
import { useAlert }                    from "@relcu/ui";
import { layoutVar }                   from "../../../../../../reactiveVars";
import { loadingVar }                  from "../../../../../../reactiveVars";
import { MessageDraft }                from "../../../../../../reactiveVars";
import { messageDraftsVar }            from "../../../../../../reactiveVars";
import { getDefaultPhoneNumberForSMS } from "../../../../../../utils/helpers";
import { getNodeEmail }                from "../../../../../../utils/helpers";
import { handleFlyerUpload }           from "../../../../../../utils/helpers";
import { transformNameToLabel }        from "../../../../../../utils/helpers";
import { getObjectPath }               from "../../../../../../utils/layoutUtils";
import { PreviewSettings }             from "../../../../../Generation";
import { useMailApi }                  from "../../../../../useMailApi";
import { validate }                    from "../../../../Dialog/ContactDialog/Form/DuplicateForm/useResolveDuplicates";
import { PointerPickerProps }          from "../../../../Field/PointerPicker";
import { useJqlLazyQuery }             from "../../../../Jql";
import { GetMergeTagsVariables }       from "../../__types__/GetMergeTags";
import { GetMergeTags }                from "../../__types__/GetMergeTags";
import { GET_MERGE_TAGS }              from "../../FlyerGenerationViewQueries";
import { FlyerContext }                from "../Content";
import { MailboxPreview }              from "./MailboxPreview";
import { ScopePreview }                from "./ScopePreview";
import { UserPreview }                 from "./UserPreview";

const requiredFields = {
  "scope": "Scope name",
  "user": "User",
  "mailbox": "Email",
  "jsonContent": "Content"
};

export const FlyerPreviewSettings: FC<{ onPreview() }> = React.memo(function TemplatePreviewSettings({ onPreview }) {
  const { isGenerating, isLO, emailEditorRef, loadingEditor } = useContext(FlyerContext);
  const layout = layoutVar();
  const { $object, $viewer } = useSource();
  const navigate = useNavigate();
  const loading = useReactiveVar(loadingVar);
  const [downloading, setDownloading] = useState(false);
  const form = useForm();
  const { input: { value: previewSettings } } = useField("previewSettings");
  const { success, error, info } = useAlert();
  const { nodeEmail, createDraftMail } = useMailApi($object);
  const [getMergeTags] = useLazyQuery<GetMergeTags, GetMergeTagsVariables>(GET_MERGE_TAGS, {
    fetchPolicy: "network-only"
  });
  const canSend = $object.__typename == "User" ? $object.flyerPermissions?.send : $viewer.flyerPermissions?.send || $object.className === "Settings";
  const [getFlyerScope] = useJqlLazyQuery({
    operation: previewSettings.scope?.__typename && toFirstLower(previewSettings.scope.__typename),
    variables: {
      id: {
        name: "id",
        type: `ID!`
      }
    },
    fields: layout[ previewSettings.scope?.__typename ]?.jql?.query?.get?.fields
  }, {
    operationName: `GetFlyerScope`
  });

  function getErrors(type) {
    const { applyTo, jsonContent, previewSettings } = form.getState().values;
    return Object.keys(requiredFields).reduce((previousValue, currentValue) => {
      if (!{ jsonContent, ...previewSettings }[ currentValue ]) {
        const name = currentValue == "scope" ? applyTo : requiredFields[ currentValue ];
        if (currentValue == "mailbox" && type != "email") {
          return previousValue;
        } else if (name) {
          previousValue.push(`${transformNameToLabel(toFirstUpper(name))} is required.`);
        }
      }

      return previousValue;
    }, []);
  }

  async function handleDownload({ type }, onClose) {
    onClose();
    setDownloading(true);
    info("PDF or image generation can take up to 30 seconds.");
    const { values } = form.getState();
    const { jsonContent, previewSettings, title } = values;
    const data = new FormData();
    data.append("data", JSON.stringify({
      jsonContent,
      generationType: type,
      user: previewSettings.user?.objectId,
      scope: previewSettings.scope?.id
    }));

    fetch("/api/v1/downloadFlyer", {
      method: "POST",
      headers: {
        "Accept": "application/json"
      },
      body: data
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.blob();
      } else {
        setDownloading(false);
        error(`Oops. Something went wrong!`);
      }
    }).then(data => {
      setDownloading(false);
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function () {
        const base64data = reader.result;
        const downloadLink = document.createElement("a");
        downloadLink.href = base64data as string;
        downloadLink.download = `${title}.${type == "image" ? "png" : type}`;
        downloadLink.click();
      };
    });
  }
  const handleSendVia = async (type: "sms" | "email") => {
    try {
      const { jsonContent, previewSettings, title } = form.getState().values;
      loadingVar(true);
      const data = new FormData();
      data.append("data", JSON.stringify({
        jsonContent,
        generationType: type == "email" ? "pdf" : "image",
        user: previewSettings.user?.objectId,
        scope: previewSettings.scope?.id,
        name: title
      }));
      const { data: scope } = await getFlyerScope({
        variables: {
          id: previewSettings.scope.id
        }
      });

      if (type == "email") {
        let to = nodeEmail;
        let from = previewSettings.mailbox.address;
        if (isGenerating) {
          to = getNodeEmail(scope[ toFirstLower(previewSettings.scope.__typename) ]);
        }

        if (!to) {
          error("The recipient has opted out or does not have an email address.");
          loadingVar(false);
          return;
        }

        info("PDF or image generation can take up to 30 seconds.");
        const file = await handleFlyerUpload(data);
        const selectedScope = isGenerating ? previewSettings.scope : $object;
        const { data: { createDraftEmail: { conversation: { objectId } } } } = await createDraftMail({
          subject: "New email with flyer",
          from: from,
          to: [to],
          html: "",
          text: "",
          scope: selectedScope.id,
          attachments: [{
            url: file.url,
            objectId: file.objectId,
            mimeType: file.mimeType,
            name: file.name
          }]
        });
        setTimeout(() => {
          navigate(`${getObjectPath(selectedScope)}/emails?from=${objectId}`);
        });
      } else {
        const scopeClassName = toFirstLower(previewSettings.scope.__typename);
        const number = getDefaultPhoneNumberForSMS(scope[ scopeClassName ].phones, scopeClassName);

        if (!number || number.smsOptOut || number.optOut) {
          error("The recipient has opted out or does not have an phone number.");
          loadingVar(false);
          return;
        }

        info("PDF or image generation can take up to 30 seconds.");
        const file = await handleFlyerUpload(data);
        const drafts = messageDraftsVar();
        const selectedScope = isGenerating ? previewSettings.scope : $object;
        const { id, ...attachment } = file;
        const draft: MessageDraft = {
          type: "sms",
          attachment: [{ attachment }]
        };
        messageDraftsVar({
          ...drafts,
          [ selectedScope.objectId ]: draft
        });

        setTimeout(() => {
          navigate(`${getObjectPath(selectedScope)}/sms`);
        });
      }
      loadingVar(false);
      success("Flyer generated.");
    } catch (e) {
      loadingVar(false);
      error(e.message);
    }
  };

  return <PreviewSettings className={"rc-flyer-preview-settings-bar"}>
    <FormField<PointerPickerProps>
      component={UserPreview}
      name={"previewSettings.user"}
      label={"User"}
      errorMessage={false}
      properties={{
        disabled: isLO
      }}
    />
    <FormSpy subscription={{ values: true, initialValues: true }}>
      {
        ({ values }) => {
          return values.previewSettings.user && values.availableForType == "email" ?
            <FormField<PointerPickerProps>
              component={MailboxPreview}
              name={"previewSettings.mailbox"}
              label={"Email"}
              errorMessage={false}
            />
            : null;
        }
      }
    </FormSpy>
    <FormSpy subscription={{ values: true, initialValues: true }}>
      {
        ({ values }) => {
          const disableSendVia = loading || !values.applyTo || !values.enabled || !!Object.keys(getErrors(values.availableForType)).length;
          return <>
            {
              values.applyTo && values.previewSettings?.user &&
              <FormField<PointerPickerProps>
                component={ScopePreview}
                name={"previewSettings.scope"}
                label={transformNameToLabel(values.applyTo)}
                errorMessage={false}
                properties={{
                  disabled: !isGenerating,
                  targetClass: toFirstUpper(values.applyTo)
                }}
              />
            }
            <Stack spacing={12} style={{ alignSelf: "center", flex: 1 }}>
              {
                canSend &&
                <Advice text={"Please choose values for all the fields on the left, to send the flyer."}
                        placement={"autoVertical"} visible={disableSendVia}>
                  <Button
                    size={"xs"}
                    onClick={() => handleSendVia(values.availableForType)}
                    loading={loading}
                    appearance={"text"}
                    disabled={disableSendVia}>
                    {`SEND VIA ${values.availableForType == "sms" ? "MMS" : "EMAIL"} `}
                  </Button>
                </Advice>
              }
              {/*todo change to this after making "availableForType" checkbox*/}
              {/*<ToggleDropdown*/}
              {/*  onClick={(event) => event.stopPropagation()}*/}
              {/*  toggleRenderer={*/}
              {/*    <Button*/}
              {/*      size={"xs"}*/}
              {/*      style={{ alignSelf: "end" }}*/}
              {/*      endIcon={<Icon type="keyboard_arrow_down"/>}>*/}
              {/*      SEND VIA*/}
              {/*    </Button>*/}
              {/*  }*/}
              {/*  placement={"autoVerticalEnd"}>*/}
              {/*  <ToggleDropdown.Item*/}
              {/*    eventKey={3}*/}
              {/*    onSelect={(event) => undefined}>*/}
              {/*    <Icon type={"email"}/>*/}
              {/*    Email*/}
              {/*  </ToggleDropdown.Item>*/}
              {/*  <ToggleDropdown.Item*/}
              {/*    eventKey={3}*/}
              {/*    onSelect={(event) => undefined}>*/}
              {/*    <Icon type={"forum"}/>*/}
              {/*    SMS*/}
              {/*  </ToggleDropdown.Item>*/}
              {/*</ToggleDropdown>*/}
              <Stack.Item flex={1}/>
              <Button
                disabled={loadingEditor}
                onClick={onPreview}
                appearance={"text"}
                size={"xs"}
                startIcon={<Icon type={"preview"}/>}>
                PREVIEW
              </Button>
              <Whisper
                trigger="click"
                placement="bottomEnd"
                speaker={(props, ref) => {
                  const { className, left, top, onClose } = props;
                  return <Popover ref={ref} className={className} style={{ left, top }} arrow={false} full>
                    <Form
                      validate={validate}
                      onSubmit={(values) => handleDownload(values, onClose)}
                      initialValues={{ type: "pdf" }}>
                      {({ handleSubmit, form }) =>
                        <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
                          <div className={"mail-template-send-test-mail-popup"}>
                            <Typography weights={"medium"} variant={"base16"}>Choose file type</Typography>
                            <Field
                              type={"radio"}
                              value={"pdf"}
                              name="type">
                              {
                                ({ input, meta }) => {
                                  return (
                                    <RadioButton{...input}>PDF</RadioButton>
                                  );
                                }
                              }
                            </Field>
                            <Field
                              type={"radio"}
                              value={"image"}
                              name="type">
                              {
                                ({ input, meta }) => {
                                  return (
                                    <RadioButton{...input}>PNG</RadioButton>
                                  );
                                }
                              }
                            </Field>
                            <Button type={"submit"} size={"xs"} style={{ alignSelf: "center" }}
                                    startIcon={<Icon type="file_download"/>}>
                              DOWNLOAD
                            </Button>
                          </div>
                        </form>
                      }
                    </Form>
                  </Popover>;
                }}
              >
                <IconButton
                  loading={downloading}
                  appearance={"text"}
                  size={"xs"}
                  icon={<Icon type={"file_download"}/>}
                  disabled={downloading || loadingEditor || !!Object.keys(getErrors(values.availableForType)).length}
                />
              </Whisper>
            </Stack>
          </>;
        }
      }
    </FormSpy>
  </PreviewSettings>;
});




