import { TypographyLine }        from "@relcu/ui";
import { TypographySize }        from "@relcu/ui";
import { TypographyColor }       from "@relcu/ui";
import { NotificationIconColor } from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { BadgeColors }           from "@relcu/ui";
import { BadgeVariants }         from "@relcu/ui";
import { timeTo }                from "@relcu/ui";
import { Badge }                 from "@relcu/ui";
import { Notification }          from "@relcu/ui";
import { NotificationHeader }    from "@relcu/ui";
import { NotificationSubject }   from "@relcu/ui";
import { NotificationContent }   from "@relcu/ui";
import { Typography }            from "@relcu/ui";
import { CommonClasses }         from "@relcu/ui";
import { DateTime }              from "@relcu/date";
import React                     from "react";
import { FC }                    from "react";
import "../notification-bar.css";
import { NotificationProps }     from "../NotificationBar";
import { NotificationIcons }     from "../NotificationBar";
import { NotificationTitles }    from "../NotificationBar";

export const ReminderNotification: FC<NotificationProps> = React.memo(function ReminderNotification(props) {
  const { node: { type, createdAt, unread, record, remindDate, isOverdue } = {}, indicateStatus, onNavigate, className, inApp } = props;
  const fontWeight = unread ? 500 : 400;

  return (
    <Notification onNavigate={onNavigate} className={className} style={{ fontWeight }}>

      <NotificationHeader title={NotificationTitles[ type ]} icon={NotificationIcons[ type ]} inApp={inApp}
                          color={isOverdue ? NotificationIconColor.Error : inApp ? NotificationIconColor.White : NotificationIconColor.Secondary}>
        {
          indicateStatus &&
          <Box container gap={"XXXS"} alignItems={"center"}>
            <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary} noWrap>
              {timeTo(DateTime.fromJSDate(new Date(createdAt)))}
            </Typography>
            {unread && <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>}
          </Box>
        }
      </NotificationHeader>
      <Box container direction={"column"} gap={inApp ? "XXXS" : "XXS"}>
        <NotificationContent inApp={inApp} content={record.title}/>
        <NotificationSubject
          objectName={
            <Typography
              size={inApp ? TypographySize.Text : TypographySize.TextSmall}
              className={CommonClasses.FlexEllipsis}
              lineHeight={inApp && TypographyLine.LineBody1}
              color={isOverdue ? TypographyColor.Error : inApp ? TypographyColor.White : TypographyColor.Secondary}>
              {remindDate}
            </Typography>
          }
          prefix={"For"} inApp={inApp}/>
      </Box>
    </Notification>
  );
});
