import { useMemo }                     from "react";
import { useCallback }                 from "react";
import { useState }                    from "react";
import React                           from "react";
import { FC }                          from "react";
import { EmptyState }                  from "@relcu/rc";
import { Form }                        from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { Typography }                  from "@relcu/rc";
import { Toolbar }                     from "@relcu/rc";
import { Header }                      from "@relcu/rc";
import { Page }                        from "@relcu/rc";
import { ToggleSwitch }                from "@relcu/ui";
import { confirmModal }                from "@relcu/ui";
import { useModal }                    from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { List }                        from "@relcu/ui";
import { ButtonColors }                from "@relcu/ui";
import { JsonViewProps }               from "@relcu/ui";
import { useMutation }                 from "@apollo/client";
import { gql }                         from "@apollo/client";
import { LocalPresenceModal }          from "./LocalPresenceModal";
import { PointerField }                from "../../Field/PointerField";
import { schemaVar }                   from "../../../../reactiveVars";
import { isNonEmptyArray }             from "@apollo/client/utilities";
import { toNodeId }                    from "../../../../utils/helpers";
import { UpdateSettingsForm }          from "./__types__/UpdateSettingsForm";
import { UpdateSettingsFormVariables } from "./__types__/UpdateSettingsForm";
import { transformFields }             from "../../../../utils/graphQlUtils";

const UPDATE_SETTINGS = gql`
  mutation UpdateSettingsForm($input: UpdateSystemSettings!) {
    updateSettings(input: $input)
  }
`;
export const LocalPresenceListView: FC<JsonViewProps> = React.memo(function LocalPresenceListView(props) {
  const { $settings } = useSource();
  const [update] = useMutation<UpdateSettingsForm, UpdateSettingsFormVariables>(UPDATE_SETTINGS);
  const [openModal, openContext] = useModal(LocalPresenceModal);
  let mappings = $settings.localPresence?.mappings.map(mapping => ({
    codes: mapping.codes.map(code => ({
      label: code,
      value: code
    })),
    lines: mapping.lines.map(line => ({
      label: line.phoneLine.objectName,
      value: line.phoneLine.objectName,
      ...line.phoneLine,
      id: toNodeId({
        objectId: line.phoneLine.objectId,
        className: line.phoneLine.className
      })
    }))
  }));
  const numbers = useMemo(() => {
    const numbers = [];
    mappings?.map(mapping => {
      mapping.lines.map(m => numbers.push(m.number));
    });
    return numbers;
  }, [mappings]);
  const count = 20;
  const [page, setPage] = useState(1);
  const objects = useMemo(() => mappings?.slice(((page - 1) * count), page * count), [page, mappings]);
  const handleRemove = async (rowIndex) => {
    try {
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete item?`,
        content: ``,
        label: "DELETE"
      });
      let mapping = mappings?.map((m) => {
        return {
          codes: m.codes.map(code => code.value || code),
          lines: m.lines.map(l => ({
            phoneLine: l
          }))
        };
      });
      mapping = mapping?.filter((e, index) => {
        return index != rowIndex;
      }, []);
      const res = mapping?.map(d => {
        return transformFields(d, d, "LocalPresenceCodeMappings", schemaVar());
      });
      update({
        variables: {
          input: {
            fields: {
              localPresence: {
                mappings: res
              }
            }
          }
        }
      }).catch(console.error);
    } catch (err) {
      console.error(err);
    }
  };
  const onEdite = useCallback((row, editableIndex) => {
    const { destroy } = openModal({
      data: row,
      open: true,
      numbers: numbers.filter((number) => row?.lines?.findIndex((line) => line?.number != number) > -1),
      localNumbers: numbers,
      onConfirm: (data) => {
        const mapping = mappings?.map((m, index) => {
          if (editableIndex == index) {
            return data;
          }
          return {
            codes: m.codes.map(code => code.value || code),
            lines: m.lines.map(l => ({
              phoneLine: l
            }))
          };
        }) || [];
        if (editableIndex == -1) {
          mapping.push(data);
        }
        const res = mapping.map(d => {
          return transformFields(d, d, "LocalPresenceCodeMappings", schemaVar());
        });
        if (isNonEmptyArray(data.codes) && isNonEmptyArray(data.lines)) {
          update({
            variables: {
              input: {
                fields: {
                  localPresence: {
                    mappings: res
                  }
                }
              }
            }
          }).then(destroy).catch(console.error);
        }
      }
    });
  }, [mappings]);
  const onToggle = useCallback((e) => {
    let mapping = mappings?.map((m) => {
      return {
        codes: m.codes.map(code => code.value || code),
        lines: m.lines.map(l => ({
          phoneLine: l
        }))
      };
    }) || [];
    const res = mapping.map(d => {
      return transformFields(d, d, "LocalPresenceCodeMappings", schemaVar());
    });
    update({
      variables: {
        input: {
          fields: {
            localPresence: {
              mappings: res,
              enabled: e
            }
          }
        }
      }
    }).catch(console.error);
  }, [mappings]);
  const onSetDefault = useCallback((e) => {
    const mapping = mappings?.map((m) => {
      return {
        codes: m.codes.map(code => code.value || code),
        lines: m.lines.map(l => ({
          phoneLine: l
        }))
      };
    }) || [];
    const res = mapping.map(d => {
      return transformFields(d, d, "LocalPresenceCodeMappings", schemaVar());
    });
    update({
      variables: {
        input: {
          fields: {
            localPresence: {
              mappings: res,
              defaultPhoneLine: {
                link: e.id
              }
            }
          }
        }
      }
    }).catch(console.error);
  }, [mappings]);
  return (
    <Page>
      <Header>
        <Toolbar childrenRenderMode={"clone"} spacing={16} size={"sm"}
                 style={{
                   padding: `0  16px 0 8px`,
                   height: "56px",
                   verticalAlign: "center"
                 }}>
          <Typography variant={"base16"}>Local Presence</Typography>
          <div style={{ flexGrow: 1 }}/>
          <Form onSubmit={() => {
          }}>
            <PointerField placeholder={"Default PhoneLine"}
                          optionKey={"id"}
                          optionLabel={"objectName"}
                          name={"objectName"}
                          flexGrow={1}
                          onChange={onSetDefault}
                          defaultValue={$settings?.localPresence?.defaultPhoneLine}
                          clearable={false}
                          filters={{
                            localPresence: { equalTo: true }
                          }}
                          targetClass={"PhoneLine"}/>
          </Form>
          <ToggleSwitch
            checked={$settings?.localPresence?.enabled}
            onChange={(e) => {
              onToggle(e);
            }}
            label={$settings?.localPresence?.enabled ? "Disable" : "Enable"}
          />
          <Button
            size={"xs"}
            onClick={() => onEdite(null, -1)}>
            Add
          </Button>
        </Toolbar>
      </Header>
      {
        $settings.localPresence?.mappings?.length > 0 ?
          <div className={"schema-fields-list"} style={{ padding: "20px 0 0 0" }}>
            <List
              flex={1}
              fields={COLUMNS}
              headers={HEADERS}
              count={count}
              onPage={setPage}
              currentPage={page}
              total={Object.keys(mappings ?? []).length}
              actions={[
                {
                  icon: "create",
                  tooltip: "Edit",
                  onClick(e, row, index) {
                    e.stopPropagation();
                    onEdite(row, index);
                  },
                  color: ButtonColors.White
                },
                {
                  icon: "delete",
                  tooltip: "Delete",
                  onClick(e, row, index) {
                    e.stopPropagation();
                    handleRemove(index).catch(console.error);
                  },
                  color: ButtonColors.White
                }
              ]}
              objects={objects || []}
            />
          </div>
          :
          <EmptyState subtitle={`No Local Presence available`}/>
      }
      {openContext}
    </Page>
  );
});

const COLUMNS = [
  {
    "name": "codes.label",
    "component": "ListItemArray",
    "flexGrow": 0,
    "flexShrink": 0,
    "showLength": 10,
    "flexBasis": "calc(40% - 32px)"
  },
  {
    "name": "lines.label",
    "component": "ListItemArray",
    "flexGrow": 0,
    "flexShrink": 0,
    "flexBasis": "calc(40% - 32px)"
  }
];
const HEADERS = [
  {
    "title": "Codes",
    "flexBasis": "calc(40% - 10px)"
  },
  {
    "title": "Lines",
    "flexBasis": "calc(40% - 10px)",
    flexGrow: 0,
    "flexShrink": 0
  }
];
