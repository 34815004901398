import React                      from "react";
import { useMemo }                from "react";
import { useCallback }            from "react";
import { createContext }          from "react";
import { GraphQLError }           from "graphql";
import { ReactiveVar }            from "@apollo/client/cache/inmemory/reactiveVars";
import { makeVar }                from "@apollo/client";
import { useApolloClient }        from "@apollo/client";
import { gql }                    from "@apollo/client";
import { useMutation }            from "@apollo/client";
import { useLocation }            from "@relcu/react-router";
import { useNavigate }            from "@relcu/react-router";
import { FORM_ERROR }             from "@relcu/form";
import { getIn }                  from "@relcu/form";
import { createCalculation }      from "@relcu/form";
import { FormApi }                from "@relcu/form";
import { FieldLevelValidator }    from "@relcu/ui";
import { omit }                   from "@relcu/ui";
import { useConstant }            from "@relcu/ui";
import { useModal }               from "@relcu/ui";
import { ContactDialog }          from "../../Dialog/ContactDialog";
import { CONTACT }                from "../../Dialog/ContactDialog/useContactDialog";
import { getJqlMutation }         from "../../Jql";
import { JqlFormProps }           from "../../Jql/JqlForm";
import { JqlForm }                from "../../Jql/JqlForm";
import { AddLeadMemberVariables } from "./__types__/AddLeadMember";
import { AddLeadMember }          from "./__types__/AddLeadMember";
import { UpdateContactVariables } from "./__types__/UpdateContact";
import { UpdateContact }          from "./__types__/UpdateContact";

export const LeadMembersContext = createContext<{ editableMemberIndexVar: ReactiveVar<number> }>(null);

const UPDATE_CONTACT_TYPES = gql`
  mutation UpdateContactTypes($input:UpdateContactInput!) {
    updateContact(input: $input){
      contact {
        id
        objectId
        types
      }
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation UpdateContact($input:UpdateContactInput!) {
    updateContact(input: $input){
      contact {
        id
        firstName
        lastName
        middleName
        emails {
          type
          address
        }
        phones {
          type
          number
          isPrimary
        }
      }
    }
  }
`;

const ADD_LEAD_MEMBER = gql`
  mutation AddLeadMember($id: ID!, $members: [CreateLeadMemberFieldsInput], $newMember: Object) {
    addLeadMember(id: $id, members: $members, newMember: $newMember){
      id
      objectId
      members {
        creditRating
        creditScore
        employmentStatus
        foreclosure
        isMilitary
        isPrimary
        jobStartedAt
        monthlyIncome
        type
        typeElaborate
        veteranStatus
        contact {
          ...Contact
        }
      }
    }
  }
  ${CONTACT}
`;

function validatePhoneOrEmail(value = [], allValues, meta, key?) {
  const emails = getIn(allValues, key ?? "emails") || [];
  for (let email of emails) {
    if (email.address) {
      return undefined;
    }
  }
  for (let phone of value) {
    if (phone.number) {
      return undefined;
    }
  }
  return "Please provide at least one contact information.";
}
export const validators: FieldLevelValidator[] = [
  {
    field: /members\[\d+\]\.contact.phones/,
    validate: (value = [], allValues, meta) => {
      const nameParts = meta.name.split(".").slice(0, -1);
      nameParts.push("emails");
      return validatePhoneOrEmail(value, allValues, meta, nameParts.join("."));
    }
  },
  {
    field: "borrowerPhones",
    validate: (value = [], allValues, meta) => {
      return validatePhoneOrEmail(value, allValues, meta, "borrowerEmails");
    }
  }
];

export const LeadForm = React.memo<JqlFormProps>(function LeadForm(props) {
  const editableMemberIndexVar = useConstant(() => makeVar<number>(null));
  const [updateContact] = useMutation<UpdateContact, UpdateContactVariables>(UPDATE_CONTACT);
  const [addContact] = useMutation<AddLeadMember, AddLeadMemberVariables>(ADD_LEAD_MEMBER);
  const [modal, contextHolder] = useModal(ContactDialog);
  const client = useApolloClient();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const createJql = {
    ...props.jql.mutation.create,
    fields: [
      {
        ...(props.jql.mutation.create.fields[ 0 ] as any),
        lead: props.jql.mutation.create.fields[ 0 ][ "lead" ].filter((f) => !props.sections[ 0 ].fields.find(field => field.name == f))
      }
    ]
  };
  function getDuplicateError(graphQLErrors: ReadonlyArray<GraphQLError>) {
    return graphQLErrors?.map((error) => error?.extensions?.validationErrors?.find(vError => vError?.type == "duplicationError")).filter((t) => !!t);
  }

  function transformContact(contact) {
    const { emails, phones, ...rest } = contact;
    return {
      ...rest,
      emails: emails?.filter((e) => e.address != undefined).map(upEmail => {
        const { __typename, ...email } = upEmail;
        return email;
      }),
      phones: phones?.filter((e) => e.number != undefined).map(upPhone => {
        const { __typename, ...number } = upPhone;
        return number;
      })
    };
  }

  async function updateMember(member) {
    const { firstName, lastName, middleName, emails, phones } = member;

    await updateContact({
      variables: {
        input: {
          id: member.id,
          fields: transformContact({ firstName, lastName, middleName, emails, phones })
        }
      }
    });

    return {
      contact: {
        link: member.id
      }
    };
  }

  async function create(variables) {
    const { documentNode: mutation } = getJqlMutation(createJql, { operationName: `Create${props.className}Form` });
    const { data } = await client.mutate({
      refetchQueries: ["ListViewQuery"],
      mutation,
      variables
    });
    return data?.[ props.jql.mutation.create.operation ];
  }

  async function update(variables) {
    const updateJql = {
      ...props.jql.mutation.update,
      fields: [
        {
          ...(props.jql.mutation.update.fields[ 0 ] as any),
          lead: props.jql.mutation.update.fields[ 0 ][ "lead" ].filter((f) => !props.sections[ 0 ].fields.find(field => field.name == f))
        }
      ]
    };
    const { documentNode: mutation } = getJqlMutation(updateJql, { operationName: `Update${props.className}Form` });
    const { data } = await client.mutate({
      refetchQueries: ["ListViewQuery"],
      mutation,
      variables
    });
    return data?.[ props.jql.mutation.update.operation ];
  }

  async function deleteMember(id, form) {
    const members = form.getState().values.members;
    const updatedMembers = members.map(member => {
      const { __typename, ...rest } = member;
      return {
        ...rest,
        contact: {
          link: rest.contact.id
        }
      };
    });
    return update({ input: { id, fields: { members: updatedMembers } } });
  }

  async function addNewMember(id: string, members, newMember) {
    return await addContact({
      variables: {
        id: id,
        members: members.filter(m => m.contact.id).map(member => {
          const { __typename, ...rest } = member;
          return {
            ...omit(rest, ["__typename"]),
            contact: {
              link: member.contact.id
            }
          };
        }),
        newMember: {
          ...newMember,
          contact: transformContact(newMember.contact)
        }
      }
    });
  }

  function defaultSubmit(values, form: FormApi, callback?) {
    const { id, fields } = values;
    if (id) {
      return update({ input: { id, fields } });
    }
    return create({ input: { fields } });
  }

  const handleSubmit = useCallback(async (values, form) => {
    const dirtyMemberIndex = editableMemberIndexVar();
    const { id, fields } = values;
    if (id) {
      //other sections update case
      if (dirtyMemberIndex == null) {
        return defaultSubmit(values, form);
      }

      let newMember;
      const members = form.getState().values.members;

      //delete case
      if (members.length < form.getState().initialValues.length) {
        return await deleteMember(id, form);
      }

      //add new member by custom endpoint case
      if (newMember = members.find(m => !m.contact.id)) {
        return await addNewMember(id, members, omit(newMember, ["__typename"]));
      }

      //update exists contact case
      const updatedMembers = await Promise.all(members.map(async (member, index) => {
        const { __typename, ...rest } = member;
        if (index == dirtyMemberIndex) {
          const updatedMember = await updateMember(rest.contact);
          return {
            ...rest,
            ...updatedMember
          };
        }
        return {
          ...rest,
          contact: {
            link: rest.contact.id
          }
        };
      }));

      return update({ input: { id, fields: { members: updatedMembers } } });
    } else {
      const values = form.getState().values;
      return create({
        input: {
          fields: {
            ...values,
            ...fields,
            borrowerEmails: values.borrowerEmails?.filter((e) => e.address != undefined) ?? [],
            borrowerPhones: values.borrowerPhones?.filter((e) => e.number != undefined) ?? [],
          }//todo speak with Armen alias fields are filtering by form
        }
      });
    }
  }, []);

  const handleError = (e, form, variables) => {
    const { id } = variables;
    const dirtyField = Object.keys(form.getState().dirtyFields).find(k => k.match(/(\d+)/) && k.match(/members\[[0-9]\].+/));//todo type to combine two matches into 1 regexp
    const dirtyMemberIndex = editableMemberIndexVar();
    if (getDuplicateError(e.graphQLErrors)?.length) {
      if (typeof dirtyMemberIndex != null || dirtyMemberIndex != undefined) {
        navigate(pathname, { state: { ...state, form: "DuplicationForm", title: "Resolve duplication" } });
        let dirtyMember = form.getState().values.members[ dirtyMemberIndex ];
        const dirtyContact = transformContact(dirtyMember.contact);

        modal({
          member: dirtyContact,
          contactsData: {
            phones: dirtyContact?.phones.map(p => p.number) ?? [],
            emails: dirtyContact?.emails.map(e => e.address) ?? [],
            ids: dirtyContact?.id ? [dirtyContact?.id] : []
          },
          navigate: false,
          className: "Contact",
          title: "Update",
          action: dirtyContact.id ? "save" : "create",
          onClose(e?) {
            if (e) {
              form.change(`members[${dirtyMemberIndex}]`, {
                ...dirtyMember,
                contact: {
                  id: e.id,
                  objectIcon: e.objectIcon,
                  objectId: e.objectId,
                  objectName: e.objectName,
                  __typename: e.__typename,
                  types: e.types,
                  firstName: e.firstName,
                  lastName: e.lastName,
                  middleName: e.middleName,
                  emails: e.emails,
                  phones: e.phones
                }
              });
              const updatedMembers = form.getState().values.members.map(member => {
                const { __typename, ...rest } = member;
                return {
                  ...rest,
                  contact: {
                    link: rest.contact.id
                  }
                };
              });
              update({ input: { id, fields: { members: updatedMembers } } });
              editableMemberIndexVar(null);
            } else {
              form.change(`${dirtyField.split(".")[ 0 ]}`, dirtyMember);
            }
          }
        });
      }
      return {
        [ FORM_ERROR ]: e.message
      };
    }
  };
  return (
    <LeadMembersContext.Provider value={{ editableMemberIndexVar }}>
      <>
        {contextHolder}
        <JqlForm
          {...props}
          validators={validators}
          onError={handleError}
          onSubmit={handleSubmit}
          decorators={useMemo(() => [decorators], [])}
        />
      </>
    </LeadMembersContext.Provider>
  );
});

const decorators = createCalculation(
  {
    field: /members\[\d+\]\.isPrimary/,
    updates: (value, name, allValues: { members }, prevValues) => {
      if (value) {
        const result = {};
        allValues.members.forEach((b, i) => {
          const fieldName = `members[${i}].isPrimary`;
          if (name !== fieldName) {
            result[ fieldName ] = false;
          }
        });
        return result;
      }
      return {
        [ name ]: value
      };
    }
  },
  {
    field: /members\[\d+\]\.type/,
    updates: (value, name, allValues: { members }, prevValues) => {
      if (value == "borrower") {
        const result = {};
        allValues.members.forEach((b, i) => {
          const fieldName = `members[${i}].type`;
          if (name !== fieldName && b.type == "borrower") {
            result[ fieldName ] = "co_borrower";
          }
        });
        return result;
      }
      return {
        [ name ]: value
      };
    }
  }
);

function getContactsTypes(values, form: FormApi) {
  const result = [];
  const memberTypeReg = /members\[\d+\]\.type/;
  const memberContact = /members\[\d+\]\.contact/;
  let { dirtyFields } = form.getState();
  let editedContactsIndexes = new Set<number>();
  // let typeChangedIndexes = [];
  if (dirtyFields.members) {
    for (const key in dirtyFields) {
      if (memberTypeReg.test(key) || memberContact.test(key)) {
        const index = key.match(/(\d+)/)[ 0 ];
        editedContactsIndexes.add(Number(index));
      }
    }
  }

  for (let i = 0; i < editedContactsIndexes.size; i++) {
    const index = Array.from(editedContactsIndexes)[ i ];
    const contact = values.members[ index ].contact;
    const memberType = values.members[ index ].type;
    const contactId = values.members[ index ].contact.id;
    let type;

    if (["buyer_realtor", "seller_realtor"].includes(memberType)) {
      type = "realtor";
    } else if (["co_borrower", "borrower"].includes(memberType)) {
      type = "borrower";
    } else {
      type = memberType == "title" ? "title" :
        memberType == "attorney" ? "attorney" : "custom";
    }

    if (type && contactId && !contact.types?.includes(type)) {
      const types = contact.types ? [...contact?.types, type] : [type];
      result.push({ types, contactId });
    }
  }
  return result;
}
