import { HTMLAttributes } from "react";
import React              from "react";
import { useClassNames }  from "../utils";
import { TabClasses }     from "./TabClasses";

export interface TabsProps extends HTMLAttributes<HTMLDivElement> {
  onChange?(index);
}
export function Tabs(props: TabsProps) {
  const { className, children, onChange, ...p } = props;
  const { merge, withClassPrefix } = useClassNames(TabClasses.Tabs, "rc");
  const classes = merge(className, withClassPrefix());

  return <div
    className={classes}
    {...p}>
    {children}
  </div>;
}
