import React                    from "react";
import { FC }                   from "react";
import { useMemo }              from "react";
import { useState }             from "react";
import { useApolloClient }      from "@apollo/client";
import { gql }                  from "@apollo/client";
import { FormApi }              from "@relcu/final-form";
import { arrayMutators }        from "@relcu/final-form";
import { AutoComplete }         from "@relcu/rc";
import { Form }                 from "@relcu/rc";
import { Icon }                 from "@relcu/rc";
import { InputGroup }           from "@relcu/rc";
import { Button }               from "@relcu/rc";
import { Typography }           from "@relcu/rc";
import { Page }                 from "@relcu/rc";
import { Toolbar }              from "@relcu/rc";
import { Header }               from "@relcu/rc";
import { Container }            from "@relcu/rc";
import { useNavigate }          from "@relcu/react-router";
import { useLocation }          from "@relcu/react-router";
import { toFirstUpper }         from "@relcu/ui";
import { useAlert }             from "@relcu/ui";
import { deepEqual }            from "@relcu/ui";
import { transformNameToLabel } from "@relcu/ui";
import { ButtonColors }         from "@relcu/ui";
import { List }                 from "@relcu/ui";
import { useModal }             from "@relcu/ui";
import { ChoiceConfig }         from "../FieldConfigDialog/Details/FieldConfigs/ChoiceConfig";
import { FieldConfigDialog }    from "../FieldConfigDialog/FieldConfigDialog";
import { postSchemas }          from "../SchemaCreateDialog/postSchemas";

export interface SchemaContentProps {
  data?: any;
  expand?: boolean;
  setExpand?: any;
  onChange?(t);
  onSubmit?(t);
  canUpdate?: unknown;
  errors?: boolean;
  onError?(t);
  reload();
}
export const SchemaContent: FC<SchemaContentProps> = React.memo(function SchemaContent(props) {
  const { data: schema, expand, setExpand, reload } = props;
  const [search, setSearch] = useState(null);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [modal, fieldContextHolder] = useModal(FieldConfigDialog);
  const count = 20;
  const [page, setPage] = useState(1);
  const objects = useMemo(() => Object.keys(schema?.fields ?? []).map(name => transformColumn(name, { ...schema.fields[ name ], name })).slice(((page - 1) * count), page * count).filter(({ label, name }) => {
    return search ? (label.toLowerCase().includes(search.toLowerCase()) || name.toLowerCase().includes(search.toLowerCase())) : true;
  }), [schema.fields, page, search]);
  const client = useApolloClient();
  const alert = useAlert();

  const handleClick = (schema, data?) => {
    const mode = !data ? "create" : "update";
    navigate(pathname, { state: { ...state, step: data ? "DetailsStep" : "TypeSelectionStep" } });
    const { destroy } = modal({
      schema,
      data,
      mode,
      onSubmit: async ({ name, ...options }, form: FormApi) => {
        try {
          if (mode === "create") {
            await client.mutate({
              mutation: CREATE_FIELD,
              variables: {
                className: schema.className,
                fields: { name, options }
              }
            });
            await reload();
            alert.success(`The ${name} field successfully added on ${schema.className} schema.`);
          } else {
            const initialValues = form.getState().initialValues;
            if (deepEqual(initialValues.visibility, options.visibility)) {
              delete options.visibility;
            }
            await client.mutate({
              mutation: UPDATE_FIELD,
              variables: {
                className: schema.className,
                fields: { name, options }
              }
            });
            await reload();
            alert.success(`The ${name} field successfully updated.`);
          }
          destroy();
        } catch (e) {
          console.error(e);
          alert.error(`Failed to ${mode} the ${name} field.`);
        }
      }
    });
  };

  return (
    <Container key={schema.className} className={"schema-fields-list-container"}>
      {fieldContextHolder}
      <Header>
        <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
                 style={{
                   padding: `0  16px 0 ${expand ? "16px" : "8px"}`,
                   height: "56px",
                   verticalAlign: "center"
                 }}>
          {
            !expand &&
            <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
          }
          <Typography variant={"base16"}>{schema.className}</Typography>
          <div style={{ flexGrow: 1 }}/>
          <Button
            size={"xs"}
            // disabled={!canUpdate || errors}
            onClick={() => handleClick(schema)}>
            Add field
          </Button>
        </Toolbar>
      </Header>
      <div className={"schema-fields-list"}>
        <InputGroup inside style={{
          alignSelf: "end",
          width: 300,
          margin: "10px 10px 0 0"
        }}>
          <InputGroup.Addon>
            <Icon type={"search"}/>
          </InputGroup.Addon>
          <Form.Control onChange={(value: string) => setSearch(value)} name={"name"} accepter={AutoComplete}
                        placeholder={"Search..."}/>
        </InputGroup>
        {/*<JsonEditor data={data} onChange={onChange} onError={onError}/>*/}
        <List
          fields={COLUMNS}
          headers={HEADERS}
          total={Object.keys(schema?.fields ?? []).length}
          count={count}
          onPage={setPage}
          currentPage={page}
          actions={[
            {
              icon: "create",
              tooltip: "Edit",
              onClick(e, row) {
                e.stopPropagation();
                handleClick(schema, row);
              },
              color: ButtonColors.White
            }
          ]}
          objects={objects}
        />
      </div>
    </Container>
  );
});
export const EnumContent: FC<SchemaContentProps> = React.memo(function EnumContent(props) {
  const { data: schema, expand, setExpand, reload } = props;
  const alert = useAlert();
  const onSubmit = async (data) => {
    const { numeric, ...rest } = data;
    const response = await postSchemas(rest);
    if (!response.ok) {
      alert.error("Failed to create schema");
    } else {
      alert.success(`${toFirstUpper(rest.kind)} ${rest.className} successfully created.`);
      await reload();
    }
  };
  return (
    <Container key={schema.className} className={"schema-fields-list-container"}>
      <Form
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={true}
        initialValues={{ ...schema, numeric: false }}
        onSubmit={onSubmit}
      >
        <Header>
          <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
                   style={{
                     padding: `0  16px 0 ${expand ? "16px" : "8px"}`,
                     height: "56px",
                     verticalAlign: "center"
                   }}>
            {
              !expand &&
              <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
            }
            <Typography variant={"base16"}>{schema.className}</Typography>
            <div style={{ flexGrow: 1 }}/>
            <Button
              size={"xs"}
              type={"submit"}>
              Save
            </Button>
          </Toolbar>
        </Header>
        <div style={{ alignItems: "center", padding: 10 }} className={"schema-fields-list"}>
          <ChoiceConfig showParent={true}/>
          {/*<JsonEditor data={data} onChange={onChange} onError={onError}/>*/}
        </div>
      </Form>
    </Container>
  );
});

const CREATE_FIELD = gql`
  mutation CreateSchemaField($className:String!,$fields:SchemaFieldInput!){
    updateSchema(name:$className,addField:$fields)
  }
`;
const UPDATE_FIELD = gql`
  mutation UpdateSchemaField($className:String!,$fields:SchemaFieldInput!){
    updateSchema(name:$className,updateField:$fields)
  }
`;

const COLUMNS = [
  {
    "name": "icon",
    "component": "ListItemImage",
    "alt": "name"
  },
  {
    "name": "label",
    "component": "ListItemTitle",
    "flexGrow": 0,
    "flexShrink": 0,
    "flexBasis": "calc(40% - 32px)"
  },
  {
    "name": "name",
    "component": "ListItemTitle",
    "flexGrow": 0,
    "flexShrink": 0,
    "flexBasis": "40%"
  },
  {
    "name": "type",
    "component": "ListItemTitle",
    "flexBasis": "40%",
    flexGrow: 1
  }
];
const HEADERS = [
  {
    "title": "",
    "flexBasis": "0px"
  },
  {
    "title": "Label",
    "flexBasis": "calc(40% - 10px)",
    flexGrow: 0,
    "flexShrink": 0
  },
  {
    "title": "Name",
    "flexBasis": "40%",
    flexGrow: 0,
    "flexShrink": 0
  },
  {
    "title": "Type",
    "flexBasis": "40%",
    flexGrow: 1
  }
];

const transformers = {
  String(label, field) {
    return {
      icon: "title",
      label: label,
      ...field
    };
  },
  Status(label, field) {
    return {
      icon: "flash_on",
      label: label,
      ...field
    };
  },
  Number(label, field) {
    return {
      icon: "rc_custom-empty",
      label: label,
      ...field
    };
  },
  Boolean(label, field) {
    return {
      icon: "power_settings_new",
      label: label,
      ...field
    };
  },
  Email(label, field) {
    return {
      icon: "email",
      label: label,
      ...field
    };
  },
  Phone(label, field) {
    return {
      icon: "phone",
      label: label,
      ...field
    };
  },
  Date(label, field) {
    return {
      icon: "calendar_today",
      label: label,
      ...field
    };
  },
  Pointer(label, field) {
    return {
      icon: "linear_scale",
      label: label,
      ...field
    };
  },
  Object(label, field) {
    return {
      icon: "rc_data_object",
      label: label,
      ...field
    };
  },
  Choice(label, field) {
    return {
      icon: "toc",
      label: label,
      ...field
    };
  }
};

function transformColumn(name, field) {
  const label = field.label || transformNameToLabel(name);
  if (transformers[ field.type ]) {
    return transformers[ field.type ](label, field);
  }
  return { icon: "note", ...field, label };
}
