import React            from "react";
import { FC }           from "react";
import { useState }     from "react";
import { useMemo }      from "react";
import { DateTime }     from "@relcu/date";
import { Box }          from "../../../Box";
import { isValidDate }  from "../../../Calendar/date-fns";
import { Checkbox }     from "../../../Input/Checkbox";
import { DatePicker }   from "../../../Input/DatePicker";
import { RelativeDate } from "../../../Input/RelativeDate";

export interface SwitchDatePickerFieldProps {
  flex: string;
  onChange(date);
  value?: { _now?, _date };
  type?: "date" | "datetime";
}

export const SwitchDatePickerField: FC<SwitchDatePickerFieldProps> = React.memo(function SwitchDatePicker(props) {
  const { value, onChange, flex, type } = props;
  const [isRelative, setIsRelative] = useState(value?._now ? true : !isValidDate(value?._date));
  const format = useMemo(() => type === "datetime" ? "MM/dd/yyyy hh:mm a" : "MM/dd/yyyy", [type]);
  function parseAbsoluteDate(value) {
    if (isValidDate(value)) {
      return new Date(value);
    }
  }
  function formatAbsoluteDate(value) {
    if (isValidDate(value)) {
      return DateTime.fromJSDate(new Date(value)).toFormat(format);
    }
    return value || null;
  }

  return (
    <Box container direction={"column"} gap={"XXXS"} flex={flex}>
      <Box container>
        <Checkbox checked={isRelative} onChange={() => setIsRelative(isRelative => !isRelative)}/>
        {isRelative ? <RelativeDate value={value?._now} onChange={(date) => onChange({ _now: date })} flex={1}/> :
          <DatePicker value={formatAbsoluteDate(value?._date)}
                      onChange={(date) => onChange({ _date: parseAbsoluteDate(date) })}
                      type={type} flex={1}/>}
      </Box>
    </Box>
  );
});
