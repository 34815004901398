import { useContext }       from "react";
import { useCallback }      from "react";
import { ReactNode }        from "react";
import { useMemo }          from "react";
import { useState }         from "react";
import { FC }               from "react";
import React                from "react";
import { FormRenderProps }  from "@relcu/final-form";
import { FormApi }          from "@relcu/form";
import { arrayMutators }    from "@relcu/form";
import { Mutator }          from "@relcu/form";
import { Form }             from "@relcu/form";
import { FormProps }        from "@relcu/form";
import { useMounted }       from "../../..";
import { FieldPattern }     from "@relcu/form";
import { FieldValidator }   from "@relcu/form";
import { deepPick }         from "../../..";
import { permissionUtils }  from "../../..";
import { useLazyCondition } from "../../Where";
import { Viewer }           from "../JsonPage";
import { mode }             from "./JsonSection";
import { JsonSection }      from "./JsonSection";
import { JsonSectionProps } from "./JsonSection";

export const JsonForm: FC<JsonFormProps> = React.memo(function JsonForm(props) {
  let { group, sections, user, mutators, className, ...findFormProps } = props;

  const evaluate = useLazyCondition();
  sections = useMemo(() =>
    sections.filter(sec => {
      return !!sec && permissionUtils.hasAccess(Object(user), sec.permissions, mode(sec.view)) && (
        sec.conditions ? evaluate({ conditions: sec.conditions }).apply : true
      );
    }), [sections, evaluate, user]);
  let element;
  if (group) {
    element = <JsonGroupForm/>;
  } else {
    element = <JsonSingleForm/>;
  }

  const onSubmit: FormProps["onSubmit"] = useCallback((record, form, callback) => {
    const filteredSections = props.sections.filter(sec => !!sec && permissionUtils.hasAccess(Object(user), sec.permissions, "write") && (
      sec.conditions ? evaluate({ conditions: sec.conditions, source: record }).apply : true
    )).map(sec => ({
      ...sec,
      fields: sec.fields.filter(field => permissionUtils.hasAccess(Object(user), field.permissions, "write") && (
        field.conditions ? evaluate({ conditions: field.conditions, source: record }).apply : true
      ))
    }));
    const writableFields = formUtils.getFieldNames(filteredSections);
    const values = deepPick(record, writableFields);
    return findFormProps.onSubmit(values, form, callback);
  }, [user, props.sections, evaluate, findFormProps.onSubmit]);
  return (
    <JsonFormContext.Provider
      value={{ ...findFormProps, onSubmit, mutators: { ...defaultMutators, ...mutators }, sections, user, className }}>
      {element}
    </JsonFormContext.Provider>
  );
});

function JsonSingleForm() {
  const { user, sections, formProps, children, ...finalFormProps } = useContext(JsonFormContext);
  function render(props) {
    const elements = sections.map((section, index) => {
      return (
        <JsonSection key={index} user={user} mode={finalFormProps.initialValues?.id ? "edit" : "create"} {...section}
                     view={"create"}/>
      );
    });
    if (typeof children === "function") {
      return <>{children({ elements: elements, ...props })}</>;
    }
    return <>{elements}{children}</>;
  }
  const fields = useMemo(() => formUtils.getFieldNames(sections), [sections]);
  const initialValues = useMemo(() => deepPick(finalFormProps.initialValues || {}, fields), [fields, finalFormProps.initialValues]);

  return (
    <Form
      {...finalFormProps}
      initialValues={initialValues}
    >
      {({ handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }} {...formProps}>
          {render(props)}
        </form>
      )}
    </Form>
  );
}
function JsonGroupForm() {
  const { sections } = useContext(JsonFormContext);
  const [state, setSections] = useState(sections);
  useMounted(() => {
    setSections(prev => {
      return sections.map((section, index) => ({
        ...prev[ index ],
        ...section
      }));
    });
  }, [sections]);
  const onEdit = useCallback((index: number) => {
    setSections(sections => {
      return sections.map((section, i) => {
        if (i === index) {
          return {
            ...section,
            view: "write"
          };
        }
        return {
          ...section,
          view: "disabled"
        };
      });
    });
  }, []);
  const onClear = useCallback(() => {
    setSections(sections => {
      return sections.map((section, i) => {
        return {
          ...section,
          view: "read"
        };
      });
    });
  }, []);

  return (
    <>
      {
        state.map((section, index) => (
          <SectionForm
            key={index}
            section={section}
            index={index}
            onEdit={onEdit}
            onClear={onClear}
          />
        ))
      }
    </>
  );
}
const SectionForm = React.memo<{ section: JsonSectionProps, onEdit, onClear, index }>(function SectionForm(props) {
  const { section, onEdit, onClear, index } = props;
  const { user, formProps, children, ...finalFormProps } = useContext(JsonFormContext);
  const handleClear = (form: FormApi<any>) => {
    form.reset();
    onClear();
  };

  const fields = useMemo(() => {
    let names = new Set<string>();
    section.fields.forEach(f => {
      names.add(section.path ? [section.path, f.name].join(".") : f.name);
    });
    return Array.from(names);
  }, [section]);
  const initialValues = useMemo(() => deepPick(finalFormProps.initialValues || {}, fields), [fields, finalFormProps.initialValues]);

  const onSubmit = useCallback(async (values, form, callback) => {
    let result;
    try {
      result = await finalFormProps.onSubmit(values, form, callback);
    } finally {
      setTimeout(() => {
        if (form.getState().submitSucceeded) {
          onClear();
        }
      });
    }
    return result;
  }, [finalFormProps.onSubmit]);

  function render(props) {
    const elements = (
      <JsonSection
        mode={initialValues?.id ? "edit" : "create"}
        user={user}
        {...section}
        onClear={() => handleClear(props.form)}
        onEdit={() => onEdit(index)}
        view={section.view || "read"}
      />
    );
    if (typeof children === "function") {
      return <>{children({ elements, ...props })}</>;
    }
    return <>{elements}{children}</>;
  }

  return (
    <Form
      {...finalFormProps}
      onSubmit={onSubmit}
      initialValues={initialValues}
      //initialValuesEqual={deepEqual}
      key={index}>
      {({ handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }} {...formProps}>
          {render(props)}
        </form>
      )}
    </Form>
  );
});
export type Mutators = { [ key: string ]: Mutator };
export const defaultMutators: Mutators = {
  ...arrayMutators,
  setFieldData: (args: any[], state) => {
    const [name, data] = args;
    const field = state.fields[ name ];
    if (field) {
      field.data = { ...field.data, ...data };
    }
  }
};
export const JsonFormContext = React.createContext<JsonFormProps>({
  className: undefined,
  group: false,
  sections: [],
  validators: [],
  onSubmit: null
});
export interface JsonFormRenderProps extends Omit<FormRenderProps<any, any>, "handleSubmit"> {
  elements: React.ReactNode;
}
type FunctionOrReactNode = ReactNode | ((props: JsonFormRenderProps) => ReactNode)
export interface JsonFormProps<P = Record<string, any>> extends Omit<FormProps, "children" | "component"> {
  className: string;
  user?: Partial<Viewer>;
  group?: boolean;
  sections: JsonSectionProps[];
  children?: FunctionOrReactNode;
  validators?: FieldLevelValidator[];
  formProps?: {
    [ key: string ]: any
  };
}
export type FieldLevelValidator<FieldValue = any> = {
  field: FieldPattern,
  validate: FieldValidator<FieldValue>
}

export const formUtils = {
  getFieldNames(sections: JsonSectionProps[]): string[] {
    let names = new Set<string>();
    sections.forEach(section => {
      section.fields.forEach(f => {
        names.add(section.path ? [section.path, f.name].join(".") : f.name);
      });
    });
    return Array.from(names);
  }
};
