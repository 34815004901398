import { useReactiveVar }   from "@apollo/client";
import { useForm }          from "@relcu/final-form";
import { usePrevious }      from "@relcu/ui";
import { useEffect }        from "react";
import React                from "react";
import { JsonSectionProps } from "@relcu/ui";
import { Button }           from "@relcu/ui";
import { ButtonVariants }   from "@relcu/ui";
import { JsonSection }      from "@relcu/ui";
import { reAuthVar }        from "../../../../reactiveVars";

export const PasswordSection = React.memo<JsonSectionProps>(function PasswordSection({ component, ...props }) {
  const form = useForm();
  const reAuth = useReactiveVar(reAuthVar);
  const previous = usePrevious(reAuth);

  useEffect(() => {
    if (previous && !reAuth) {
      form.submit();
    }
  }, [reAuth, previous, form]);

  if (props.view === "read" || props.view === "disabled") {
    return (
      <Button type="button"
              alignSelf={"end"}
              disabled={props.view === "disabled"}
              variant={ButtonVariants.Ghost}
              onClick={props.onEdit}
      >
        Change Password
      </Button>
    );
  }
  return <JsonSection {...props} onDone={(e) => {
    e.preventDefault();
    reAuthVar(true);
  }}/>;
});
