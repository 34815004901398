import React                            from "react";
import { FC }                           from "react";
import { useMemo }                      from "react";
import { useCallback }                  from "react";
import { ButtonColors }                 from "@relcu/ui";
import { Tooltip }                      from "@relcu/ui";
import { ButtonSizes }                  from "@relcu/ui";
import { Button }                       from "@relcu/ui";
import { Alignment }                    from "@relcu/ui";
import { IActionCellProps }             from "@relcu/ui";
import { getDefaultPhoneNumberForCall } from "../../../../../utils/helpers";
import { getBorrowerName }              from "../../../../../utils/helpers";
import { getPrimaryBorrower }           from "../../../../../utils/helpers";
import { getField }                     from "../../../../../utils/schemaUtils";
import { usePermissions }               from "../../../../AccessControl";
import { usePhone }                     from "../../../../usePhone";
import { useViewerPhoneLines }          from "../../../../useViewerPhoneLines";

export const CallAction: FC<IActionCellProps> = React.memo(function CallAction(props) {
  const { rowData, tooltip, bulkPermissions, singlePermissions, ...p } = props;
  const { states } = getField("Lead", "leadStatus");
  const phone = usePhone();
  const { hasMicrophoneIssue, defaultPhoneNumber, getLocalNumber } = useViewerPhoneLines();
  const { canUpdate } = usePermissions(rowData);
  const isLeadNode = rowData.__typename == "Lead";
  let primaryBorrower = isLeadNode ? getPrimaryBorrower(rowData) : rowData;
  let number = getDefaultPhoneNumberForCall(primaryBorrower?.contact?.phones, "Contact");
  const localFromNumber = getLocalNumber(number?.number);
  const isInCall = useMemo(() => {
    return phone?.hybridCalls?.initialDisplayCall.scopeId == rowData.objectId;
  }, [phone?.hybridCalls?.initialDisplayCall, rowData.objectId]);
  const disabled =
    states[ rowData?.leadStatus?.status ]?.not_contact ||
    !canUpdate ||
    (!!phone.active && !isInCall) ||
    (rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length) ||
    (isLeadNode && !localFromNumber && !defaultPhoneNumber) ||
    (!isLeadNode && !defaultPhoneNumber) ||
    !number ||
    number?.callOptOut ||
    !primaryBorrower?.contact?.phones?.length ||
    hasMicrophoneIssue;
  const onCall = useCallback((e, rowData) => {
    e.stopPropagation();
    if (isInCall) {
      if (phone.direction == "incoming") {
        phone.active.reject();
      } else {
        phone.active.drop();
      }
    } else if (!phone.active && !disabled) {
      let borrowerName = getBorrowerName(primaryBorrower);
      if (number && !number.callOptOut) {
        phone.call({
          from: isLeadNode ? (localFromNumber ?? defaultPhoneNumber?.value) : defaultPhoneNumber?.value,
          to: number.number,
          scopeId: rowData.objectId,
          scopeName: rowData.objectName,
          scopeClassName: rowData.__typename,
          contactId: primaryBorrower.contact.objectId,
          contactName: borrowerName
        });
        // navigate(`/lead/${rowData.objectId}`);
      }
    }

  }, [phone, defaultPhoneNumber, number, isInCall, disabled, primaryBorrower]);
  return <Tooltip title={tooltip} alignment={Alignment.Bottom}>
    <Button
      style={{ pointerEvents: "all" }}
      icon={isInCall ? "call_end" : "call"}
      color={isInCall ? ButtonColors.Error : ButtonColors.Success}
      onlyIcon
      size={ButtonSizes.Small}
      disabled={disabled}
      onClick={(e) => onCall(e, rowData)}/>
  </Tooltip>;
});
