import { DateTime }                             from "@relcu/date";
import { useMemo }                              from "react";
import { useEffect }                            from "react";
import { useCallback }                          from "react";
import { useState }                             from "react";
import { gql, useMutation }                     from "@apollo/client";
import { useAlert }                             from "@relcu/ui";
import { useLazySummary }                       from "@relcu/ui";
import { modal }                                from "@relcu/ui";
import { useSource }                            from "@relcu/ui";
import { EmptyDialog }                          from "@relcu/ui";
import { classNames }                           from "@relcu/ui";
import { FILE_FRAGMENT }                        from "../../../../../graph/operations.graphql";
import { DOCUMENT }                             from "../../../../../graph/operations.graphql";
import { NODE_FRAGMENT }                        from "../../../../../graph/operations.graphql";
import { schemaVar }                            from "../../../../../reactiveVars";
import { transformFields }                      from "../../../../../utils/graphQlUtils";
import { daysOfaYear }                          from "../../../../../utils/helpers";
import { toPointer }                            from "../../../../../utils/helpers";
import { getField }                             from "../../../../../utils/schemaUtils";
import { GetPendingOffersByLeadVariables }      from "../__types__/GetPendingOffersByLead";
import { GetPendingOffersByLead }               from "../__types__/GetPendingOffersByLead";
import { OFFER_FRAGMENT }                       from "../operations.graphql";
import { GET_PENDING_OFFERS_BY_LEAD }           from "../usePendingOffers";
import { usePendingOffers }                     from "../usePendingOffers";
import { CreateLoanProposal }                   from "./__types__/CreateLoanProposal";
import { CreateLoanProposalVariables }          from "./__types__/CreateLoanProposal";
import { UpdateOffer_updateOffer_offer }        from "./__types__/UpdateOffer";
import { UpdateOfferVariables }                 from "./__types__/UpdateOffer";
import { UpdateOffer }                          from "./__types__/UpdateOffer";
import { DIALOG_TOTAL_CLOSING_COST_SUM_FIELDS } from "./LoanProposalOfferConditions";
import { DIALOG_CLOSING_COST_SUM_FIELDS }       from "./LoanProposalOfferConditions";
import { DIALOG_MONTHLY_PAYMENT_SUM_FIELDS }    from "./LoanProposalOfferConditions";
import { DIALOG_PREPAID_INTEREST }              from "./LoanProposalOfferConditions";
import { DIALOG_PREPAID_INSURANCE }             from "./LoanProposalOfferConditions";
import { DIALOG_PREPAID_TAX }                   from "./LoanProposalOfferConditions";
import { DIALOG_PREPAID_COSTS_SUM_FIELDS }      from "./LoanProposalOfferConditions";
import { OfferClasses }                         from "./Offer/OfferClasses";

export function useLoadProposalDialog({ id, templateId, onClose, onPreview }) {
  const { edges, count, offerIds } = usePendingOffers(id);
  const schemas = schemaVar();
  const [update] = useMutation<UpdateOffer, UpdateOfferVariables>(UPDATE_OFFER);
  const loanPurposes = getField("Lead", "loanPurpose");
  const [prepaid, setPrepaid] = useState(true);
  const [closing, setClosing] = useState(true);
  const [monthly, setMonthly] = useState(true);
  const [openFiled, setOpenField] = useState("");
  const [includedOffers, setIncludedOffers] = useState(offerIds);
  const [proposalTitle, setProposalTitle] = useState(`Proposal - ${DateTime.now().toFormat("MMM dd yyyy/h:mm a")}`);
  const calculatePrepaidCost = useLazySummary({ fields: DIALOG_PREPAID_COSTS_SUM_FIELDS });
  const calculateTax = useLazySummary({ fields: DIALOG_PREPAID_TAX, operator: "multiply" });
  const calculateInsurance = useLazySummary({ fields: DIALOG_PREPAID_INSURANCE, operator: "multiply" });
  const calculateInterest = useLazySummary({ fields: DIALOG_PREPAID_INTEREST, operator: "multiply" });
  const calculateMonthlyPayment = useLazySummary({ fields: DIALOG_MONTHLY_PAYMENT_SUM_FIELDS });
  const calculateClosingCost = useLazySummary({ fields: DIALOG_CLOSING_COST_SUM_FIELDS });
  const calculateTotalClosingCost = useLazySummary({ fields: DIALOG_TOTAL_CLOSING_COST_SUM_FIELDS });
  // const applyUrl = window.__CONFIG__.applyUrl;
  const { $viewer: user } = useSource();
  const { objectId: userId } = user;
  const { success, error } = useAlert();
  const { objectId } = toPointer(id);
  const [create, { loading }] = useMutation<CreateLoanProposal, CreateLoanProposalVariables>(CREATE_LOAN_PROPOSAL, {
    update(cache) {
      const data = cache.readQuery<GetPendingOffersByLead, GetPendingOffersByLeadVariables>({
        query: GET_PENDING_OFFERS_BY_LEAD,
        variables: { id }
      });
      const edges = data.offers.edges.filter(({ node }) => !includedOffers.includes(node.objectId));

      cache.writeQuery<GetPendingOffersByLead, GetPendingOffersByLeadVariables>({
        query: GET_PENDING_OFFERS_BY_LEAD,
        variables: { id },
        data: {
          ...data,
          offers: {
            ...data.offers,
            edges: edges,
            count: edges.length
          }
        }
      });
    }
  });

  const validateMonth = useCallback((value) => {
    if (value < 0 || value > 12) {
      return true;
    }
  }, []);

  useEffect(() => {
    const offers = document.querySelectorAll(".offer");
    for (let i = 0; i < offers.length; i++) {
      (offers[ i ] as any).style.setProperty("--border-height", `${offers[ 0 ].scrollHeight - 10}px`);
    }
  }, [prepaid, closing, monthly]);

  const validateDay = useCallback((value) => {
    if (value < 0 || value > daysOfaYear(new Date().getFullYear())) {
      return true;
    }
  }, []);

  const onEdit = (name) => {
    setOpenField(prevState => prevState === name ? "" : name);
  };

  const checkRowExpand = (name) => {
    return openFiled.includes(name);
  };

  const applyClassName = (name) => {
    return classNames({
      [ OfferClasses.OfferExpanded ]: checkRowExpand(name)
    });
  };

  const handleSubmit = (data: UpdateOffer_updateOffer_offer) => {
    const fieldName = openFiled.split(":")[ 0 ];
    let prepaid = {};
    let monthly = {};
    let tax = calculateTax(data);
    let insurance = calculateInsurance(data);
    const interest = calculateInterest(data);
    switch (fieldName) {
      case "monthlyPayment.insurance":
        insurance = data.monthlyPayment.insurance * data.prepaid.monthsToPayInsurance;
        let yearlyInsurance;
        if (data.loanCriteria.loanPurpose == "purchase") {
          yearlyInsurance = data.monthlyPayment.insurance * 12;
        }
        prepaid = {
          monthlyInsurance: data.monthlyPayment.insurance,
          yearlyInsurance
        };
        break;
      case "monthlyPayment.tax":
        tax = data.monthlyPayment.tax * data.prepaid.monthsToPayTax;
        prepaid = {
          monthlyTax: data.monthlyPayment.tax
        };
        break;
      case "insurance":
        monthly = {
          insurance: data.prepaid.monthlyInsurance
        };
        if (data.loanCriteria.loanPurpose == "purchase") {
          prepaid = {
            yearlyInsurance: data.prepaid.monthlyInsurance * 12
          };
        }
        break;
      case "tax":
        monthly = {
          tax: data.prepaid.monthlyTax
        };
        break;
    }
    const offer = {
      lead: {
        link: data.lead.id
      },
      closingCost: {
        ...data.closingCost,
        total: calculateClosingCost({
          ...data,
          closingCost: {
            ...data.closingCost,
            sellerConcession: -data.closingCost.sellerConcession,
            earnestMoneyPaid: -data.closingCost.earnestMoneyPaid
          }
        })
      },
      monthlyPayment: {
        ...data.monthlyPayment,
        ...monthly,
        total: calculateMonthlyPayment({ ...data, monthlyPayment: { ...data.monthlyPayment, ...monthly } })
      },
      prepaid: {
        ...data.prepaid,
        ...prepaid,
        tax,
        insurance,
        interest,
        total: calculatePrepaidCost({ ...data, prepaid: { ...data.prepaid, ...prepaid, tax, insurance, interest } })
      },
      lenderCredits: data.lenderCredits,
      totalClosingCost: calculateTotalClosingCost({
        ...data,
        closingCost: {
          ...data.closingCost,
          sellerConcession: -data.closingCost.sellerConcession,
          earnestMoneyPaid: -data.closingCost.earnestMoneyPaid
        },
        prepaid: { ...data.prepaid, ...prepaid, tax, insurance, interest },
        lenderCredits: -data.lenderCredits
      })
    };
    update({
      variables: {
        input: {
          id: data.id,
          fields: {
            ...transformFields(offer, offer, "Offer", schemas),
            objectName: data.objectName
          }
        }
      }
    });
  };

  const handleGenerate = async () => {
    try {
      if (!templateId) {
        onClose();
        modal(EmptyDialog, {
          modalTitle: "No Loan Proposal templates were found",
          title: "There are no templates for loan proposal generation.",
          content: "Ask your administration to set up LP template",
          icon: "rc_lp"
        });
        return;
      }
      const { data: { createLoanProposal: { loanProposal: { file, objectId, image } } } } = await create({
        variables: {
          input: {
            fields: {
              objectName: proposalTitle,
              lead: {
                link: id
              },
              offers: {
                add: includedOffers
              },
              owner: {
                link: userId
              },
              applyUrl: user.applyUrl ?? ""
            }
          }
        }
      });
      success("Loan proposal saved successfully.");
      onPreview({file, proposalId:objectId, image});
      onClose();
    } catch (e) {
      console.error(e);
      error("Oops. Something went wrong!");
    }
  };

  const handleMonthlyToggle = (toggle) => {
    setMonthly(toggle);
  };
  const handleClosingToggle = (toggle) => {
    setClosing(toggle);
  };
  const handlePrepaidToggle = (toggle) => {
    setPrepaid(toggle);
  };

  const handleCancel = (form) => {
    form.setConfig("keepDirtyOnReinitialize", false);
    form.reset();
    form.setConfig("keepDirtyOnReinitialize", true);
  };

  const handleIncludeToggle = (objectId) => {
    setIncludedOffers(prev => {
      if (prev.includes(objectId)) {
        return prev.filter(p => p != objectId);
      }
      return [...prev, objectId];
    });
  };
  let sortedOffers = useMemo(() =>
    edges.slice().sort((a, b) =>
      a.node.selectedRate.quoteDetails.rate - b.node.selectedRate.quoteDetails.rate
    ), [edges]);

  let offers = useMemo(() => sortedOffers.map(offer => {
    const propertyVal = offer.node.loanCriteria.loanPurpose == "purchase" ? offer.node.loanCriteria.property.value : offer.node.loanCriteria.property.currentMortgageBalance;
    return {
      ...offer,
      node: {
        ...offer.node,
        cashAtClosing: offer.node.totalClosingCost + propertyVal - offer.node.loanCriteria.loanAmount
      }
    };
  }), [edges]);
  return {
    offers,
    count,
    openFiled,
    loanPurposes,
    prepaid,
    closing,
    monthly,
    includedOffers,
    proposalTitle,
    setProposalTitle,
    validateMonth,
    validateDay,
    loading,
    onEdit,
    handleGenerate,
    applyClassName,
    handleSubmit,
    handleMonthlyToggle,
    handleClosingToggle,
    handlePrepaidToggle,
    handleIncludeToggle,
    handleCancel
  };
}
export const CREATE_LOAN_PROPOSAL = gql`
  ${NODE_FRAGMENT}
  ${FILE_FRAGMENT}
  ${DOCUMENT}
  mutation CreateLoanProposal($input: CreateLoanProposalInput!) {
    createLoanProposal(input: $input) {
      loanProposal {
        ...Node
        ...Document
        file{
          ...File
        }
        image{
          ...File
        }

      }
    }
  }
`;
export const UPDATE_OFFER = gql`
  ${OFFER_FRAGMENT}
  mutation UpdateOffer($input:UpdateOfferInput!) {
    updateOffer(input: $input) {
      offer {
        ...Offer
      }
    }
  }
`;
