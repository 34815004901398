import React                 from "react";
import { FC }                from "react";
import { useState }          from "react";
import { FormSpy }           from "@relcu/final-form";
import { useForm }           from "@relcu/final-form";
import { useField }          from "@relcu/final-form";
import { Icon }              from "@relcu/rc";
import { IconButton }        from "@relcu/rc";
import { useClassNames }     from "@relcu/rc";
import { Typography }        from "@relcu/rc";
import { TabItem }           from "@relcu/rc";
import { TabPanel }          from "@relcu/rc";
import { TabPanelContainer } from "@relcu/rc";
import { TabContainer }      from "@relcu/rc";
import { Tabs }              from "@relcu/rc";
import { HiddenField }       from "@relcu/ui";
import { ISchema }           from "../../../../../../types/ISchemas";
import { Advanced }          from "./Advanced";
import { Basic }             from "./Basic";
import { StatusEditor }      from "./StatusEditor";

export const DetailsStep: FC<{
  schema: ISchema,
  mode: string,
  type: string
}> = React.memo(function DetailsStep({ schema, mode, type }) {
  const { withClassPrefix, prefix } = useClassNames("details-step-tab-container", "rc");
  const { input } = useField("type", { subscription: { value: true, initial: true } });
  const form = useForm();
  const [active, setActive] = useState(0);

  return (
    <TabContainer gap={16} outerState={[active, setActive]} className={withClassPrefix()}>
      <TabPanelContainer>
        <TabPanel>
          <Basic mode={mode}/>
        </TabPanel>
        <TabPanel>
          <>
            <HiddenField name={"name"}/>
            {
              type == "Status" ?
                <>
                  <StatusEditor/>
                </> :
                <Advanced schema={schema}/>
            }
          </>
        </TabPanel>
      </TabPanelContainer>
      <FormSpy
        subscription={{ submitErrors: true }}
        onChange={({ submitErrors }) => {
          if (submitErrors?.name) {
            setActive(0);
          }
        }}/>
      {
        mode == "create" &&
        <IconButton
          appearance={"text"}
          size={"sm"}
          className={prefix("back-button")}
          icon={<Icon type={"west"}/>}
          onClick={() => form.change("type", null)}
        />
      }
      <Typography weights={"regular"} variant={"large"} className={prefix("title", { "title-edit": mode != "create" })}>
        Add new <strong>{input.value}</strong> field
      </Typography>
      <Tabs style={{ justifyContent: "flex-end" }}>
        <TabItem active={true} className={prefix("name")}>
          Basic
        </TabItem>
        <TabItem
          active={false}
          disabled={schema.kind !== "collection" || input.value === "ACL"}
          className={prefix("name")}>
          Advanced
        </TabItem>
      </Tabs>
    </TabContainer>
  );
});
