import { FC }                from "react";
import React                 from "react";
import { useEffect }         from "react";
import { useState }          from "react";
import { DateTime }          from "@relcu/date";
import { classNames }        from "../../..";
import { Tooltip }           from "../../..";
import { BoxComponentProps } from "../../..";
import { Box }               from "../../..";
import { Alignment }         from "../../../constants/Alignemnt";

window[ "DateTime" ] = DateTime;
interface ListItemLocalTimeProps extends BoxComponentProps {
  value: string;
  label: string;
}

export const ListItemLocalTime: FC<ListItemLocalTimeProps> = React.memo(function ListItemLocalTime(props) {
  const { className, value, label, ...p } = props;
  const classes = classNames(ListItemLocalTimeClasses.ListItemLocalTime, className);
  if (!value) {
    return <Box className={classes} {...p}/>;
  }

  const [formattedTime, setFormattedTime] = useState(DateTime.local().setZone(value).toLocaleString(DateTime.TIME_SIMPLE));

  useEffect(() => {
    let timer = setInterval(() => setFormattedTime(DateTime.local().setZone(value).toLocaleString(DateTime.TIME_SIMPLE)), 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <Box className={classes} {...p}>
    <Tooltip title={label} alignment={Alignment.TopLeft}>
      <Box container direction={"row"} alignItems={"center"} gap={"XXXS"}>
        {formattedTime}
      </Box>
    </Tooltip>
  </Box>;
});

export enum ListItemLocalTimeClasses {
  ListItemLocalTime = "list-item-local-time"
}
