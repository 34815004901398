import { useReactiveVar }       from "@apollo/client";
import { Field }                from "@relcu/final-form";
import { FormSpy }              from "@relcu/final-form";
import { FormApi }              from "@relcu/final-form";
import { createCalculation }    from "@relcu/form";
import { defaultMutators }      from "@relcu/ui";
import { transformNameToLabel } from "@relcu/ui";
import { useMemo }              from "react";
import React                    from "react";
import { FC }                   from "react";
import { useLocation }          from "@relcu/react-router";
import { Button }               from "@relcu/rc";
import { Form }                 from "@relcu/rc";
import { Typography }           from "@relcu/rc";
import { ModalProps }           from "@relcu/rc";
import { Modal }                from "@relcu/rc";
import { layoutVar }            from "../../../../../reactiveVars";
import { ISchema }              from "../../../../../types/ISchemas";
import { NAME_VALIDATOR }       from "./Details/Basic";
import { ArrayConfig }          from "./Details/FieldConfigs/ArrayConfig";
import { BooleanConfig }        from "./Details/FieldConfigs/BooleanConfig";
import { ChoiceConfig }         from "./Details/FieldConfigs/ChoiceConfig";
import { DateConfig }           from "./Details/FieldConfigs/DateConfig";
import { EmailConfig }          from "./Details/FieldConfigs/EmailConfig";
import { NumberConfig }         from "./Details/FieldConfigs/NumberConfig";
import { ObjectConfig }         from "./Details/FieldConfigs/ObjectConfig";
import { PhoneConfig }          from "./Details/FieldConfigs/PhoneConfig";
import { PointerConfig }        from "./Details/FieldConfigs/PointerConfig";
import { StatusConfig }         from "./Details/FieldConfigs/StatusConfig";
import { StringConfig }         from "./Details/FieldConfigs/StringConfig";
import { VirtualConfig }        from "./Details/FieldConfigs/VirtualConfig";
import { FieldDialogNavigator } from "./FieldDialogNavigator";
import { TypeSelectionStep }    from "./TypeSelection";
import { DetailsStep }          from "./Details";
import JsonPath                 from "jsonpath";
import "./field-config-dialog.css";

export interface FieldConfigDialogProps extends Partial<ModalProps> {
  data: Record<string, any>;
  schema: ISchema;
  onSubmit(data: Record<string, any>, form: FormApi);
  mode: "update" | "create";
}

const FieldSteps = {
  TypeSelectionStep,
  DetailsStep
};

export const FIELD_TYPES = {
  String: {
    title: "String",
    value: "String",
    description: "String",
    icon: "title",
    component: StringConfig
  },
  Pointer: {
    title: "Pointer",
    value: "Pointer",
    description: "Pointer",
    icon: "linear_scale",
    component: PointerConfig
  },
  Date: {
    title: "Date",
    value: "Date",
    description: "Date",
    icon: "calendar_today",
    component: DateConfig
  },
  Choice: {
    title: "Choice",
    value: "Choice",
    description: "Choice",
    icon: "toc",
    component: ChoiceConfig
  },
  Number: {
    title: "Number",
    value: "Number",
    description: "Number",
    icon: "rc_custom-empty",
    component: NumberConfig
  },
  Percent: {
    title: "Percent",
    value: "Percent",
    description: "Percent",
    icon: "rc_percent",
    component: NumberConfig
  },
  Boolean: {
    title: "Boolean",
    value: "Boolean",
    description: "Boolean",
    icon: "power_settings_new",
    component: BooleanConfig
  },
  Object: {
    title: "Object",
    value: "Object",
    description: "Object",
    icon: "rc_data_object",
    component: ObjectConfig
  },
  Array: {
    title: "Array",
    value: "Array",
    description: "Array",
    icon: "view_array",
    component: ArrayConfig
  },
  Email: {
    title: "Email",
    value: "Email",
    description: "Email",
    icon: "email",
    component: EmailConfig
  },
  Phone: {
    title: "Phone",
    value: "Phone",
    description: "Phone",
    icon: "phone",
    component: PhoneConfig
  },
  Virtual: {
    title: "Virtual",
    value: "Virtual",
    description: "Virtual",
    icon: "cloud",
    component: VirtualConfig
  },
  Status: {
    title: "Status",
    value: "Status",
    description: "Status",
    icon: "published_with_changes",
    component: StatusConfig
  }
};

export const FieldConfigDialog: FC<FieldConfigDialogProps> = React.memo(function FieldConfigDialog(props) {
  const { open, onClose, mode, data, schema, onSubmit } = props;
  const name = data?.type === "Status" ? `${data.name}.status` : data?.name;
  const layouts = useReactiveVar(layoutVar);
  const visibility = useMemo(() => {
    const width = 200;
    if (mode === "update") {
      const visibilityConfig = VisibilityPaths[ schema.className ];
      if (visibilityConfig) {
        return Object.keys(visibilityConfig).reduce((obj, key) => {
          const isVisible = visibilityConfig[ key ].every(({ path }) => {
            try {
              const array = JsonPath.query(layouts, path);
              if (Array.isArray(array)) {
                return !!array.find(c => c.name === name);
              }
            } catch (e) {
              return false;
            }
          });
          if (isVisible) {
            obj[ key ] = isVisible;
          }
          return obj;
        }, { width });
      }
    }
    return { width };
  }, [layouts, name, mode, schema]);
  const initialValues: Record<string, any> = {
    visibility,
    ...data
  };
  if (["Object", "Pointer", "Array"].includes(data?.type)) {
    if (Array.isArray(data?.targetClass)) {
      initialValues.targetClass = data.targetClass;
    } else if (data?.targetClass) {
      initialValues.targetClass = [data.targetClass];
    } else {
      initialValues.targetClass = [];
    }
  }
  if (data?.type == "Status") {
    initialValues.defaultValue = data.defaultValue ?? {};
    initialValues.value = {
      states: data.states ?? {},
      actions: data.actions ?? {},
      transitions: data.transitions ?? {}
    };
  }
  const handleSubmit = (values, form) => {
    if (!NAME_VALIDATOR.test(values.name)) {
      return {
        name: "Invalid name."
      };
    }

    let data = { ...values };

    if (data.type == "Status") {
      data = { ...data, ...data.value };
      delete data.value;
    }

    if (data.type == "Choice") {
      delete data.numeric;
    }

    if (data.type == "Array" || data.type == "Object") {
      if (data.targetClass.length == 1) {
        data.targetClass = data.targetClass[ 0 ];
      } else if (data.targetClass.length == 0) {
        delete data.targetClass;
      }
    }

    onSubmit(data, form);
  };
  return (
    <Modal open={open} onClose={onClose} size={"md"}>
      <Form
        mutators={{ ...defaultMutators }}
        keepDirtyOnReinitialize={true}
        decorators={[decorator]}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        <Modal.Header>
          <Typography weights={"medium"} variant={"base16"}>{mode == "create" ? "Create" : "Edit"} field</Typography>
        </Modal.Header>
        <Modal.Body>
          <FieldDialogNavigator/>
          <Field name={"type"}>
            {
              ({ input }) => <StepRenderer schema={schema} mode={mode} type={input.value}/>
            }
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <Button type={"button"} size={"xs"} onClick={props.onClose} color={"blue"} appearance="text">CANCEL</Button>
          <FormSpy subscription={{ submitting: true }}>
            {({ submitting, pristine }) =>
              <Button
                disabled={submitting || pristine}
                type={"submit"}
                size={"xs"}
                appearance="primary">
                SUBMIT
              </Button>
            }
          </FormSpy>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export const StepRenderer: FC<{
  schema: ISchema;
  mode: string,
  type: string
}> = React.memo(function StepRenderer(props) {
  const { state } = useLocation();
  const Step = FieldSteps[ state?.[ "step" ] ];

  return (
    <Step {...props}/>
  );
});

export const VisibilityPaths = {
  Lead: {
    table: [
      {
        path: "$.Home.tabs[?(@.path=='leads')].properties.fields.*"// Home Leads View
      },
      {
        path: "$.Home.tabs[?(@.path=='leads')].properties.filters.fields.*"// Home Create Page View
      },
      {
        path: "$.User.tabs[?(@.path=='lead')].properties.fields.*" // User Leads View
      },
      {
        path: "$.User.tabs[?(@.path=='lead')].properties.filters.fields.*"// User Leads Create page view
      }
    ],
    focus: [
      {
        path: "$.User.tabs[?(@.path=='focus')].properties.fields.*"// User Focus view
      },
      {
        path: "$.Settings.tabs[?(@.path=='focus-configuration')].properties.fields.*"// Focus configuration
      },
      {
        path: "$.PriorityQueue.dialog.properties.leadFilters.*" // Focus filter configuration
      }
    ],
    distribution: [
      {
        path: "$.Settings.tabs[?(@.path=='queue-settings')].properties.dialog.properties.leadFilters.*" // Setting Distribution View
      }
    ]
  },
  RelcuLoan: {
    table: [
      {
        path: "$.User.tabs[?(@.path=='relcuLoan')].properties.fields.*" // User Leads View
      },
      {
        path: "$.User.tabs[?(@.path=='relcuLoan')].properties.filters.fields.*"// User Leads Create page view
      }
    ]
  },
  User: {
    table: [
      {
        path: "$.Home.tabs[?(@.path=='users')].properties.fields.*"// Home Leads View
      },
      {
        path: "$.Home.tabs[?(@.path=='users')].properties.filters.fields.*"// Home Create Page View
      }
    ]
  },
  Contact: {
    table: [
      {
        path: "$.Home.tabs[?(@.path=='contacts')].properties.fields.*"// Home Leads View
      },
      {
        path: "$.Home.tabs[?(@.path=='contacts')].properties.filters.fields.*"// Home Create Page View
      }
    ]
  }
};

const decorator = createCalculation({
  field: "name",
  isEqual(a, b) {
    if (typeof b === "undefined") {
      return true;
    }
    return a === b;
  },
  updates: {
    label: (value) => {
      if (value) {
        return transformNameToLabel(value);
      }
    }
  }
});
