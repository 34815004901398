import { useMemo }          from "react";
import { useCallback }      from "react";
import { groupBy }          from "../../../utils/helpers";
import { StatusFieldProps } from "./StatusField";

export function useStatusField({ states }: StatusFieldProps) {
  const api = {
    format: (value) => value ? value.status : "",
    parse: (status) => (status && { status, action: "manual" }),
    options: () => {
      const statuses = Object.entries<{ stage: string, status: string }>(states).map(([value, { stage, status }]) => {
        return { value, label: status, parent: stage };
      });
      const byStages = groupBy(statuses, "parent");
      return Object.entries(byStages).reduce((previousValue, currentValue) => {
        return [...previousValue, { value: currentValue[ 0 ], label: currentValue[ 0 ], disabled: true }, ...currentValue[ 1 ]];
      }, []);
    }
  };
  const format = useCallback(api.format, []);
  const parse = useCallback(api.parse, []);
  const options = useMemo(api.options, []);
  return {
    ...api,
    format,
    options,
    parse
  };
}
