import React, { useState } from "react";
import { FC }              from "react";
import { DateTime }        from "@relcu/date";
import { ChipsColors }     from "@relcu/ui";
import { ChipsSizes }      from "@relcu/ui";
import { Chips }           from "@relcu/ui";
import { Input }           from "@relcu/rc";
import { useClassNames }   from "@relcu/rc";
import { IconButton }      from "@relcu/rc";
import { ToggleDropdown }  from "@relcu/rc";
import { Typography }      from "@relcu/rc";
import { Icon }            from "@relcu/rc";
import { Stack }           from "@relcu/rc";
import "./generation-list-card.css";

enum TypeToIcon {
  plainText = "article",
  html = "code",
  builder = "dashboard_customize"
}

export interface GenerationListCardProps {
  title: string;
  objectId: string;
  date: string;
  canUpdate?: boolean;
  selected?: boolean;
  enabled?: boolean;
  onClick?();
  onUpdate?(id: string, title: string);
  onDuplicate?();
  onRemove?();
  type?: string;
}

export const GenerationListCard: FC<GenerationListCardProps> = React.memo(function GenerationListCard(props) {
  const { type, title, date, selected, enabled, onClick, objectId, onUpdate, onRemove, onDuplicate, canUpdate } = props;
  const [templateName, setTemplateName] = useState(title);
  const [editItem, setEditItem] = useState(null);

  const { withClassPrefix, prefix, merge } = useClassNames("generation-item", "rc");
  const classes = merge(
    withClassPrefix({ selected })
  );

  return <Stack direction={"column"} alignItems={"flex-start"} className={classes} childrenRenderMode={"clone"}
                onClick={onClick}>
    <Stack
      spacing={4}
      justifyContent={"center"}
      childrenRenderMode={"clone"}
      style={{ justifyContent: "flex-start", alignSelf: "stretch" }}>
      {
        type &&
        <Icon type={TypeToIcon[ type ]} size={20} className={prefix("icon")}/>
      }
      {
        editItem === objectId ?
          <Stack childrenRenderMode={"clone"} spacing={8}>
            <Input defaultValue={title} size={"xs"} style={{ height: "20px" }} autoFocus={true}
                   onChange={setTemplateName}/>
            <Stack childrenRenderMode={"clone"}>
              <Icon onClick={() => {
                onUpdate(objectId, templateName);
                setEditItem(null);
              }} type="check" style={{ color: "var(--rc-accent-03-primary)" }}/>
              <Icon onClick={() => {
                setEditItem(null);
              }} type="clear" style={{ color: "var(--rc-accent-03-primary)" }}/>
            </Stack>
          </Stack> :
          <Typography variant={"base14"} lineHeight={"lh20"} className={"ell-flex"}>{title}</Typography>
      }
      {
        enabled === false && <>
          <Stack.Item style={{ flex: 1 }}/>
          <Chips size={ChipsSizes.Small} color={ChipsColors.Grey} label={"Disabled"}/>
        </>
      }
    </Stack>
    <Stack spacing={4} alignItems={"flex-start"} className={type && prefix("date")}>
      <Typography variant={"small10"}
                  color={"tertiary"}>{DateTime.fromISO(date).toFormat("dd.MM.yyyy")}</Typography>
    </Stack>
    {
      canUpdate &&
      <ToggleDropdown
        onClick={(event) => event.stopPropagation()}
        toggleRenderer={
          <IconButton
            className={prefix("dropdown-toggle")}
            size={"xs"}
            appearance={"subtle"} icon={<Icon type="more_vert"/>}/>
        }
        placement={"autoVerticalEnd"}>
        <ToggleDropdown.Item
          eventKey={3}
          onSelect={(event) => {
            setEditItem(objectId);
          }}>
          <Icon type="create"/>
          Rename
        </ToggleDropdown.Item>
        {
          onDuplicate &&
          <ToggleDropdown.Item
            eventKey={3}
            onSelect={onDuplicate}>
            <Icon type="content_copy"/>
            Duplicate
          </ToggleDropdown.Item>
        }
        <ToggleDropdown.Item
          eventKey={3}
          onSelect={onRemove}>
          <Icon type="delete"/>
          Remove
        </ToggleDropdown.Item>
      </ToggleDropdown>
    }
  </Stack>;
});
