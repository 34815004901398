import React                             from "react";
import { FC }                            from "react";
import { useMemo }                       from "react";
import { useEffect }                     from "react";
import { DateTime }                      from "@relcu/date";
import { Typography }                    from "@relcu/rc";
import { BadgeVariants, getCallContact } from "@relcu/ui";
import { ItemsEllipsis }                 from "@relcu/ui";
import { ChipsSizes }                    from "@relcu/ui";
import { ChipsColors }                   from "@relcu/ui";
import { Chips }                         from "@relcu/ui";
import { Ellipsis }                      from "@relcu/ui";
import { BadgeColors }                   from "@relcu/ui";
import { ListItemIcon }                  from "@relcu/ui";
import { IconType }                      from "@relcu/ui";
import { Badge }                         from "@relcu/ui";
import { ListItemDate }                  from "@relcu/ui";
import { AvatarSizes }                   from "@relcu/ui";
import { Avatar }                        from "@relcu/ui";
import { Box }                           from "@relcu/ui";
import { classNames }                    from "@relcu/ui";
import { BoxComponentProps }             from "@relcu/ui";
import { useSource }                     from "@relcu/ui";
import { useWindowDimensions }           from "@relcu/ui";
import { pad }                           from "../../../utils/helpers";
import { ListItemClasses }               from "./ListItemClasses";
import "./list-item.css";

export interface ListCallItemProps extends BoxComponentProps {
  selected?: boolean,
  call: any,
  onMarkRead(id: string)
}
export const ListCallItem = React.forwardRef<any, ListCallItemProps>(function ListCallItem(props, ref) {
  const { className, children, call, selected, onMarkRead, ...p } = props;
  const { $object, $viewer: user } = useSource();
  const { width } = useWindowDimensions();

  const viewerIsParty = useMemo(() => (call.calls.findIndex((call) => (call?.party?.objectId == user.objectId)) > -1), [call, user]);
  useEffect(() => {
    if (selected && call.unread && viewerIsParty) {
      onMarkRead(call.id);
    }
  }, [selected, call.unread, viewerIsParty]);

  const classes = classNames(ListItemClasses.ListItem,
    ListItemClasses.ListItemCall, {
      [ ListItemClasses.ListItemActive ]: (viewerIsParty && !!call.unread),
      [ ListItemClasses.ListItemSelected ]: selected
    }, className);

  const contact = getCallContact(call);
  const start = call.startDate ? DateTime.fromISO(call.startDate) : DateTime.fromISO(call.createdAt);
  const end = (call.startDate && call.endDate) ? DateTime.fromISO(call.endDate) : start;
  const duration = end.diff(start, ["hours", "minutes", "seconds"]);

  return <Box container direction={"column"} gap={"XS"} className={classes} ref={ref} {...p}>
    <Box container gap={"XXS"} alignItems={"center"} justify={"space-between"}>
      <Box container alignItems={"center"} gap={"XXS"} flexBasis={145}>
        <CallDirectionIcon call={call}/>
        <Box container gap={"XXS"}>
          {contact && <Avatar text={contact.objectName} icon={contact.objectIcon}
                              size={AvatarSizes.Small}/>}
          <Ellipsis from={$object.__typename === "User" ? 20 : 14} className={ListItemClasses.ListItemText}>
            {contact?.objectName}
          </Ellipsis>
        </Box>
      </Box>
      <Box flex={1}/>
      <ListItemDate date={call.createdAt} format={"withTime"}/>
      {
        (viewerIsParty && !!call.unread) &&
        <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>
      }
    </Box>
    <Box container gap={"XXS"} alignItems={"center"}>
      {
        call.status == "in-progress" ?
          <Typography
            color={"secondary"}>
            Call in progress...
          </Typography>
          :
          <Typography
            color={"secondary"}>
            {`${pad(duration.hours)}:${pad(duration.minutes)}:${pad(Math.round(duration.seconds))}`}
          </Typography>
      }
      <Box flex={1}/>
      <ItemsEllipsis
        showLength={1}
        items={call.tags}
        renderItem={(item, index) => <Chips
          thumbnail={<Badge variant={BadgeVariants.Dot}/>}
          length={7}
          key={index}
          color={ChipsColors.Grey}
          label={item}
          size={ChipsSizes.Small}
        />}
        ellipsis={(count) => <Chips
          length={7}
          color={ChipsColors.Grey}
          label={`+${count}`}
          size={ChipsSizes.Small}
        />}
      />
    </Box>
  </Box>;
});

const CallDirectionIcon: FC<{ call: any }> = React.memo(({ call }) => {
  //const isMissed = (!call.startDate && call.status === "completed");
  let icon: IconType;
  if (call.missed) {
    icon = call.direction === "inbound" ? (call.voicemail ? "rc_missed_voicemail" : "phone_missed") : "rc_outgoing_missed";
  } else {
    //todo change icon with outbound voice
    icon = call.direction == "inbound" ? "rc_incoming_call" : "rc_outgoing_call";
  }
  return <ListItemIcon className={classNames({ [ "error" ]: call.missed })} type={icon}/>;
});
