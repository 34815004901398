import React               from "react";
import { FC }              from "react";
import { useField }        from "@relcu/final-form";
import { classNames }      from "@relcu/ui";
import { Divider }         from "@relcu/rc";
import { Stack }           from "@relcu/rc";
import { ISchema }         from "../../../../../../types/ISchemas";
import { Addition }        from "../Addition";
import { VisibilityPaths } from "../FieldConfigDialog";
import { Visibility }      from "../Visibility";

export const Advanced: FC<{ schema: ISchema }> = React.memo(function Advanced({ schema }) {
  const { input } = useField("type", { subscription: { value: true, initial: true } });
  const showVisibility = !!VisibilityPaths[ schema.className ] && !(input.value === "Pointer" || input.value === "Object" || input.value === "Array");
  return (
    <Stack direction={"column"} style={{ flex: 1 }} spacing={32} childrenRenderMode={"clone"} alignItems={"stretch"}>
      {showVisibility && <>
        <Visibility schema={schema}/>
        <Divider/>
      </>}
      <Addition className={classNames({ "rc-field-addition-section": !showVisibility })}/>
    </Stack>
  );
});
