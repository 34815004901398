import { makeVar }       from "@apollo/client";
import { Sound }         from "./Sound";
import { Device }        from "./Tw";
import { MailSendInput } from "./types/graphql-global-types";
import { ILayouts }      from "./types/ILayouts";
import { ISchemas }      from "./types/ISchemas";

export const isAuthenticatedVar = makeVar<boolean>(false);
export const versionVar = makeVar<string>(null);
export const layoutUpdatedVar = makeVar<string>(null);
export const schemaUpdatedVar = makeVar<string>(null);

/*export const configVar = makeVar<Config>(
 window.__CONFIG__
 );*/

export const loadingVar = makeVar<boolean>(false);
export const fullScreenVar = makeVar<boolean>(false);
export const leadPricingVar = makeVar<{ [ k: string ]: any }>({});
export const microphoneAccessVar = makeVar<boolean>(true);
export const messageDraftsVar = makeVar<MessageDraftsVar>({});
export const emailDraftsVar = makeVar<EmailDraftsVar>({});
export const audioDevicesVar = makeVar<AudioDevicesVar>({ audioinput:undefined, audiooutput: undefined });
export const audioOutputIdVar = makeVar<string>(null);
export const audioNotificationOutputIdVar = makeVar<string>(null);
export const audioInputIdVar = makeVar<string>(null);
export const activeQueueVar = makeVar<number>(null);
export const mailInputVar = makeVar<MailSendInput>(null);
export const visibleQueuesVar = makeVar<string[]>([]);
export const expiresVar = makeVar<number>(null);
export const reAuthVar = makeVar<boolean>(false);
export const snackbarVar = makeVar<{
  previous: string
  current: string
}>({
  previous: null,
  current: null
});
export const deviceVar = makeVar<Device>({
  audio: null,
  advancedPhone: null,
  state: "offline",
  status: null,
  hybridCalls: null,
  direction: null,
  incoming: []
});
export const layoutVar = makeVar<ILayouts>(null);
export const schemaVar = makeVar<ISchemas>(null);
export const soundVar = makeVar<Sound>(null);

export const orgSchemaVar = makeVar<any>(null)
export interface MessageDraftTemplate {
  objectId: string;
  value: string;
  label: string;
}

export interface MessageDraft {
  attachment: Array<{ attachment: Partial<any> }>;
  template?: MessageDraftTemplate;
  proposalId?: string;
  loanEstimateId?: string;
  type?: string;
}

export interface MessageDraftsVar {
  [ nodeId: string ]: MessageDraft;
}

export interface AudioDevicesVar {
  audioinput: AudioDevice[];
  audiooutput: AudioDevice[];
}

export interface AudioDevice {
  deviceId: string,
  groupId: string,
  kind: string,
  label: string,
}

export interface EmailDraft {
  subject: string;
  to?: string;
  attachments?: any;
  proposalId?: string;
  loanEstimateId?: string;
}
export interface EmailDraftsVar {
  [ nodeId: string ]: EmailDraft;
}

export interface ModalVar {
  id: string,
  // type: DialogTypes,
  // props: DialogProps
}
