import React          from "react";
import { FC }         from "react";
import { useForm }    from "@relcu/final-form";
import { FormSpy }    from "@relcu/final-form";
import { OnChange }   from "@relcu/form";

export const PmiChangeTracker: FC<any> = React.memo(function PmiChangeTracker(props) {
  const form = useForm();
  return (
    <FormSpy subscription={{ active: true }}>
      {({ active }) => {
        return <OnChange name={"pmi"} children={(value) => {
          if (active == "pmi") {
            form.change("pmiCompany", "custom")
          }
        }}/>;
      }}
    </FormSpy>
  );
});
