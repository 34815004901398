import React                        from "react";
import { FC }                       from "react";
import { mode }                     from "@relcu/ui";
import { Section }                  from "@relcu/ui";
import { CallableHoursEditSection } from "./CallableHoursEditSection";
import { CallableHoursReadSection } from "./CallableHoursReadSection";
import "./callable-hours-section.css";

export const CallableHoursSection: FC<any> = React.memo(function CallableHoursSection(props) {
  const { fields: jsonFields, ...rest } = props;
  const viewMode = mode(rest.view);

  return <Section {...rest}>
    {
      viewMode == "write" ?
        <CallableHoursEditSection {...props}/>
        :
        <CallableHoursReadSection {...props}/>
    }
  </Section>;
});
