import React             from "react";
import { FC }            from "react";
import { Field }         from "@relcu/final-form";
import { Typography }    from "@relcu/rc";
import { Icon }          from "@relcu/rc";
import { IconType }      from "@relcu/rc";
import { useClassNames } from "@relcu/rc";
import { Stack }         from "@relcu/rc";
import { StandardProps } from "rsuite-table/src/@types/common";
import "./field-type-radio-button.css";

export interface FieldTypeRadioButton extends StandardProps {
  title: string,
  description: string,
  icon: IconType,
  value: string
}

export const FieldTypeRadioButton: FC<FieldTypeRadioButton> = React.memo(function FieldTypeRadioButton(props) {
  const { className, icon, title, description, value } = props;
  const { merge, withClassPrefix, prefix } = useClassNames("type-radio-button", "rc");
  const classes = merge(className, withClassPrefix());
  return (
    <>
      <Field
        type={"radio"}
        value={value}
        name="type">
        {
          ({ input, meta }) => {
            return (
              <label className={classes}>
                <Stack childrenRenderMode={"clone"} spacing={8}>
                  <input type="radio" name={input.name} value={value} checked={input.checked} onChange={input.onChange}/>
                  <Icon type={icon} size={24}/>
                  <Stack direction={"column"} spacing={8}>
                    <Typography weights={"medium"}>{title}</Typography>
                    <Typography color={"tertiary"} variant={"small12"}>{description}</Typography>
                  </Stack>
                </Stack>
              </label>
            );
          }
        }
      </Field>
    </>
  );
});
