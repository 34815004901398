import React                                from "react";
import { FC }                               from "react";
import { useCallback }                      from "react";
import { useContext }                       from "react";
import { useMemo }                          from "react";
import { useQuery }                         from "@apollo/client";
import { useApolloClient }                  from "@apollo/client";
import { useLazyQuery }                     from "@apollo/client";
import { gql }                              from "@apollo/client";
import { useMutation }                      from "@apollo/client";
import { setIn }                            from "@relcu/final-form";
import { FormApi }                          from "@relcu/final-form";
import { getIn }                            from "@relcu/final-form";
import { arrayMutators }                    from "@relcu/final-form";
import { Form }                             from "@relcu/rc";
import { EmptyModal }                       from "@relcu/rc";
import { modal }                            from "@relcu/ui";
import { pick }                             from "@relcu/ui";
import { useLatest }                        from "@relcu/ui";
import { deepEqual }                        from "@relcu/ui";
import { omit }                             from "@relcu/ui";
import { useModal }                         from "@relcu/ui";
import { useSource }                        from "@relcu/ui";
import { LoanEstimateOffer }                from "../../../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE_OFFER }              from "../../../../../../graph/operations.graphql";
import { cleanObject }                      from "../../../../../../utils/helpers";
import { useSchemaField }                   from "../../../../useSchemaField";
import { GetDefaultOffer }                  from "../../__types__/GetDefaultOffer";
import { GetDefaultOfferRatesVariables }    from "../../__types__/GetDefaultOfferRates";
import { GetDefaultOfferRates }             from "../../__types__/GetDefaultOfferRates";
import { Proposal }                         from "../../Proposal";
import { GetLoanEstimateVariables }         from "../../Proposal/__types__/GetLoanEstimate";
import { GetLoanEstimate }                  from "../../Proposal/__types__/GetLoanEstimate";
import { GET_LOAN_ESTIMATE }                from "../../Proposal/ProposalProvider";
import { IneligibleRateDialog }             from "../../RateTable/IneligibleRateDialog";
import { GET_DEFAULT_OFFER_RATES }          from "../../useDefaultOffer";
import { useAppendObCustomFields }          from "../../useDefaultOffer";
import { totalLoanAmount }                  from "../../utils";
import { CreateLoanEstimateOfferVariables } from "./__types__/CreateLoanEstimateOffer";
import { CreateLoanEstimateOffer }          from "./__types__/CreateLoanEstimateOffer";
import { GetPricingRatesVariables }         from "./__types__/GetPricingRates";
import { GetPricingRates }                  from "./__types__/GetPricingRates";
import { UpdateLoanEstimateOfferVariables } from "./__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "./__types__/UpdateLoanEstimateOffer";
import { FeeContext }                       from "./FeeProvider";
import { OfferCalculate }                   from "./OfferCalculate";
import { OfferHeader }                      from "./OfferHeader";
import { PricingEngineContext }             from "./PricingEngineProvider";
import { useCalculations }                  from "./useCalculations";

export const Offer: FC<{
  data: Partial<LoanEstimateOffer>,
  onRatesRedirect?: (offerId) => void
}> = React.memo((props) => {
  const { children, data, onRatesRedirect } = props;
  const { $object: lead } = useSource();
  const [ineligibleModal, contextHolder] = useModal(IneligibleRateDialog);
  const { data: { loanEstimateOffers: { edges = [] } = {} } = {} } = useQuery<GetDefaultOfferRates, GetDefaultOfferRatesVariables>(GET_DEFAULT_OFFER_RATES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      leadId: lead.id
    }
  });
  const { rate = 0, apr = 0, price = 100 } = Object(edges?.at(0)?.node);

  const calculate = useCalculations();
  const loanEstimate = useContext(Proposal.Context);
  const { populateTitleFees } = useContext(FeeContext);
  const { defaultValue: pricingEngineDefaultValue } = useSchemaField("LoanEstimateOffer", "pricingEngine");
  const { defaultValue: lockInDaysDefaultValue } = useSchemaField("LoanEstimateOffer", "lockInDays");
  const { defaultValue: veteranStatusDefaultValue } = useSchemaField("LoanEstimateOffer", "veteranStatus");
  const { defaultValue: documentTypeDefaultValue } = useSchemaField("LoanEstimateOffer", "documentType");
  const { defaultValue: initialArmTermValue } = useSchemaField("LoanEstimateOffer", "initialArmTerm");
  const { defaultValue: dtiDefaultValue } = useSchemaField("LoanEstimateOffer", "dti");

  const { defaultValue: obProductTypesDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.productTypes");
  const { defaultValue: automatedUnderwritingSystemDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.automatedUnderwritingSystem");
  const { defaultValue: prepaymentPenaltyDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.prepaymentPenalty");
  const { defaultValue: obViewDefaultValue } = useSchemaField("LoanEstimateOffer", "optimalBlue.view");
  const { defaultValue: deliveryType } = useSchemaField("LoanEstimateOffer", "mortech.deliveryType");

  const { defaultValue: pollyAutomatedUnderwritingSystemDefaultValue } = useSchemaField("LoanEstimateOffer", "polly.automatedUnderwritingSystem");
  const { defaultValue: pollyPrepaymentPenaltyDefaultValue } = useSchemaField("LoanEstimateOffer", "polly.prepaymentPenalty");

  const client = useApolloClient();
  const { $settings: { pricing: settings } } = useSource();
  const fico = lead.members.find((members) => members.type == "borrower")?.creditScore;
  const fico1 = lead.members.find((members) => members.type == "co_borrower")?.creditScore;
  const formData = useMemo(() => {
    return pick(data, [
      "id",
      "objectId",
      "objectName",
      "mortech.investor",
      "mortech.loanProductId",
      "mortech.source",
      "mortech.view",
      "mortech.deliveryType",
      "optimalBlue.productTypes",
      "optimalBlue.automatedUnderwritingSystem",
      "polly.productTypes",
      "polly.automatedUnderwritingSystem",
      "pricingEngine",
      "loanProduct",
      "productType",
      "conforming",
      "amortizationType",
      "loanTerm",
      "propertyValue",
      "isHUDReo",
      "cashAmount",
      "currentMortgageBalance",
      "loanAmount",
      "mip",
      "financeMip",
      "ff",
      "financeFf",
      "pmiEligible",
      "totalLoanAmount",
      "downPayment",
      "ltv",
      "cltv",
      "dti",
      "secondaryFinancing",
      "fico",
      "fico1",
      "firstTimeHomeBuyer",
      "selfEmployed",
      "isStreamLine",
      "withAppraisal",
      "withCredit",
      "firstTimeUse",
      "firstUseOfVaProgram",
      "veteranStatus",
      "exempt",
      "waiveEscrow",
      "lockInDays",
      "amiWaiverEligibility",
      "loanPurpose",
      "initialArmTerm",
      "documentType",
      "propertyUse",
      "propertyAnnualInsurance",
      "propertyAnnualTax",
      "propertyAnnualInsuranceUnit",
      "propertyAnnualTaxUnit",
      "monthlyIncome",
      "lienAmount"
    ], true);
  }, [data]);

  const context = useContext(PricingEngineContext);
  const initialValues = useMemo(() => {
    let initialData: Partial<LoanEstimateOffer> = formData;
    if (!data.objectId) {
      let borrower = lead.members.find((b) => b.type == "borrower");
      initialData = {
        propertyUse: getIn(lead, "property.use"),
        propertyType: loanEstimate.propertyType,
        pricingEngine: pricingEngineDefaultValue,
        propertyValue: getIn(lead, "property.value") || getIn(lead, "property.estimatedValue"),
        loanAmount: getIn(lead, "loanAmount"),
        secondaryFinancing: getIn(lead, "secondaryFinancing"),
        cltv: getIn(lead, "cltv"),
        downPayment: getIn(lead, "downPayment"),
        fico: fico,
        fico1: fico1,
        lockInDays: lockInDaysDefaultValue,
        amortizationType: "fixed",
        conforming: true,
        loanTerm: "360",
        productType: "conventional",
        documentType: documentTypeDefaultValue,
        initialArmTerm: initialArmTermValue,
        firstUseOfVaProgram: getIn(lead, "firstUseOfVaProgram"),
        veteranStatus: getIn(borrower, "veteranStatus") || veteranStatusDefaultValue,
        financeFf: getIn(lead, "financeFf"),
        financeMip: getIn(lead, "financeMip"),
        dti: getIn(lead, "dti") || dtiDefaultValue,
        waiveEscrow: getIn(lead, "waiveEscrow"),
        monthlyIncome: borrower.monthlyIncome,
        propertyAnnualInsurance: getIn(lead, "property.annualInsurance"),
        propertyAnnualTax: getIn(lead, "property.annualTaxes"),
        get currentMortgageBalance() {
          const currentMortgageBalance = getIn(lead, "property.currentMortgageBalance");
          if (loanEstimate.loanPurpose === "cash_out_refinance") {
            return (!currentMortgageBalance || this.loanAmount < currentMortgageBalance) ? Math.round(this.loanAmount * 100) / 100 : Math.round(currentMortgageBalance * 100) / 100;
          } else if (loanEstimate.loanPurpose === "rate_term_refinance") {
            return currentMortgageBalance;
          } else {
            return Math.round(this.loanAmount * 100) / 100;
          }
        },
        get cashAmount() {
          return this.loanAmount - this[ "currentMortgageBalance" ] > 0 ? Math.round((this.loanAmount - this[ "currentMortgageBalance" ]) * 100) / 100 : 0;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, initialData, settings);
        },
        get ltv() {
          return (this.loanAmount / this.propertyValue) * 100;
        },
        get pmiEligible() {
          return (this.productType === "conventional" && this.conforming && (this.ltv > 100 - settings.conventional.pmiDownPaymentBoundary));
        },
        ...initialData
      };
    }
    initialData.mortech = {
      investor: context.investorIds,
      loanProductId: context.loanProductId,

      ...initialData.mortech
    };
    if (deliveryType) {
      initialData.mortech = {
        deliveryType,
        ...initialData.mortech
      };
    }

    if (initialData.pricingEngine === "optimalblue") {
      initialData.optimalBlue = {
        productTypes: obProductTypesDefaultValue,
        automatedUnderwritingSystem: automatedUnderwritingSystemDefaultValue,
        prepaymentPenalty: prepaymentPenaltyDefaultValue,
        view: obViewDefaultValue,
        ...cleanObject({ ...data.optimalBlue })
      };
    }

    if (initialData.pricingEngine === "polly") {
      initialData.polly = {
        automatedUnderwritingSystem: pollyAutomatedUnderwritingSystemDefaultValue,
        prepaymentPenalty: pollyPrepaymentPenaltyDefaultValue,
        ...cleanObject({ ...data.polly })
      };
    }

    initialData.propertyType = loanEstimate.propertyType;

    const changes = calculate(initialData);
    Object.entries(changes).forEach(([key, value]) => {
      initialData = setIn(initialData, key, value);
    });
    return cleanObject({ ...initialData });
  }, [loanEstimate, lead, formData, data.objectId, settings, pricingEngineDefaultValue, context]);

  const dataIdRef = useLatest(data.id);
  const [create] = useMutation<CreateLoanEstimateOffer, CreateLoanEstimateOfferVariables>(useAppendObCustomFields(CREATE_LOAN_ESTIMATE_OFFER), {
    refetchQueries: ["GetDefaultOffer"],
    update(cache, { data: { createLoanEstimateOffer: { loanEstimateOffer } } }) {
      const { loanEstimates } = cache.readQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true },
            deleted: { equalTo: false }
          }
        }
      });
      cache.writeQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        data: {
          loanEstimates: {
            ...loanEstimates,
            edges: [
              {
                ...loanEstimates.edges[ 0 ],
                node: {
                  ...loanEstimates.edges[ 0 ].node,
                  offers: {
                    ...loanEstimates.edges[ 0 ].node.offers,
                    edges: [
                      ...loanEstimates.edges[ 0 ].node.offers.edges.filter(e => e.node.id !== dataIdRef.current),
                      {
                        node: {
                          ...loanEstimateOffer,
                          isValid: true,
                          isDirty: false,
                          isTitleFeeEditable: false,
                          propertyAnnualInsuranceUnit: loanEstimateOffer.propertyAnnualInsuranceUnit,
                          propertyAnnualTaxUnit: loanEstimateOffer.propertyAnnualTaxUnit
                        },
                        __typename: "LoanEstimateOfferEdge"
                      }
                    ]
                  }
                }
              }
            ]
          }
        },
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true }
          }
        }
      });
      cache.evict({ id: client.cache.identify({ __typename: "LoanEstimateOffer", id: dataIdRef.current }) });
    }
  });
  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(UPDATE_LOAN_ESTIMATE_OFFER, {
    refetchQueries: ["GetDefaultOffer"]
  });
  const [getRates] = useLazyQuery<GetPricingRates, GetPricingRatesVariables>(GET_PRICING_RATES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const handleCreate = useCallback(async (values, form: FormApi) => {
    const { id, objectId, points, __typename, objectIcon, isValid, isTitleFeeEditable, isDirty, propertyAnnualInsuranceUnit, propertyAnnualTaxUnit, ...params } = values;
    let offer;
    const dirty = form.getState().dirty;
    const resetFees = dirty ? {
      titleCompany: null,
      titleCompanyName: null,
      ownersTitle: null,
      recordingCharges: null,
      settlementFee: null,
      titleInsurance: null,
      transferTax: null,
      lendersTitle: null,
      pmiCompany: null,
      pmiType: null,
      pmi: null,
      appraisalFee: params.withAppraisal ? params.appraisalFee : null
    } : {};
    if (objectId) {
      offer = await update({
        variables: {
          input: {
            id: objectId,
            fields: {
              loanEstimate: {
                link: loanEstimate.id
              },
              ...omit({
                ...params,
                ...resetFees,
                mortech: params.mortech ? omit(params.mortech, ["__typename"]) : params.mortech,
                polly: params.polly ? omit(params.polly, ["__typename"]) : params.polly,
                optimalBlue: params.optimalBlue ? omit(params.optimalBlue, ["__typename"]) : params.optimalBlue
              }, ["__typename", "objectIcon"])
            }
          }
        }
      });
    } else {
      const cachedUnSavedLoanEstimateOffer = client.readFragment<LoanEstimateOffer>({
        fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
        fragmentName: "LoanEstimateOffer",
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: id })
      });
      offer = await create({
        variables: {
          input: {
            fields: {
              lead: {
                link: lead.id
              },
              loanEstimate: {
                link: loanEstimate.id
              },
              ...{
                ...omit(cachedUnSavedLoanEstimateOffer, [
                  "mortech",
                  "optimalBlue",
                  "polly",
                  "propertyAnnualInsuranceUnit",
                  "propertyAnnualTaxUnit",
                  "propertyAnnualInsurance",
                  "propertyAnnualTax",
                  "pmi",
                  "pi",
                  "mip",
                  "ltv",
                  "price",
                  "amortizationType",
                  "initialArmTerm",
                  "documentType",
                  "loanAmount",
                  "totalLoanAmount",
                  "loanTerm",
                  "pricingEngine",
                  "productType",
                  "monthlyPremium",
                  "lendersTitle",
                  "discountPoints",
                  "id",
                  "objectName",
                  "objectIcon",
                  "objectId",
                  "points",
                  "__typename",
                  "isValid",
                  "isTitleFeeEditable",
                  "isDirty"
                ]),
                ...params,
                appraisalFee: params.withAppraisal ? params.appraisalFee : null,
                rate: params.pricingEngine != "manual" ? null : params.rate ?? rate,
                apr: params.pricingEngine != "manual" ? null : params.apr ?? apr,
                price: params.pricingEngine != "manual" ? null : params.price ?? price,
                createdAt: data.createdAt,
                mortech: params.mortech ? omit(params.mortech, ["__typename"]) : params.mortech,
                polly: params.polly ? omit(params.polly, ["__typename"]) : params.polly,
                optimalBlue: params.optimalBlue ? omit(params.optimalBlue, ["__typename"]) : params.optimalBlue
              }
            }
          }
        }
      });
    }

    const loanEstimateOffer = offer.data[ `${objectId ? "update" : "create"}LoanEstimateOffer` ].loanEstimateOffer;
    const getRatesData = client.readQuery<GetPricingRates, GetPricingRatesVariables>({
      query: GET_PRICING_RATES,
      variables: {
        offerId: loanEstimateOffer.objectId
      }
    });

    if (values.pricingEngine === "manual") {
      return;
    } else if (values.pricingEngine === "optimalblue" && loanEstimateOffer.propertyType === "5_unit") {
      const { destroy } = modal(EmptyModal, {
        header: "Not supported",
        label: "CONTINUE",
        title: `Selected property type is not supported by OptimalBlue. Please adjust the provided information and click on 'UPDATE RATES' to see results`,
        onSubmit() {
          destroy();
        }
      });
    } else if (!getRatesData?.getRates?.rates?.length || dirty) {
      const { data: { getRates: { searchId, rates = [], errors } } = {}, loading } = await getRates({
        variables: {
          offerId: loanEstimateOffer.objectId
        }
      });
      await update({
        variables: {
          input: {
            id: loanEstimateOffer.objectId,
            fields: {
              rateId: null,
              rate: null,
              apr: null,
              price: null

            }
          }
        }
      });

      //todo not sure this is the best solution tried to use update on useMutation got error
      const cachedLoanEstimateOffer = client.readFragment<LoanEstimateOffer>({
        fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
        fragmentName: "LoanEstimateOffer",
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: loanEstimateOffer.id })
      });

      client.writeFragment<LoanEstimateOffer>({
        fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
        fragmentName: "LoanEstimateOffer",
        data: {
          ...cachedLoanEstimateOffer,
          propertyAnnualInsuranceUnit,
          propertyAnnualTaxUnit
        },
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: loanEstimateOffer.id })
      });

      if (rates?.length) {
        populateTitleFees({ ...values, id: loanEstimateOffer.id }, true);
        onRatesRedirect?.(loanEstimateOffer.objectId);
        return;
      } else {
        let message = `Please adjust the provided information and click on 'UPDATE RATES' to see results.${searchId ? `\nSearchID:${searchId}` : ""}`;
        if (errors) {
          message = errors;
        }
        let ineligibleModalData = {};
        if (values.pricingEngine === "optimalblue" && searchId) {
          ineligibleModalData = {
            cancelLabel: "Ineligible rates",
            onCancel() {
              destroy();
              ineligibleModal({ searchId: searchId });
            }
          };
        }
        const { destroy } = modal(EmptyModal, {
          header: "No rate results",
          label: "CONTINUE",
          title: message,
          onSubmit() {
            destroy();
          },
          ...ineligibleModalData
        });
      }
    } else {
      onRatesRedirect?.(loanEstimateOffer.objectId);
      return;
    }
  }, [lead]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        onSubmit={handleCreate}
        initialValuesEqual={(a, b) => deepEqual(a, b)}
        formProps={{
          onKeyPress: handleKeyPress
        }}
        mutators={{
          ...arrayMutators
        }}>
        <OfferHeader/>
        {children}
        <OfferCalculate/>
      </Form>
    </>
  );
});

export const UPDATE_LOAN_ESTIMATE_OFFER = gql`
  mutation UpdateLoanEstimateOffer($input: UpdateLoanEstimateOfferInput!) {
    updateLoanEstimateOffer(input: $input) {
      loanEstimateOffer {
        ... LoanEstimateOffer
      }
    }
  }
  ${LOAN_ESTIMATE_OFFER}
`;

export const CREATE_LOAN_ESTIMATE_OFFER = gql`
  mutation CreateLoanEstimateOffer($input: CreateLoanEstimateOfferInput!) {
    createLoanEstimateOffer(input: $input) {
      loanEstimateOffer {
        ... LoanEstimateOffer
      }
    }
  }
  ${LOAN_ESTIMATE_OFFER}
`;

export const GET_PRICING_RATES = gql`
  query GetPricingRates ($offerId: String!) {
    getRates (offerId: $offerId) {
      searchId
      engine
      rates {
        __typename
        productName
        productId
        apr
        lastUpdate
        pi
        rate
        vendorName
        price
        monthlyPremium
        rateId
        status
        adjustments
      }
      errors
    }
  }
`;
