import React                   from "react";
import { useEffect }           from "react";
import { useState }            from "react";
import { FC }                  from "react";
import { GlobalClasses }       from "../../../../constants/GlobalClasses";
import { Box }                 from "../../../Box";
import { TypographyColor }     from "../../../Typography";
import { TypographySize }      from "../../../Typography";
import { TypographyWeight }    from "../../../Typography";
import { Typography }          from "../../../Typography";
import { ICellProps }          from "../ICellProps";
import {DateTime}              from "@relcu/date";

export interface TimezoneCellProps extends ICellProps {
  tiny?: boolean;
  bold?: boolean;
  isMultiText?: boolean;
  defaultValue?: string;
}

window[ "DateTime" ] = DateTime;

export const TimezoneCell: FC<TimezoneCellProps> = React.memo(function TimezoneCell({ getCellValue, className, defaultValue, rowData, bold, tiny, dataKey, isMultiText,verticalAlign, ...props }) {
  const value = rowData[dataKey];

  if (!value){
    return <Box className={className}>
      {defaultValue || ""}
    </Box>
  }

  const [formattedTime, setFormattedTime] = useState(DateTime.local().setZone(value).toLocaleString(DateTime.TIME_SIMPLE));

  useEffect(() => {
    let timer = setInterval(() => setFormattedTime(DateTime.local().setZone(value).toLocaleString(DateTime.TIME_SIMPLE)), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [value]);

  return <Box className={className}>
    <Typography className={GlobalClasses.EllipsisFlex}
                size={tiny && TypographySize.TextSmall}
                color={tiny && TypographyColor.Secondary}
                weight={bold && TypographyWeight.Bold}>
      {formattedTime}
    </Typography>
  </Box>;
});
