import { CheckPicker }    from "@relcu/rc";
import React              from "react";
import { FC }             from "react";
import { FormField }      from "@relcu/rc";
import { useClassNames }  from "../useCollections";

export const ObjectConfig: FC<any> = React.memo(function StringField() {
  return (
    <FormField
      name="targetClass"
      component={CheckPicker}
      properties={{
        data: useClassNames(),
        style: { width: "100%" }
      }}
      label={"Reference Collection"}
      helperText="Refers to a Collection Type"
    />
  );
});
