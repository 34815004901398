import { createCalculation }              from "@relcu/form";
import { useConstant }                    from "@relcu/ui";
import { useState }                       from "react";
import { useLazyQuery }                   from "@apollo/client";
import { useReactiveVar }                 from "@apollo/client";
import { gql }                            from "@apollo/client";
import { useMutation }                    from "@apollo/client";
import { useSource }                      from "@relcu/ui";
import { deviceVar }                      from "../../../../reactiveVars";
import { getDefaultPhoneNumberForCall }   from "../../../../utils/helpers";
import { createPhoneNumbers }             from "../../../../utils/helpers";
import { usePermissions }                 from "../../../AccessControl";
import { ASSIGN_LEAD_TO_CONFERENCE }      from "../../../operations.graphql";
import { useMarkConferenceRead }          from "../../../useMarkConferenceRead";
import { usePhone }                       from "../../../usePhone";
import { useViewerPhoneLines }            from "../../../useViewerPhoneLines";
import { GetConferenceByCallIdVariables } from "./__types__/GetConferenceByCallId";
import { GetConferenceByCallId }          from "./__types__/GetConferenceByCallId";

export function useHeaderCall() {
  const { active, hybridCalls, direction, call } = usePhone();
  const { initialDisplayCall, myCall } = Object(hybridCalls);
  const { $object, $viewer } = useSource();
  const node = $object;
  const isLeadNode = $object.__typename == "Lead";
  const { canUpdate } = usePermissions($object);
  const { fromNumbers, defaultPhoneNumber, hasMicrophoneIssue, getLocalNumbers, getLocalNumber } = useViewerPhoneLines();
  const device = useReactiveVar(deviceVar);
  const [getConferenceByCallId] = useLazyQuery<GetConferenceByCallId, GetConferenceByCallIdVariables>(GET_CONFERENCE_BY_CALL_ID);
  const [assignLeadToConference] = useMutation(ASSIGN_LEAD_TO_CONFERENCE);
  const { markAsRead } = useMarkConferenceRead({ id: $object.id, __typename: $object.__typename, userObjectId: $viewer.objectId });
  const [updatedScope, setUpdatedScope] = useState(null);
  const decorators = useConstant(() => {
    return [createCalculation(
      {
        field: /to/,
        updates: (value, name, allValues, prevValues) => {
          return {
            from: isLeadNode ? (api.localPresenceNumber(typeof value == "string" ? { value } : value) || allValues[ "from" ]) : allValues[ "from" ]
          };
        }
      }
    )];
  });
  const api = {
    decorators,
    localPresenceNumber(to) {
      return getLocalNumber(to);
    },
    get from() {
      return isLeadNode ? (this.localPresenceNumber(this.to) || defaultPhoneNumber) : defaultPhoneNumber;
    },
    active,
    myCall,
    direction,
    updatedScope,
    setUpdatedScope,
    initialDisplayCall,
    callDevice: device?.call,
    get to() {
      return !!api.toNumbers.length && getDefaultPhoneNumberForCall(api.toNumbers, node?.__typename);
    },
    get node() {
      return $object;
    },
    canUpdate,
    get fromNumbers() {
      const localNumber = getLocalNumbers(this.toNumbers);
      return isLeadNode ? [...localNumber, ...fromNumbers] : fromNumbers;
    },
    async assign(variables) {
      if (direction == "outgoing") {
        assignLeadToConference(variables);
      } else {
        const conf = await getConferenceByCallId({ variables: { callId: myCall?.callSid } });
        if (conf.data.conferences.edges[ 0 ].node.objectId) {
          const vars = {
            ...variables,
            variables: {
              ...variables.variables,
              id: conf.data.conferences.edges[ 0 ].node.objectId
            }
          };
          assignLeadToConference(vars);
        }
      }
    },
    get toNumbers() {
      return node ? createPhoneNumbers(node, "callOptOut") : [];
    },
    getSelectFrom(select) {
      const d = this.fromNumbers.find(f =>
          f.value === select)?.label ||
        (fromNumbers.length == 0 ? "No available numbers" : "Select Number");
      return d;
    },
    getSelectTo(select) {
      const selectedTo = api.toNumbers.find(t =>
        t.value === select);
      return selectedTo?.label ||
        (api.toNumbers.length == 0 ? "No available numbers" : "Select Number");
    },
    get isSameNode() {
      return !!initialDisplayCall && (
        ($object.__typename != "Contact" && $object.objectId === api?.initialDisplayCall?.scopeId) ||
        ($object.__typename === "Contact" && $object.objectId === api?.initialDisplayCall?.objectId)
      );
    },
    get scope() {
      return updatedScope
        ?
        (updatedScope.objectId ? updatedScope : null)
        :
        (
          $object.__typename != "Contact" ? {
            __typename: $object.__typename,
            objectId: initialDisplayCall?.scopeId,
            objectName: initialDisplayCall?.scopeName
          } : null
        );
    },
    get isOpen() {
      return !!active && active?.status === "open";
    },
    get isDisabled() {
      return (
        (api.isOpen && !api.isSameNode) ||
        (!canUpdate || this.fromNumbers.length == 0 || api.toNumbers.length == 0) ||
        hasMicrophoneIssue
      );
    },
    get isActive() {
      return api.isOpen && api.isSameNode;
    },
    get isPending() {
      return api.isSameNode && (
        active.status === "pending" ||
        active.status === "ringing" ||
        active.status === "connecting"
      );
    },
    get contact() {
      if (node?.__typename === "Lead") {
        return node.members.find(borrower => borrower.isPrimary)?.contact;
      }
      return node;
    },
    markAsRead,
    call({ from, to }) {
      const params = {
        get from() {
          return from?.value || from;
        },
        get to() {
          return to?.value || to;
        },
        get scopeId() {
          return $object.__typename !== "Contact" ? $object.objectId : "";
        },
        get scopeName() {
          return $object.__typename !== "Contact" ? $object.objectName : "";
        },
        get scopeClassName() {
          return $object.__typename !== "Contact" ? $object.__typename : "";
        },
        get contactId() {
          return api.contact?.objectId;
        },
        get contactName() {
          if ($object.__typename === "Lead") {
            const callee = $object.members.find(borrower => borrower.contact?.phones?.find(phone => phone.number === (to?.value || to)));
            return callee?.contact?.objectName;
          }
          return $object.objectName;
        },
        get contactCompany() {
          return ($object.__typename == "Contact" && $object.company) || "";
        },
        markAsRead: api.markAsRead
      };
      call(Object.assign({}, params));
    }
  };
  return api;
}
const GET_CONFERENCE_BY_CALL_ID = gql`
  query GetConferenceByCallId($callId: String) {
    conferences (where: {calls: {have: {call: {equalTo: $callId}}}}) {
      edges {
        node {
          objectId
        }
      }
    }
  }
`;
