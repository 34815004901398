import { gql }                         from "@apollo/client";
import { useSubscription }             from "@apollo/client";
import { useQuery }                    from "@apollo/client";
import { useMutation }                 from "@apollo/client";
import { Icon }                        from "@relcu/rc";
import { useNavigate }                 from "@relcu/react-router";
import { TypographyLine }              from "@relcu/ui";
import { TypographyColor }             from "@relcu/ui";
import { Typography }                  from "@relcu/ui";
import { ButtonVariants }              from "@relcu/ui";
import { Button }                      from "@relcu/ui";
import { classNames }                  from "@relcu/ui";
import { AvatarSizes }                 from "@relcu/ui";
import { Ellipsis }                    from "@relcu/ui";
import { Avatar }                      from "@relcu/ui";
import { Keys }                        from "@relcu/ui";
import { Input }                       from "@relcu/ui";
import { FontIcon }                    from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { EmptyList }                   from "@relcu/ui";
import { Widget }                      from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { DateTime }                    from "@relcu/date";
import { useMemo }                     from "react";
import React                           from "react";
import { useCallback }                 from "react";
import { SubscriptionEvent }           from "../../../../types/graphql-global-types";
import { usePermissions }              from "../../../AccessControl";
import { GetLastNotesVariables }       from "./__types__/GetLastNotes";
import { GetLastNotes }                from "./__types__/GetLastNotes";
import { SendNewNoteVariables }        from "./__types__/SendNewNote";
import { SendNewNote }                 from "./__types__/SendNewNote";
import { SubscribeLastNotesVariables } from "./__types__/SubscribeLastNotes";
import { SubscribeLastNotes }          from "./__types__/SubscribeLastNotes";
import { NoteWidgetClasses }           from "./NoteWidgedClasses";
import "./note-widget.css";

export const NoteWidget = React.memo(function NoteWidget() {
  const { $object: node, $viewer } = useSource();
  const [note, setNote] = React.useState("");
  const [expand, setExpand] = React.useState(false);
  const [readMore, setReadMore] = React.useState([]);
  const { canUpdate } = usePermissions(node);
  const navigate = useNavigate();
  const {
    data: { notes: { edges = [] } = {} } = {},
    refetch
  } = useQuery<GetLastNotes, GetLastNotesVariables>(GET_LAST_NOTES, {
    variables: { id: node.id },
    fetchPolicy: "no-cache"
  });
  const [send, { loading }] = useMutation<SendNewNote, SendNewNoteVariables>(SEND_NEW_NOTE, {
    update(cache, { data: { createNote: { note: node } } }) {
      setNote("");
    }
  });

  useSubscription<SubscribeLastNotes, SubscribeLastNotesVariables>(SUBSCRIBE_LAST_NOTES, {
    variables: {
      link: node.id
    },
    onData({ client, data: { data: { notes: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          refetch();
      }
    }
  });
  const data = useMemo(() => {
    return edges?.reverse();
  }, [edges]);

  const onSend = useCallback(async () => {
    try {
      await send({
        variables: {
          input: {
            subject: {
              link: node.id
            },
            owner: {
              link: $viewer.id
            },
            text: note
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [send, note]);
  const expandMore = (id) => {
    const index = readMore.findIndex((item) => item === id);
    if (index == -1) {
      setReadMore(prev => [...prev, id]);
    } else {
      setReadMore(prev => {
        const newIds = prev.slice(index, 1);
        return newIds;
      });
    }
  };

  // const handleSplit = async () => {
  //     const pipWindow = await documentPictureInPicture.requestWindow({
  //       width: 640,
  //       height: 360
  //     });
  //     const widget = document.querySelector(".note-widget-getter");
  //     const styleSheet = Object.values(document.styleSheets).find(sheet => {
  //       return Object.values(sheet.cssRules).find(rule => {
  //         return rule?.[ "selectorText" ] == ".note-widget";
  //       });
  //     });
  //
  //     if (styleSheet) {
  //       let styleSheet = "";
  //       Object.values(document.styleSheets).map(sheet => {
  //         styleSheet = styleSheet.concat("", [...sheet.cssRules].map((rule) => rule.cssText).join(""));
  //       });
  //
  //       console.info("styleSheet", styleSheet);
  //       const style = document.createElement("style");
  //       style.textContent = styleSheet;
  //       pipWindow.document.head.appendChild(style);
  //     }
  //
  //     pipWindow.document.body.append(widget);
  //
  //     pipWindow.addEventListener("pagehide", (event) => {
  //       const playerContainer = document.querySelector(".note-widgetContainer");
  //       const pipPlayer = event.target.querySelector(".note-widget-getter");
  //       playerContainer.append(pipPlayer);
  //     });
  //   };

  return (
    <Widget
      title="Notes"
      className={NoteWidgetClasses.NoteWidget}
      button={<Button disabled={data.length == 0} onClick={() => {
        setExpand(prev => !prev);
        setReadMore([]);
      }} variant={ButtonVariants.Ghost} onlyIcon={true}
                      icon={!expand ? "keyboard_arrow_down" : "keyboard_arrow_up"}/>}
      gap={"XXXXS"}
    >
      <Box container justify={"space-between"} alignItems={"center"}
           className={classNames(NoteWidgetClasses.NoteWidgetActions, {
             [ NoteWidgetClasses.Active ]: !!note?.trim() && !loading
           })}>
        <Input
          placeholder={"Add note..."}
          onChange={(value) => (value?.length < 3000 || value == null) && setNote(value)}
          flex={1}
          readOnly={!canUpdate}
          value={note}
          onKeyPress={(e) => {
            if (e.key == Keys.Enter && !e.shiftKey && note?.trim() && !loading) {
              onSend();
            }
          }}
        />
        <FontIcon type={"done"} onClick={onSend}/>
      </Box>
      {data.length == 0 ?
        <Box
          container
          direction={"column"}
          alignItems={"center"}
          justify={"center"}
          className={NoteWidgetClasses.NoteWidgetEmptyView}
          alignSelf={"center"}
          gap={"XXS"} flex={1}
        >
          <EmptyList icon={"rc_note"} content={"Click to \"Add note...\" below to get started"} title={"No notes"}
                     alignSelf={"center"} small/>
        </Box> :
        <Box
          container
          direction={"column"}
          gap={"S"}
          flex={1}>
          {
            data.map(({ node }, index) =>
              <Box key={index} container gap={"XXS"} className={NoteWidgetClasses.NoteWidgetItem}>
                <Avatar size={AvatarSizes.Small} text={node.owner?.objectName ?? "Relcu"} icon={node.owner?.objectIcon}
                        onClick={() => node.owner && navigate(`/user/${node.owner.objectId}`)}/>
                <Box container flex={1} direction={"column"} alignItems={"start"}>
                  <span>{DateTime.fromISO(node.createdAt).toFormat("MMM dd, h:mm a")}</span>
                  <Box container direction={"column"}>
                    {readMore.includes(node.id)
                      ? <p className={NoteWidgetClasses.NoteWidgetItemContent}>{node.text}</p>
                      : <Ellipsis withoutTooltip={expand} from={expand ? 1000 : 35}
                                  className={NoteWidgetClasses.NoteWidgetItemContent}>{node.text}</Ellipsis>}

                    {expand && node.text.length > 1000 && !readMore.includes(node.id) &&
                    <Typography color={TypographyColor.Primary} lineHeight={TypographyLine.LineBody2}
                                className={NoteWidgetClasses.NoteWidgetReadMoreButton} onClick={() => {
                      expandMore(node.id);
                    }}>Read more</Typography>}
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      }
    </Widget>
  );
});

const GET_LAST_NOTES = gql`
  query GetLastNotes($id: ID!) {
    notes(
      last: 3,
      order: [createdAt_ASC]
      where: {
        subject: {
          have: {
            link: $id
          }
        }
      }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          objectId
          objectIcon
          objectName
          text
          createdAt
          owner {
            id
            objectId
            objectIcon
            objectName
          }
        }
      }
    }
  }
`;
const SEND_NEW_NOTE = gql`
  mutation SendNewNote($input:CreateNoteFieldsInput!){
    createNote(input: {
      fields: $input
    }){
      clientMutationId
      note {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;

const SUBSCRIBE_LAST_NOTES = gql`
  subscription SubscribeLastNotes($link: ID) {
    notes(where: {subject: {have: {link: $link}}}) {
      event
      node {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        subject {
          ...on Document{
            objectId
            objectIcon
            objectName
          }
        }
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;
