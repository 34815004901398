import React            from "react";
import { FC }           from "react";
import { Field }        from "@relcu/final-form";
import { StackProps }   from "@relcu/rc";
import { Checkbox }     from "@relcu/rc";
import { Stack }        from "@relcu/rc";
import { DetailsField } from "../../../../Generation";

export const Addition: FC<StackProps> = React.memo(function Addition(props) {
  return (
    <Stack
      spacing={16}
      childrenRenderMode={"clone"}
      alignItems={"flex-start"}
      style={{ alignSelf: "stretch" }}
      {...props}>
      <DetailsField style={{ width: "100%" }}>
        <DetailsField.Title>Automation</DetailsField.Title>
        <Stack spacing={16}>
          <Field
            name="wfa.can.change"
            type="checkbox">
            {({ input }) => {
              return <Checkbox
                checked={input.checked}
                onChange={(value, checked) => input.onChange(checked)}>
                Change
              </Checkbox>;
            }}
          </Field>
          <Field
            name="wfa.can.create"
            type="checkbox">
            {({ input }) => {
              return <Checkbox
                checked={input.checked}
                onChange={(value, checked) => input.onChange(checked)}>
                Create
              </Checkbox>;
            }}
          </Field>
          <Field
            name="wfa.can.update"
            type="checkbox">
            {({ input }) => {
              return <Checkbox
                checked={input.checked}
                onChange={(value, checked) => input.onChange(checked)}>
                Update
              </Checkbox>;
            }}
          </Field>
          <Field
            name="wfa.can.return"
            type="checkbox">
            {({ input }) => {
              return <Checkbox
                checked={input.checked}
                onChange={(value, checked) => input.onChange(checked)}>
                Return
              </Checkbox>;
            }}
          </Field>
          <Field
            name="wfa.can.filter"
            type="checkbox">
            {({ input }) => {
              return <Checkbox
                checked={input.checked}
                onChange={(value, checked) => input.onChange(checked)}>
                Filter
              </Checkbox>;
            }}
          </Field>
        </Stack>
      </DetailsField>
      <Field name={"type"}>
        {({ input }) => (
          input.value == "Pointer" ?
            null :
            <DetailsField style={{ width: "50%" }}>
              <DetailsField.Title>Available for template</DetailsField.Title>
              <Stack spacing={16}>
                <Field
                  name="template.email"
                  type="checkbox">
                  {({ input }) => {
                    return <Checkbox
                      checked={input.checked}
                      onChange={(value, checked) => input.onChange(checked)}>
                      Email
                    </Checkbox>;
                  }}
                </Field>
                <Field
                  name="template.sms"
                  type="checkbox">
                  {({ input }) => {
                    return <Checkbox
                      checked={input.checked}
                      onChange={(value, checked) => input.onChange(checked)}>
                      Sms
                    </Checkbox>;
                  }}
                </Field>
              </Stack>
            </DetailsField>
        )}
      </Field>
    </Stack>
  );
});
