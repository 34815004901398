import React                            from "react";
import { FC }                           from "react";
import { DateTime }                     from "@relcu/date";
import { NotificationContent }          from "@relcu/ui";
import { BadgeColors }                  from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import { BadgeVariants }                from "@relcu/ui";
import { TypographyColor }              from "@relcu/ui";
import { TypographySize }               from "@relcu/ui";
import { Typography }                   from "@relcu/ui";
import { timeTo }                       from "@relcu/ui";
import { Badge, NotificationIconColor } from "@relcu/ui";
import { Notification }                 from "@relcu/ui";
import { NotificationHeader }           from "@relcu/ui";
import { NotificationSubject }          from "@relcu/ui";
import { NotificationProps }            from "../NotificationBar";
import { NotificationIcons }            from "../NotificationBar";
import { NotificationTitles }           from "../NotificationBar";

export const SmsNotification: FC<NotificationProps> = React.memo(function SmsNotification(props) {
  const { node: { type, createdAt, unread, record, targetObjectName } = {}, inApp, className, onNavigate, indicateStatus } = props;
  const fontWeight = unread ? 500 : 400;
  return (
    <Notification onNavigate={onNavigate} className={className} style={{ fontWeight }}>
      <NotificationHeader inApp={inApp} title={NotificationTitles[ type ]} icon={NotificationIcons[ type ]}
                          color={NotificationIconColor.Info}>
        {
          indicateStatus &&
          <Box container gap={"XXXS"} alignItems={"center"}>
            <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary} noWrap>
              {timeTo(DateTime.fromJSDate(new Date(createdAt)))}
            </Typography>
            {unread && <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>}
          </Box>
        }
      </NotificationHeader>
      <Box container direction={"column"} gap={inApp ? "XXXS" : "XXS"}>
        <NotificationSubject inApp={inApp} objectName={targetObjectName}/>
        <NotificationContent inApp={inApp} content={record.content}/>
      </Box>
    </Notification>
  );
});
