import React                                     from "react";
import { FC }                                    from "react";
import { useMemo }                               from "react";
import { DateTime }                              from "@relcu/date";
import { useLazyQuery }                          from "@apollo/client";
import { gql }                                   from "@apollo/client";
import { useMutation }                           from "@apollo/client";
import { useSubscription }                       from "@apollo/client";
import { Typography }                            from "@relcu/rc";
import { CopyText }                              from "@relcu/ui";
import { EmptyList }                             from "@relcu/ui";
import { AvatarColors }                          from "@relcu/ui";
import { ChipsSizes }                            from "@relcu/ui";
import { ListItemDate }                          from "@relcu/ui";
import { ListItemChips }                         from "@relcu/ui";
import { ListItemCurrency }                      from "@relcu/ui";
import { ListItemText }                          from "@relcu/ui";
import { ListItemImage }                         from "@relcu/ui";
import { ListRow }                               from "@relcu/ui";
import { BoxComponentProps }                     from "@relcu/ui";
import { Avatar }                                from "@relcu/ui";
import { Tooltip }                               from "@relcu/ui";
import { Link }                                  from "@relcu/ui";
import { ItemsEllipsis }                         from "@relcu/ui";
import { Box }                                   from "@relcu/ui";
import { classNames }                            from "@relcu/ui";
import { AudioPlayer }                           from "@relcu/ui";
import { useSource }                             from "@relcu/ui";
import { TagSizes }                              from "@relcu/ui";
import { SubscriptionEvent }                     from "../../../../types/graphql-global-types";
import { getPurelizedLead }                      from "../../../../utils/helpers";
import { getContactName }                        from "../../../../utils/helpers";
import { pad }                                   from "../../../../utils/helpers";
import { formatPhoneNumber }                     from "../../../../utils/helpers";
import { toFirstUpper }                          from "../../../../utils/helpers";
import { getHumanized }                          from "../../../../utils/schemaUtils";
import { ScopeConnection }                       from "../../../ScopeConnection";
import { ASSIGN_LEAD_TO_CONFERENCE }             from "../../../operations.graphql";
import { Tag }                                   from "../../../Tag";
import { SubscribeConferenceRecordingVariables } from "./__types__/SubscribeConferenceRecording";
import { SubscribeConferenceRecording }          from "./__types__/SubscribeConferenceRecording";
import { ConferenceViewClasses }                 from "./ConferenceViewClasses";
import { Recording }                             from "./Recording";
import CallIntelligence                          from "./CallIntelligence";

interface ConferenceProps extends BoxComponentProps {
  selected: any,
  node: any
  toPath,
  disabled
}

export const Conference: FC<ConferenceProps> = React.memo(function Conference(props) {
  const { selected, node, toPath, disabled, className, ...rest } = props;
  const [updateConference] = useMutation(ASSIGN_LEAD_TO_CONFERENCE);
  const classes = classNames(ConferenceViewClasses.Conference, className);
  const start = selected.startDate ? DateTime.fromISO(selected.startDate) : DateTime.fromISO(selected.createdAt);
  const end = (selected.startDate && selected.endDate) ? DateTime.fromISO(selected.endDate) : start;
  const duration = end.diff(start, ["hours", "minutes", "seconds"]);
  const { $viewer } = useSource();
  const allowedRoles = ["admin", "sales_manager", "team_manager"];
  const conferenceUrl = useMemo(() => {
    let url = window.location.origin;
    if (selected.scope) {
      url = `${url}/lead/${selected.scope.objectId}/calls/${selected.objectId}`;
    } else {
      const contact = selected.calls.find(c => c.party.__typename === "Contact")?.party;
      if (contact) {
        url = `${url}/contact/${contact.objectId}/calls/${selected.objectId}`;
      }

    }
    return url;
  }, [window.location.origin, selected]);

  return <Box container direction={"column"} alignSelf={"start"} className={classes} {...rest}
              style={{ overflowY: "auto" }}>
    <Box
      container
      justify={"space-between"}
      gap={"XL"}
      className={classNames(ConferenceViewClasses.ConferenceSection, ConferenceViewClasses.ConferenceHeader)}>
      <CopyText value={conferenceUrl}>{selected.objectName}</CopyText>
      <p>{toFirstUpper(selected.status)}</p>
    </Box>
    <Box container alignItems={"center"} gap={"XXS"} justify={"start"}
         className={classNames(ConferenceViewClasses.ConferenceSection)} wrap={"wrap"}>
      <Box container direction={"column"} className={ConferenceViewClasses.ConferenceSectionInfo} flexBasis={150}>
        Call direction
        <p>{toFirstUpper(selected.direction)}</p>
      </Box>
      <Box container direction={"column"} className={ConferenceViewClasses.ConferenceSectionInfo} flexBasis={150}>
        Start date
        <p>{DateTime.fromISO(selected.startDate ?? selected.createdAt).toFormat("MMM dd, h:mm a")}</p>
      </Box>
      <Box container direction={"column"} className={ConferenceViewClasses.ConferenceSectionInfo} flexBasis={150}>
        From
        <p>{formatPhoneNumber(selected.from)}</p>
      </Box>
      <Box container direction={"column"} className={ConferenceViewClasses.ConferenceSectionInfo} flexBasis={150}>
        To
        <p>{formatPhoneNumber(selected.to)}</p>
      </Box>
      <Box container direction={"column"} className={ConferenceViewClasses.ConferenceSectionInfo} flexBasis={150}>
        {
          selected.status == "in-progress" ? "Status" : "Duration"
        }
        {
          selected.status == "in-progress" ?
            <Typography
              color={"secondary"}>
              Call in progress...
            </Typography>
            :
            <p>{`${pad(duration.hours)}:${pad(duration.minutes)}:${pad(Math.round(duration.seconds))}`}</p>
        }
      </Box>
    </Box>
    <Box container gap={"S"} className={classNames(ConferenceViewClasses.ConferenceSection)}>
      <Box container direction={"column"} gap={"XS"} className={ConferenceViewClasses.ConferenceSectionHeader}>
        Participants
        <Box container gap={"XXXS"}>
          <ItemsEllipsis
            items={selected.calls}
            renderItem={(call, index) =>
              call.party && <Link key={index} to={toPath(call.party)}>
                <Tooltip title={getContactName(call.party, node)}>
                  <Avatar text={getContactName(call.party, node)} icon={call.party.objectIcon}/>
                </Tooltip>
              </Link>
            }
            ellipsis={(count, extended) => (
              <Avatar
                text={!extended && `+${count}`}
                icon={extended && "keyboard_arrow_left"}
                color={AvatarColors.Primary}/>
            )}
          />
        </Box>
      </Box>
      <Tag key={selected.id} className={"Conference"} label={"Tag"} size={TagSizes.Big}
           tags={selected.tags} disabled={disabled}
           view={"read"}
           id={selected.id} chipSize={ChipsSizes.Small} placeholder={"Add tag here"}/>
    </Box>
    <Box container direction={"column"} gap={"XS"} className={classNames(ConferenceViewClasses.ConferenceSection)}>
      <Box container justify={"space-between"} className={ConferenceViewClasses.ConferenceSectionHeader}>
        Scope of conversation
        <ScopeConnection
          typename={"Conference"}
          onClick={(item) => updateConference({
            variables: {
              id: selected.id,
              fields: {
                scope: {
                  link: item.id
                }
              }
            }
          })}
          onRemove={() => updateConference({
            variables: {
              id: selected.id,
              fields: {
                scope: {
                  link: null
                }
              }
            }
          })}
          scope={selected.scope}/>
      </Box>
      {
        selected.scope?.__typename == "Lead" ?
          <ConferenceScope title="Lead" scope={selected.scope}/>
          :
          (
            !selected.scope &&
            <EmptyList content={"No leads assigned to the call"}
                       alignSelf={"center"}/>
          )
      }
    </Box>
    {
      (selected.voicemail && selected.voicemail.uploaded) ?
        <Box
          container
          direction={"column"}
          gap={"XS"}
          flex={1}
          className={classNames(ConferenceViewClasses.ConferenceSection)}>
          <Box container direction={"column"}
               gap={"XXS"}
               className={ConferenceViewClasses.ConferenceSectionHeader}>
            Voicemail received
            <AudioPlayer src={selected.voicemail.url}/>
          </Box>
        </Box> : selected.voicemail && selected.voicemail?.uploadStatus != "failed" &&
        <RecordingInProgress id={selected.voicemail.id}/>
    }
    {selected.recording && selected.recording.uploaded ?
      <Box container direction={"column"} className={classNames(ConferenceViewClasses.ConferenceSection)}>
        <Recording recording={selected.recording}/>
      </Box>
      :
      selected.recording && selected.recording?.uploadStatus != "failed" &&
      <RecordingInProgress id={selected.recording.id}/>
    }
    {
      $viewer.role && allowedRoles.includes($viewer.role) && (
        <Box container className={ConferenceViewClasses.ConferenceScope}>
          <CallIntelligence conference={selected.objectId} layoutType="conference"/>
        </Box>
      )
    }
  </Box>;
});

interface RecordingInProgressProps {
  id: string;
}
const RecordingInProgress: FC<RecordingInProgressProps> = React.memo(({ id }) => {
  const [load] = useLazyQuery(GET_CONFERENCE_RECORDING, {
    variables: {
      id
    }
  });
  useSubscription<SubscribeConferenceRecording, SubscribeConferenceRecordingVariables>(SUBSCRIBE_CONFERENCE_RECORDING, {
    variables: {
      where: {
        id: { equalTo: id },
        uploaded: { equalTo: false }
      }
    },
    onData({ data: { data: { recordings: { event, node: { url } } } } }) {
      console.info(event, url);
      switch (event) {
        case SubscriptionEvent.LEAVE:
          load();
      }
    }
  });
  return <Box
    container
    direction={"column"}
    gap={"XXS"}
    className={ConferenceViewClasses.ConferenceScope}>
    <p style={{ marginTop: "10px" }}>The voice recording is being processed. </p>
  </Box>;
});

interface ConferenceScopeProps {
  title: string,
  scope: any
}
const ConferenceScope: FC<ConferenceScopeProps> = React.memo(({ title, scope }) => {
  let loan = useMemo(() => getPurelizedLead(scope), [scope]);
  const { $object } = useSource();
  return <Box
    container
    direction={"column"}
    gap={"XXS"}
    className={ConferenceViewClasses.ConferenceScope}>

    <Link to={`/lead/${scope.objectId}`}>
      <ListRow container gap={"XXS"} justify={"space-between"}>
        {
          $object.__typename === "User"
            ?
            <>
              <Box container alignItems={"center"} gap={"XXS"}>
                <ListItemImage text={loan.name}/>
                <ListItemText text={loan.name}/>
              </Box>
              <ListItemText text={getHumanized("Lead", "loanProgram", loan.loanProgram)}
                            tiny={true}/>
              <ListItemText text={getHumanized("Lead", "loanPurpose", loan.loanPurpose)}
                            tiny={true}/>
              <ListItemCurrency currency={loan.loanAmount}/>
              <ListItemText text={loan.leadSource} tiny={true}/>
              <ListItemChips value={getHumanized("Lead", "leadStatus.status", loan.leadStatus.status)}/>
              <ListItemDate format="withTime"/>
            </>
            :
            <>
              <Box container alignItems={"center"} gap={"XXS"}>
                <ListItemImage text={loan.name}/>
                <ListItemText text={loan.name}/>
              </Box>
              <ListItemCurrency currency={loan.loanAmount}/>
              <ListItemChips value={getHumanized("Lead", "leadStatus.status", loan.leadStatus.status)}/>
            </>
        }
      </ListRow>
    </Link>
  </Box>;
});

export const SUBSCRIBE_CONFERENCE_RECORDING = gql`
  subscription SubscribeConferenceRecording($where: RecordingSubscriptionWhereInput) {
    recordings(where: $where) {
      event
      node {
        id
        name
        url
        type
        uploaded
        uploadStatus
      }
    }
  }
`;

export const GET_CONFERENCE_RECORDING = gql`
  query GetConferenceRecording($id: ID!) {
    recording(id: $id){
      id
      name
      url
      type
      uploaded
      uploadStatus
    }
  }
`;
