import React                  from "react";
import { useEffect }          from "react";
import { useMemo }            from "react";
import { KeyboardEvent }      from "react";
import { ReactNode }          from "react";
import { useImperativeState } from "../../";
import { Checkbox }           from "../../";
import { MenuClasses }        from "../../";
import { SearchInput }        from "../../";
import { IconType }           from "../../";
import { BoxComponentProps }  from "../../";
import { CircularLoader }     from "../../";
import { Box }                from "../../";
import { ButtonVariants }     from "../../";
import { Button }             from "../../";
import { classNames }         from "../../";
import { MenuItem }           from "../../";
import { InfiniteList }       from "./InfiniteList";
import { MenuProvider }       from "./MenuProvider";
import { MultiList }          from "./MultiList";
import { VirtualList }        from "./VirtualList";

export interface SearchableMenuProps extends Omit<BoxComponentProps, "onChange"> {
  loading?: boolean,
  searchable?: boolean,
  options: any[],
  className?: string,
  selected?: object | object[];
  optionKey?: string,
  optionLabel?: string,
  placeholder?: string,
  emptyMessage?: string,
  searchText?: string,
  showCreateButton?: boolean,
  width?: number,
  searchIcon?: IconType,
  renderOption?: (option) => ReactNode;
  onSelectAll?()
  onUnSelectAll?()
  selectedFilter?: boolean
  onSelectedFilter?(filtered: boolean)
  onSelect?(item),
  onType?(value: string, e?),
  onLoadMoreHandler?: React.ReactElement
  onCreateNew?(),
  allSelected?: boolean;
}

export const SearchableMenu = React.forwardRef(function SearchableMenu(props: SearchableMenuProps, ref: React.Ref<any>) {
  const {
    className,
    onType,
    onCreateNew,
    onSelect,
    loading,
    options,
    optionKey,
    optionLabel,
    emptyMessage,
    searchText,
    searchIcon = "search",
    placeholder = "Search here",
    selected,
    showCreateButton = true,
    renderOption,
    onSelectAll,
    onUnSelectAll,
    selectedFilter,
    onSelectedFilter,
    searchable,
    onLoadMoreHandler,
    width,
    allSelected = false,
    ...p
  } = props;

  const [filtered, setFiltered] = useImperativeState(selectedFilter, onSelectedFilter);
  const classes = classNames(SearchableClasses.SearchableMenu, MenuClasses.Menu, className);
  const selectedIsArray = useMemo(() => Array.isArray(selected), [selected]);
  const onSearchKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && onCreateNew) {
      onCreateNew();
    }
    return false;
  };

  useEffect(() => {
    setFiltered(false);
    return () => {
      onType("")
    }
  }, []);//have a problem with pointerField(it's get selected list on select toggle)

  return <ul className={classes} {...p} style={{ width, ...p?.style, overflowY: "hidden" }} ref={ref}>
    {
      searchable &&
      (
        (onSelectAll && onUnSelectAll && selectedIsArray) ?
          <SearchInput icon={searchIcon} placeholder={placeholder} value={searchText} onChange={onType}
                       style={{ paddingTop: 8 }}
                       inputProps={{ onKeyDown: onSearchKeyDown }}>
            {
              onSelectAll && selectedIsArray &&
              <Box
                onClick={onSelectAll}
                className={classNames(SearchableClasses.Action, {
                  [ SearchableClasses.Disabled ]: (allSelected || (options.length == (selected as object[]).length))
                })}>Select All</Box>
            }
            {
              onUnSelectAll && selectedIsArray &&
              <Box
                onClick={onUnSelectAll}
                className={classNames(SearchableClasses.Action, {
                  [ SearchableClasses.Disabled ]: (selected as object[]).length == 0
                })}>Uncheck All</Box>
            }
          </SearchInput>
          :
          <SearchInput icon={searchIcon} placeholder={placeholder} value={searchText} onChange={onType}
                       style={{ paddingTop: 8 }}
                       inputProps={{ onKeyDown: onSearchKeyDown }}/>
      )
    }
    {
      onSelectedFilter &&
      <Box container alignItems={"center"} style={{ padding: "0 8px", height: 48 }}>
        <Checkbox checked={filtered} placeholder={"Selected items"} onChange={() => setFiltered(!filtered)}/>
      </Box>
    }
    {
      options.length > 0 ?
        <MenuProvider
          onSelect={onSelect}
          optionKey={optionKey}
          optionLabel={optionLabel}
          options={options}
          selected={selected}
          renderOption={renderOption}
          onCreateNew={onCreateNew}>
          {
            options.find(option => option.options)
              ?
              <MultiList/>
              :
              onLoadMoreHandler
                ?
                <InfiniteList onLoadMoreHandler={onLoadMoreHandler}/>
                :
                <VirtualList/>
          }
        </MenuProvider>
        :
        <MenuItem
          container
          contentProps={{
            style: { "overflow": "hidden" },
            justify: "center"
          }}
          gap={"XXS"}>{loading ? <CircularLoader/> : emptyMessage}</MenuItem>
    }
    {
      (onCreateNew && showCreateButton) &&
      <li className={SearchableClasses.ActionItem}>
        <Button type={"button"} variant={ButtonVariants.Ghost} icon="add" disabled={!searchText} onClick={onCreateNew}>CREATE NEW</Button>
      </li>
    }
  </ul>;
});

export enum SearchableClasses {
  SearchableMenu = "searchable-menu",
  Action = "searchable-menu__action",
  Disabled = "searchable-menu__action--disabled",
  ActionItem = "searchable-menu__action--create",
  EmptyItem = "empty-item",
  Search = "search",
}

SearchableMenu.defaultProps = {
  emptyMessage: "Nothing found",
  optionLabel: "label",
  optionKey: "value",
  searchable: true,
  width: 216
};
