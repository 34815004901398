import React            from "react";
import { Routes }       from "@relcu/react-router";
import { Route }        from "@relcu/react-router";
import { Content }      from "@relcu/rc";
import { Header }       from "@relcu/rc";
import { Container }    from "@relcu/rc";
import { Typography }   from "@relcu/rc";
import { Toolbar }      from "@relcu/rc";
import { Sidenav }      from "@relcu/rc";
import { Page }         from "@relcu/rc";
import { AddOffer }     from "./AddOffer";
import { MakeProposal } from "./MakeProposal";
import { OfferTable }   from "./OfferTable/OfferTable";
import { Proposal }     from "./Proposal";

export const PricingBetaView = React.memo(function PricingBetaView(props: any, context) {
  const { additionalOfferFields = [] } = props;
  const [expand, setExpand] = React.useState(true);

  return (
    <Page style={{ position: "relative" }}>
      <Proposal.Provider>
        <Routes>
          <Route element={
            <>
              <Page.Sidebar width={expand ? 300 : 0}
                            style={{ borderWidth: expand ? "1px" : "0", opacity: expand ? 1 : 0 }}>
                <Sidenav.Header>
                  <Toolbar spacing={8} size={"sm"}>
                    <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
                    <Typography weights={"medium"} variant={"base16"}>Loan details</Typography>
                  </Toolbar>
                </Sidenav.Header>
                <Sidenav style={{ height: "calc(100% - 56px)" }} expanded={expand} appearance="subtle">
                  <Sidenav.Body style={{ height: "100%" }}>
                    <Proposal>
                      <Proposal.PropertyType/>
                      <Proposal.Occupancy/>
                      <Proposal.LoanPurpose/>
                      <Proposal.Address/>
                    </Proposal>
                  </Sidenav.Body>
                </Sidenav>
              </Page.Sidebar>
              <Container style={{ overflowX: "auto" }}>
                <Header>
                  <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
                           style={{ padding: `0  16px 0 ${expand ? "16px" : "8px"}`, height: "56px", verticalAlign: "center" }}>
                    {!expand &&
                      <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>}
                    <Typography color={"primary"} weights={"medium"} variant={"base16"}>Available offers:</Typography>
                    <Proposal.Consumer>
                      {context => (
                        <>
                          {!!context?.id && <>
                            <AddOffer/>
                            <Typography>You can include up to 3 offers in the proposal.</Typography>
                          </>}
                        </>
                      )}
                    </Proposal.Consumer>
                    <div style={{ flexGrow: 1 }}/>
                    <MakeProposal/>
                  </Toolbar>
                </Header>
                <Content style={{ overflow: "overlay" }}>
                  <OfferTable additionalOfferFields={additionalOfferFields}/>
                </Content>
              </Container>
            </>
          }/>
        </Routes>
      </Proposal.Provider>
    </Page>
  );
});
