import React          from "react";
import { FC }         from "react";
import { Popover }    from "@relcu/rc";
import { Cell }       from "rsuite-table";
import { HeaderCell } from "rsuite-table";
import { Column }     from "rsuite-table";
import { Table }      from "rsuite-table";

export const Adjustments: FC<any> = React.forwardRef(function Adjustments(props, ref: any) {
  const { adjustments, ...rest } = props;
  return (
    <Popover ref={ref} {...rest} arrow={false} full>
      { adjustments &&
        <Table data={adjustments} bordered width={600} cellBordered
             wordWrap="break-word"
             style={{ border: "1px solid var(--rc-background-tint5)", borderRadius: "5px" }}
             minHeight={20}
             autoHeight>
        {
          Object.keys(adjustments?.[ 0 ]).map((k, i) => {
            return <Column key={i} flexGrow={1}>
              <HeaderCell>{k}</HeaderCell>
              <Cell dataKey={k}/>
            </Column>;
          })
        }
      </Table>
      }
    </Popover>
  );
});
