import React                                          from "react";
import { FC }                                         from "react";
import { MutableRefObject }                           from "react";
import { useRef }                                     from "react";
import { SetStateAction }                             from "react";
import { Dispatch }                                   from "react";
import { useLayoutEffect }                            from "react";
import { useContext }                                 from "react";
import { createContext }                              from "react";
import { useMemo }                                    from "react";
import { useState }                                   from "react";
import { useNavigate }                                from "@relcu/react-router";
import { useParams }                                  from "@relcu/react-router";
import { useReactiveVar }                             from "@apollo/client";
import { useMutation }                                from "@apollo/client";
import { gql }                                        from "@apollo/client";
import { OnChange }                                   from "@relcu/form";
import { FormSpy }                                    from "@relcu/form";
import { IconButton }                                 from "@relcu/rc";
import { ToggleDropdown }                             from "@relcu/rc";
import { Icon }                                       from "@relcu/rc";
import { Form }                                       from "@relcu/rc";
import { Stack }                                      from "@relcu/rc";
import { Button }                                     from "@relcu/rc";
import { TabItem }                                    from "@relcu/rc";
import { TabsSwitcher }                               from "@relcu/rc";
import { TabPanel }                                   from "@relcu/rc";
import { TabPanelContainer }                          from "@relcu/rc";
import { TabContainer }                               from "@relcu/rc";
import { Container }                                  from "@relcu/rc";
import { Page }                                       from "@relcu/rc";
import { Toolbar }                                    from "@relcu/rc";
import { Header }                                     from "@relcu/rc";
import { Content as RCContent }                       from "@relcu/rc";
import { Alignment }                                  from "@relcu/ui";
import { Tooltip }                                    from "@relcu/ui";
import { toFirstUpper }                               from "@relcu/ui";
import { useAlert }                                   from "@relcu/ui";
import { HiddenField }                                from "@relcu/ui";
import { useSource }                                  from "@relcu/ui";
import { omit }                                       from "@relcu/ui";
import { defaultMutators }                            from "@relcu/ui";
import { HorizontalDivider }                          from "@relcu/ui";
import { EditorRef }                                  from "react-email-editor/dist/types";
import { fullScreenVar }                              from "../../../../../reactiveVars";
import { toFirstLower, htmlToText }                   from "../../../../../utils/helpers";
import { getReplacementParams }                       from "../../../../../utils/schemaUtils";
import { DirtyDialogController }                      from "../../../../Generation";
import { EMAIL_TEMPLATE_FRAGMENT }                    from "../../../../operations.graphql";
import { GetMailTemplates_emailTemplates_edges_node } from "../__types__/GetMailTemplates";
import { CreateEmailTemplateVariables }               from "./__types__/CreateEmailTemplate";
import { CreateEmailTemplate }                        from "./__types__/CreateEmailTemplate";
import { UpdateEmailTemplateVariables }               from "./__types__/UpdateEmailTemplate";
import { UpdateEmailTemplate }                        from "./__types__/UpdateEmailTemplate";
import { HtmlEditor }                                 from "./Editors/HtmlEditor";
import { PlainTextEditor }                            from "./Editors/PlainTextEditor";
import { UnlayerEditor }                              from "./Editors/UnlayerEditor";
import { SendTestMailButton }                         from "./SendTestMailButton";
import { TemplateDetails }                            from "./TemplateDetails/TemplateDetails";
import { TemplatePreviewSettings }                    from "./TemplatePreview/TemplatePreviewSettings";
import "./mail-template-content.css";

export interface ContentProps {
  expand: boolean;
  setExpand: any;
  template?: GetMailTemplates_emailTemplates_edges_node;
  onDuplicate?(data: GetMailTemplates_emailTemplates_edges_node);
  onRemove?(id: string);
  hasPermissions: boolean;
  replaceableFieldsSources?: string[];
}

const requiredFields = {
  "title": "Template name",
  "subject": "Subject",
  "content": "Content"
};

const fieldsTabs = {
  "title": 0,
  "subject": 0,
  "content": 1
};

export const Content: FC<ContentProps> = React.memo(function Content(props) {
  const {
    expand,
    setExpand,
    template,
    onDuplicate,
    onRemove,
    hasPermissions
  } = props;
  const isFullScreen = useReactiveVar(fullScreenVar);
  const emailEditorRef = useRef<EditorRef>(null);
  const { $viewer, $object } = useSource();
  const { success } = useAlert();
  const { type, mailTemplateId, objectId: userObjectId } = useParams();
  const navigate = useNavigate();
  const initialValues = useMemo(() => {
    if (mailTemplateId == "create") {
      return {
        type,
        title: "New template",
        applyTo: ["lead"],
        availableFor: ["manual"],
        to: []
      };
    }
    const existsData = { to: [], ...template };

    if (!Array.isArray(existsData?.applyTo)) {
      existsData.applyTo = [];
    }

    if (!Array.isArray(existsData?.availableFor)) {
      existsData.availableFor = [];
    }

    return existsData;
  }, [template, type, mailTemplateId]);
  const permissionCheck = (hasPermissions && $viewer.emailTemplateEditorPermissions?.[ initialValues.type ]) || ($viewer.role === "admin" && $object.id !== $viewer.id);
  const [preview, setPreview] = useState(!permissionCheck);
  useLayoutEffect(() => {
    setPreview(!permissionCheck);
  }, [mailTemplateId]);
  const [loadingEditor, setLoadingEditor] = useState(false);
  const [create] = useMutation<CreateEmailTemplate, CreateEmailTemplateVariables>(CREATE_EMAIL_TEMPLATE);
  const [update] = useMutation<UpdateEmailTemplate, UpdateEmailTemplateVariables>(UPDATE_EMAIL_TEMPLATE, {
    refetchQueries: ["GetMailTemplates"]
  });

  const handleSubmit = async (values, form) => {
    const previewFields = props.replaceableFieldsSources.map(field => toFirstLower(field));
    const formValues: any = omit({ ...values }, [...previewFields, "from", "to", "id", "objectIcon", "objectId", "__typename", "ACL", "scope"]);
    const errors = Object.keys(requiredFields).reduce((previousValue, currentValue) => {
      if (!values[ currentValue ]) {
        previousValue[ currentValue ] = `${requiredFields[ currentValue ]} is required.`;
      }

      return previousValue;
    }, {});

    if (Object.keys(errors).length) {
      return errors;
    }

    const data = {
      ...formValues
    };

    if (values.id) {
      if (formValues.owner) {
        data[ "owner" ] = {
          link: formValues.owner.id
        };
      } else {
        delete data.owner;
      }

      await update({
        variables: {
          input: {
            id: values.id,
            fields: data
          }
        }
      });
      setTimeout(() => {
        form.reset(values);
      }, 0);
      success("Template successfully updated.");
    } else {
      if ($object.className !== "Settings") {
        data[ "owner" ] = {
          link: $viewer.id
        };
      }

      const { data: { createEmailTemplate: { emailTemplate: { objectId, type } } = {} } = {} } = await create({
        variables: {
          input: {
            fields: data
          }
        }
      });
      // todo redirect to result.data.createEmailTemplate.emailTemplate.objectId
      success("Template successfully created.");
      setTimeout(() => {
        form.reset(values);
      }, 0);
      setTimeout(() => {
        if ($object.className == "Settings") {
          navigate(`/settings/mail-templates/${objectId}/${type}`);
        } else {
          navigate(`/user/${userObjectId}/mail-templates/${objectId}/${type}`);
        }
      }, 500);
    }
  };

  const handleFullScreen = () => {
    const isFullScreen = fullScreenVar();
    fullScreenVar(!isFullScreen);
  };

  const checkCanAction = (values) => {
    return !!htmlToText(values.content)?.trim() && !!(values?.title?.trim()) && !!(values.subject?.trim()) && values.applyTo?.length && values.availableFor?.length;
  };

  const canUpdate = (($object?.emailTemplateEditorPermissions?.[ template?.type ] && $object.id === $viewer.id) || $object.className == "Settings");

  return <Container style={{ overflowX: "auto" }} key={template?.id || type}>
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...defaultMutators,
        setFieldState: (args, state) => {
          const [error, field] = args;
          const formField = state.fields[ field ];
          if (formField) {
            if (error) {
              formField.data = { ...field.data, error };
            } else {
              formField.data = {};
            }
          }
        }
      }}
      fluid
      className={"mail-template-form"}>
      <MailTemplateContext.Provider value={{
        replaceableFieldsSources: props.replaceableFieldsSources,
        loadingEditor,
        onEditorLoad: setLoadingEditor,
        permissionCheck,
        emailEditorRef,
        setPreview,
        preview
      }}>
        <>
          <Header>
            <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
                     style={{
                       padding: `0  16px 0 ${expand ? "16px" : "8px"}`,
                       height: "56px",
                       verticalAlign: "center"
                     }}>
              {
                !expand &&
                <Page.Toggle size={"md"} expand={expand} onToggle={() => setExpand(expand => !expand)}/>
              }
              <FormSpy subscription={{ values: true }}>
                {
                  ({ values }) => {
                    return (
                      (template?.id && permissionCheck) ?
                        <ToggleDropdown
                          onClick={(event) => event.stopPropagation()}
                          toggleRenderer={
                            <Button
                              size={"xs"}
                              appearance={"subtle"}
                              endIcon={<Icon type="keyboard_arrow_down"/>}>
                              {values?.title}
                            </Button>
                          }
                          placement={"autoVerticalEnd"}>
                          {
                            canUpdate &&
                            <ToggleDropdown.Item
                              eventKey={3}
                              onSelect={(event) => onDuplicate({ ...template, ...values })}>
                              <Icon type="content_copy"/>
                              Duplicate
                            </ToggleDropdown.Item>
                          }
                          <ToggleDropdown.Item
                            eventKey={3}
                            onSelect={(event) => onRemove(template.id)}>
                            <Icon type="delete"/>
                            Remove
                          </ToggleDropdown.Item>
                        </ToggleDropdown> :
                        <Button
                          disabled
                          size={"xs"}
                          appearance={"subtle"}>
                          {values?.title}
                        </Button>
                    );
                  }
                }
              </FormSpy>
              <div style={{ flexGrow: 1 }}/>
              {
                permissionCheck &&
                <FormSpy subscription={{ values: true }}>
                  {
                    ({ values }) => {
                      const canAction = checkCanAction(values);
                      return <Button size={"xs"} type={"submit"}
                                     disabled={canAction ? loadingEditor : true}>
                        {
                          canAction ?
                            (mailTemplateId == "create" ? "CREATE" : "SAVE") + " TEMPLATE" :
                            <Tooltip title={"Please complete all information necessary for template creation."}
                                     alignment={Alignment.Top}>
                              <p> {mailTemplateId == "create" ? "CREATE" : "SAVE"} TEMPLATE</p>
                            </Tooltip>
                        }
                      </Button>;
                    }
                  }
                </FormSpy>
              }
              <IconButton
                onClick={handleFullScreen}
                icon={<Icon type={isFullScreen ? "close_fullscreen" : "open_in_full"}/>}
                style={{ color: "var(--rc-accent-03-primary)" }}
                appearance={"subtle"}/>
            </Toolbar>
          </Header>
          <TemplateContent type={type}/>
        </>
      </MailTemplateContext.Provider>
    </Form>
  </Container>;
});

const TemplateContent = ({ type }) => {
  const { permissionCheck, emailEditorRef, preview, setPreview, onEditorLoad } = useContext(MailTemplateContext);
  const unlayer = emailEditorRef.current?.editor;
  const [tab, setTab] = useState(0);
  const [replacementParams, setReplacementParams] = useState([]);

  const handleApplyToChange = (value) => {
    let result = value;
    if (value.includes("loanProposal")) {
      result = value.filter(v => v != "loanProposal").concat(["loanEstimate", "loanEstimateOffer"]);
    }
    const params = getReplacementParams(["User", "Settings", ...result].map(v => toFirstUpper(v)));
    setReplacementParams(params);
  };

  const handlePreview = () => {
    setPreview(prev => !prev);
    if (type == "builder" && unlayer) {
      unlayer.showPreview({ device: "desktop" });
    }
  };
  function renderEditor() {
    switch (type) {
      case "html":
        return <HtmlEditor replacements={replacementParams}/>;
      case "plainText":
        return <PlainTextEditor replacements={replacementParams}/>;
      case "builder":
        return <UnlayerEditor replacements={replacementParams}/>;
    }
  }

  return <RCContent style={{ overflow: "overlay", flex: 1 }}>
    <DirtyDialogController/>
    <FormSpy
      subscription={{ submitErrors: true }}
      onChange={({ submitErrors }) => {
        const invalidField = submitErrors && fieldsTabs[ Object.keys(submitErrors)[ 0 ] ];
        if (invalidField != null) {
          setTimeout(() => {
            setTab(invalidField);
          }, 0);//todo workaround
        }
      }}/>
    <OnChange
      name={"applyTo"}
      children={handleApplyToChange}/>
    <FormSpy
      subscription={{ initialValues: true }}
      onChange={({ initialValues }) => {
        setTimeout(() => {
          handleApplyToChange(initialValues.applyTo);
        }, 0);//todo workaround
      }}/>
    <TabContainer gap={11} outerState={[tab, setTab]} className={"mail-template-tab-container"}>
      <TabPanelContainer>
        {
          permissionCheck &&
          <TabPanel>
            <TemplateDetails replacements={replacementParams}/>
          </TabPanel>
        }
        <TabPanel>
          <Stack childrenRenderMode={"clone"} direction={"column"} style={{ flex: 1 }} alignItems={"stretch"}>
            <HiddenField name={"subject"}/>
            <HiddenField name={"title"}/>
            <SendTestMailButton/>
            <TemplatePreviewSettings
              onPreview={handlePreview}
            />
            {renderEditor()}
          </Stack>
        </TabPanel>
      </TabPanelContainer>
      <TabsSwitcher itemsWidth={145}>
        {
          permissionCheck &&
          <TabItem active={true}>
            Template details
          </TabItem>
        }
        <TabItem active={false}>
          Layout and content
        </TabItem>
      </TabsSwitcher>
      <HorizontalDivider className={"mail-template-controller-divider"}/>
    </TabContainer>
  </RCContent>;
};

export const MailTemplateContext = createContext<{
  permissionCheck: boolean,
  loadingEditor: boolean,
  onEditorLoad: Dispatch<SetStateAction<boolean>>,
  setPreview: Dispatch<SetStateAction<boolean>>,
  emailEditorRef: MutableRefObject<EditorRef>
  preview: boolean,
  replaceableFieldsSources: string[]
}>(null);

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate ($input: CreateEmailTemplateInput!) {
    createEmailTemplate(input: $input) {
      emailTemplate {
        ...EmailTemplate
      }
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;
export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($input: UpdateEmailTemplateInput!) {
    updateEmailTemplate(input: $input) {
      emailTemplate {
        ...EmailTemplate
      }
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;
