import { makeVar }                          from "@apollo/client";
import { gql, useApolloClient }             from "@apollo/client";
import { useLazyQuery }                     from "@apollo/client";
import { mergeDeep }                        from "@apollo/client/utilities";
import { FormApi }                          from "@relcu/form";
import { useSource }                        from "@relcu/ui";
import { useConstant }                      from "@relcu/ui";
import { useRef }                           from "react";
import { useCallback }                      from "react";
import { DOCUMENT }                         from "../../../../graph/operations.graphql";
import { NODE_FRAGMENT }                    from "../../../../graph/operations.graphql";
import { schemaVar }                        from "../../../../reactiveVars";
import { layoutVar }                        from "../../../../reactiveVars";
import { TitleFeeArgs }                     from "../../../../types/graphql-global-types";
import { transformFields }                  from "../../../../utils/graphQlUtils";
import { getPrimaryBorrower }               from "../../../../utils/helpers";
import { loanAmountDetails }                from "../../../../utils/helpers";
import { useJqlMutation }                   from "../../Jql";
import { GetPmiVariables }                  from "../PricingBetaView/OfferTable/Offer/__types__/GetPmi";
import { PMI_V2_RESULT_COMPANY_FRAGMENT }   from "../PricingBetaView/OfferTable/Offer/FeeProvider";
import { GetAppraisalFeeByCountyVariables } from "./__types__/GetAppraisalFeeByCounty";
import { GetAppraisalFeeByCounty }          from "./__types__/GetAppraisalFeeByCounty";
import { GetFeeByStateVariables }           from "./__types__/GetFeeByState";
import { GetFeeByState }                    from "./__types__/GetFeeByState";
import { GetPmiRates_getPmiRatesV2 }        from "./__types__/GetPmiRates";
import { GetPmiRatesVariables }             from "./__types__/GetPmiRates";
import { GetPmiRates }                      from "./__types__/GetPmiRates";
import { GetTitleFeesVariables }            from "./__types__/GetTitleFees";
import { GetTitleFees }                     from "./__types__/GetTitleFees";
import { MortechPricingVariables }          from "./__types__/MortechPricing";
import { MortechPricing }                   from "./__types__/MortechPricing";
import { MortechProducts_mortechProducts }  from "./LoanCriteria/__types__/MortechProducts";
import { usePendingOffers }                 from "./usePendingOffers";

export function usePricingView() {
  const client = useApolloClient();
  const layouts = layoutVar();
  const schemas = schemaVar();
  const leadLayout = layouts[ "Lead" ];
  const { $object: node, $settings: { pricing: settings, excludeProductCategory, decouplePricingPage } } = useSource();
  const pmiEligible = useRef(false);
  const criteriaDataVar = useConstant(() => makeVar(null));
  const updateTitleFeesRef = useRef<UpdateTitleFees>(null);
  const { remove, offers, count: offersCount, loading: offersLoading } = usePendingOffers(node.id);
  const [getPricing, {
    data: mortechData,
    loading: mortechLoading
  }] = useLazyQuery<MortechPricing, MortechPricingVariables>(GET_MORTECH_PRICING, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  const [getTitleFees, {
    data: {
      titleFees = []
    } = Object({}),
    error: titleFeesError,
    loading: titleFeesLoading,
    fetchMore: titleFeesFetchMore,
    variables: titleFeesVariables
  }] = useLazyQuery<GetTitleFees, GetTitleFeesVariables>(GET_TITLE_FEES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  const [getFeesByState, { data: stateBasedFee }] = useLazyQuery<GetFeeByState, GetFeeByStateVariables>(GET_FEE_BY_STATE);
  const [getAppraisalList] = useLazyQuery(GET_ENABLED_APPRAISAL_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const [getFeesByCounty, { data: appraisalFees }] = useLazyQuery<GetAppraisalFeeByCounty, GetAppraisalFeeByCountyVariables>(GET_FEE_BY_COUNTY);
  const [getPmiRates, {
    loading: pmiLoading,
    data: pmiData,
    fetchMore,
    error,
    variables
  }] = useLazyQuery<GetPmiRates, GetPmiRatesVariables>(GET_PMI_RATES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  const [updateLead] = useJqlMutation(leadLayout.jql.mutation.update, {
    operationName: "UpdateLeadPricing"
  });
  const removeOffer = (obj) => {
    remove({
      variables: {
        id: obj.id
      }
    });
  };
  function getAmortizationType(product: string) {
    const productName = product?.toLowerCase() || "";
    return productName.includes("arm") ? "ARM" : "Fixed";
  }

  function getLoanType(product: string) {
    let type = "";
    const productName = product?.toLowerCase() || "";
    let hasNonConf = productName.includes("non conf") || productName.includes("non conv");
    // Home Ready , Home Poss , HomeStyle also included as conv loans
    let hasConf = productName.includes("conf") || productName.includes("conv") || productName.includes("home");

    if ((!hasNonConf && hasConf) || productName.includes("fhlmc") || productName.includes("fhma")) {
      type = "ConvIns";
    } else if (productName.includes("fha")) {
      type = "fha";
    } else if (productName.includes("va")) {
      type = "va";
    } else if (productName.includes("fmha")) {
      type = "FmHA";
    } else {
      type = "ConvUnins";
    }
    return type;
  }
  const cleanedPMI = (data) => {
    let cleanedData = {};
    Object.keys(data).forEach(k => {
      if (k !== "__typename") {
        if (typeof data[ k ] == "object" && data[ k ] !== null) {
          cleanedData[ k ] = cleanedPMI(data[ k ]);
        } else {
          cleanedData[ k ] = data[ k ];
        }
      }
    });
    return cleanedData;
  };

  const api = {
    get initialValues() {
      let { id, objectId, original, ACL, createdAt, updatedAt, duplicates, ...rest } = node;
      const isExcludedCategory = rest.mortech && excludeProductCategory?.findIndex(t => t == rest.mortech.product) > -1;
      if (rest?.mortech?.product && isExcludedCategory) {
        rest = {
          ...rest,
          mortech: {
            ...rest.mortech,
            product: null,
            productName: null
          }
        };
      }
      return rest;
    },
    pmiLoading,
    mortechLoading,
    refetch: () => fetchMore({ variables }),
    offers,
    removeOffer,
    offersLoading,
    offersCount,
    isPmiEligible: pmiEligible.current,
    // get error() {
    //   return error || pmiData?.getPmiRatesV2?.status !== "Success" && pmiData?.getPmiRatesV2?.errorMessage;
    // },
    get stateBasedFee() {
      return stateBasedFee?.stateBasedFees?.edges?.[ 0 ].node;
    },
    get appraisalFees() {
      let appfees = appraisalFees?.appraisalFees?.edges?.map(({ node }) => node);
      return appfees;
    },
    titleFeesLoading,
    titleFeesRefetch: (input) => titleFeesFetchMore({ variables: mergeDeep(titleFeesVariables, input) }),
    get titleFees() {
      return {
        error: titleFeesError?.message,
        result: titleFees
      };
    },
    get mortechData() {
      return mortechData?.mortechPricing?.products;
    },
    get pmiData() {
      return pmiEligible.current ? cleanedPMI(pmiData?.getPmiRatesV2 || {}) as GetPmiRates_getPmiRatesV2 : undefined;
    },
    get pmiError() {
      return pmiData?.getPmiRatesV2?.errorMessage;
    },
    createTitleFeeInput(lead): TitleFeeArgs {
      const { total } = loanAmountDetails(lead, settings);
      return {
        loanAmount: total,
        cashOutAmount: lead.cashOut,
        loanPurpose: lead.loanPurpose,
        currentMortgageBalance: lead?.property?.currentMortgageBalance,
        amortizationType: getAmortizationType(lead.mortech.productName),
        loanType: getLoanType(lead.mortech.productName),
        firstTimeHomeBuyer: lead.firstTimeHomeBuyer,
        property: {
          type: lead?.property?.type,
          occupancy: lead.property.occupancy,
          propertyAddress: {
            city: lead?.property?.propertyAddress?.city,
            county: lead?.property?.propertyAddress?.county.replace(" County", ""),
            state: lead?.property?.propertyAddress?.state,
            zipCode: lead?.property?.propertyAddress?.zipCode
          },
          value: lead?.property?.value
        }
      };
    },
    createPmiInput(lead): GetPmiVariables["input"] {
      const borrowers = lead.members.filter((members) => ["borrower", "co_borrower"].includes(members.type)).map(({
        type,
        creditScore,
        employmentStatus
      }) => ({
        type,
        creditScore,
        isSelfEmployed: employmentStatus === "self_employed"
      }));
      let initialArmTerm;
      let productMatches = lead.loanProduct.match(/.*(\d{2}).*[Yr|Year].*[ARM|LIBOR][^0-9]+(\d{1,2})[\/1| Yr.*]/);
      if (productMatches) {
        initialArmTerm = (parseInt(productMatches[ 2 ]) * 12).toString();
      }
      const normLoanProgram = (lead.mortech?.productName || "").toLowerCase();
      let pmiLoanProgram = "None";
      if (normLoanProgram.includes("home") && normLoanProgram.includes("ready")) {
        pmiLoanProgram = "Fannie Mae HomeReady";
      } else if (normLoanProgram.includes("home") && (normLoanProgram.includes("poss") || normLoanProgram.includes("possible"))) {
        pmiLoanProgram = "Freddie Mac HomePossible";
      }
      const { total } = loanAmountDetails(lead, settings);
      return {
        leadId: node.objectId,
        loanAmount: Math.round(total),
        dti: Math.round(lead.dti * 100) / 100,
        loanPurpose: lead.loanPurpose,
        loanProgram: pmiLoanProgram,
        loanTerm: parseInt(lead.loanTerm),
        interestRate: 3.99,
        amortizationType: getAmortizationType(lead.mortech.productName).toLowerCase(),
        firstTimeHomeBuyer: lead.firstTimeHomeBuyer,
        initialArmTerm: initialArmTerm ? Number(initialArmTerm) : null,
        borrower: borrowers.find(({ type }) => type === "borrower"),
        coBorrower: borrowers.find(({ type }) => type === "co_borrower"),
        property: {
          type: lead.property.type,
          occupancy: lead.property.occupancy,
          value: Math.round(lead.property.value),
          propertyAddress: {
            city: lead.property.propertyAddress.city,
            county: lead.property.propertyAddress.county,
            state: lead.property.propertyAddress.state,
            zipCode: lead.property.propertyAddress.zipCode
          }
        }
      };
    },
    async onCriteriaSubmit(formData: Partial<any & {
      totalLoanAmount: number,
      mortechProductsList: MortechProducts_mortechProducts []
    }>, form: FormApi<any>) {
      const { totalLoanAmount, mortechProductsList, ...lead } = formData;
      pmiEligible.current = false;
      const fields = transformFields(lead, api.initialValues, "Lead", schemas, true);
      !decouplePricingPage && await updateLead({
        variables: {
          input: {
            id: node.id,
            fields
          }
        }
      });
      criteriaDataVar(lead);
      const isPmiEligible = (
        lead.loanProgram === "conventional" &&
        !lead.mortech.productName.toLowerCase().includes("jum") &&
        !lead.mortech.productName.toLowerCase().includes("jumbo")  &&
        !lead.mortech.productName.toLowerCase().includes("non conf")  &&
        (lead.downPayment < lead.property.value * settings.conventional.pmiDownPaymentBoundary / 100)
      );

      let pricingInput: any = {
        mortech: {
          source: lead.mortech.source,
          investorIds: lead.mortech.investorIds,
          product: lead.mortech.product,
          view: lead.mortech.view,
          lockInDays: lead.mortech.lockInDays,
          hudReo: lead.mortech.hudReo,
          streamLine: lead.mortech.streamLine,
          streamLineWithAppraisal: lead.mortech.streamLineWithAppraisal,
          streamLineWithCredit: lead.mortech.streamLineWithCredit,
          amiLlpaWaiver: lead.mortech.amiLlpaWaiver
        },
        property: {
          type: lead.property.type,
          occupancy: lead.property.occupancy,
          value: lead.property.value,
          propertyAddress: {
            state: lead.property.propertyAddress.state,
            zipCode: lead.property.propertyAddress.zipCode,
            propertyCounty: lead.property.propertyAddress.county
          }
        },
        downPayment: lead.downPayment,
        secondaryFinancing: lead.secondaryFinancing,
        loanPurpose: lead.loanPurpose,
        cashOut: lead.cashOut,
        cltv: lead.cltv,
        ltv: lead.ltv,
        fico: lead.members.filter((m) => (["borrower", "co_borrower"].includes(m.type))).map(({ creditScore }) => creditScore),
        financeFf: lead.financeFf,
        financeMip: lead.financeMip,
        firstTimeHomeBuyer: lead.firstTimeHomeBuyer,
        firstUseOfVaProgram: lead.firstUseOfVaProgram,
        loanAmount: lead.loanAmount,
        veteranStatus: getPrimaryBorrower(lead).veteranStatus,
        waiveEscrow: lead.waiveEscrow
      };
      pricingInput.dti = Math.round(lead.dti * 100) / 100;
      if (isPmiEligible) {
        pmiEligible.current = true;
      }

      client.writeQuery<GetPmiRates, GetPmiRatesVariables>({
        query: GET_PMI_RATES,
        data: {
          getPmiRatesV2: {
            requestId: "",
            status: "",
            errorMessage: "",
            payload: {
              single_lp: {},
              single_nr: {},
              single_r: {},
              monthly: {},
              split_bd: {}
            }
          } as any
        }
      });//todo remove temporary solution

      await getPricing({
        variables: {
          input: pricingInput
        }
      });
      const titleFeeInput = api.createTitleFeeInput(lead);
      client.writeQuery<GetTitleFees, GetTitleFeesVariables>({
        query: GET_TITLE_FEES,
        data: { titleFees: [] }
      });//todo remove temporary solution
      if (isPmiEligible) {
        getPmiRates({
          variables: {
            input: api.createPmiInput(lead)
          }
        }).catch(console.error);
      }
      await getTitleFees({
        variables: {
          input: titleFeeInput
        }
      });
      updateTitleFeesRef.current = (providerId, fees) => {
        const existing = client.cache.readQuery<GetTitleFees, GetTitleFeesVariables>({ query: GET_TITLE_FEES, variables: { input: titleFeeInput } });
        const titleFees = existing?.titleFees?.map(titleFee => titleFee.providerId == providerId ? { ...titleFee, fees: { ...titleFee.fees, ...fees } } : titleFee);
        client.cache.writeQuery<GetTitleFees, GetTitleFeesVariables>({
          query: GET_TITLE_FEES,
          data: {
            ...existing,
            titleFees
          },
          variables: {
            input: titleFeeInput
          }

        });
      };
      await getFeesByState({
        variables: {
          state: lead.property.propertyAddress.state
        }
      });
      const appraisalName = () => {
        let name = "";
        const selectedProduct = lead.mortech.productName;
        let productName = selectedProduct?.toLowerCase() || "";
        let hasJumbo = productName.includes("jumbo");
        let hasNonConf = productName.includes("non conf") || productName.includes("non conv");
        let hasConf = productName.includes("conf") || productName.includes("conv") || productName.includes("home");
        if (hasJumbo) {
          name = "jumbo";
        } else if ((!hasNonConf && hasConf) || productName.includes("fhlmc") || productName.includes("fhma")) {
          name = "conventional";
        } else if (productName.includes("fha")) {
          name = "fha";
        } else if (productName.includes("va")) {
          name = "va";
        } else {
          name = "nonConf";
        }
        return name;
      };
      const { data: appraisalData } = await getAppraisalList();
      const usableAppraisalNode = appraisalData?.appraisals?.edges[ 0 ]?.node;
      let apprType = usableAppraisalNode?.defaultType;
      if (usableAppraisalNode) {
        for (let file of usableAppraisalNode.uploadedFiles) {
          if (file.type == appraisalName()) {
            apprType = appraisalName();
          }
        }
      }
      await getFeesByCounty({
        variables: {
          where: {
            AND: [
              {
                appraisal: {
                  have: {
                    objectId: {
                      equalTo: usableAppraisalNode?.objectId
                    }
                  }
                }
              },
              {
                state: {
                  equalTo: lead.property.propertyAddress.state
                }
              },
              {
                OR: [
                  {
                    county: {
                      equalTo: lead.property.propertyAddress.county
                    }
                  },
                  {
                    county: {
                      equalTo: "*"
                    }
                  }
                ]
              },
              {
                loanType: {
                  equalTo: apprType
                }
              }
            ]
          }
        }

      });
    }
  };
  return {
    ...api,
    criteriaDataVar,
    updateTitleFees(providerId, fees) {
      updateTitleFeesRef.current(providerId, fees);
    },
    // onCriteriaSubmit: useCallback(api.onCriteriaSubmit, [getPricing, updateLead]),
    createPmiInput: useCallback(api.createPmiInput, [settings]),
    createTitleFeeInput: useCallback(api.createTitleFeeInput, [settings])
  };
}
export type UpdateTitleFees = (providerId: string, fees: { [ k: string ]: number }) => void;

export const GET_MORTECH_PRICING = gql`
  query MortechPricing($input: MortechPricingInput!){
    mortechPricing(input: $input){
      status{
        errorNum
        errorDesc
      }
      products{
        productName
        productId
        results{
          quote{
            lastUpdate
            pricingStatus
            productTerm
            vendorName
            productDesc
            quoteDetails{
              loanAmount
              rate
              apr
              price
              piti
              srp
              monthlyPremium
              downPayment
              indexValue
              lockURL
              prepayType
              rateSheetPrice
              fees{
                hudLine
                feeAmount
                description
              }
              adjustments{
                totalRateAdj
                totalMarginAdj
                adjustmentDetails{
                  desc
                  applied
                  rateAdj
                  marginAdj
                  priceAdj
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PMI_RATES = gql`
  query GetPmiRates($input: PmiRatesArgs!)  {
    getPmiRatesV2(input:$input) @connection(key: "getPmiRatesV2"){
      payload{
        monthly {
          ...PmiRateV2ResultCompany
        }
        single_lp {
          ...PmiRateV2ResultCompany
        }
        single_nr {
          ...PmiRateV2ResultCompany
        }
        single_r {
          ...PmiRateV2ResultCompany
        }
        split_bd {
          ...PmiRateV2ResultCompany
        }
      }
      errorMessage
      requestId
      status
    }
  }
  ${PMI_V2_RESULT_COMPANY_FRAGMENT}
`;

export const APPRAISAL_FEE = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  fragment AppraisalFee on AppraisalFee{
    ...Node
    ...Document
    coOp
    condo
    manufactured
    multiFamily
    pud
    singleFamily
    mobile
    townhome
    county
    fipsCode
  }
`;

export const STATE_BASED_FEE_FRAGMENT = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  fragment StateBasedFee on StateBasedFee {
    ...Node
    ...Document
    state
    convCondo
    convMultiUnit
    convSingleUnit
    fhaCondo
    fhaMultiUnit
    fhaSingleUnit
    jumboCondo
    jumboMultiUnit
    jumboSingleUnit
    nonConfCondo
    nonConfMultiUnit
    nonConfSingleUnit
    vaCondo
    vaMultiUnit
    vaSingleUnit
    condoCertFee
  }
`;
export const GET_FEE_BY_STATE = gql`
  ${STATE_BASED_FEE_FRAGMENT}
  query GetFeeByState($state: String!) {
    stateBasedFees(where: {state: {equalTo: $state}}) {
      edges {
        node {
          ...StateBasedFee
        }
      }
    }
  }
`;
export const GET_FEE_BY_COUNTY = gql`
  ${APPRAISAL_FEE}
  query GetAppraisalFeeByCounty($where:AppraisalFeeWhereInput!) {
    appraisalFees(where: $where,order: [county_DESC]) {
      edges {
        node {
          ...AppraisalFee
        }
      }
    }
  }
`;
export const TITLE_FEE_FRAGMENT = gql`
  fragment TitleFee on TitleFeeResponses {
    title
    providerId
    default
    editable
    errorMessage
    fees{
      ownersTitle
      recordingCharges
      settlementFee
      titleInsurance
      transferTax
      lendersTitle
    }
  }
`;
export const GET_TITLE_FEES = gql`
  query GetTitleFees($input: TitleFeeArgs!)   {
    titleFees(input: $input) @connection(key: "titleFees") {
      ...TitleFee
    }
  }
  ${TITLE_FEE_FRAGMENT}
`;

export const GET_ENABLED_APPRAISAL_LIST = gql`
  query GetEnabled_AppraisalList{
    appraisals(where:{enabled:{equalTo: true}}, order: [createdAt_DESC]) {
      count
      edges{
        node{
          enabled
          uploadedFiles{
            type
          }
          defaultType
          objectId
          objectName
          createdAt
        }
      }
    }
  }
`;
