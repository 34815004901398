import React                                from "react";
import { FC }                               from "react";
import { useEffect }                        from "react";
import { useMemo }                          from "react";
import { useContext }                       from "react";
import { useState }                         from "react";
import { useMutation }                      from "@apollo/client";
import { useForm }                          from "@relcu/final-form";
import { FieldCell }                        from "@relcu/rc";
import { UPDATE_LOAN_ESTIMATE_OFFER }       from "../../Offer";
import { UpdateLoanEstimateOfferVariables } from "../../Offer/__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "../../Offer/__types__/UpdateLoanEstimateOffer";
import { FeeContext }                       from "../../Offer/FeeProvider";
import { PmiDialog }                        from "./PmiDialog";

export const PmiSelectCell: FC<{
  pmiEnabled?: boolean,
  pmiEditable?: boolean
}> = React.memo(function PmiSelectCell({ pmiEnabled, pmiEditable }) {
  const { getState } = useForm();
  const {
    populatePmi,
    reFetchPmi,
    pmiLoading,
    setSelectedPmi,
    selectedPmi: formPmi,
    pmiRates
  } = useContext(FeeContext);
  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(UPDATE_LOAN_ESTIMATE_OFFER);
  const form = useForm();
  const [open, setOpen] = useState(false);
  const getPmiData = (pmiRates) => {
    return {
      ...pmiRates,
      payload: Object.fromEntries(Object.entries<any>(pmiRates.payload || {}).map(([key, r]) => {
        const rate = Object.keys(r).map((key) => ({ ...r[ key ], providerId: key.toLowerCase(), providerName: key }));
        return [key, [...rate].sort((a, b) => a.premium1 - b.premium1)];
      }))
    };
  };
  const sortedPmiData = useMemo(() => getPmiData(pmiRates), [pmiRates]);
  const { pmiCompany: selectedId, pmiType: selectedType  } = Object(formPmi);

  function getSelectedPmi(type, id) {
    if (!(pmiRates?.payload && pmiRates?.payload[ type ])) {
      return null;
    }
    const rates = pmiRates?.payload[ type ] || {};
    const rateArray = Object.keys(rates).map((key) => ({ ...rates[ key ], providerId: key.toLowerCase(), providerName: key }));
    return rateArray?.find(fee => fee.providerId == id);
  }

  const selectedPmi = useMemo(() => {
    return getSelectedPmi(selectedType, selectedId);
  }, [selectedId, selectedType, pmiRates, selectedType]);

  const onApply = ({ id, type }) => {
    setOpen(false);
    setSelectedPmi({
      pmiCompany: id,
      pmiType: type,
      pmi: getSelectedPmi(type, id)?.premium1
    });
  };

  const onOpen = async () => {
    const { values } = getState();
    populatePmi(values);

    if (selectedId && Object.keys(sortedPmiData?.payload || {}).length) {
      setOpen(true);
    }
  };

  const reFetch = async () => {
    const { values } = getState();
    reFetchPmi(values);
  };

  const handleTypeChange = (type) => {
    const values = form.getState().values
    setSelectedPmi(prev => ({
      pmiCompany: values.pmiCompany,
      pmi: values.pmi,
      pmiType: type
    }));
  }

  useEffect(() => {
    if (formPmi) {
      Object.keys(formPmi).map(key => {
        form.change(key, formPmi[ key ]);
      });
      update({
        variables: {
          input: {
            id: form.getState().values.objectId,
            fields: formPmi
          }
        }
      })

    }
  }, [formPmi]);

  const warning = useMemo(() => {
    return pmiRates?.payload && Object.keys(pmiRates.payload).some(key => {
      const rates = pmiRates.payload[ key ];
      const rateArray = Object.keys(rates).map((key) => ({ ...rates[ key ], providerId: key.toLowerCase(), providerName: key }));
      const index = rateArray?.findIndex((provider) => provider.status !== "Success");
      return index > -1;
    });
  }, [sortedPmiData]);
  const error = useMemo(() => {
    return pmiRates?.status == "Error";
  }, [pmiRates]);

  return <>
    <PmiDialog open={open} onClose={() => setOpen(false)} reFetch={reFetch} loading={pmiLoading}
               pmiRates={sortedPmiData}
               onApply={onApply} selectedId={selectedId} selectedPmi={selectedPmi} selectedType={selectedType}/>
    <FieldCell.PmiCell
      pmiEnabled={pmiEnabled}
      pmiEditable={pmiEditable}
      onTypeChange={handleTypeChange}
      helperText={error ? "Failed to get PMI rates." : (warning ? "There are options that failed to load." : "")}
      status={error ? "error" : (warning ? "warning" : "info")} required
      loading={pmiLoading} onOpen={onOpen} name={"pmiCompany"}/>
  </>;
});
