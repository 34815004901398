import { FC }                      from "react";
import { useMemo }                 from "react";
import React                       from "react";
import { DateTime }                from "@relcu/date";
import { Box }                     from "@relcu/ui";
import { Widget }                  from "@relcu/ui";
import { useSource }               from "@relcu/ui";
import { IChoiceField }            from "../../../../types/ISchemas";
import { useSchemaField }          from "../../useSchemaField";
import { LeadCustomWidgetClasses } from "./LeadCustomWidgetClasses";
import "./lead-custom-widget.css";


export interface LeadCustomWidgetProps {
  header: {
    title: string[]
  }
  fields: any[]
}

export const LeadCustomWidget : FC<LeadCustomWidgetProps> = (props) => {
  const { header, fields } = props;
  const { $object: node } = useSource();
  const title = useMemo(() => {
    return header.title.reduce((tit, item, i) => {
      let itemVal = item;
      if (item[ 0 ] === "$") {
        itemVal = node[ item.substring(1) ];
      }
      return (i === 0 ? tit : tit + " ") + itemVal;
    }, "");
  }, [header, node]);
  const resolveValue = (field) => {
    const { name, component } = field;
    switch (component) {
      case "ChoiceField":
        const schemaField = useSchemaField<IChoiceField>("Lead", name);
        const obj = schemaField.options.find((option) => option.value == node[ name ]);
        return obj?.[ "label" ];
      case "PercentField":
        return node[ name ] ? `${Number(node[ name ])} %` : "-";
      case "BooleanField":
        return typeof node[ name ] === "boolean" ? node[ name ] ? "Yes" : "No" : "-";
      case "NumberField":
        return node[ name ] ? Number(node[ name ]) : "-";
      case "CurrencyField":
        return node[ name ] ? `${Number(node[ name ])} $` : "-";
      case "DateField":
        if (field.properties?.withTime) {
          return node[ name ] ? DateTime.fromISO(node[ name ]).toFormat("f") : "-";
        }
        return node[ name ] ? DateTime.fromISO(node[ name ]).toFormat("D") : "-";
      default:
        return node[ name ] ? node[ name ] : "-";
    }
  };

  return (
    <Widget gap={"XS"} className={LeadCustomWidgetClasses.LeadCustomWidget}>
      <Box container direction={"column"}>
        <Box container justify={"start"} className={LeadCustomWidgetClasses.LeadCustomWidgetMainRow}>
          {title}
        </Box>
        {
          fields.map(field => (
            <Box container key={field.name} justify={"start"} alignItems={"center"}
                 className={LeadCustomWidgetClasses.LeadCustomWidgetRow}>
              <Box flexBasis={"50%"} flexShrink={0}>{field.label}</Box>
              {resolveValue(field)}
            </Box>
          ))
        }
      </Box>
    </Widget>
  );
}
