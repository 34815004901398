import { useApolloClient }          from "@apollo/client";
import { DocumentNode }             from "@apollo/client";
import { useQuery }                 from "@apollo/client";
import { gql }                      from "@apollo/client";
import { omit }                     from "@relcu/ui";
import { pick }                     from "@relcu/ui";
import { useSource }                from "@relcu/ui";
import { LoanEstimateOffer }        from "../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE_OFFER }      from "../../../../graph/operations.graphql";
import { LOAN_ESTIMATE_OFFER_OB }   from "../../../../graph/operations.graphql";
import { schemaVar }                from "../../../../reactiveVars";
import { toNodeId }                 from "../../../../utils/helpers";
import { getFields }                from "../../../../utils/schemaUtils";
import { GetDefaultOfferVariables } from "./__types__/GetDefaultOffer";
import { GetDefaultOffer }          from "./__types__/GetDefaultOffer";

export const DEFAULT_LOAN_ESTIMATE_OFFER = gql`
  fragment DefaultLoanEstimateOffer on LoanEstimateOffer {
    objectId
    adminFee
    appraisalFee
    pricingEngine
    cltv
    creditReportFee
    dti
    electronicDocumentDelivery
    fico
    fico1
    pmiEligible
    financeMip
    mip
    financeFf
    ff
    firstTimeHomeBuyer
    selfEmployed
    firstTimeUse
    firstUseOfVaProgram
    floodCertificationFee
    insurance
    mortech {
      investor
      loanProductId
      loanProductName
      source
      view
      deliveryType
    }
    optimalBlue {
      ...LoanEstimateOfferOB
    }
    isHUDReo
    loanAmount
    loanProduct
    loanSafeFee
    loanTerm
    lockInDays
    lockInFee
    amiWaiverEligibility
    ltv
    mersFee
    mip
    pestInspectionFee
    docReviewFee
    attorneyFee
    surveyFee
    subordinationFee
    condoCertificateFee
    earnestMoneyPaid
    sellerConcession
    originationFee
    prepaidInterestAmount
    prepaidInterestDays
    prepaidInterestTotal
    propertyInsurancePrepaidAmount
    propertyInsurancePrepaidMonth
    propertyInsurancePrepaidTotal
    propertyTaxMonth
    propertyTaxAmount
    propertyTaxTotal
    propertyInsuranceYearly
    propertyAnnualInsurance
    propertyAnnualTax
    productType
    propertyValue
    propertyType
    amortizationType
    initialArmTerm
    downPayment
    currentMortgageBalance
    cashAmount
    purchasePrice
    isStreamLine
    withCredit
    withAppraisal
    loanProgram
    propertyUse
    veteranStatus
    pi
    monthlyPremium
    secondaryFinancing
    taxes
    totalLoanAmount
    transcriptFee
    verificationFee
    exempt
    waive
    waiveEscrow
    conforming
    documentType
    rate
    price
    apr
    monthlyIncome
    lienAmount
  }
  ${LOAN_ESTIMATE_OFFER_OB}
`;

export const GET_DEFAULT_OFFER = gql`
  query GetDefaultOffer($leadId:ID!){
    loanEstimateOffers(where: {
      lead:{have:{id:{equalTo:$leadId}}},
      OR: [
        {deleted: {equalTo: false}},
        {deleted: {equalTo: null}}
      ]
    },order: [createdAt_DESC],first: 1){
      edges {
        node {
          ...DefaultLoanEstimateOffer
        }
      }
    }
  }
  ${DEFAULT_LOAN_ESTIMATE_OFFER}
`;

export const GET_DEFAULT_OFFER_RATES = gql`
  query GetDefaultOfferRates($leadId:ID!){
    loanEstimateOffers(where: {lead:{have:{id:{equalTo:$leadId}}},deleted: {equalTo: false}},order: [createdAt_DESC],first: 1){
      edges {
        node {
          rate
          price
          apr
        }
      }
    }
  }
`;

export function useDefaultOffer() {
  const { $object: lead } = useSource();
  const client = useApolloClient();
  const { data: { loanEstimateOffers } = {}, loading } = useQuery<GetDefaultOffer, GetDefaultOfferVariables>(useAppendObCustomFields(GET_DEFAULT_OFFER), {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      leadId: lead.id
    }
  });
  const data = loanEstimateOffers?.edges?.at(0)?.node;

  const loanEstimateOffer = client.readFragment<LoanEstimateOffer>({
    fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
    fragmentName: "LoanEstimateOffer",
    id: client.cache.identify({ __typename: "LoanEstimateOffer", id: toNodeId({ className: "LoanEstimateOffer", objectId: data?.[ "objectId" ] }) })
  });//todo not sure this is good solution but the data from cache is invalid (missing fields)

  const cachedLoanEstimateOffer = pick(loanEstimateOffer ?? {}, [
    "earnestMoneyPaid",
    "sellerConcession",
    "propertyInsurancePrepaidMonth",
    "propertyInsurancePrepaidAmount",
    "propertyTaxMonth",
    "propertyTaxAmount",
    "prepaidInterestDays",
    "prepaidInterestAmount"
  ]);//todo not sure this is good solution but the data from cache is invalid (missing fields)

  return { data: data ? { ...omit(data, ["objectId"]), ...cachedLoanEstimateOffer } : {} as any, loading };
}

export function useAppendObCustomFields(document: DocumentNode) {
  const schemas = schemaVar();
  const obSchemaFields = getFields(schemas, "LoanEstimateOptimalBlue");
  const obCustomFields = obSchemaFields.filter((f) => f.integration?.name).map((f) => f.name);
  if (obCustomFields.length > 0) {
    const OB_CUSTOM_FIELDS = gql`
      fragment LoanEstimateOfferOB on LoanEstimateOptimalBlue{
        ${obCustomFields.join("\n")}
      }
    `;

    let obFragmentIndex = document.definitions.findIndex((d) => (d.kind === "FragmentDefinition" && d.name.value === "LoanEstimateOfferOB"));

    if (obFragmentIndex > -1) {
      let currentSelection = (document.definitions[ obFragmentIndex ] as any).selectionSet.selections;
      let missingFields = obCustomFields.filter((o) => ((currentSelection.findIndex((s) => (s.name.value === o))) === -1));
      if (missingFields.length) {
        (document.definitions[ obFragmentIndex ] as any).selectionSet.selections.push(...(OB_CUSTOM_FIELDS.definitions[ 0 ] as any).selectionSet.selections.filter((s) => missingFields.includes(s.name.value)));
      }
    } else {
      (document.definitions as any).push(OB_CUSTOM_FIELDS.definitions[ 0 ]);
    }
  }
  return document;
}
