import React               from "react";
import { FC }              from "react";
import { FormField }       from "@relcu/rc";
import { FormInputNumber } from "@relcu/rc/src/FormField";

export const NumberConfig: FC<any> = React.memo(function NumberConfig(props) {
  return (
    <FormField
      component={FormInputNumber}
      name="defaultValue"
      label="Default value"
    />
  );
});
