import { DateTime }             from "@relcu/date";
import { FunctionComponent }    from "react";
import { useContext }           from "react";
import { FC }                   from "react";
import { useMemo }              from "react";
import React                    from "react";
import { ListItemIconColors }   from "../../";
import { Alignment }            from "../../";
import { ListItemClasses }      from "../../";
import { BadgeColors }          from "../../";
import { BadgeVariants }        from "../../";
import { Badge }                from "../../";
import { FontIcon }             from "../../";
import { ListDraggableContext } from "../../";
import { DraggableListRow }     from "../../";
import { classNames }           from "../../";
import { ListActions }          from "../../";
import { ButtonVariants }       from "../../";
import { ListRowColors }        from "../../";
import { Tooltip }              from "../../";
import { ButtonSizes }          from "../../";
import { Button }               from "../../";
import { ButtonProps }          from "../../";
import { IconType }             from "../../";
import { useImperativeState }   from "../../";
import { Pagination }           from "../../";
import { BoxComponentProps }    from "../../";
import { Box }                  from "../../";
import { ListItemArray }        from "./ListItemArray/ListItemArray";
import { ListItemChips }        from "./ListItemChips/ListItemChips";
import { ListItemChoice }       from "./ListItemChoice/ListItemChoice";
import { ListItemCurrency }     from "./ListItemCurrency/ListItemCurrency";
import { ListItemDate }         from "./ListItemDate/ListItemDate";
import { ListItemEmail }        from "./ListItemEmail/ListItemEmail";
import { ListItemIcon }         from "./ListItemIcon/ListItemIcon";
import { ListItemImage }        from "./ListItemImage/ListItemImage";
import { ListItemLocalTime }    from "./ListItemLocalTime/ListItemLocalTime";
import { ListItemMultiChoice }  from "./ListItemMultiChoice/ListItemMultiChoice";
import { ListItemMultiText }    from "./ListItemMultiText/ListItemMultiText";
import { ListItemPercent }      from "./ListItemPercent/ListItemPercent";
import { ListItemPhoneNumber }  from "./ListItemPhoneNumber/ListItemPhoneNumber";
import { ListItemSwitch }       from "./ListItemSwitch/ListItemSwitch";
import { ListItemTags }         from "./ListItemTags/ListItemTags";
import { ListItemText }         from "./ListItemText/ListItemText";
import { ListItemTitle }        from "./ListItemTitle/ListItemTitle";
import { ListItemUrl }          from "./ListItemUrl/ListItemUrl";
import { ListRow }              from "./ListRow/ListRow";
import { ListRowClasses }       from "./ListRow/ListRow";

export interface FieldProps {
  name: string;
  label?: string;
  type?: string;
  title?: string;
  alt?: string;
  component: string;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  showTooltip?: boolean;
  properties?: any;
}

function noop() {
}

export interface ActionsProps extends Omit<ButtonProps, "onClick"> {
  tooltip?: string;
  onClick(event: Event, data: any, index?);
}
export interface ListHeader {
  title: string;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  properties?: any;
}

export interface ListProps extends BoxComponentProps {
  fields: FieldProps[]
  objects: any[],
  notifiableItems?: any[],
  matchingKey?: string,
  actions?: ActionsProps[] | ((data: any) => ActionsProps[])
  createAction?: ActionsProps,
  headers?: ListHeader[]
  count?: number,
  total?: number,
  scrollable?: boolean,
  isActionSticky?: boolean,
  currentPage?: number,
  onPage?: (page: number) => void
  onRowClick?: (data: any) => void
  onColumnRender?: (name: string, obj: any, value: any) => any
  getColumnValue?: (row: object, column: FieldProps) => any
  getColumnOptions?: (row: object, column: FieldProps) => FieldProps
  emptyIcon?: IconType
  rowProps?: {
    [ key: string ]: any
  }
  description?: string
  onRowRender?(objects: any)
}

export const List: FC<ListProps> = React.memo(function List(props) {
  const { draggable } = useContext(ListDraggableContext);
  const { className, children, isActionSticky, objects, fields, count, total, onPage, currentPage, onRowClick, getColumnValue, getColumnOptions, emptyIcon, actions, createAction, title, headers, rowProps, description, scrollable, notifiableItems, matchingKey, onRowRender, onColumnRender, ...p } = props;
  const [page, setPage] = useImperativeState(currentPage, onPage);
  const classes = classNames(ListClasses.List, className);
  const ListRowComponent = draggable ? DraggableListRow : ListRow;
  const data = useMemo(() => {
    if (total) {
      return objects;
    }
    return objects.slice(((page - 1) * count), page * count);
  }, [objects, page, count, total]);

  function renderActions(obj, rowIndex) {
    let actionButtons = actions as ActionsProps[];
    if (typeof actions == "function") {
      actionButtons = actions(obj);
    }

    return !!actionButtons.length && <ListActions>
      {
        actionButtons.map(({ tooltip, onClick, ...action }, index) => {
          return <Tooltip title={tooltip} key={index}>
            <Button onlyIcon size={ButtonSizes.Small} {...action} onClick={(e) => onClick(e, obj, rowIndex)}/>
          </Tooltip>;
        })
      }
    </ListActions>;
  }

  return <Box container direction={"column"} className={classes} gap={"XXS"} {...p}>
    {
      (title || createAction) &&
      <Box container justify={!title ? "end" : "space-between"} flexShrink={0} alignItems={"center"}>
        {
          title &&
          <h2 className={ListClasses.ListHeader}>
            {title}
          </h2>
        }
        {
          createAction &&
          <Button alignSelf={"end"} variant={ButtonVariants.Ghost} icon={"add"} {...createAction as any}>Create</Button>
        }
      </Box>
    }
    {description &&
      <Box className={ListClasses.ListDescription}>{description}</Box>
    }
    <Box gap={"XXS"} direction={"column"} container flexGrow={1} style={{ overflowY: "hidden" }}>
      {
        headers &&
        <ListRow
          className={classNames(ListClasses.ListRowHead, {
            [ ListClasses.Scrollable ]: scrollable
          })}
          gap={"XXS"}
          color={ListRowColors.White}
          justify={"space-between"}
          {...rowProps}>
          {
            draggable &&
            <FontIcon type={"drag_indicator"}
                      style={{ fontSize: "var(--typography-icon-size)", opacity: 0 }}/>
          }
          {headers.map((headerItem, index) => {
            return (<ListItemText
              key={index}
              tiny={true}
              text={headerItem.title}
              flexGrow={headerItem.flexGrow}
              flexShrink={headerItem.flexShrink}
              flexBasis={headerItem.flexBasis}
              {...headerItem}
              {...headerItem.properties}
            />);
          })}
        </ListRow>
      }
      <Box className={ListClasses.ListRowBody} gap={"XXS"} direction={"column"} style={{ overflowY: "auto" }} container
           flexGrow={1}>
        {data.map((obj, index) => {
          const rowProps = onRowRender?.(obj);
          return <ListRowComponent key={obj.objectId || index} gap={"XXS"} container justify={"space-between"}
                                   className={classNames({
                                     [ ListClasses.Scrollable ]: scrollable,
                                     [ ListRowClasses.Clickable ]: !!onRowClick,
                                     [ ListRowClasses.ActionSticky ]: isActionSticky
                                   })}
                                   index={index}
                                   onClick={() => onRowClick?.(obj)}
                                   {...rowProps}
                                   {...onRowRender?.(obj)}
          >
            {
              (notifiableItems?.length > 0 && matchingKey && notifiableItems.findIndex(n => n[ matchingKey ] == obj[ matchingKey ]) != -1) &&
              <Badge variant={BadgeVariants.Indicator} color={BadgeColors.Error}
                     className={ListItemClasses.ListItemBadge}/>
            }
            {fields.map((field, index) => {
              const value = getColumnValue(obj, field);
              field = getColumnOptions(obj, field);
              const listColumn = onColumnRender?.(field.name, obj, value);
              if (listColumn) {
                return <React.Fragment key={index}>
                  {listColumn}
                </React.Fragment>;
              }

              const component: string = field[ "component" ];

              switch (component) {
                case "ListItemImage": {
                  let alt;
                  if (field[ "alt" ]) {
                    alt = getColumnValue(obj, { name: field[ "alt" ], component: null, label: "" });
                  }
                  return <ListItemImage {...field}
                                        {...field.properties}
                                        key={index}
                                        flexGrow={field[ "flexGrow" ]}
                                        flexShrink={field[ "flexShrink" ]}
                                        flexBasis={field[ "flexBasis" ]}
                                        icon={value}
                                        text={alt || "No Image"}
                  />;
                }
                case "ListItemTags": {
                  return <ListItemTags {...field}
                                       {...field.properties}
                                       key={index}
                                       tags={value}
                  />;
                }
                case "ListItemArray": {
                  const [fieldName, objKey] = field.name.split(".");
                  const value = obj[ fieldName ].map(item => item[ objKey ]);
                  return <ListItemArray {...field}
                                        {...field.properties}
                                        key={index}
                                        items={value || []}
                  />;
                }
                case "ListItemTitle": {
                  return <ListItemTitle {...field}
                                        {...field.properties}
                                        key={index}
                                        flexGrow={field[ "flexGrow" ]}
                                        flexShrink={field[ "flexShrink" ]}
                                        flexBasis={field[ "flexBasis" ]}
                                        title={value}/>;
                }
                case "ListItemUrl": {
                  return <ListItemUrl {...field}
                                      {...field.properties}
                                      key={index}
                                      label={field[ "label" ]}
                                      flexGrow={field[ "flexGrow" ]}
                                      flexShrink={field[ "flexShrink" ]}
                                      flexBasis={field[ "flexBasis" ]}
                                      url={value}/>;
                }
                case "ListItemText": {
                  return <ListItemText {...field}
                                       {...field.properties}
                                       key={index}
                                       flexGrow={field[ "flexGrow" ]}
                                       flexShrink={field[ "flexShrink" ]}
                                       flexBasis={field[ "flexBasis" ]}
                                       text={value}/>;
                }
                case "ListItemUserStatus": {
                  let properties = { ...field.properties };
                  let text = value;
                  if (obj.deactivated) {
                    properties.style = {
                      ...properties.style,
                      color: "#FF4047"
                    };
                    text = "Deactivated";
                  } else if (obj.status === "out_of_work") {
                    text = "Out of office";
                  }
                  return <ListItemText {...field}
                                       {...properties}
                                       key={index}
                                       flexGrow={field[ "flexGrow" ]}
                                       flexShrink={field[ "flexShrink" ]}
                                       flexBasis={field[ "flexBasis" ]}
                                       text={text}/>;
                }
                case "ListItemChoice": {
                  return <ListItemChoice {...field}
                                         {...field.properties}
                                         key={index}
                                         label={field[ "label" ]}
                                         flexGrow={field[ "flexGrow" ]}
                                         flexBasis={field[ "flexBasis" ]}
                                         flexShrink={field[ "flexShrink" ]}
                                         value={value}/>;//need to discus
                }
                case "ListItemMultiChoice": {
                  return <ListItemMultiChoice {...field}
                                              {...field.properties}
                                              key={index}
                                              visibleCount={1}
                                              label={field[ "label" ]}
                                              flexGrow={field[ "flexGrow" ]}
                                              flexBasis={field[ "flexBasis" ]}
                                              flexShrink={field[ "flexShrink" ]}
                                              items={value}
                                              options={field[ "options" ]}/>;//need to discus
                }
                case "ListItemPhoneNumber": {
                  return <ListItemPhoneNumber {...field}
                                              {...field.properties}
                                              key={index}
                                              label={field[ "label" ]}
                                              flexGrow={field[ "flexGrow" ]}
                                              flexBasis={field[ "flexBasis" ]}
                                              flexShrink={field[ "flexShrink" ]}
                                              number={value}/>;//need to discus
                }
                case "ListItemEmail": {
                  return <ListItemEmail {...field}
                                        {...field.properties}
                                        key={index}
                                        label={field[ "label" ]}
                                        flexGrow={field[ "flexGrow" ]}
                                        flexBasis={field[ "flexBasis" ]}
                                        flexShrink={field[ "flexShrink" ]}
                                        email={value}/>;
                }
                case "ListItemMultiText": {
                  return <ListItemMultiText {...field}
                                            {...field.properties}
                                            key={index}
                                            label={field[ "label" ]}
                                            flexGrow={field[ "flexGrow" ]}
                                            flexBasis={field[ "flexBasis" ]}
                                            flexShrink={field[ "flexShrink" ]}>
                    {value}
                  </ListItemMultiText>;
                }
                case "ListItemSwitch": {
                  return <ListItemSwitch {...field}
                                         {...field.properties}
                                         key={index}
                                         label={field[ "label" ]}
                                         flexGrow={field[ "flexGrow" ]}
                                         flexBasis={field[ "flexBasis" ]}
                                         flexShrink={field[ "flexShrink" ]} value={value}/>;
                }
                case "ListItemIcon": {
                  return <ListItemIcon {...field}
                                       {...field.properties}
                                       key={index}
                                       type={value}
                                       label={field[ "label" ]}
                                       flexGrow={field[ "flexGrow" ]}
                                       flexBasis={field[ "flexBasis" ]}
                                       flexShrink={field[ "flexShrink" ]}
                  />;
                }
                case "ListItemDate": {
                  return <ListItemDate {...field}
                                       {...field.properties}
                                       key={index}
                                       date={value}
                                       label={field[ "label" ]}
                                       flexGrow={field[ "flexGrow" ]}
                                       flexBasis={field[ "flexBasis" ]}
                                       flexShrink={field[ "flexShrink" ]}/>;
                }
                case "ListItemCurrency": {
                  return <ListItemCurrency {...field}
                                           {...field.properties}
                                           key={index}
                                           label={field[ "label" ]}
                                           currency={value}
                                           flexGrow={field[ "flexGrow" ]}
                                           flexShrink={field[ "flexShrink" ]}
                                           flexBasis={field[ "flexBasis" ]}/>;
                }
                case "ListItemPercent": {
                  return <ListItemPercent {...field}
                                          {...field.properties}
                                          key={index}
                                          label={field[ "label" ]}
                                          percent={value}
                                          flexGrow={field[ "flexGrow" ]}
                                          flexShrink={field[ "flexShrink" ]}
                                          flexBasis={field[ "flexBasis" ]}/>;
                }
                case "ListItemChips": {
                  return <ListItemChips {...field}
                                        {...field.properties}
                                        key={index}
                                        value={value}
                                        flexGrow={field[ "flexGrow" ]}
                                        flexShrink={field[ "flexShrink" ]}
                                        flexBasis={field[ "flexBasis" ]}/>;
                }
                case "ListItemLocalTime": {
                  return <ListItemLocalTime {...field}
                                            {...field.properties}
                                            value={value}
                                            key={index}
                                            flexGrow={field[ "flexGrow" ]}
                                            flexShrink={field[ "flexShrink" ]}
                                            flexBasis={field[ "flexBasis" ]}/>;
                }
                case "ListItemStatus": {
                  let status = value.status;
                  let action = value.action;
                  let tooltip = "Last action triggered : ";
                  if (value.updatedAt) {
                    let updatedAt = DateTime.fromISO(value.updatedAt).toFormat("MMM dd, h:mm a");
                    tooltip += `${updatedAt}`;
                  } else {
                    tooltip += "-";
                  }
                  if (action) {
                    tooltip += `\nLast action : ${action}`;
                  }
                  return <ListItemChips {...field}
                                        {...field.properties}
                                        key={index}
                                        label={tooltip}
                                        value={status}
                                        flexGrow={field[ "flexGrow" ]}
                                        flexShrink={field[ "flexShrink" ]}
                                        flexBasis={field[ "flexBasis" ]}/>;

                }
                case "ListItemReminder": {
                  let title = !value ? "No reminder" : `Next reminder: ${DateTime.fromISO(value).toFormat("MMM dd, h:mm a")}`;
                  return <Tooltip title={title} key={index} alignment={Alignment.Top}>
                    <ListItemIcon {...field}
                                  {...field.properties}
                                  type={"access_alarm"}
                                  label={field[ "label" ]}
                                  color={!value ? ListItemIconColors.Secondary : ListItemIconColors.Dark}
                                  flexGrow={field[ "flexGrow" ]}
                                  flexBasis={field[ "flexBasis" ]}
                                  flexShrink={field[ "flexShrink" ]}
                    />
                  </Tooltip>;
                }
                case "ListItemLeadTitle": {
                  let valueSplitted = value?.split(" - ");
                  let name = valueSplitted[ 0 ];

                  return <Box
                    container
                    key={index}
                    flexGrow={field[ "flexGrow" ]}
                    flexShrink={field[ "flexShrink" ]}
                    flexBasis={field[ "flexBasis" ]}
                    style={{ overflow: "hidden" }}
                  >
                    <ListItemImage flexBasis={"32px"} flexShrink={0} flexGrow={0}
                                   text={name || "No Image"}
                                   icon={null}
                    />
                    <ListItemTitle {...field}
                                   {...field.properties}
                                   title={name || "Unknown"}/>
                  </Box>;
                }
                case "ListItemUserTitle": {
                  return <Box
                    container
                    key={index}
                    flexGrow={field[ "flexGrow" ]}
                    flexShrink={field[ "flexShrink" ]}
                    flexBasis={field[ "flexBasis" ]}
                    style={{ overflow: "hidden" }}
                  >
                    <ListItemImage flexBasis={"32px"} flexShrink={0} flexGrow={0}
                                   text={value || "No Image"}
                                   icon={obj.objectIcon}
                    />
                    <ListItemTitle {...field}
                                   {...field.properties}
                                   title={value || "Unknown"}/>
                  </Box>;
                }
              }
            })}
            {
              renderActions(obj, index)
            }
          </ListRowComponent>;
        })}
      </Box>
    </Box>
    {total > count &&
      <Pagination container justify={"start"} flexShrink={0} flexBasis={"55px"} alignItems={"center"}
                  count={Math.ceil((total || objects.length) / count)} defaultPage={page} onChange={setPage}/>
    }
  </Box>;
});

const defaultListProps = {
  count: 15,
  currentPage: 1,
  actions: [],
  onPage: noop,
  getColumnValue: (row, column) => row[ column[ "name" ] ],
  getColumnOptions: (row, column) => column
};
List.defaultProps = defaultListProps;

export enum ListClasses {
  List = "list",
  ListHeader = "list-header",
  ListRowHead = "list-row-head",
  ListRowBody = "list-row-body",
  ListDescription = "list-description",
  ListScrollable = "list--scrollable",
  Scrollable = "list__row--scrollable"
}
