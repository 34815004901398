import React                           from "react";
import { FC }                          from "react";
import { useContext }                  from "react";
import { useCallback }                 from "react";
import { useMemo }                     from "react";
import { useLazyQuery }                from "@apollo/client";
import { useMutation }                 from "@apollo/client";
import { useLocation }                 from "@relcu/react-router";
import { Location }                    from "@relcu/react-router";
import { Form }                        from "@relcu/form";
import { FieldContainer, StepRow }     from "@relcu/ui";
import { StepSection }                 from "@relcu/ui";
import { ButtonVariants }              from "@relcu/ui";
import { Button }                      from "@relcu/ui";
import { ModalFooter }                 from "@relcu/ui";
import { ModalProps }                  from "@relcu/ui";
import { ModalBody }                   from "@relcu/ui";
import { Modal }                       from "@relcu/ui";
import { ChoiceField }                 from "@relcu/ui";
import { SelectVariants }              from "@relcu/ui";
import { defaultMutators }             from "@relcu/ui";
import { StepContainer }               from "@relcu/ui";
import { AlertColors }                 from "@relcu/ui";
import { Alert }                       from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { Transition }                  from "react-transition-group";
import { ClientContext }               from "../../../Client";
import { PhoneMessageRenderInput }     from "../../../types/graphql-global-types";
import { toFirstLower }                from "../../../utils/helpers";
import { getPrimaryBorrower }          from "../../../utils/helpers";
import { getDefaultPhoneNumberForSMS } from "../../../utils/helpers";
import { createPhoneNumbers }          from "../../../utils/helpers";
import { RenderPhoneMessageVariables } from "../../__types__/RenderPhoneMessage";
import { RenderPhoneMessage }          from "../../__types__/RenderPhoneMessage";
import { SendPhoneMessageVariables }   from "../../__types__/SendPhoneMessage";
import { SendPhoneMessage }            from "../../__types__/SendPhoneMessage";
import { useMessageTemplates }         from "../../Layout/View/MessageView/useMessageTemplates";
import { ActionBarClasses }            from "../../Message/ActionBar/ActionBarClasses";
import { useActionBar }                from "../../Message/ActionBar/useActionBar";
import { Messenger }                   from "../../Message/Messanger/Messenger";
import { RENDER_PHONE_MESSAGE_QUERY }  from "../../operations.graphql";
import { SEND_MESSAGE }                from "../../operations.graphql";
import { useViewerPhoneLines }         from "../../useViewerPhoneLines";

const defaultStyle = {
  transition: `opacity ${300}ms ease-in-out`,
  minHeight: 40,
  height: 40,
  paddingTop: 0,
  paddingBottom: 0
};
const transitionStyles = {
  entering: { opacity: .5 },
  entered: { opacity: 1 },
  exiting: { opacity: .5 },
  exited: { opacity: 0 }
};
interface QuickSendSmsModalProps extends Partial<ModalProps> {
  data: any;
}

export const QuickSendSmsModal: FC<QuickSendSmsModalProps> = React.memo(function QuickSendSmsModal(props) {
  const { onClose, data } = props;
  const { tw } = useContext(ClientContext);
  const { state }: Location<Record<string, any>> = useLocation();
  const [send] = useMutation<SendPhoneMessage, SendPhoneMessageVariables>(SEND_MESSAGE);
  const [load] = useLazyQuery<RenderPhoneMessage, RenderPhoneMessageVariables>(RENDER_PHONE_MESSAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only"
  });
  const preferred = useMemo(() => ({ to: state?.to }), [state?.to]);
  const { $viewer } = useSource();
  const templateFilters = useMemo(() => {
    const filters: Record<string, object> = {
      type: { equalTo: "sms" },
      enabled: { equalTo: true },
      availableFor: { equalTo: "manual" },
      AND: [
        { applyTo: { equalTo: toFirstLower(props.data.__typename) } }
      ],
      OR: [
        {
          owner: {
            exists: false
          }
        },
        {
          owner: {
            have: {
              id: {
                equalTo: $viewer.id
              }
            }
          }
        }
      ]
    };
    return filters;
  }, [props.className, $viewer.id]);
  const { templates } = useMessageTemplates(templateFilters);

  const primaryBorrower = data.__typename == "Lead" ? getPrimaryBorrower(data) : data;
  const { fromNumbers: from, defaultPhoneNumber } = useViewerPhoneLines();
  const to = useMemo(() => createPhoneNumbers(primaryBorrower.contact, "smsOptOut"), [primaryBorrower]);
  let receiver = useMemo(() => {
    const result = [];
    if (data.__typename == "Lead") {
      (data.members || []).map((member) => {
        member.contact?.phones?.map((phone) => {
          if (!result.find((n) => n.number == phone.number)) {
            result.push({
              firstName: member.contact.firstName,
              lastName: member.contact.lastName,
              number: phone.number
            });
          }
        });
      });
    } else if (data.__typename == "Contact") {
      (data.phones || []).map((phone) => {
        if (!result.find((n) => n.number == phone.number)) {
          result.push(
            {
              firstName: data.firstName,
              lastName: data.lastName,
              number: phone.number
            });
        }
      });
    }
    return result;
  }, [data]);
  const toNumber = getDefaultPhoneNumberForSMS(to, "Contact").value;

  const handleTemplateChange = useCallback(async (e, form) => {
    if (!e) {
      form.change("content", "");
      form.change("template", "");
    } else {
      const template = templates.find(({ node }) => node.objectId == e);
      const input: PhoneMessageRenderInput = {
        template: template.node.text,
        user: $viewer.objectId,
        scope: props.data.id,
        to: toNumber
      };

      const phoneMessage = await load({
        variables: {
          input: input
        }
      });

      form.change("content", phoneMessage.data.renderPhoneMessage.html);
      form.change("template", e);
    }
  }, [toNumber, templates, receiver, data]);

  const { showConsent, loading, messageTemplates } = useActionBar({
    receiver: receiver,
    to: to,
    templates,
    preferred: preferred
  });

  const handleSubmit = useCallback(async (item) => {
    if (data.__typename == "Lead" && !await tw.checkTimezone(data.objectId)) {
      return;
    }
    await send({
      variables: {
        from: item.from,
        to: item.to,
        message: item.content,
        templateId: item.template || null,
        scopeId: data.id
      }
    });
    onClose();
  }, [data]);

  return (
    <Modal
      onClose={onClose}
      variant={"middle"}
      open={true}
      disableBackdropClick={true}
      closeIcon={true}
      title={`Send sms to ${primaryBorrower.contact.objectName}`}>
      <Form
        keepDirtyOnReinitialize={true}
        mutators={defaultMutators}
        onSubmit={handleSubmit}
        initialValues={{
          attachments: [],
          from: defaultPhoneNumber?.value,
          to: toNumber,
          content: null
        }}
      >
        {({ handleSubmit, invalid, submitting, form, values }) => (
          <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
            <StepSection style={{ background: "white", padding: "16px 16px 0" }}>
              <Transition mountOnEnter={true} unmountOnExit={true} in={!loading && showConsent} timeout={300}>
                {state => {
                  return <Alert style={{
                    ...defaultStyle,
                    ...transitionStyles[ state ]
                  }} className={ActionBarClasses.Hide} stretch={true} variant={AlertColors.PrimaryLight}>
                      <span style={{ lineHeight: "20px" }}>
                        This will be your first SMS to this contact. Make sure to use a template where you follow compliance requirements to avoid being marked as a spam.
                      </span>
                  </Alert>;
                }}
              </Transition>
            </StepSection>
            <ModalBody container direction={"column"} flex={1}
                       style={{ paddingTop: "var(--layout-box-gap-x-s)", paddingBottom: "var(--layout-box-gap-x-s)" }}>
              <StepSection gap={"XXS"}>
                {
                  messageTemplates?.length > 0 &&
                  <StepContainer direction={"row"}>
                    <StepRow style={{ overflow: "hidden" }} flex={1}>
                      <FieldContainer label={"Template:"} alignItems={"center"} space={"5%"}
                                      style={{ overflow: "hidden" }} flex={1}>
                        <ChoiceField
                          flex={1}
                          name="template"
                          height={40}
                          onChange={(e) => {
                            handleTemplateChange(e, form);
                          }}
                          handleClear={(event) => {
                            event.stopPropagation();
                            handleTemplateChange(null, form);
                          }}
                          placeholder={"Select template"}
                          variant={SelectVariants.Ghost}
                          options={messageTemplates}
                        />
                      </FieldContainer>
                    </StepRow>
                  </StepContainer>
                }
                <StepContainer background={"white"}
                               style={{ paddingTop: "var(--layout-box-gap-x-x-s)", paddingBottom: "var(--layout-box-gap-x-x-s)" }}>
                  <StepRow>
                    <FieldContainer label={"From:"} alignItems={"center"} space={"5%"} flex={1}>
                      <ChoiceField
                        flex={1}
                        width={250}
                        height={40}
                        mode="button"
                        variant={SelectVariants.Ghost}
                        options={from}
                        message={""}
                        name={"from"}
                      />
                    </FieldContainer>
                  </StepRow>
                  <StepRow>
                    <FieldContainer label={"To:"} alignItems={"center"} space={"5%"} flex={1}>
                      <ChoiceField
                        style={{ flexBasis: "10%" }}
                        flex={1}
                        width={250}
                        mode="button"
                        value={values.to}
                        variant={SelectVariants.Ghost}
                        options={to}
                        message={""}
                        name={"to"}
                      />
                    </FieldContainer>
                  </StepRow>
                </StepContainer>
                <StepContainer background={"white"}
                               style={{ paddingTop: "var(--layout-box-gap-x-x-s)", paddingBottom: "var(--layout-box-gap-x-x-s)" }}>
                  <StepRow>
                    <Messenger
                      flex={1}
                      showUpload={false}
                      required={true}
                      name={"content"}
                      canUpdate
                    >
                    </Messenger>
                  </StepRow>
                </StepContainer>
              </StepSection>
            </ModalBody>
            <ModalFooter>
              <Button variant={ButtonVariants.Ghost} onClick={onClose}>CANCEL</Button>
              <Button disabled={!values.content?.trim()} type={"submit"}>SEND</Button>
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
});
