import { useNavigate }       from "@relcu/react-router";
import React                 from "react";
import { DateTime }          from "@relcu/date";
import { AvatarSizes }       from "../Avatar";
import { Avatar }            from "../Avatar";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";
import { classNames }        from "../..";
import { LinkableText }      from "../..";
import { NoteItemClasses }   from "./NoteItemClasses";

export interface NoteItemProps extends BoxComponentProps {
  text: string
  date: string
  owner: {
    objectName: string
    objectIcon: string
    objectId: string
  }
}

export const NoteItem = React.forwardRef((props: NoteItemProps, ref: React.Ref<HTMLDivElement>) => {
  const { owner, text, date, className, ...p } = props;
  const ownerName = owner?.objectName ?? "Relcu"
  const classes = classNames(NoteItemClasses.NoteItem, className);
  const navigate = useNavigate();

  return <Box container gap={"XXS"} className={classes} ref={ref} {...p}>
    <Avatar text={ownerName} icon={owner?.objectIcon} alignSelf={"start"} size={AvatarSizes.Medium}
            onClick={() => owner && navigate(`/user/${owner.objectId}`)}/>
    <Box container direction={"column"} gap={"XXXS"} className={NoteItemClasses.NoteItemContent} flex={1}>
      <Box container justify={"space-between"}>
        {ownerName}
        <span>{DateTime.fromISO(date).toFormat("MMM dd, h:mm a")}</span>
      </Box>
      <Box className={NoteItemClasses.NoteItemText}>
        <LinkableText text={text} />
      </Box>
    </Box>
  </Box>;
});

