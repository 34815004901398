import React                     from "react";
import { FC }                    from "react";
import { classNames }            from "../../../utils/classNames";
import { BoxComponentProps }     from "../../Box";
import { BoxItemComponentProps } from "../../Box";
import { Box }                   from "../../Box";
import { TypographyColor }       from "../../Typography";
import { Typography }            from "../../Typography";
import { BaseInputProps }        from "../BaseInput";
import { useSwitch }             from "./useSwitch";

export enum ToggleSwitchColors {
  Primary = "primary",
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
}

export interface ToggleSwitchProps extends BoxItemComponentProps,BoxComponentProps, BaseInputProps  {
  color?: ToggleSwitchColors,
  onClick?(event)
  checked: boolean,
  onChange?(e?)
  onBlur?(e?)
  onFocus?(e?)
}

const defaultToggleSwitchProps: ToggleSwitchProps = {
  color: ToggleSwitchColors.Primary,
  disabled: false,
  onClick: null,
  checked:false
};

export const ToggleSwitch: FC<ToggleSwitchProps> = React.memo(function ToggleSwitch(props) {
  const { isChecked, name, disabled, label, color, opts } = useSwitch(props);
  const classes = classNames(ToggleSwitchClasses.ToggleSwitch, {
    [ ToggleSwitchClasses.Disabled ]: disabled,
    [ ToggleSwitchClasses.Revers ]: !isChecked,
    [ ToggleSwitchClasses.PrimaryColor ]: (color == ToggleSwitchColors.Primary),
  });

  return <Box container alignItems={"center"}>
    <label style={{ display: "inline-flex" }}>
      <input type="checkbox" name={name} checked={isChecked}
             disabled={disabled} {...opts}/>
      <Box className={classes}>
        <Box className={ToggleSwitchClasses.ToggleCircle}/>
      </Box>
    </label>
    {!!label && <Typography className={ToggleSwitchClasses.Label} color={disabled && TypographyColor.Secondary}> {label}</Typography>}
  </Box>;

});

ToggleSwitch.defaultProps = defaultToggleSwitchProps;

export enum ToggleSwitchClasses {
  ToggleSwitch = "toggle-switch",
  Revers = "toggle-switch--revers",
  ToggleCircle = "toggle-switch__circle",
  Disabled = "toggle-switch--disabled",
  Label = "toggle-switch--label",
  PrimaryColor = "toggle-switch-primary-color"
}
