import { useReactiveVar }       from "@apollo/client";
import { gql }                  from "@apollo/client";
import { useQuery }             from "@apollo/client";
import { Form }                 from "@relcu/final-form";
import { TypographyWeight }     from "@relcu/ui";
import { TypographyVariant }    from "@relcu/ui";
import { TypographyLine }       from "@relcu/ui";
import { TypographyColor }      from "@relcu/ui";
import { FontIcon }             from "@relcu/ui";
import { TextField }            from "@relcu/ui";
import { ConfirmDialogClasses } from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { Typography }           from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { ModalBody }            from "@relcu/ui";
import { Modal }                from "@relcu/ui";
import { useContext }           from "react";
import { useEffect }            from "react";
import { useCallback }          from "react";
import { useState }             from "react";
import React                    from "react";
import { ClientContext }        from "../../../Client";
import { reAuthVar }            from "../../../reactiveVars";
import { expiresVar }           from "../../../reactiveVars";

const VIEWER_USERNAME = gql`
  query ViewerUsername {
    viewer {
      user {
        username
      }
    }
  }
`;

export const ReauthorizeModal = React.memo(function EmptyDialog(props) {
  const [open, setOpen] = useState(false);
  const expires = useReactiveVar(expiresVar);
  const reAuth = useReactiveVar(reAuthVar);
  const context = useContext(ClientContext);
  const { data: { viewer: { user } = { user: {} } } } = useQuery(VIEWER_USERNAME, { fetchPolicy: "cache-only" });
  const validate = useCallback((values) => {
    const errors: any = {};
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  }, []);
  const onLogin = useCallback(async ({ password }) => {
    try {
      const response = await context.attempt(`Basic ${btoa(`${user.username}:${password}`)}`);
      if (!response.ok) {
        const result = await response.json();
        return { password: result.error };
      }
      const result = await response.json();
      expiresVar(result.expires);
      reAuthVar(false);
      setOpen(false);
    } catch (e) {
      console.error(e);
      return { password: "Ops something went wrong." };
    }
  }, [user]);
  useEffect(() => {
    if (reAuth){
      setOpen(true);
    }
    if (expires) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, expires * 1000 - Date.now());
      return () => {
        clearTimeout(timer);
      };
    }
  }, [expires,reAuth]);
  return <Modal
    disableEscapeKeyDown={true}
    title={"Re-authentication required"}
    open={open}
    closeIcon={false}
    disableBackdropClick={true}
    onClose={() => setOpen(false)}
    variant={"small"}
  >
    <ModalBody container direction={"column"} flex={"1 1 auto"} justify={"center"} gap={"S"}
               className={ConfirmDialogClasses.Container}>
      <Form
        subscription={{ submitting: true }}
        validate={validate}
        onSubmit={onLogin}
      >
        {({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
            <Box container direction={"column"} gap={"S"} alignItems={"center"}>
              <Typography color={TypographyColor.Secondary} lineHeight={TypographyLine.LineOverline}
                          style={{ textAlign: "center" }}>
                Please retype your password for <Typography variant={TypographyVariant.span}
                                                            weight={TypographyWeight.Bold}>{user.username}</Typography> to
                continue working in Relcu, otherwise you will be logged out.
              </Typography>
              <Box container direction={"column"} gap={"XS"}>
                <TextField
                  style={{ margin: "0 29.5px" }}
                  name="password"
                  label="Password"
                  type={"password"}
                  icon={<FontIcon type={"https"} alignSelf={"center"}/>}
                  required
                  placeholder={"Enter your password"}
                />
                <Typography color={TypographyColor.Secondary} lineHeight={TypographyLine.LineOverline}
                            style={{ textAlign: "center" }}>
                  If you do not remember your password, please reach out to admin.
                </Typography>
              </Box>
            </Box>
            <Box container justify={"end"}>
              <Button type={"submit"} disabled={submitting}>AUTHENTICATE</Button>
            </Box>
          </form>
        )}
      </Form>
    </ModalBody>
  </Modal>;
});
