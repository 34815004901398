import React           from "react";
import { FC }          from "react";
import { useForm }     from "@relcu/form";
import { OnChange }    from "@relcu/form";
import { useNavigate } from "@relcu/react-router";
import { useLocation } from "@relcu/react-router";

export const FieldDialogNavigator: FC<any> = React.memo(function FieldDialogNavigator(props) {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const form = useForm();
  const handleTypeChange = (value) => {
    form.reset();
    if (value == "Choice") {
      form.change("options", [{}]);
    }
    if (["Object", "Pointer", "Array"].includes(value)) {
      form.change("targetClass", []);
    }

    if (value == "Status") {
      form.change("defaultValue", {});
      form.change("value", {
        states: {},
        actions: {},
        transitions: {}
      });
    }

    queueMicrotask(() => {
      navigate(pathname, { state: { ...state, step: value ? "DetailsStep" : "TypeSelectionStep" } });
    });
  };

  return (
    <OnChange name="type" children={handleTypeChange}/>
  );
});
