import React                               from "react";
import { FC }                              from "react";
import { useMemo }                         from "react";
import { useState }                        from "react";
import { Field }                           from "@relcu/form";
import { Form }                            from "@relcu/rc";
import { defaultMutators }                 from "@relcu/ui";
import { format }                          from "@relcu/ui";
import { AlertColors }                     from "@relcu/ui";
import { Ellipsis, FontIcon, ModalFooter } from "@relcu/ui";
import { ModalBody }                       from "@relcu/ui";
import { ListItemText }                    from "@relcu/ui";
import { BackDropLoader }                  from "@relcu/ui";
import { ButtonVariants }                  from "@relcu/ui";
import { ModalProps }                      from "@relcu/ui";
import { Button }                          from "@relcu/ui";
import { ListItemTitle }                   from "@relcu/ui";
import { ListRow }                         from "@relcu/ui";
import { Radio }                           from "@relcu/ui";
import { Alert }                           from "@relcu/ui";
import { TabItem }                         from "@relcu/ui";
import { Tabs }                            from "@relcu/ui";
import { TabPanel }                        from "@relcu/ui";
import { TabPanelContainer }               from "@relcu/ui";
import { TabContainer }                    from "@relcu/ui";
import { EmptyList }                       from "@relcu/ui";
import { Modal }                           from "@relcu/ui";
import { CommonClasses }                   from "@relcu/ui";
import { Box }                             from "@relcu/ui";
import { GetPmi }                          from "../../../Offer/__types__/GetPmi";
import { PmiDialogClasses }                from "./PmiDialogClasses";
import "./pmi-dialog.css";

export const productTypesMap = {
  monthly: "MONTHLY",
  single_lp: "LENDER SINGLE",
  single_nr: "SINGLE NON REFUNDABLE",
  single_r: "SINGLE",
  split_bd: "SPLIT PREMIUM"
};

export interface PmiPartnerDialogProps extends Partial<ModalProps> {
  reFetch: () => void;
  pmiRates: GetPmi["getPmiRatesV2"];
  loading?: boolean;
  onApply: (pmi: Record<string, any>) => void;
  selectedPmi: any;
  selectedType: string;
  selectedId: string;
}
export const PmiDialog: FC<PmiPartnerDialogProps> = React.memo(function PmiDialog(props) {
    const { loading, onClose, onApply, selectedId, pmiRates, reFetch, open, selectedPmi, selectedType } = props;
    const pmiError = pmiRates?.errorMessage;
    const { __typename, ...rates } = pmiRates?.payload;
    const options = useMemo(() => Object.keys(rates || {}), [rates]);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(selectedPmi ? options.indexOf(selectedType) : 0);

    return <Modal
      title={"PMI"}
      onClose={onClose}
      open={open}
      closeIcon={true}
      disableBackdropClick={false}
      className={PmiDialogClasses.Modal}
    >
      <ModalBody direction={"column"} gap={"S"} className={PmiDialogClasses.Container}>
        {pmiError &&
          <Box container gap={"XXXS"} direction={"row"} flex={"1 0 auto"} alignItems={"center"} justify={"end"}>

            <Alert variant={AlertColors.Error}
                   children={`${pmiError}`}
                   width={"100%"}/>
            <Button style={{ pointerEvents: loading ? "none" : "auto" }}
                    disabled={loading} variant={ButtonVariants.Ghost}
                    onClick={reFetch}>
              Reload
            </Button>
          </Box>
        }
        {
          options.length > 0 ?
            <Form
              initialValues={{ id: selectedId, type: selectedType }}
              onSubmit={(values) => onApply(values)}
              mutators={{
                ...defaultMutators
              }}>
              <TabContainer gap={"S"} outerState={[selectedOptionIndex, setSelectedOptionIndex]}>
                <TabPanelContainer>
                  {
                    options.map((option, key) => {
                      return <TabPanel style={{ overflowY: "hidden" }} key={`tab-panel-${key}`}
                                       active={selectedOptionIndex == key}>
                        <Box container direction={"column"} gap={"XXS"} flex={1}>
                          {

                            rates[ option ].map((rate, k) => {
                              return <Field
                                key={`tab-panel-item-${k}`}
                                name="type">
                                {({ input: pmiTypeInput }) => {
                                  return (
                                    <Field
                                      type={"radio"}
                                      value={rate?.providerId}
                                      key={`tab-panel-item-${k}`}
                                      name="id">
                                      {
                                        ({ input, meta }) => {
                                          return (
                                            <Radio
                                              disabled={rate.status !== "Success"}
                                              {...input}
                                              checked={pmiTypeInput.value == option && input.checked}
                                              onChange={(e) => {
                                                pmiTypeInput.onChange(option);
                                                input.onChange(e);
                                              }}
                                              label={<ListRow className={PmiDialogClasses.PmiPartner} flex={1} gap={"XS"}>
                                                <ListItemTitle title={rate.providerName} flexBasis={"20%"}/>
                                                {
                                                  rate.status !== "Success" ?
                                                    <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"}
                                                         justify={"end"}>
                                                      <ListItemText text={<Ellipsis
                                                        style={{ pointerEvents: "auto", color: "var(--color-error)" }}
                                                        from={90}>{rate.status}</Ellipsis>} tiny/>
                                                      <Button style={{ pointerEvents: loading ? "none" : "auto" }}
                                                              disabled={loading} variant={ButtonVariants.Ghost}
                                                              onClick={() => reFetch()}>
                                                        Reload
                                                      </Button>
                                                    </Box>
                                                    :
                                                    <>
                                                      <Box container gap={"XXXS"} flexBasis={"30%"} flexShrink={0}
                                                           alignItems={"center"}>
                                                        <ListItemText text={"Rate:"} tiny/>
                                                        <ListItemText
                                                          text={`${format(rate.rate1, 3)}% / $${format(rate.premium1, 2)}`}/>
                                                      </Box>
                                                      <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"}>
                                                        <ListItemText text={"Ref:"} tiny/>
                                                        <ListItemText text={rate.quoteNumber}/>
                                                      </Box>
                                                      <Box container gap={"XXXS"} alignItems={"center"}>
                                                        <ListItemText text={"Full quote:"} alignItems={"center"} tiny/>
                                                        <a href={rate.pdfUrl} style={{ textDecoration: "none" }}
                                                           target="_blank">
                                                          <FontIcon className={CommonClasses.ClickableIcon}
                                                                    type={"rc_pdf_doc"}/>
                                                        </a>
                                                      </Box>
                                                    </>
                                                }
                                              </ListRow>
                                              }/>
                                          );
                                        }
                                      }
                                    </Field>
                                  );
                                }}
                              </Field>;
                            })
                          }
                        </Box>
                      </TabPanel>;
                    })
                  }
                </TabPanelContainer>
                <Tabs>
                  {
                    options.map((option, key) => (
                      <TabItem active={selectedOptionIndex == key} onClick={() => setSelectedOptionIndex(key)}
                               key={key}>{productTypesMap[ option ]}</TabItem>))
                  }
                </Tabs>
              </TabContainer>
              <ModalFooter>
                <Button variant={ButtonVariants.Outline} onClick={() => onClose()}>CANCEL</Button>
                <Button variant={ButtonVariants.Fill} type="submit">APPLY</Button>
              </ModalFooter>
            </Form>
            :
            !pmiError && <Box flex={"1 1 auto"} justify={"center"}>
              <EmptyList style={{ padding: 20 }} icon={"pie_chart"} content={"Please update lead data and try again."}
                         title={"No matching rates"}
                         alignSelf={"center"}/>
            </Box>
        }
      </ModalBody>
      {
        loading && <BackDropLoader/>
      }
    </Modal>;
  }
);

export default PmiDialog;
