import React         from "react";
import { FC }        from "react";
import { FormField } from "@relcu/rc";
import { isEmail }   from "@relcu/ui";

export const EmailConfig: FC<any> = React.memo(function EmailConfig(props) {
  return (
    <FormField
      validate={(value) => {
        if (value && !isEmail(value)) {
          return `Email is invalid`;
        }
      }}
      name="defaultValue"
      label="Default value"
    />
  );
});
