import { DateTime }            from "@relcu/date";
import { FC }                  from "react";
import { ReactElement }        from "react";
import React                   from "react";
import { useImperativeState }  from "../../hooks/useImperativeState";
import { CommonClasses }       from "../../theme/classes";
import { classNames }          from "../../utils/classNames";
import { BoxComponentProps }   from "../Box";
import { Box }                 from "../Box";
import { FontIcon }            from "../Icon";
import { TypographyColor }     from "../Typography";
import { Typography }          from "../Typography";
import { TimelineItemClasses } from "./TimelineItemClasses";

export interface TimelineItemProps extends BoxComponentProps {
  primary: ReactElement;
  secondary?: ReactElement;
  expand?: boolean;
  onExpand?(open, e);
  creationDate: string;
  avatar: ReactElement;
}

export const TimelineItem: FC<TimelineItemProps> = React.memo(({ children, onExpand, expand, secondary, creationDate, primary, avatar }) => {
  const [collapsed, setIsCollapsed] = useImperativeState(expand, onExpand);
  const date = DateTime.fromISO(creationDate).toFormat("h:mm a");
  return <Box className={TimelineItemClasses.TimelineItem} onClick={(e) => {
    children && setIsCollapsed(!collapsed, e);
  }}>
    <Box container gap={"XXS"} justify={"space-between"} alignItems={"center"}
         className={classNames(TimelineItemClasses.TimelineItemHeader)}>
      {avatar}
      <Box container gap={"XS"} alignItems={"center"} flexGrow={1} className={TimelineItemClasses.TimelineItemOverflow}>
        {primary}
        {!collapsed && secondary}
      </Box>
      <Box container gap={"XXS"} alignItems={"center"} style={{ paddingRight: children ? 0 : 32 }}>
        <Typography noWrap color={TypographyColor.Secondary}>{date.toLowerCase()}</Typography>
        {
          children ? <FontIcon style={{ fontSize: 20 }} type={collapsed ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                               className={CommonClasses.ClickableIcon}/> : null
        }
      </Box>
    </Box>
    {collapsed &&
      <Box container direction={"column"} gap={"XS"} className={TimelineItemClasses.TimelineItemBodyContainer}>
        {children}
      </Box>}
  </Box>;
});

TimelineItem.defaultProps = {
  expand: false
};


