import React              from "react";
import { FC }             from "react";
import { useField }       from "@relcu/final-form";
import { useFieldArray }  from "@relcu/final-form";
import { List }           from "@relcu/ui";
import { useSchemaField } from "../../useSchemaField";

export const CallableHoursReadSection: FC<any> = React.memo(function CallableHoursReadSection(props) {
  const { fields: jsonFields } = props;
  const basis = `${100 / jsonFields.length + 1}%`;
  const { fields: { value = [] } } = useFieldArray("callableStatesHours");
  const { input: { value: callableEndHour } } = useField("callableEndHour");
  const { input: { value: callableStartHour } } = useField("callableStartHour");
  const { input: { value: callableSaturdayStartHour } } = useField("callableSaturdayStartHour");
  const { input: { value: callableSaturdayEndHour } } = useField("callableSaturdayEndHour");
  const { input: { value: callableSundayStartHour } } = useField("callableSundayStartHour");
  const { input: { value: callableSundayEndHour } } = useField("callableSundayEndHour");
  const { options } = useSchemaField("StateBasedFee", "state");
  const headers = [
    {
      title: "States",
      flexBasis: basis
    }
  ];
  const fields: any[] = [
    {
      component: "ListItemMultiChoice",
      name: "states",
      options: [{ label: "All", value: "all" }, ...options],
      flexBasis: basis
    }
  ];
  jsonFields.filter(jField => jField.name != "callableStatesHours").forEach(({ label, defaultValue, ...rest }) => {
    headers.push({ ...rest, title: label, flexBasis: basis });
    fields.push({ ...rest, flexBasis: basis, component: "ListItemText" });
  });

  return (
    <List
      flex={1}
      fields={fields}
      objects={
        [
          {
            callableEndHour,
            callableStartHour,
            callableSaturdayStartHour,
            callableSaturdayEndHour,
            callableSundayStartHour,
            callableSundayEndHour,
            states: ["all"]
          },
          ...value.map(val => {
            return {
              ...val,
              states: options.filter(opt => val.states.includes(opt.value))
            };
          })
        ]
      }
      headers={headers}
    />
  );
});
