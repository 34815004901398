import React                     from "react";
import { FC }                    from "react";
import { useState }              from "react";
import { gql }                   from "@apollo/client";
import { useMutation }           from "@apollo/client";
import { useForm }               from "@relcu/final-form";
import { FormSpy }               from "@relcu/final-form";
import { Stack }                 from "@relcu/rc";
import { Icon }                  from "@relcu/rc";
import { Button }                from "@relcu/rc";
import { Form }                  from "@relcu/rc";
import { Typography }            from "@relcu/rc";
import { Popover }               from "@relcu/rc";
import { Whisper }               from "@relcu/rc";
import { useAlert }              from "@relcu/ui";
import { htmlToText }            from "../../../../../utils/helpers";
import { SendTestMailVariables } from "./__types__/SendTestMail";
import { SendTestMail }          from "./__types__/SendTestMail";

export const SendTestMailButton: FC<any> = React.memo(function SendTestMailButton() {
  const form = useForm();
  const [email, setEmail] = useState();
  const { success, error } = useAlert();
  const [send] = useMutation<SendTestMail, SendTestMailVariables>(SEND_TEST_MAIL);
  const handleSendTestMail = async () => {
    const { subject, content, scope, from, loanProposal } = form.getState().values;
    try {
      await send({
        variables: {
          input: {
            scope,
            subject: subject,
            to: email,
            from: from,
            loanProposalId: loanProposal,
            text: content
          }
        }
      });
      success("Email sent.");
    } catch (e) {
      error(e.message);
    }
  };

  return (
    <Stack spacing={12} className={"mail-template-preview-data"}>
      <Whisper
        trigger="click"
        placement="bottomEnd"
        onClose={() => setEmail(null)}
        speaker={(props, ref) => {
          const { className, left, top } = props;
          return <Popover ref={ref} className={className} style={{ left, top }} arrow={false} full>
            <div className={"mail-template-send-test-mail-popup"}>
              <Typography weights={"medium"} variant={"base16"}>Send test email</Typography>
              <Form.Control name="username" placeholder="Recipient" onChange={(value) => setEmail(value)}
                            size={"lg"}/>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  return <Button
                    disabled={!email || !htmlToText(values.content)?.trim() || !values.subject?.trim() || !values.from || !values.scope}
                    onClick={handleSendTestMail} appearance={"text"}
                    startIcon={<Icon type="email"/>}>
                    SEND TEST EMAIL
                  </Button>;
                }}
              </FormSpy>
            </div>
          </Popover>;
        }}>
        <Button appearance={"text"} endIcon={<Icon type="keyboard_arrow_down"/>} size={"xs"}>
          SEND TEST EMAIL
        </Button>
      </Whisper>
    </Stack>
  );
});

export const SEND_TEST_MAIL = gql`
  mutation SendTestMail($input:MailSendInput){
    sendTestEmail(input:$input){
      id
    }
  }
`;
