import { Field }    from "@relcu/final-form";
import { Checkbox } from "@relcu/rc";
import React        from "react";
import { FC }       from "react";

export const DateConfig: FC<any> = React.memo(function BooleanConfig(props) {
  return (
    <Field
      name="isTime"
      defaultValue={true}
      type="checkbox">
      {({ input }) => {
        return (
          <Checkbox
            style={{ marginTop: 24 }}
            checked={input.checked}
            onChange={(value, checked, event) => input.onChange(event)}>
            Time included
          </Checkbox>
        );
      }}
    </Field>
  )
});
