import React, { useCallback, useState } from "react";
import { FC }                           from "react";
import { useMemo }                      from "react";
import { DateTime }                     from "@relcu/date";
import { useNavigate }                  from "@relcu/react-router";
import { Checkbox }                     from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { ButtonVariants }               from "@relcu/ui";
import { IconDirection }                from "@relcu/ui";
import { Button }                       from "@relcu/ui";
import { useBoxProps }                  from "@relcu/ui";
import { CommonClasses }                from "@relcu/ui";
import { LinkableText }                 from "@relcu/ui";
import { stringToHslColor }             from "@relcu/ui";
import { FontIcon }                     from "@relcu/ui";
import { AvatarSizes }                  from "@relcu/ui";
import { Avatar }                       from "@relcu/ui";
import { BoxComponentProps }            from "@relcu/ui";
import { classNames }                   from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import { getObjectPath }                from "../../../utils/layoutUtils";
import { PhoneMessageConversation }     from "../../Layout/View/ConversationView/__types__/PhoneMessageConversation";
import { ScopeConnection }              from "../../ScopeConnection";
import "./msg.css";
import { Attachment }                   from "./MsgAttachments/MsgAttachment";
import { MsgAttachment }                from "./MsgAttachments/MsgAttachment";
import { MsgClasses }                   from "./MsgClasses";

export const retryableErrorCodes = {
  "11751": {
    resend: false,
    message: "Content size exceeds carrier limit."
  },
  "30111": {
    resend: false,
    message: "Url is blacklisted."
  },
  "30118": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30006": {
    resend: false,
    message: "Landline or unreachable carrier."
  },
  "30117": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30107": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30019": {
    resend: false,
    message: "Content size exceeds carrier limit."
  },
  "30119": {
    resend: true,
    message: "Content size exceeds carrier limit."
  },
  "30108": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30022": {
    resend: true,
    message: "Your messages to this carrier has exceeded the maximum allowable limits for your phone number and/or campaign."
  },
  "30100": {
    resend: true,
    message: "An error has occurred, please contact the admin"
  },
  "30002": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30116": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30007": {
    resend: false,
    message: "The content of your message is against carrier guidelines."
  },
  "30008": {
    resend: true,
    message: "An unknown error has occurred from carrier."
  },
  "30003": {
    resend: false,
    message: "Landline or unreachable carrier."
  },
  "30010": {
    resend: false,
    message: "Message price exceeds max price."
  },
  "30011": {
    resend: false,
    message: "MMS not supported by the receiving phone number in this region."
  },
  "30023": {
    resend: true,
    message: "Your messages to this carrier has exceeded the maximum allowable messages for the day."
  },
  "30017": {
    resend: true,
    message: "Message request was rejected because the carrier has high traffic."
  },
  "30102": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "30005": {
    resend: false,
    message: "The destination number you are trying to reach is unknown and may no longer exist."
  },
  "30110": {
    resend: false,
    message: "Domain is blocked."
  },
  "30004": {
    resend: false,
    message: "The destination number you are trying to reach is blocked from receiving this message."
  },
  "21606": {
    resend: false,
    message: "The number you want to send an SMS with is not valid."
  },
  "21408": {
    resend: true,
    message: "Please indicate the number you are messaging to."
  },
  "21614": {
    resend: false,
    message: "The number you want to send an SMS with is not valid."
  },
  "21703": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "21605": {
    resend: false,
    message: "Your message should have maximum 480 characters."
  },
  "21619": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "21610": {
    resend: true,
    message: "The recipient has unsubscribed from your messages."
  },
  "63036": {
    resend: true,
    message: "The specified phone number cannot be reached."
  },
  "63031": {
    resend: false,
    message: "You cannot send a message to your number."
  },
  "63038": {
    resend: true,
    message: "Your account exceeded the daily messages limit."
  },
  "63025": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "63034": {
    resend: false,
    message: "Media exceeds size limit."
  },
  "missing_replacement_params": {
    resend: false,
    message: "Missing replacement parameters."
  },
  "default": {
    resend: true,
    message: "An error has occurred, please contact Relcu support."
  },
  "nan": {
    resend: true,
    message: "No phone number"
  },
  "duplicate": {
    resend: false,
    message: "Duplicate lead"
  },
  "not_assigned": {
    resend: false,
    message: "The lead is not assigned to any user"
  },
  "deactivated_user": {
    resend: false,
    message: "Lead's assignee is deactivated"
  },
  "not_assigned_phone_number": {
    resend: false,
    message: "The assigned user does not have assigned phone number."
  }
};
const messageStatuses = {
  accepted: "Sending...",
  queued: "Sending...",
  sending: "Sending...",
  sent: "Sent",
  delivered: "Delivered",
  undelivered: "Failed",
  failed: "Failed"
};

export interface MessageProps extends Omit<BoxComponentProps, "direction"> {
  objectId: string,
  viewerId: string,
  scope?,
  checkboxChecked?: boolean
  showCheckbox?: boolean
  objectIcon: string,
  objectName: string,
  direction: "incoming" | "outgoing" | string,
  status: "failed" | "received" | "queued" | "delivered",
  content: string,
  from: string,
  to: string,
  errorCode?: string,
  createdAt: string,
  participants?: any
  bulkConversation: PhoneMessageConversation
  attachments: Attachment[]
  onConnect(id)
  onResend()
  onRemove()
  onCheck()
}

export const Msg = React.forwardRef(function Msg(props: MessageProps, ref: React.Ref<HTMLDivElement>) {
  const {
    className,
    children,
    onChange,
    objectName,
    content,
    objectIcon,
    from,
    to,
    createdAt,
    objectId,
    direction,
    onConnect,
    onRemove,
    scope,
    status,
    onResend,
    onCheck,
    id,
    viewerId,
    attachments,
    errorCode,
    participants,
    bulkConversation,
    checkboxChecked,
    showCheckbox,
    ...p
  } = props;
  const navigate = useNavigate();
  const { $viewer } = useSource();
  const reverse = useMemo(() => direction === "outgoing", [direction]);
  const backgroundColor = useMemo(() => stringToHslColor(objectName, 70, 60, .1), [objectName]);
  const [open, setOpen] = useState(false);
  const bulkOwner = useMemo(() => {
    const bulkSender = bulkConversation?.participants.find(p => p.type === "sender").party;
    return bulkSender && bulkSender.objectId === $viewer.objectId;
  }, [bulkConversation, $viewer.objectId]);
  const behalfOf = participants.find(p => p.party?.objectId == $viewer.objectId) && !bulkConversation?.participants.find(p => p.party?.objectId == $viewer.objectId);
  const navigateTo = () => {
    const { party } = participants.find(p => p.type == "sender");
    navigate(getObjectPath(party));
  };
  const classes = classNames({
    [ MsgClasses.MsgOpen ]: open
  }, className);
  return <Box container ref={ref} className={classes} gap={"XXS"}
              direction={!reverse ? "row" : "row-reverse"} {...useBoxProps(p)}>
    <Avatar className={MsgClasses.MsgAvatar} size={AvatarSizes.Small} alignSelf={"start"} text={objectName}
            icon={objectIcon} onClick={navigateTo}/>
    <Box
      direction={"column"}
      className={classNames(MsgClasses.MsgContent, {
        [ MsgClasses.MsgContentError ]: !!errorCode || status == "failed"
      })}
    >
      <Box container className={classNames(MsgClasses.MsgText, { [ MsgClasses.MsgReverse ]: reverse })}
           style={{ background: backgroundColor }}>
        <Box container gap={"XXS"} direction={"column"} flex={1}>
          <Box container justify={"space-between"} direction={reverse ? "row-reverse" : "row"}>
            <Box className={MsgClasses.MsgHeader} data-self={id === viewerId ? " (You)" : ""}>
              {objectName}
            </Box>
            <Box container wrap={"nowrap"} gap={"XXS"} flex={1} justify={"end"}
                 direction={reverse ? "row-reverse" : "row"} alignItems={"center"}>
              <Box container alignItems={"start"} wrap={"nowrap"} className={MsgClasses.MsgDate} direction={"column"}>
                <p>{messageStatuses[ status ]}</p>
                <p>{DateTime.fromISO(createdAt).toFormat("MMM dd, h:mm a")}</p>
              </Box>
              {!!onCheck &&
                <Checkbox
                  className={classNames(MsgClasses.MsgCheckBox, { [ MsgClasses.MsgCheckBoxShow ]: showCheckbox })}
                  alignSelf={"start"}
                  leftMessageSpace={false}
                  InputProps={{ style: { padding: 0, marginTop: !reverse ? "-10px" : 0 } }}
                  checked={checkboxChecked}
                  onChange={onCheck}
                />}
            </Box>
          </Box>
          <Box container direction={reverse ? "row-reverse" : "row"} alignItems={"end"} justify={"space-between"}
               style={{ paddingBottom: 16 }}>
            <LinkableText text={content}/>
          </Box>
          {
            !!attachments?.length &&
            <Box container direction={"column"} gap={"XXS"} alignSelf={reverse ? "end" : "start"}>
              {
                attachments.map((attachment, index) => {
                  return <MsgAttachment key={index} incoming attachment={attachment}/>;
                })
              }
            </Box>

          }
          <FontIcon type={open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                    className={classNames(MsgClasses.MsgToggle, CommonClasses.GrayIcon)}
                    onClick={useCallback(() => setOpen(!open), [open, setOpen])}/>
          {!!bulkConversation && <Button
            alignSelf={"end"}
            iconDirection={IconDirection.Right}
            icon={"rc_bulk_sms"}
            variant={ButtonVariants.Ghost}
            disabled={!(bulkOwner || behalfOf)}
            onClick={() => navigate(`/user/${$viewer.objectId}/sms/${bulkConversation.objectId}`)}
          >
            {bulkOwner || behalfOf ? `See full bulk action` : `Bulk action`}
          </Button>}
        </Box>
      </Box>
      {
        open &&
        <MessageInfo from={from} to={to} onConnect={onConnect} scope={scope} onRemove={onRemove}/>
      }
      {
        (errorCode || status == "failed") &&
        <MessageStatus error={retryableErrorCodes[ errorCode ] || retryableErrorCodes[ "default" ]}
                       onResend={onResend}/>
      }
    </Box>
  </Box>;
});

interface MessageInfoProps {
  from: string;
  to: string;
  scope?,
  onConnect(id)
  onRemove()
}

const MessageInfo: FC<MessageInfoProps> = React.memo(props => {
  const { from, to, scope, onConnect, onRemove } = props;
  return (
    <Box container className={MsgClasses.MsgInfo} gap={"XXS"} justify={"space-between"} flexGrow={1}>
      <Box container direction={"column"} gap={"XXXS"} flexShrink={0}>
        <Box>
          From: {from}
        </Box>
        <Box>
          To: {to}
        </Box>
      </Box>
      <ScopeConnection
        typename={"PhoneMessage"}
        alignSelf={"baseline"}
        scope={scope}
        onClick={onConnect}
        onRemove={onRemove}
        flexBasis={220}
        justify={"end"}/>
    </Box>
  );
});

interface errorMessage {
  resend: boolean,
  message: string
}

interface MessageStatusProps {
  error: errorMessage;
  onResend();
}

const MessageStatus: FC<MessageStatusProps> = React.memo(props => {
  const { error, onResend } = props;
  return <Box container justify={"space-between"} className={MsgClasses.MsgStatus}>
    <Box container gap={"XXS"} alignItems={"center"} className={MsgClasses.MsgError}>
      <FontIcon type="error"/>
      {error.message}
    </Box>
    {
      error.resend &&
      <Box container gap={"XXS"} alignItems={"center"} className={MsgClasses.MsgResend} onClick={onResend}>
        <FontIcon type="cached"/>
        RESEND
      </Box>
    }
  </Box>;
});
