import React                                        from "react";
import { useEffect }                                from "react";
import { useState }                                 from "react";
import { useCallback }                              from "react";
import { useMemo }                                  from "react";
import { useLazyQuery }                             from "@apollo/client";
import { gql }                                      from "@apollo/client";
import { useSource }                                from "@relcu/ui";
import { useQuery }                                 from "@apollo/client";
import { FormApi }                                  from "@relcu/form";
import { messageDraftsVar }                         from "../../../reactiveVars";
import { PhoneMessageRenderInput }                  from "../../../types/graphql-global-types";
import { SubscriptionEvent }                        from "../../../types/graphql-global-types";
import { createPhoneNumbers }                       from "../../../utils/helpers";
import { getDefaultPhoneNumberForSMS }              from "../../../utils/helpers";
import { RenderPhoneMessageVariables }              from "../../__types__/RenderPhoneMessage";
import { RenderPhoneMessage }                       from "../../__types__/RenderPhoneMessage";
import { RENDER_PHONE_MESSAGE_QUERY }               from "../../operations.graphql";
import { useViewerPhoneLines }                      from "../../useViewerPhoneLines";
import { GetMessagesCountByNumbersVariables }       from "./__types__/GetMessagesCountByNumbers";
import { GetMessagesCountByNumbers }                from "./__types__/GetMessagesCountByNumbers";
import { SubscribeMessagesCountByNumbersVariables } from "./__types__/SubscribeMessagesCountByNumbers";
import { SubscribeMessagesCountByNumbers }          from "./__types__/SubscribeMessagesCountByNumbers";
import { useTemplate }                              from "./useTemplate";

export function useActionBar(props) {
  const { onSend, templates, receiver, to: toNumbers } = props;
  const { $object, $viewer } = useSource();
  const { fromNumbers: from, defaultPhoneNumber, preferred } = useViewerPhoneLines();
  const to = useMemo(() => toNumbers ?? createPhoneNumbers($object, "smsOptOut"), [$object, toNumbers]);
  const [fromNumber, setFromNumber] = useState<string>(defaultPhoneNumber?.value);//todo ask Armen for refetch and state numbers
  const [toNumber, setToNumber] = useState<string>(preferred?.to?.value || getDefaultPhoneNumberForSMS(to, $object?.__typename)?.value);//todo ask Armen for refetch and state numbers
  const { data, refetch, loading, subscribeToMore } = useQuery<GetMessagesCountByNumbers, GetMessagesCountByNumbersVariables>(GET_MESSAGES_COUNT_BY_NUMBERS, {
    variables: {
      from: fromNumber,
      to: toNumber
    },
    skip: !toNumber || !fromNumber,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const [load, result] = useLazyQuery<RenderPhoneMessage, RenderPhoneMessageVariables>(RENDER_PHONE_MESSAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: { errorPolicy: "ignore" }
  });
  const [replacement] = useState<any>({});
  const [selectedNumber, setSelectedNumber] = useState<any>("");
  const messageTemplates = React.useMemo(() => templates.map(t => {
    return { label: t.node.title, value: t.node.objectId };
  }).sort((a, b) => a.label.localeCompare(b.label)), [templates]);
  const template = useTemplate($object.objectId);
  const deleteDraft = useCallback(() => {
    const drafts = { ...messageDraftsVar() };
    delete drafts[ $object.objectId ];
    messageDraftsVar({ ...drafts });
  }, []);
  const handleSubmit = useCallback(async (values, form: FormApi) => {
    const attachments = values.attachments.map(({ attachment }) => ({ objectId: attachment.objectId, url: attachment.url }));
    let result = await onSend({ ...values, attachments });
    if (result != undefined) {
      form.change("attachments", []);
      form.change("content", "");
      form.resetFieldState("content");
      if (form.getFieldState("template")) {
        form.change("template", "");
        form.resetFieldState("template");
      }
      deleteDraft();
    }
  }, [onSend, $object.objectId]);
  const handleConsentChange = useCallback((from, to) => {
    refetch({ from, to });
  }, [refetch]);

  const handleKeyDown = function (e, cb) {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  const getRenderedTemplate = async (content) => {
    const input: PhoneMessageRenderInput = {
      template: content,
      user: $viewer.objectId,
      to: toNumber
    };

    if ($object.__typename != "User" && $object.__typename != "Contact") {
      input.scope = $object.id;
    } else if ($object.__typename == "Contact") {
      input.contact = $object.objectId;
    }

    const phoneMessage = await load({
      variables: {
        input: input
      }
    });

    return phoneMessage.data.renderPhoneMessage;
  };

  const handleTemplateChange = useCallback(async (e, form) => {
    const template = templates.find(({ node }) => node.objectId == e);
    const { html } = await getRenderedTemplate(template.node.text);

    form.change("content", html);
    form.change("template", e);
  }, [replacement, toNumber, templates, receiver, $object]);

  const handleToChange = useCallback(async (number, form) => {
    setSelectedNumber(number);
    const templateObjectId = form.getFieldState("template")?.value;
    const template = templates.find(({ node }) => node.objectId == templateObjectId);
    if (template) {
      const { html } = await getRenderedTemplate(template.node.text);
      form.change("content", html);
    }
    form.change("to", number);
  }, [replacement, $viewer, receiver, templates, $object]);

  const handleFromChange = useCallback((number, form) => {
    form.change("from", number);
  }, [replacement]);

  useEffect(() => {
    return () => {
      deleteDraft();
    };
  }, []);

  useEffect(() => {
    return subscribeToMore<SubscribeMessagesCountByNumbers, SubscribeMessagesCountByNumbersVariables>({
      document: SUBSCRIBE_MESSAGES_COUNT_BY_NUMBERS,
      variables: {
        from: fromNumber,
        to: toNumber
      },
      updateQuery(prev, { subscriptionData: { data: { phoneMessages: { event } } } }) {
        switch (event) {
          case SubscriptionEvent.ENTER:
          case SubscriptionEvent.CREATE:
            refetch().catch(console.error);
        }
        return prev;
      }
    });
  }, [fromNumber, toNumber]);

  useEffect(() => {
    if (!selectedNumber) {
      if (preferred?.to && preferred.to.value) {
        if (!preferred?.to?.smsOptOut) {
          setToNumber(preferred?.to?.value);
        } else {
          setToNumber("");
        }
      } else {
        const defaultNumber = getDefaultPhoneNumberForSMS(to, $object?.__typename);
        if (defaultNumber && !defaultNumber.smsOptOut) {
          setToNumber(defaultNumber.value);
        } else {
          setToNumber("");
        }
      }
    }
    if (preferred?.from) {
      setFromNumber(preferred.from.value);
    }
  }, [to, preferred, selectedNumber]);

  return {
    to,
    from,
    content: template.value ?? "",
    template,
    selectedNumber,
    handleToChange,
    handleFromChange,
    handleTemplateChange,
    handleSubmit,
    handleKeyDown,
    messageTemplates,
    handleConsentChange,
    loading,
    setFromNumber,
    fromNumber,
    setToNumber,
    toNumber,
    showConsent: !data?.phoneMessages?.count
  };
}

const GET_MESSAGES_COUNT_BY_NUMBERS = gql`
  query GetMessagesCountByNumbers($from: String, $to:String!) {
    phoneMessages(
      last: 1,
      where: {
        AND: [
          {
            OR: [
              {
                from: {
                  equalTo: $from
                }
                to: {
                  equalTo: $to
                }
              },
              {
                from: {
                  equalTo: $to
                }
                to: {
                  equalTo: $from
                }
              }
            ]
          }
          {
            OR: [
              {
                status: {
                  equalTo: "received"
                }
              },
              {
                status: {
                  equalTo: "delivered"
                }
              }
            ]
          }
        ]
      }
    ) {
      count
    }
  }
`;

const SUBSCRIBE_MESSAGES_COUNT_BY_NUMBERS = gql`
  subscription SubscribeMessagesCountByNumbers($from: String, $to:String!) {
    phoneMessages(
      where: {
        AND: [
          {
            OR: [
              {
                from: {
                  equalTo: $from
                }
                to: {
                  equalTo: $to
                }
              },
              {
                from: {
                  equalTo: $to
                }
                to: {
                  equalTo: $from
                }
              }
            ]
          }
          {
            OR: [
              {
                status: {
                  equalTo: "received"
                }
              },
              {
                status: {
                  equalTo: "delivered"
                }
              }
            ]
          }
        ]
      }
    ){
      event
    }
  }
`;
