import React               from "react";
import { FC }              from "react";
import { useMemo }         from "react";
import { useClassNames }   from "@relcu/rc";
import { InputGroup }      from "@relcu/rc";
import { Form }            from "@relcu/rc";
import { DateTime }        from "@relcu/date";
import { useField }        from "@relcu/final-form";
import { DateRangePicker } from "@relcu/rc";

export interface CallableHourRangeFieldProps {
  startFieldName: string;
  endFieldName: string;
  placeholder: string;
}
export const CallableHourRangeField: FC<CallableHourRangeFieldProps> = React.memo(function CallableHourRangeField(props) {
  const { startFieldName, endFieldName, placeholder } = props;
  const { input: startField } = useField(startFieldName);
  const { input: endField } = useField(endFieldName);
  const value: any = useMemo(() => {
    let value = [];

    if (startField.value && endField.value) {
      value = [DateTime.fromISO(startField.value).toJSDate(), DateTime.fromISO(endField.value).toJSDate()];
    }

    return value;
  }, [startField.value, endField.value]);

  const { withClassPrefix, prefix } = useClassNames("form-control");
  const classes = withClassPrefix("wrapper");
  const handleChange = (value) => {
    const [start, end] = value;
    startField.onChange(DateTime.fromJSDate(start).toFormat("HH:mm"));
    endField.onChange(DateTime.fromJSDate(end).toFormat("HH:mm"));
  };
  return (
    <Form.Group controlId={startFieldName + endFieldName}>
      {!!placeholder && <Form.ControlLabel>{placeholder}</Form.ControlLabel>}
      <InputGroup inside full={true} size={"md"}>
        <div className={classes}>
          <DateRangePicker
            cleanable={false}
            size={"lg"}
            ranges={[]}
            format="HH:mm"
            value={value}
            onChange={(value) => {
              if (value == null) {
                startField.onChange(null);
                endField.onChange(null);
              } else {
                handleChange(value);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </InputGroup>
    </Form.Group>

  );
});
