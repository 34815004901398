import React             from "react";
import { FC }            from "react";
import { FormWhere }     from "@relcu/ui";
import { Divider, Form } from "@relcu/rc";
import { Field }         from "@relcu/final-form";
import { Checkbox }      from "@relcu/rc";
import { Stack }         from "@relcu/rc";
import { FIELD_TYPES }   from "../FieldConfigDialog";

export const NAME_VALIDATOR = /^[_$a-zA-Z\xA0-\uFFFF][_$a-zA-Z0-9\xA0-\uFFFF]*$/;

export const Basic: FC<{ mode: string }> = React.memo(function Basic({ mode }) {
  return (
    <Stack direction={"column"} childrenRenderMode={"clone"} style={{ alignSelf: "stretch", flex: 1 }}
           alignItems={"flex-start"}>
      <Stack.Item alignSelf={"stretch"}>
        <Stack direction={"row"} alignItems={"flex-start"} wrap spacing={16}>
          <Form.Field
            name="name"
            label="Field name"
            required
            properties={{ disabled: mode === "update" }}
            validate={(value) => {
              if (!value) {
                return `Field name is required.`;
              }
              if (!NAME_VALIDATOR.test(value)) {
                return "Invalid name.";
              }
            }}
          />
          <Form.Field
            name="label"
            label="Field label"
            required
            validate={(value) => {
              if (value?.trim() === "") {
                return `Field label is required.`;
              }
            }}
          />
          <div style={{ width: 36 }}/>
        </Stack>
        <Stack direction={"row"} alignItems={"flex-start"} spacing={16} childrenRenderMode={"clone"}>
          <Field
            name="required"
            type="checkbox">
            {({ input }) => {
              return (
                <Checkbox
                  style={{ marginTop: 24, flexBasis: "calc(40% - 8px)" }}
                  checked={input.checked}
                  onChange={(value, checked, event) => input.onChange(event)}>
                  Required
                </Checkbox>
              );
            }}
          </Field>
          <FormWhere conditions={{ and: [{ path: "type", operator: "equals", value: "Choice" }] }}>
            <>
              <Field
                name="numeric"
                type="checkbox">
                {({ input }) => {
                  return (
                    <Checkbox
                      style={{ marginTop: 24, flexBasis: "calc(40% - 8px)" }}
                      checked={input.checked}
                      onChange={(value, checked, event) => input.onChange(event)}>
                      Numeric
                    </Checkbox>
                  );
                }}
              </Field>
              <div style={{ width: 36 }}/>
            </>
          </FormWhere>
        </Stack>
        <Field name={"type"}>
          {({ input: { value } }) => {
            const FieldConfigComponent = FIELD_TYPES[ value ]?.component;
            return FieldConfigComponent ? <>
              <Divider/>
              <FieldConfigComponent/>
            </> : null;
          }}
        </Field>
      </Stack.Item>
    </Stack>
  );
});
