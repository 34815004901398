import { groupBy }               from "@relcu/ui";
import { MenuItem }              from "@relcu/ui";
import { useCallback }           from "react";
import React                     from "react";
import { FC }                    from "react";
import { useState }              from "react";
import { MultiSelect }           from "@relcu/ui";
import { NumberField }           from "./NumberField";
import { SwitchDatePickerField } from "./SwitchDatePickerField";

export interface StatusFieldProps {
  field: string;
  operator: string;
  value: any;
  schema: any;
  onChange(data: any);
}

export const StatusField: FC<StatusFieldProps> = React.memo(function StatusField(props) {
  const { field, operator, value, schema, onChange } = props;
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  if (field == "leadStatus.updatedAt") {
    return <SwitchDatePickerField flex={"0 1 32%"}
                                  value={value}
                                  type={[">", "<", "greaterThan", "lessThan"].includes(operator) ? "datetime" : (["during", "notDuring"].includes(operator) ? "calendar" : "date")}
                                  onChange={(date) => onChange(date)}/>;
  }
  if (["leadStatus.actionCount","leadStatus.currentStatusActionCount","leadStatus.currentStageActionCount", ].includes(field)) {
    return <NumberField flex={"0 1 32%"} value={isNaN(value) ? null : value}
                        onChange={onChange}/>;
  }
  let availableActions = [];

  if (field == "leadStatus.action") {
    availableActions = Object.keys(schema.actions)?.map((state) => ({ value: state, label: schema.actions[ state ].name }));
  } else {
    const structuringAvailableActions = Object.keys(schema.states)?.map((state) => ({ value: state, label: schema.states[ state ].status, parent: schema.states[ state ].stage }));
    const byStages = groupBy(structuringAvailableActions, "parent");
    availableActions = Object.entries(byStages).reduce((previousValue, currentValue) => {
      return [...previousValue, { value: currentValue[ 0 ], label: currentValue[ 0 ], disabled: true }, ...currentValue[ 1 ]];
    }, []);
  }

  function options() {
    if (selectedIds.length) {
      return availableActions.filter((action) => selectedIds.includes(action.value));
    }

    return availableActions;
  }

  const getParentsCount = (item, items) => {
    if (!item.parent) {
      return 0;
    }
    let count = 1;
    for (const opt of items) {
      if (item.parent == opt.value) {
        count += getParentsCount(opt, items);
      }
    }
    return count;
  };

  const Option = useCallback((item) => {
    let parentsCount = getParentsCount(item, availableActions);

    return <MenuItem style={{ paddingLeft: (16 + parentsCount * 8) }} disabled={item.disabled}>{item.label}</MenuItem>;
  }, [availableActions]);

  return <MultiSelect
    selectedFilter={selectedFilter}
    onSelectedFilter={(selectedFilter) => {
      setSelectedFilter(selectedFilter);
      setSelectedIds(selectedFilter ? value : []);
    }}
    showSelectedCount
    showSelectionActions
    getOptionValueByKey
    value={value || []}
    options={options()}
    direction={"column"}
    style={{ padding: "0 8px" }}
    flex={"0 1 32%"}
    placeholder={"Select value"}
    onChange={onChange}
    renderOption={Option}
    treeView={field === "leadStatus.status"}
  />;
});
