import React               from "react";
import { FC }              from "react";
import { useField }        from "@relcu/form";
import { Form }            from "@relcu/rc";
import { Button }          from "@relcu/rc";
import { Icon }            from "@relcu/rc";
import { IconButton }      from "@relcu/rc";
import { FormField }       from "@relcu/rc";
import { Stack }           from "@relcu/rc";
import { FieldArray }      from "@relcu/form";
import { FormInputNumber } from "@relcu/rc/src/FormField";

export const ChoiceConfig: FC<{ showParent?: boolean }> = React.memo(function ChoiceField({ showParent = false }) {
  const { input: { checked } } = useField("numeric", { subscription: { value: true }, type: "checkbox" });
  const { input: { value: options = [] } = {} } = useField("options");
  function filterMyParents(myValue, option) {
    return option.value &&
      option.value != myValue &&
      (!option.parent || (option.parent != myValue));
  }

  return (
    <Stack direction={"column"} alignItems={"stretch"} childrenRenderMode={"clone"} spacing={16}>
      <Form.Select
        properties={{ style: { width: "100%" } }}
        name="defaultValue"
        label="Default value"
        data={(options || []).filter(value => value.value)}
      />
      <FieldArray name={"options"}>
        {
          ({ fields, meta }) => {
            return <>
              {
                fields.map((name, index) => {
                  return <Stack key={name + index} wrap spacing={16}>
                    <FormField name={`${name}.label`} label={"Option label"}/>
                    {
                      checked ?
                        <FormField
                          component={FormInputNumber}
                          name={`${name}.value`}
                          label={"Option value"}
                        /> :
                        <FormField name={`${name}.value`} label={"Option value"}/>
                    }
                    {
                      showParent &&
                      <Form.Select
                        properties={{ style: { width: "100%" }, cleanable: true }}
                        name={`${name}.parent`}
                        label="Parent"
                        data={(options || []).filter(option => {
                          return filterMyParents(fields.value[ index ]?.value, option);
                        })}
                      />
                    }
                    <IconButton
                      icon={<Icon type={"remove"}/>}
                      onClick={() => fields.remove(index)}
                      style={{ alignSelf: "flex-end" }}
                      size={"sm"} appearance={"text"}/>
                  </Stack>;
                })
              }
              <Button
                onClick={() => fields.push({ label: "", value: checked ? 0 : "" })}
                style={{ alignSelf: "flex-start" }}
                size={"sm"} appearance={"text"}>Add option</Button>
            </>;
          }
        }
      </FieldArray>
    </Stack>
  );
});
