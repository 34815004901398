import { DateTime }          from "@relcu/date";
import { isWithinInterval }  from "../date-fns";
import { addDays }           from "../date-fns";
import { eachDayOfInterval } from "../date-fns";
import { endOfMonth }        from "../date-fns";
import { endOfWeek }         from "../date-fns";
import { getDay }            from "../date-fns";
import { startOfMonth }      from "../date-fns";
import { startOfWeek }       from "../date-fns";

type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6
export interface GetWeekdayLabelsProps {
  firstDayOfWeek?: FirstDayOfWeek
  weekdayLabelFormat?(date: Date): string
}

export function getWeekdayLabels({
  firstDayOfWeek = 1,
  weekdayLabelFormat = (date: Date) => DateTime.fromJSDate(date).toFormat("LLLLLL")
}: GetWeekdayLabelsProps = {}) {
  const now = new Date();
  const arr = eachDayOfInterval({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek)
  });
  return arr.reduce((array, date) => {
    // @ts-ignore
    array.push(weekdayLabelFormat(date));
    return array;
  }, []);
}

export interface GetDaysProps {
  year: number
  month: number,
  firstDayOfWeek?: FirstDayOfWeek
  dayLabelFormat?(date: Date): string
}
export type CalendarDayObject = { dayLabel: string; date: Date, isInMonth: boolean }
export type CalendarDay = number | CalendarDayObject
export function getDays({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = (date: Date) => DateTime.fromJSDate(date).toFormat("DD")
}: GetDaysProps): CalendarDay[] {
  const date = new Date(year, month);

  const monthStart = startOfMonth(date);
  const monthStartDay = getDay(monthStart);
  const monthEnd = endOfMonth(date);

  const prevMonthDays = Array.from(
    Array(
      monthStartDay >= firstDayOfWeek
        ? monthStartDay - firstDayOfWeek
        : 6 - firstDayOfWeek + monthStartDay + 1
    ).keys()
  ).fill(0);
  const startDay = addDays(monthStart, -prevMonthDays.length);
  const endDay = addDays(monthEnd, 42 - (monthEnd.getDate() + prevMonthDays.length));
  const days = eachDayOfInterval({ start: startDay, end: endDay }).map(date => ({
    date,
    dayLabel: dayLabelFormat(date),
    isInMonth: isWithinInterval(date, { start: monthStart, end: monthEnd })
  }));
  return [...days];
}
