import React         from "react";
import { FC }        from "react";
import { FormField } from "@relcu/rc";

export const StringConfig: FC<any> = React.memo(function StringField() {
  return (
    <FormField
      name="defaultValue"
      label="Default value"
    />
  );
});
