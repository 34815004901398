import React                          from "react";
import { SetStateAction }             from "react";
import { Dispatch }                   from "react";
import { useEffect }                  from "react";
import { useContext }                 from "react";
import { useCallback }                from "react";
import { useState }                   from "react";
import { usePrevious }                from "@relcu/ui";
import { useSource }                  from "@relcu/ui";
import { gql }                        from "@apollo/client";
import { useApolloClient }            from "@apollo/client";
import { useLazyQuery }               from "@apollo/client";
import { LoanEstimateOffer }          from "../../../../../../graph/__types__/LoanEstimateOffer";
import { Proposal }                   from "../../Proposal";
import { GetPmiVariables }            from "./__types__/GetPmi";
import { GetPmi }                     from "./__types__/GetPmi";
import { GetPmi_getPmiRatesV2 }       from "./__types__/GetPmi";
import { StateRef }                   from "../OfferTable";
import { GetTitleFeesBeta_titleFees } from "./__types__/GetTitleFeesBeta";
import { GetTitleFeesBetaVariables }  from "./__types__/GetTitleFeesBeta";
import { GetTitleFeesBeta }           from "./__types__/GetTitleFeesBeta";
import {
  LoanEstimateOfferClientTitleFeeEditableState
}                                     from "./__types__/LoanEstimateOfferClientTitleFeeEditableState";
import { RsRefForwardingComponent }   from "@relcu/rc/src/@types/common";

const cleanup = (data) => {
  let cleanedData = {};
  Object.keys(data).forEach(k => {
    if (k !== "__typename") {
      if (typeof data[ k ] == "object" && data[ k ] !== null) {
        cleanedData[ k ] = cleanup(data[ k ]);
      } else {
        cleanedData[ k ] = data[ k ];
      }
    }
  });
  return cleanedData;
};

export type FeeProviderContextValue = {
  titleFeeLoading?: boolean
  titleFees: GetTitleFeesBeta_titleFees[]
  populateTitleFees(values: Record<string, any>, cleanId?: boolean)
  populatePmi?(values: Record<string, any>, cleanId?: boolean)
  selectedTitleFee?: Record<string, any>
  selectedTitleFeeId?: string
  setTitleFee(providerId)
  reFetchTitleFees(providerId: string, data: Record<string, any>)
  pmiLoading?: boolean
  pmiRates: GetPmi_getPmiRatesV2
  selectedPmi?: Record<string, any>
  selectedPmiId?: string
  setSelectedPmi: Dispatch<SetStateAction<Record<string, any>>>
  reFetchPmi(data: Record<string, any>)
}
export const FeeContext = React.createContext<FeeProviderContextValue>(null);
export const FeeProvider: RsRefForwardingComponent<any, {
  offer: LoanEstimateOffer
}> = React.forwardRef(function FeeProvider({ children, offer }, ref: React.RefObject<StateRef>) {
  const client = useApolloClient();
  const { $object: lead, $settings: { pricing: { pmiRateProEnabled } } } = useSource();
  const [getTitleFees, {
    data: { titleFees } = { titleFees: [] }, fetchMore, loading: titleFeeLoading
  }] = useLazyQuery<GetTitleFeesBeta, GetTitleFeesBetaVariables>(GET_TITLE_FEES, {
    returnPartialData: true,
    notifyOnNetworkStatusChange: true
  });
  const [getPmi, { data, loading: pmiLoading, fetchMore: pmiFetchMore }] = useLazyQuery<GetPmi, GetPmiVariables>(GET_PMI, {
    notifyOnNetworkStatusChange: true
  });
  const { id: offerId } = offer;
  const loanEstimate = useContext(Proposal.Context);
  const [selectedTitleFeeId, setSelectedTitleFeeId] = useState(null);
  const [selectedTitleFee, setSelectedTitleFee] = useState(null);
  const [selectedPmiId, setSelectedPmiId] = useState(null);
  const [selectedPmi, setSelectedPmi] = useState(null);
  const newlySelectedRate = ref.current.offers[ offer.objectId ]?.rate;
  const titleFeeInput = useCallback((values) => {
    return {
      loanAmount: values.totalLoanAmount,
      cashOutAmount: values.cashOutAmount,
      loanPurpose: loanEstimate.loanPurpose,
      currentMortgageBalance: values.currentMortgageBalance,
      amortizationType: values.amortizationType,
      loanType: values.productType,
      cltv: values.cltv,
      firstTimeHomeBuyer: values.firstTimeHomeBuyer,
      property: {
        type: loanEstimate.propertyType,
        occupancy: loanEstimate.propertyOccupancy,
        propertyAddress: {
          city: loanEstimate.propertyCity,
          county: loanEstimate.propertyCounty.replace(" County", ""),
          state: loanEstimate.propertyState,
          zipCode: loanEstimate.propertyZipCode,
          fipsCode: loanEstimate.propertyFipsCode
        },
        value: values.propertyValue
      },
      offerId: values.id
    };
  }, [loanEstimate]);
  const setChanges = (selected) => {
    const fee = {
      "titleCompany": selected.providerId,
      "titleCompanyName": selected.title,
      "ownersTitle": selected.fees.ownersTitle,
      "recordingCharges": selected.fees.recordingCharges,
      "settlementFee": selected.fees.settlementFee,
      "titleInsurance": selected.fees.titleInsurance,
      "transferTax": selected.fees.transferTax
    };

    if (selected.fees.lendersTitle != null && selected.fees.lendersTitle != undefined) {
      fee[ "lendersTitle" ] = selected.fees.lendersTitle;
    }

    setSelectedTitleFee(fee);
    queueMicrotask(() => {
      client.writeFragment<LoanEstimateOfferClientTitleFeeEditableState>({
        fragment: LOAN_ESTIMATE_OFFER_CLIENT_TITLE_FEE_EDITABLE_STATE,
        data: {
          __typename: "LoanEstimateOffer",
          isTitleFeeEditable: selected.editable
        },
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: offerId })
      });
    });
  };
  const populateTitleFees = async (values, cleanId) => {
    if (cleanId) {
      setSelectedTitleFeeId(null);
    }
    const { data } = await getTitleFees({
      variables: {
        input: {
          ...titleFeeInput(values)
        }
      }
    });
    const titleFees = data?.titleFees || [];
    if (!selectedTitleFeeId && titleFees.length) {
      const selectedFeeId = titleFees.find(fee => fee.default)?.providerId;
      setTitleFee(selectedFeeId, titleFees);
    }
  };
  const pmiInput = useCallback((values): GetPmiVariables["input"] => {
    const borrowers = lead.members.filter((members) => ["borrower", "co_borrower"].includes(members.type)).map(({
      type,
      employmentStatus
    }) => ({
      type,
      creditScore: (type == "borrower" ? values.fico : values.fico1),
      isSelfEmployed: employmentStatus === "self_employed"
    }));
    //todo Review this part do pmi need to be depended on pricing engine.
    const normLoanProgram = (values.loanProduct || "").toLowerCase();
    let pmiLoanProgram = "None";
    if (normLoanProgram.includes("home") && normLoanProgram.includes("ready")) {
      pmiLoanProgram = "Fannie Mae HomeReady";
    } else if (normLoanProgram.includes("home") && (normLoanProgram.includes("poss") || normLoanProgram.includes("possible"))) {
      pmiLoanProgram = "Freddie Mac HomePossible";
    }

    return {
      leadId: lead.objectId,
      loanAmount: Math.round(values.totalLoanAmount),
      dti: Math.round(values.dti * 100) / 100,
      loanPurpose: loanEstimate.loanPurpose,
      loanProgram: pmiLoanProgram,
      loanTerm: parseInt(values.loanTerm),
      firstTimeHomeBuyer: values.firstTimeHomeBuyer ?? null,
      interestRate: values.rate,
      amortizationType: values.amortizationType,
      initialArmTerm: values.initialArmTerm ? Number(values.initialArmTerm) : null,
      borrower: borrowers.find(({ type }) => type === "borrower"),
      coBorrower: borrowers.find(({ type }) => type === "co_borrower"),
      property: {
        type: loanEstimate.propertyType,
        occupancy: loanEstimate.propertyOccupancy,
        value: values.propertyValue,
        propertyAddress: {
          state: loanEstimate.propertyState,
          zipCode: loanEstimate.propertyZipCode,
          city: loanEstimate.propertyCity,
          county: loanEstimate.propertyCounty
        }
      }
    };
  }, [loanEstimate, lead.members]);
  const getPmiData = (pmiRates) => {
    return {
      ...pmiRates,
      payload: Object.fromEntries(Object.entries<any>(pmiRates.payload || {}).map(([key, r]) => {
        const rate = Object.keys(r).map((key) => ({ ...r[ key ], providerId: key.toLowerCase(), providerName: key }));
        return [key, [...rate].sort((a, b) => a.premium1 - b.premium1)];
      }))
    };
  };

  const populatePmi = async (values, cleanId) => {
    if (cleanId) {
      setSelectedPmiId(null);
    }
    const { data } = await getPmi({ variables: { input: pmiInput(values) } });
    const pmiRates = cleanup(data?.getPmiRatesV2 || {}) as GetPmi_getPmiRatesV2;
    const sortedPmiData = getPmiData(pmiRates);
    if (!selectedPmiId && Object.keys(sortedPmiData?.payload || {}).length) {
      const monthly = !!sortedPmiData.payload.monthly;
      const keys = Object.keys(sortedPmiData?.payload);
      const selected = monthly
        ? sortedPmiData.payload.monthly.find(r => r.status == "Success")
        : sortedPmiData.payload[ keys[ 0 ] ].find(r => r.status == "Success");
      if (selected) {
        let selectedType;
        let selectedId;
        selectedId = selected.providerId;
        if (monthly) {
          selectedType = "monthly";
        } else {
          selectedType = keys[ 0 ];
        }
        setSelectedPmiId(selectedId);
        const selectedPmi = sortedPmiData?.payload[ selectedType ]?.find(fee => fee.providerId == selectedId);
        setSelectedPmi({
          pmiCompany: selectedId,
          pmiType: selectedType,
          pmi: selectedPmi.premium1
        });
      }
    }
  };
  const setTitleFee = (providerId, fees?) => {
    const selectedFee = (fees || titleFees).find(fee => fee.providerId == providerId);
    setSelectedTitleFeeId(providerId);
    setChanges(selectedFee);
  };

  const reFetchTitleFees = useCallback(async (providerIds, data) => {
    await fetchMore({
      variables: {
        input: {
          ...data,
          providerIds: providerIds
        }
      }
    });
  }, [titleFeeInput]);
  const reFetchPmi = useCallback(async (data) => {
    await pmiFetchMore({
      variables: {
        input: {
          ...data
        }
      }
    });
  }, [titleFeeInput]);
  const pmiRates = cleanup(data?.getPmiRatesV2 || {}) as GetPmi_getPmiRatesV2;

  useEffect(() => {
    if (!pmiLoading && offer.pmiEligible && newlySelectedRate && pmiRateProEnabled) {
      populatePmi(offer, true);
    }
  }, [pmiLoading, newlySelectedRate, pmiRateProEnabled]);

  return <FeeContext.Provider
    value={{
      reFetchTitleFees,
      titleFees,
      titleFeeLoading,
      populateTitleFees,
      selectedTitleFee,
      selectedTitleFeeId,
      setTitleFee,
      setSelectedPmi,
      populatePmi,
      reFetchPmi,
      selectedPmi,
      pmiRates,
      pmiLoading
    }}>
    {children}
  </FeeContext.Provider>;
});

export const TITLE_FEE_FRAGMENT = gql`
  fragment TitleFeeResponse on TitleFeeResponses {
    title
    providerId
    default
    editable
    errorMessage
    fees{
      ownersTitle
      recordingCharges
      settlementFee
      titleInsurance
      transferTax
      lendersTitle
    }
  }
`;
export const GET_TITLE_FEES = gql`
  query GetTitleFeesBeta($input: TitleFeeArgs!){
    titleFees(input: $input) @connection(key: "titleFeesBeta",filter: ["input"]){
      ...TitleFeeResponse
    }
  }
  ${TITLE_FEE_FRAGMENT}
`;
export const LOAN_ESTIMATE_OFFER_CLIENT_TITLE_FEE_EDITABLE_STATE = gql`
  fragment LoanEstimateOfferClientTitleFeeEditableState on LoanEstimateOffer {
    isTitleFeeEditable @client
  }
`;

export const PMI_V2_RESULT = gql`
  fragment PmiRateV2Result on PmiRateV2ResultItem{
    status
    premium1
    rate1
    pdfUrl
    quoteNumber
  }
`;
export const PMI_V2_RESULT_COMPANY_FRAGMENT = gql`
  fragment PmiRateV2ResultCompany on PmiRateV2ResultCompany {
    Arch {
      ...PmiRateV2Result
    }
    Enact {
      ...PmiRateV2Result
    }
    Essent {
      ...PmiRateV2Result
    }
    Mgic {
      ...PmiRateV2Result
    }
    National {
      ...PmiRateV2Result
    }
    Radian {
      ...PmiRateV2Result
    }
  }
  ${PMI_V2_RESULT}
`;

export const GET_PMI = gql`
  query GetPmi($input: PmiRatesArgs!)  {
    getPmiRatesV2(input:$input) @connection(key: "pmiRatesBeta",filter: ["input"]){
      payload{
        monthly {
          ...PmiRateV2ResultCompany
        }
        single_lp {
          ...PmiRateV2ResultCompany
        }
        single_nr {
          ...PmiRateV2ResultCompany
        }
        single_r {
          ...PmiRateV2ResultCompany
        }
        split_bd {
          ...PmiRateV2ResultCompany
        }
      }
      errorMessage
      requestId
      status
    }
  }
  ${PMI_V2_RESULT_COMPANY_FRAGMENT}
`;
