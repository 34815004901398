import { useMemo }          from "react";
import React                from "react";
import { FC }               from "react";
import { FieldArray }       from "@relcu/final-form";
import { Input }            from "@relcu/rc";
import { FormField }        from "@relcu/rc";
import { CheckPicker }      from "@relcu/rc";
import { Typography }       from "@relcu/rc";
import { mode }             from "@relcu/ui";
import { JsonSectionProps } from "@relcu/ui";
import { ButtonSizes }      from "@relcu/ui";
import { ButtonColors }           from "@relcu/ui";
import { ButtonVariants }         from "@relcu/ui";
import { Button }                 from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { useSchemaField }         from "../../useSchemaField";
import { CallableHourRangeField } from "./CallableHourRangeField";

export const CallableHoursEditSection: FC<JsonSectionProps> = React.memo(function CallableHoursEditSection(props) {
  const { fields: jsonFields, ...rest } = props;
  const { options } = useSchemaField("StateBasedFee", "state");
  const viewMode = mode(rest.view);
  const filteredOptions = (current, values) => {
    let freeStats = [...options];
    values.forEach((currentValue, index) => {
      if (current != index) {
        freeStats = freeStats.filter(state => !currentValue.states?.includes(state.value));
      }
    });
    return freeStats;
  };

  return (
    <FieldArray
      name={"callableStatesHours"}
      defaultValue={useMemo(()=>([]),[])}
      validate={(value) => {
        if (value?.find(v => Object.keys(v).length == 1)) {
          return "State should have at least 1 callable hour.";
        }
      }}>
      {
        ({ fields, meta }) => {
          const nextState = filteredOptions(fields.value.length, fields.value)[ 0 ]?.value;
          return <Box container direction={"column"} flex={1} gap={"S"}
                      className={"rs-form-floating callable-edit-section"}>
            <Box justify={"start"} container wrap={"wrap"} gap={"XS"} className={"capable-hours-row"}>
              <Input disabled={true} value={"ALL"} style={{ width: "30%" }} size={"lg"}/>
              <CallableHourRangeField
                startFieldName={`callableStartHour`}
                endFieldName={`callableEndHour`}
                placeholder={"Weekday callable hours"}
              />
              <CallableHourRangeField
                startFieldName={`callableSaturdayStartHour`}
                endFieldName={`callableSaturdayEndHour`}
                placeholder={"Saturday callable hours"}
              />
              <CallableHourRangeField
                startFieldName={`callableSundayStartHour`}
                endFieldName={`callableSundayEndHour`}
                placeholder={"Sunday callable hours"}
              />
            </Box>
            {
              fields.map((name, index) => {
                return <Box justify={"start"} key={index} container wrap={"wrap"} gap={"XS"} className={"capable-hours-row"}>
                  <FormField
                    fullWidth={true}
                    name={`${name}.states`}
                    component={CheckPicker}
                    properties={{
                      size: "lg",
                      data: filteredOptions(index, fields.value),
                      style: { width: "100%" }
                    }}
                    label={"States"}
                    required
                  />
                  <CallableHourRangeField
                    startFieldName={`${name}.callableStartHour`}
                    endFieldName={`${name}.callableEndHour`}
                    placeholder={"Weekday callable hours"}
                  />
                  <CallableHourRangeField
                    startFieldName={`${name}.callableSaturdayStartHour`}
                    endFieldName={`${name}.callableSaturdayEndHour`}
                    placeholder={"Saturday callable hours"}
                  />
                  <CallableHourRangeField
                    startFieldName={`${name}.callableSundayStartHour`}
                    endFieldName={`${name}.callableSundayEndHour`}
                    placeholder={"Sunday callable hours"}
                  />
                  <Button
                    icon={"delete"}
                    type="button"
                    variant={ButtonVariants.Ghost}
                    color={ButtonColors.White}
                    onClick={() => fields.remove(index)}
                    onlyIcon
                    size={ButtonSizes.Medium}/>
                </Box>;
              })
            }
            {
              meta.error &&
              <Typography color={"error"} variant={"base16"}>{meta.error}</Typography>
            }
            {
              viewMode == "write" && <>
                <Button
                  type="button" icon={"add"} alignSelf={"end"}
                  disabled={!nextState}
                  onClick={() => {
                    fields.push({ states: [nextState] });
                  }}
                  variant={ButtonVariants.Ghost}>
                  Add schedule
                </Button>
              </>
            }
          </Box>;
        }
      }
    </FieldArray>
  );
});
