import React            from "react";
import { FC }           from "react";
import { useCallback }  from "react";
import Editor           from "@monaco-editor/react";
import { useForm }      from "@relcu/final-form";
import { Field }        from "@relcu/final-form";
import { Typography }   from "@relcu/rc";
import { Stack }        from "@relcu/rc";
import { DetailsField } from "../../../../../../Generation";

export const StatusConfig: FC<any> = React.memo(function StatusConfig() {
  const form = useForm();
  const handleEditorValidation = useCallback((markers) => {
    if (!!markers.length) {
      form.mutators?.setFieldData?.("defaultValue", { warning: "Invalid default value." });
    } else {
      form.mutators?.setFieldData?.("defaultValue", { warning: null });
    }
  }, []);

  return (
    <Field
      name={`defaultValue`}
      parse={(value) => {
        return JSON.parse(value);
      }}
      format={(value) => {
        return JSON.stringify(value, null, 2);
      }}
    >
      {({ input: { value, onChange }, meta: { data } }) => {
        return <Stack direction={"column"} childrenRenderMode={"clone"} alignItems={"flex-start"} spacing={16}>
          <DetailsField.Title>Default value</DetailsField.Title>
          <Editor
            height={"70px"}
            defaultLanguage={"json"}
            value={value}
            theme={"dark"}
            onChange={onChange}
            options={{ minimap: { enabled: false } }}
            onValidate={handleEditorValidation}
          />
          {
            data?.warning ?
              <Typography style={{ color: "var(--rc-status-03-warning-primary)" }}
                          variant={"small12"}>{data?.warning}</Typography>
              : null
          }
        </Stack>;
      }}
    </Field>
  );
});
