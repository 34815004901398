import React             from "react";
import { FC }            from "react";
import { useState }      from "react";
import { useNavigate }   from "@relcu/react-router";
import { useClassNames } from "@relcu/rc";
import { AvatarGroup }   from "@relcu/rc";
import { Stack }         from "@relcu/rc";
import { Typography }    from "@relcu/rc";
import { Avatar }        from "@relcu/rc";
import { Ellipsis }      from "@relcu/ui";
import { formatPhoneNumber } from "../../../utils/helpers";
import { useSchemaField }    from "../../Layout/useSchemaField";
import "./caller.css";

export interface PartyType {
  id?: string;
  objectId?: string;
  name?: string;
  icon?: string;
  number?: string;
  company?: string;
  role?: string;
  className?: string;
  isUnknown?: boolean;
  showNumber?: boolean;
}

export interface CallerProps {
  hold?: boolean;
  parties: PartyType[];
}

export const Caller: FC<CallerProps> = React.memo(function Caller(props) {
  const { parties, hold } = props;
  if (!parties?.length) {
    return <></>;
  }
  const { options } = useSchemaField("User", "role");
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { company, role, number, name, id, isUnknown, showNumber = true, icon } = (parties[ selectedIndex ] || parties[ parties.length-1 ]);
  const { prefix } = useClassNames("caller", "rc");
  const className = prefix({
    "hold": hold
  });
  const handlePartySelect = (index, className, objectId) => {
    setSelectedIndex(index)
    navigate(`/${className?.toLowerCase()}/${objectId}/details`);
  }

  return (
    <Stack direction={"column"} alignItems={"center"} childrenRenderMode={"clone"} className={className}>
      {
        parties.length > 1 ?
          <AvatarGroup stack>
            {
              parties.map((party, index) => {
                return <Avatar
                  key={index}
                  onClick={() => handlePartySelect(index, party.className, party.objectId)}
                  circle
                  src={party.icon}
                  showIndicator={false}
                  alt={party.name}>
                  {party.name}
                </Avatar>;
              })
            }
          </AvatarGroup> :
          <Avatar
            circle
            src={icon}
            showIndicator={false}
            alt={name}>
            {name}
          </Avatar>
      }

      <Stack.Item basis={"12px"}/>
      {
        isUnknown ?
          <Stack direction={"column"} spacing={4} childrenRenderMode={"clone"} alignItems={"center"}>
            <Typography lineHeight={"lh24"} color={"secondary"}>
              Unknown contact
            </Typography>
            {
              showNumber &&
              <Typography variant={"large"}>{formatPhoneNumber(name)}</Typography>
            }
          </Stack> :
          <Ellipsis
            from={21}
            className={prefix("unknown-call-addition")}>
            {name}
          </Ellipsis>
      }
      <Stack.Item basis={"4px"}/>
      {
        (company || role) && !isUnknown ?
          <Stack spacing={4} childrenRenderMode={"clone"}>
            <Typography lineHeight={"lh24"} color={"secondary"}>
              {company ? "Company:" : "Role:"}
            </Typography>
            <Ellipsis
              from={23}
              className={prefix("call-addition")}
              style={{}}>
              {company || (role && options.find(o => o.value == role)?.label)}
            </Ellipsis>
          </Stack> :
          null
      }
      {
        !isUnknown && showNumber &&
        <Typography lineHeight={"lh24"} color={"secondary"}>
          {number ? formatPhoneNumber(number) : "Unknown contact"}
        </Typography>
      }
    </Stack>
  );
});
