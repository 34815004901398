import { useReactiveVar } from "@apollo/client";
import { arrayMutators }  from "@relcu/final-form";
import { FormField }      from "@relcu/rc";
import { ModalProps }     from "@relcu/rc";
import { Button }         from "@relcu/rc";
import { Typography }     from "@relcu/rc";
import { Form }           from "@relcu/rc";
import { Modal }          from "@relcu/rc";
import React              from "react";
import { FC }             from "react";
import { NAME_VALIDATOR } from "../FieldConfigDialog/Details/Basic";

export interface SchemaCreateDialogProps extends Partial<ModalProps> {
  onSubmit(data: Record<string, any>);
}
const options = [
  {
    value: "collection",
    label: "Collection",
    action: "Kind"
  },
  {
    value: "document",
    label: "Document",
    action: "Kind"
  },
  {
    value: "enum",
    label: "Enum",
    action: "Kind"
  },
  {
    value: "inputs",
    label: "Inputs",
    action: "Kind"
  },
  {
    value: "outputs",
    label: "Outputs",
    action: "Kind"
  }
];


export const SchemaCreateDialog: FC<SchemaCreateDialogProps> = React.memo(function SchemaCreateDialog(props) {
  const { open, onClose, onSubmit } = props;

  return (
    <Modal open={open} onClose={onClose} size={"xs"}>
      <Form
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={true}
        fluid
        initialValues={{ kind: "collection" }}
        validate={(values) => {
          let errors = {};
          if (values.className && !NAME_VALIDATOR.test(values.className)) {
            errors[ "className" ] = "Invalid Name";
          }
          return errors;
        }}
        onSubmit={onSubmit}>
        <Modal.Header>
          <Typography weights={"medium"} variant={"base16"}>Create new schema</Typography>
        </Modal.Header>
        <Modal.Body>
          <Form.Select properties={{ style: { width: "100%" } }} label={"Kind"} data={options} name={"kind"}/>
          <Form.Field properties={{ style: { width: "100%" } }} label={"Name"} name={"className"} required/>
        </Modal.Body>
        <Modal.Footer>
          <Button type={"button"} size={"xs"} onClick={props.onClose} color={"blue"} appearance="text">CANCEL</Button>
          <Button type={"submit"} size={"xs"} appearance="primary">SUBMIT</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});
