import React                                from "react";
import { FC }                               from "react";
import { useEffect }                        from "react";
import { useMemo }                          from "react";
import { useContext }                       from "react";
import { useState }                         from "react";
import { useField }                         from "@relcu/final-form";
import { useForm }                          from "@relcu/final-form";
import { FieldCell }                        from "@relcu/rc";
import { Proposal }                         from "../../../Proposal";
import { useMutation }                      from "@apollo/client";
import { UPDATE_LOAN_ESTIMATE_OFFER }       from "../../Offer";
import { UpdateLoanEstimateOfferVariables } from "../../Offer/__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "../../Offer/__types__/UpdateLoanEstimateOffer";
import { FeeContext }                       from "../../Offer/FeeProvider";
import { TitleFeeDialog }                   from "./TitleFeeDialog/TitleFeeDialog";

export const TitleSelectCell: FC = React.memo(function TitleSelectCell() {
  const { getState } = useForm();
  const loanEstimate = useContext(Proposal.Context);
  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(UPDATE_LOAN_ESTIMATE_OFFER);
  const {
    selectedTitleFee,
    titleFeeLoading,
    populateTitleFees,
    reFetchTitleFees,
    setTitleFee,
    selectedTitleFeeId,
    titleFees
  } = useContext(FeeContext);
  const [open, setOpen] = useState(false);
  const form = useForm();
  const { input: { value: selectedTitleFeeName } } = useField("titleCompanyName", { subscription: { value: true } });

  const reFetch = async (providerIds) => {
    const { values } = getState();
    const data = {
      loanAmount: values.totalLoanAmount,
      cashOutAmount: values.cashOutAmount,
      loanPurpose: loanEstimate.loanPurpose,
      currentMortgageBalance: values.currentMortgageBalance,
      amortizationType: values.amortizationType,
      loanType: values.productType,
      cltv: values.cltv,
      firstTimeHomeBuyer: values.firstTimeHomeBuyer,
      property: {
        type: loanEstimate.propertyType,
        occupancy: loanEstimate.propertyOccupancy,
        propertyAddress: {
          city: loanEstimate.propertyCity,
          county: loanEstimate.propertyCounty.replace(" County", ""),
          state: loanEstimate.propertyState,
          zipCode: loanEstimate.propertyZipCode,
          fipsCode: loanEstimate.propertyFipsCode
        },
        value: values.propertyValue
      },
      offerId: values.id
    };

    await reFetchTitleFees(providerIds, data);
  };

  useEffect(() => {
    if (selectedTitleFee) {
      update({
        variables: {
          input: {
            id: form.getState().values.objectId,
            fields: selectedTitleFee
          }
        }
      });
      Object.keys(selectedTitleFee).map(key => {
        form.change(key, selectedTitleFee[ key ]);
      });
    }
  }, [selectedTitleFee]);

  const onApply = (titleId) => {
    setTitleFee(titleId);
    setOpen(false);
  };

  const onOpen = async () => {
    const { values } = getState();
    await populateTitleFees(values);
    if (selectedTitleFeeId && titleFees.length) {
      setOpen(true);
    }
  };

  const warning = useMemo(() => {
    return titleFees.some(t => t.errorMessage);
  }, [titleFees]);

  const error = useMemo(() => {
    return titleFees.length && titleFees.every(t => t.errorMessage);
  }, [titleFees]);

  return <>
    <TitleFeeDialog loading={titleFeeLoading} onClose={() => setOpen(false)} titleFees={titleFees} open={open}
                    reFetch={reFetch} onApply={onApply} selectedId={selectedTitleFeeId}/>
    <FieldCell.TitleCell
      title={selectedTitleFeeName}
      helperText={error ? "Failed to get title fees." : (warning ? "There are options that failed to load." : "")}
      status={error ? "error" : (warning ? "warning" : "info")} required loading={titleFeeLoading} onOpen={onOpen}
      name={"titleCompany"}/>
  </>;
});
