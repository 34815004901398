import { useReactiveVar } from "@apollo/client";
import { useMemo }        from "react";
import { schemaVar }      from "../../../../../../reactiveVars";

export function useCollections() {
  const schemas = useReactiveVar(schemaVar);
  return useMemo(() => Object.keys(schemas)
  .filter(k => schemas[ k ].kind === "collection")
  .map(className => ({ label: className, value: className })), [schemas]);
}

export function useClassNames() {
  const schemas = useReactiveVar(schemaVar);
  return useMemo(() => Object.keys(schemas)
  .filter(k => schemas[ k ].kind === "document" || schemas[ k ].kind === "collection")
  .map(className => ({ label: className, value: className })), [schemas]);
}
