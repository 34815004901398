import { TimeWarningDialog } from "@relcu/ui";
import { modal }             from "@relcu/ui";
import { isValidDate }       from "@relcu/ui";
import { DateTime }          from "@relcu/date";
import { Timezone }          from "@relcu/tz";

export function getCallableStates(settings) {
  return new Timezone(settings).getCallableStates();
}
export function getValidTimezones(settings) {
  return new Timezone(settings).getValidTimezones();
}
export function toZoneName(timezone: string) {
  return Timezone.toZoneName(timezone);
}
export function isStateInCallableHours(state: string, settings) {
  return new Timezone(settings).isStateInCallableHours(state);
}
export function isTimezoneInCallableHours(timezone: string, settings) {
  return new Timezone(settings).isTimezoneInCallableHours(timezone);
}
export function getZoneNameMapping() {
  return Timezone.getZoneNameMapping();
}
export const toTimezoneDate = function (date: string, timezone = "US/Central"): Date {
  if (!(date && isValidDate(date))) {
    return;
  }
  const getOffset = (timeZone = "UTC", date = new Date()) => {
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
    const totalMinutes = (tzDate.getTime() - utcDate.getTime()) / 6e4;
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return { hours, minutes };
  };

  const serverTimezoneOffset = getOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const configuredTimezoneOffset = getOffset(timezone);

  return new Date(DateTime.fromJSDate(new Date(date)).plus({
    hour: Number(serverTimezoneOffset.hours || 0),
    minute: Number(serverTimezoneOffset.minutes || 0)
  }).minus({
    hour: Number(configuredTimezoneOffset.hours || 0),
    minute: Number(configuredTimezoneOffset.minutes || 0)
  }).toISO());
};
export const usTimeZoneNames = ["HAST", "AKST", "MST", "CST", "EST", "PST", "HST"];


export function checkTimezone(lead, settings) {
  const { members, timezone, propertyState } = lead;
  if (!timezone) {
    return new Promise((accept) => {
      const { destroy } = modal(TimeWarningDialog, {
        icon: "rc_timezone_error",
        title: `Lead's time zone information is not found`,
        content: `It may be a late hour for this lead. Click on “Contact anyway” to contact the lead.`,
        onConfirm: () => {
          accept(true);
          destroy();
        },
        onCancel: () => {
          accept(false);
          destroy();
        }
      });
    });
  }
  const now = DateTime.local();
  let time = now.setZone(timezone);

  if (propertyState && isStateInCallableHours(propertyState, settings)) {
    return true;
  }else if(!propertyState && isTimezoneInCallableHours(timezone,settings)){
    return true;
  }

  const primaryBorrower = members.find(({ isPrimary }) => isPrimary);
  return new Promise((accept) => {
    const { destroy } = modal(TimeWarningDialog, {
      date: time,
      name: primaryBorrower.contact.firstName ?? primaryBorrower.contact.objectName,
      onConfirm: () => {
        accept(true);
        destroy();
      },
      onCancel: () => {
        accept(false);
        destroy();
      }
    });
  });
}
