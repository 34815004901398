import React         from "react";
import { FC }        from "react";
import { FormField } from "@relcu/rc";

export const VirtualConfig: FC<any> = React.memo(function StringField() {
  return (
    <FormField
      name="alias"
      label="Alias"
      required
      helperText={"Refers to another field using JSONPath syntax."}
    />
  );
});
