import React                                   from "react";
import { FC }                                  from "react";
import { DateTime }                            from "@relcu/date";
import { Box }                                 from "@relcu/ui";
import { BadgeColors }                         from "@relcu/ui";
import { BadgeVariants }                       from "@relcu/ui";
import { TypographyColor }                     from "@relcu/ui";
import { TypographySize }                      from "@relcu/ui";
import { Typography }                          from "@relcu/ui";
import { timeTo }                              from "@relcu/ui";
import { Badge }                               from "@relcu/ui";
import { NotificationContent }                 from "@relcu/ui";
import { Notification, NotificationIconColor } from "@relcu/ui";
import { NotificationHeader }                  from "@relcu/ui";
import { NotificationSubject }                 from "@relcu/ui";
import { NotificationProps }                   from "../NotificationBar";
import { NotificationIcons }                   from "../NotificationBar";
import { NotificationTitles }                  from "../NotificationBar";

export const EmailNotification: FC<NotificationProps> = React.memo(function EmailNotification(props) {
  const { node: { record, type, createdAt, unread, targetObjectName, attachments } = {}, inApp, indicateStatus, onNavigate, className } = props;
  const fontWeight = unread ? 500 : 400;

  return (
    <Notification onNavigate={onNavigate} className={className} style={{ fontWeight }}>
      <NotificationHeader inApp={inApp} title={NotificationTitles[ type ]} icon={NotificationIcons[ type ]}
                          color={NotificationIconColor.Warning}>
        {
          indicateStatus &&
          <Box container gap={"XXXS"} alignItems={"center"}>
            <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary} noWrap>
              {timeTo(DateTime.fromJSDate(new Date(createdAt)))}
            </Typography>
            {
              unread && <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>
            }
          </Box>
        }
      </NotificationHeader>
      <Box container direction={"column"} gap={inApp ? "XXXS" : "XXS"}>
        <NotificationSubject inApp={inApp} objectName={targetObjectName} subject={record?.subject}/>
        {/*<NotificationContent content={node.html}/>*/}
        {
          !!attachments?.length &&
          <NotificationContent inApp={inApp} attachments={attachments}/>
        }
      </Box>
    </Notification>
  );
});
