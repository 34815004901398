import React                    from "react";
import { FC }                   from "react";
import { Stack }                from "@relcu/rc";
import { DetailsField }         from "../../../../../Generation";
import { FIELD_TYPES }          from "../FieldConfigDialog";
import { FieldTypeRadioButton } from "./FieldTypeRadioButton";

export const TypeSelectionStep: FC<any> = React.memo(function TypeSelectionStep(props) {
  return <DetailsField style={{ flex: 1 }}>
    <DetailsField.Title>Select a field type</DetailsField.Title>
    <Stack spacing={16} childrenRenderMode={"clone"} style={{ alignSelf: "stretch" }} wrap>
      {
        Object.entries(FIELD_TYPES).map((field, index) => {
          return <FieldTypeRadioButton key={index} {...field[ 1 ] as any}/>;
        })
      }
    </Stack>
  </DetailsField>;
});
