import { parsePhoneNumber } from "@relcu/ui";
import { FC }               from "react";
import React                from "react";
import { MaskedInput }      from "@relcu/rc";
import { FormField }        from "@relcu/rc";

export const PhoneConfig: FC<any> = React.memo(function PhoneConfig(props) {
  return (
    <FormField
      component={MaskedInput}
      properties={{
        name: 'US phone number',
        mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        placeholder: '(555) 495-3947'
      }}
      parse={(value) => {
        return (value ? parsePhoneNumber(value) : "");
      }}
      validate={(value) => {
        if (String(value).includes("_")) {
          return `${props.label || "Phone"} is invalid`;
        }
      }}
      name="defaultValue"
      label="Default value"
    />
  );
});
